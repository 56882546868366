import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {CustomSettings} from '@domain/custom-settings/custom-settings';
import {NameGeneratorProperty} from '@domain/custom-settings/name-generation-property';
import {NameGenerator} from '@domain/custom-settings/name-generator';
import {HttpPlasticProductionOrdersCustomSettingsService} from '@infrastructure/http/plastic-production-order/http-plastic-production-orders-custom-settings.service';
import {BaseComponent, TabsMenuItem, ToastService, TranslateService} from '@vdw/angular-component-library';
import {forkJoin, takeUntil} from 'rxjs';

@Component({
  templateUrl: './plastic-production-order-custom-settings.component.html'
})
export class PlasticProductionOrderCustomSettingsComponent extends BaseComponent implements OnInit {
  protected nameGenerationProperties: NameGeneratorProperty[] = [];
  protected generalTab: TabsMenuItem;
  protected selectedTab: TabsMenuItem;
  protected settingsForm = new FormGroup({
    nameGenerator: new FormControl<NameGenerator>(null)
  });

  public constructor(
    private readonly plasticProductionOrderCustomSettings: HttpPlasticProductionOrdersCustomSettingsService,
    private readonly dialogRef: MatDialogRef<PlasticProductionOrderCustomSettingsComponent>,
    private readonly toastService: ToastService,
    private readonly translate: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setTabs();
    this.getCustomSettings();
  }

  protected closeDialog(): void {
    this.dialogRef.close();
  }

  protected save(): void {
    this.plasticProductionOrderCustomSettings
      .save(new CustomSettings(undefined, this.settingsForm.value.nameGenerator))
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => {
        this.closeDialog();
        this.toastService.showInfo({
          tapToDismiss: false,
          timeOut: 2000,
          message: this.translate.instant('GENERAL.ACTIONS.UPDATED_OBJECT_WITHOUT_NAME', {object: 'GENERAL.CUSTOM_SETTINGS.CUSTOM_SETTINGS'})
        });
      });
  }

  private setTabs(): void {
    this.generalTab = {
      value: 'generalTab',
      translationKey: 'GENERAL.GENERAL',
      form: this.settingsForm
    };
    this.selectedTab = this.generalTab;
  }

  private getCustomSettings(): void {
    forkJoin([this.plasticProductionOrderCustomSettings.get(), this.plasticProductionOrderCustomSettings.getNameGenerationProperties()])
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(([customSettings, nameGenerationProperties]: [CustomSettings, NameGeneratorProperty[]]) => {
        this.settingsForm.controls.nameGenerator.patchValue(customSettings.nameGenerator);
        this.nameGenerationProperties = nameGenerationProperties;
      });
  }
}
