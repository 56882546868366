// IMPORTANT: THIS IS A GENERATED FILE AND SHOULD NOT BE EDITED OR CHECKED INTO VERSION CONTROL!
/* eslint-disable */
export const VERSION = {
    "dirty": true,
    "raw": "686f9f6b2e-dirty",
    "hash": "686f9f6b2e",
    "distance": null,
    "tag": null,
    "semver": null,
    "suffix": "686f9f6b2e-dirty",
    "semverString": null,
    "version": "5.38.1"
};
