import {Component} from '@angular/core';
import {TranslateService} from '@vdw/angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {isUndefined, toLower} from 'lodash-es';
import {WeaveQualityType} from '../overview/weave-quality-type.enum';

@Component({
  templateUrl: './overview-weave-quality-type.component.html',
  styleUrl: './overview-weave-quality-type.component.scss'
})
export class OverviewWeaveQualityTypeComponent implements ICellRendererAngularComp {
  private weaveQualityType: WeaveQualityType;
  private readonly translate: TranslateService;

  public constructor(translate: TranslateService) {
    this.translate = translate;
  }

  public agInit(params: any): void {
    if (isUndefined(params.data.qualityType)) {
      this.weaveQualityType = params.data.type;
    } else {
      this.weaveQualityType = params.data.qualityType;
    }
  }

  public getIconNameForWeaveQualityType(): string {
    return `${toLower(String(this.weaveQualityType))}-quality`;
  }

  public getWeaveQualityTypeLabelForTranslate(): string {
    return this.translate.instant(`TEXTILE_DATA.MACHINE_QUALITY.TYPES.${this.weaveQualityType}`);
  }

  public refresh(params: any): boolean {
    return false;
  }
}
