import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {AgGridAngular} from 'ag-grid-angular';
import {GridOptions} from 'ag-grid-community';
import {BaseComponent} from '../../../base-component';
import {AssertionUtils} from '../../../common/utils/assertion-utils';
import {TranslateService} from '../../../translation/translate.service';
import {CrudGridContextService} from '../crud-grid-context-service/crud-grid.context.service';

@Component({
  selector: 'vdw-crud-grid-with-actions',
  templateUrl: './crud-grid-with-actions.component.html',
  styleUrls: ['./crud-grid-with-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrudGridWithActionsComponent extends BaseComponent implements AfterViewInit {
  @Input() public rowData: any[];
  @Input() public entityName: string;
  @Input() public gridOptions: GridOptions;
  @Input() public canShowActions = true;
  @Input() public withNewClicked: (event: MouseEvent) => void;
  @Input() public showCustomSettingsButton: boolean;

  @Output() public showCustomSettings = new EventEmitter<void>();

  @ViewChild('grid') public grid: AgGridAngular;
  @ViewChild('gridContainer') public gridContainer: ElementRef<HTMLDivElement>;

  public constructor(
    private readonly translate: TranslateService,
    private readonly context: CrudGridContextService
  ) {
    super();
  }

  public getNewButtonText(): string {
    const newText = this.translate.instant('ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.NEW');

    return AssertionUtils.isNullOrUndefined(this.entityName) ? newText : `${newText} ${this.translate.instant(this.entityName, {count: 1}).toLowerCase()}`;
  }

  public ngAfterViewInit(): void {
    this.context.grid = this.grid;
    this.context.gridContainer = this.gridContainer;
    this.context.gridReady.emit();
  }

  protected exportData(): void {
    this.context.grid.api.exportDataAsCsv();
  }
}
