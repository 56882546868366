<mat-form-field class="full-width pl-8 pr-8">
  <mat-label>{{ 'GENERAL.PLACEHOLDER.PROPERTY' | bmsTranslate }}</mat-label>
  <mat-select [compareWith]="compareProperties" [formControl]="selectedProperty" class="full-width" panelClass="bms-theme">
    @for (property of nameGeneratorProperties; track property) {
      <mat-option [value]="property">{{ getPropertyTranslation(property) }}</mat-option>
    }
  </mat-select>
  @if (selectedProperty.errors?.serialNumberInMiddle) {
    <mat-error>
      <vdw-ellipsis-label [text]="'GENERAL.PLACEHOLDER.SERIAL_NUMBER_SEQUENCE_ERROR' | bmsTranslate" matTooltipPosition="below" />
    </mat-error>
  }
</mat-form-field>
