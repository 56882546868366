import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  templateUrl: './disabled-row-group-cell-renderer.component.html',
  styleUrls: ['./disabled-row-group-cell-renderer.component.scss']
})
export class DisabledRowGroupCellRendererComponent implements ICellRendererAngularComp {
  public value: number;
  public indentClass: string;
  public parentClass: string;

  public agInit(params: any): void {
    this.value = params.value;
    this.indentClass = `indent-${params.node.level}`;
    this.parentClass = `parent-level-${params.node.level}`;
  }

  public refresh(_: ICellRendererParams): boolean {
    return false;
  }
}
