import {Component, Input, OnChanges} from '@angular/core';
import {INoRowsOverlayAngularComp} from 'ag-grid-angular';
import {GridApi, INoRowsOverlayParams} from 'ag-grid-community';
import {isEmpty, isNil} from 'lodash-es';
import {AssertionUtils} from '../../../common/utils/assertion-utils';
import {TranslateService} from '../../../translation/translate.service';
import {BaseOverlayComponent} from '../base-overlay.component';
import {OverlayActionsService} from '../overlay-actions.service';
import {OverlayComponentParams} from '../overlay-component-params';
import {NoDataOverlayComponentParams} from './no-data-overlay-component-params';

@Component({
  selector: 'vdw-nodata-overlay',
  templateUrl: '../base-overlay.component.html',
  styleUrls: ['../base-overlay.component.scss']
})
export class NoDataOverlayComponent extends BaseOverlayComponent implements INoRowsOverlayAngularComp, OnChanges {
  @Input() public shouldShowFilterNoResult = false;
  @Input() public filterDescriptionParam: any;
  @Input() public machineDisconnectedOverlay = false;
  private hasAgInitBeenCalled = false;

  public constructor(translate: TranslateService, actionsService: OverlayActionsService) {
    super(actionsService, translate);
  }

  public ngOnChanges(): void {
    if (!this.hasAgInitBeenCalled) {
      const params: NoDataOverlayComponentParams = this.createParamsObject() as NoDataOverlayComponentParams;

      params.filterDescriptionParam = this.filterDescriptionParam;
      params.isAnyFilterPresent = (): boolean => this.shouldShowFilterNoResult;

      this.initializeOverlayComponent(params);
      this.initializeNoDataOverlayComponent(params);
    }
  }

  public agInit({api, ...noDataOverlayComponentParams}: INoRowsOverlayParams): void {
    this.initializeOverlayComponent(noDataOverlayComponentParams as NoDataOverlayComponentParams);
    this.initializeNoDataOverlayComponent({
      isAnyFilterPresent: this.getIsAnyFilterPresentFunction(api),
      ...(noDataOverlayComponentParams as NoDataOverlayComponentParams)
    });
    this.hasAgInitBeenCalled = true;
  }

  private initializeNoDataOverlayComponent(params: NoDataOverlayComponentParams): void {
    if (params.isAnyFilterPresent() === true) {
      this.title = this.getTranslation('ANGULAR_COMPONENT_LIBRARY.OVERLAY.FILTER.NO_DATA.TITLE');
      this.description = this.description ?? this.getTranslation('ANGULAR_COMPONENT_LIBRARY.OVERLAY.FILTER.NO_DATA.UPDATE_QUERY');
      this.icon = 'solid-search-off';
    } else {
      this.title = '';
      if (isEmpty(params.titleKey) && !isNil(params.titleParam) && !this.machineDisconnectedOverlay) {
        params.titleKey = 'ANGULAR_COMPONENT_LIBRARY.OVERLAY.NO_DATA.TITLE_WITH_PARAM';
      } else if (this.machineDisconnectedOverlay) {
        params.titleKey = 'ANGULAR_COMPONENT_LIBRARY.OVERLAY.NO_DATA.MACHINE_DISCONNECTED_MESSAGE';
      }

      this.title = this.getTranslation(params.titleKey, params.titleParam);

      if (!params.hideDescription) {
        this.setDescription(params);
      }

      this.setActions(params);
      this.icon = 'solid-edit-document';
    }
  }

  private setDescription(params: OverlayComponentParams): void {
    if (params.descriptionKey) {
      this.description = this.getTranslation(params.descriptionKey);
    } else {
      this.description = this.getTranslation(
        'ANGULAR_COMPONENT_LIBRARY.OVERLAY.NO_DATA.CREATE_OBJECT',
        AssertionUtils.isNullOrUndefined(params.titleParam) ? this.translate.instant('ANGULAR_COMPONENT_LIBRARY.OVERLAY.NO_DATA.DEFAULT_PARAM').toLowerCase() : params.titleParam
      );
    }
  }

  private setActions(params: OverlayComponentParams): void {
    if (params.actions) {
      this.actions = params.actions;
    }
  }

  private getIsAnyFilterPresentFunction(api: GridApi): () => boolean {
    if (isNil(api?.isAnyFilterPresent)) {
      return (): boolean => false;
    } else {
      return (): boolean => !!api.isAnyFilterPresent();
    }
  }
}
