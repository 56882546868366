<div class="bms-theme flex-column full-height p-24">
  <div class="flex-row flex-start-center">
    <button mat-icon-button class="back" (click)="dialogRef.close()">
      <mat-icon svgIcon="back"></mat-icon>
    </button>
    <h2>{{ 'GENERAL.PLACEHOLDER.PATTERN_BUILDER' | bmsTranslate }}</h2>
  </div>
  <vdw-tabs-and-actions-header [menuItems]="[generalMenuTab]" [selectedMenuItem]="generalMenuTab">
    <button mat-button (click)="dialogRef.close()">{{ 'GENERAL.ACTIONS.CANCEL' | bmsTranslate }}</button>
    <button mat-flat-button (click)="confirmPattern()">{{ 'GENERAL.ACTIONS.SAVE' | bmsTranslate }} {{ 'GENERAL.PATTERN.PATTERN' | bmsTranslate: {count: 1} | lowercase }}</button>
  </vdw-tabs-and-actions-header>

  <form class="bms-theme p-48 gap-16 flex-column flex-grow min-height-0 b-neutral-950">
    <div class="mt-8 mb-8 flex-row flex-start-start gap-12">
      <mat-form-field>
        <mat-label>{{ 'GENERAL.PLACEHOLDER.SEPARATOR.SEPARATOR' | bmsTranslate }}</mat-label>
        <mat-select [formControl]="nameGeneratorForm.controls.separator" panelClass="bms-theme">
          @for (separator of separatorValues; track separator) {
            <mat-option [value]="separatorEnum[separator]">{{ 'GENERAL.PLACEHOLDER.SEPARATOR.' + separator | bmsTranslate }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      @if (showPatternPreview()) {
        <div class="mock-mat-form-field flex-column flex-start-start">
          <mat-label>{{ 'GENERAL.PREVIEW' | bmsTranslate }}</mat-label>
          <vdw-input-chip class="large-input-chip" [value]="patternPreview()" [error]="false" [canShowBMSTheme]="true" />
        </div>
      }
    </div>
    <div class="flex-grow min-height-0 flex-column flex-start-end gap-8">
      <div class="min-height-0 full-width">
        <ag-grid-angular class="ag-theme-vdw ag-grid-form-view ag-auto-grow-grid-height ag-full-height-overlay" [gridOptions]="patternGridOptions" />
      </div>
      <button mat-button (click)="addEmptyPartForm()">+ {{ 'GENERAL.ACTIONS.ADD_OBJECT' | bmsTranslate: {object: 'GENERAL.PLACEHOLDER.RULE' | bmsTranslate: {count: 1} | lowercase} }}</button>
    </div>
  </form>
</div>
