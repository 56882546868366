import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorHandlers} from '@application/helper/error-handlers';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {NavigationHistoryService} from '@application/navigation-history/navigation-history.service';
import {Editable} from '@domain/editable';
import {Drawing} from '@domain/production-schedule/drawing';
import {FinishingForProductionSchedule} from '@domain/production-schedule/finishing-for-production-schedule';
import {ProductionSchedule} from '@domain/production-schedule/production-schedule';
import {ProductionScheduleItemInPathGroup} from '@domain/production-schedule/production-schedule-item-in-path-group';
import {ProductionScheduleItemInPathGroupStatus} from '@domain/production-schedule/production-schedule-item-in-path-group-status';
import {ProductionScheduleOrderLine} from '@domain/production-schedule/production-schedule-order-line';
import {ProductionSchedulePath} from '@domain/production-schedule/production-schedule-path';
import {ProductionSchedulePathsOfColoredYarnSet} from '@domain/production-schedule/production-schedule-paths-of-colored-yarn-set';
import {ProductionSchedulePhase} from '@domain/production-schedule/production-schedule-phase.enum';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {PropertyValue} from '@domain/property-value';
import {PathWidth} from '@domain/textile-data/machine-quality/path-width';
import {Authentication, AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {FINISHINGS, Finishings} from '@infrastructure/http/finishing/finishings';
import {MACHINE_QUALITIES, MachineQualities} from '@infrastructure/http/machine-quality/machine-qualities';
import {PRODUCTION_ORDERS, ProductionOrders} from '@infrastructure/http/production-order/production-orders';
import {PRODUCTION_SCHEDULES, ProductionSchedules} from '@infrastructure/http/production-schedule/production-schedules';
import {ListDrawing} from '@presentation/components/drawing-list/list-drawing';
import {ListDrawingConfiguration} from '@presentation/components/drawing-list/list-drawing-configuration';
import {AddProductionSchedule} from '@presentation/pages/texfab/production-schedule/add/add-production-schedule';
import {ADD_PRODUCTION_SCHEDULE} from '@presentation/pages/texfab/production-schedule/add/add-production-schedule.service';
import {ProductionScheduleInitialPreparationsComponent} from '@presentation/pages/texfab/production-schedule/add/initial-preparations/production-schedule-initial-preparations.component';
import {PRODUCTION_SCHEDULE_PLAN, ProductionSchedulePlan} from '@presentation/pages/texfab/production-schedule/add/plan/production-schedule-plan';
import {ProductionSchedulePlanComponent} from '@presentation/pages/texfab/production-schedule/add/plan/production-schedule-plan.component';
import {ConfirmProductionScheduleOnDesktopCommand} from '@presentation/pages/texfab/production-schedule/confirm/confirm-production-schedule-on-desktop-command';
import {ConfirmProductionScheduleOnDesktopResult} from '@presentation/pages/texfab/production-schedule/confirm/confirm-production-schedule-on-desktop-result';
import {OverviewListFinishing} from '@presentation/pages/textile-data/finishing/overview/overview-list-finishing';
import {AssertionUtils, BidirectionalMap, convertUnit, ObjectUtils, skeletonViewAnimation, ToastService, TranslateService, UnhandledBackendError, Unit} from '@vdw/angular-component-library';
import {cloneDeep, min, noop} from 'lodash-es';
import {forkJoin, Observable} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {
  AddProductionScheduleNavigationData,
  ProductionScheduleData,
  ProductionScheduleNameData,
  ProductionScheduleNavigationData,
  ProductionSchedulePhaseData
} from './add-production-schedule-navigation-data.type';
import {BaseAddProductionSchedulePageComponent} from './base-add-production-schedule-page.component';
import {MenuItemsCanShowProperties} from './menu-items-new/menu-items-can-show-properties.interface';
import {MenuItemsLoadingProperties} from './menu-items-new/menu-items-loading-properties.interface';
import {ProductionScheduleClickEvent} from './menu-items-new/production-schedule-click-event.enum';

@Component({
  templateUrl: './add-production-schedule-page.component.html',
  styleUrls: ['./add-production-schedule-page.component.scss'],
  animations: [skeletonViewAnimation('.form-field-skeleton-wrapper, .button-skeleton-wrapper, app-production-schedule-progress')]
})
export class AddProductionSchedulePageComponent extends BaseAddProductionSchedulePageComponent implements OnInit, Editable {
  private static readonly PRODUCTION_SCHEDULE_OVERVIEW_URL = RouteUtils.paths.texFab.productionOrder.absolutePath;
  private static readonly EDIT_PRODUCTION_SCHEDULE_URL: string = RouteUtils.paths.texFab.productionOrder.editProductionOrder.path;
  private static readonly DUPLICATE_PRODUCTION_SCHEDULE_URL: string = RouteUtils.paths.texFab.productionOrder.duplicateProductionOrder.path;
  @ViewChild('initialPreparations') public initialPreparationsComponent: ProductionScheduleInitialPreparationsComponent;
  @ViewChild('productionSchedulePlan') public productionSchedulePlanComponent: ProductionSchedulePlanComponent;
  public listOfParametersForDefaultParameters: PropertyValue[] = [];
  public productionScheduleToAdd: ProductionSchedule;
  public lastProductionSchedulePhase: ProductionSchedulePhase = ProductionSchedulePhase.CONFIGURE;
  public currentProductionSchedulePhase: ProductionSchedulePhase;
  public isVerifyingFromConfigurePhase = false;
  public factorForConversionFromMillimetersToPicks: number;
  public factorForConversionFromMillimetersToDents: number;
  public pathWidths: PathWidth[];
  public isRunningInitialPreparations = false;
  public isRunningInitialPreparationsToDesigns = false;
  public verifyingProductionSchedule = false;
  public isLoadingProductionSchedule = true;
  public completingProductionSchedule = false;
  public decreatingProductionOrder = false;
  public preselectedOrderlineIds = [];
  public headerVisible = true;

  private alreadySetUpDefaultParameters: boolean;
  private showSkeletonViewForToolbar = false;
  private nextButtonTouched = false;
  private currentSubscription: Subscription;
  private confirmingProductionSchedule = false;
  private initialRestZoneLoaded = false;
  private initialFreeZoneLoaded = false;
  private initialPathZoneLoaded = false;
  private initialJuteZoneLoaded = false;
  private readonly PRODUCTION_ORDER_TRANSLATION_KEY = 'PRODUCTION_ORDER.PRODUCTION_ORDER';

  public constructor(
    @Inject(PRODUCTION_SCHEDULES) private readonly productionSchedules: ProductionSchedules,
    @Inject(PRODUCTION_ORDERS) private readonly productionOrders: ProductionOrders,
    @Inject(MACHINE_QUALITIES) private readonly machineQualities: MachineQualities,
    @Inject(PRODUCTION_SCHEDULE_PLAN) private readonly productionSchedulePlan: ProductionSchedulePlan,
    @Inject(ADD_PRODUCTION_SCHEDULE) private readonly addProductionSchedule: AddProductionSchedule,
    @Inject(FINISHINGS) private readonly finishings: Finishings,
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    private readonly navigationHistory: NavigationHistoryService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly confirmProductionScheduleOnDesktopCommand: ConfirmProductionScheduleOnDesktopCommand,
    private readonly navigationHelperService: NavigationHelperService<ProductionScheduleNavigationData>,
    private readonly formBuilder: FormBuilder,
    private readonly toastService: ToastService,
    activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  public ngOnInit(): void {
    this.showSkeletonViewForToolbar = this.isCurrentRouteEditProductionScheduleRoute() || this.isCurrentRouteDuplicateProductionScheduleRoute();
    this.currentSubscription = this.authentication.getCurrentSubscription();

    this.setProductionScheduleInitialPreparationsFormFields();

    this.initialiseAddProductionSchedule();
  }

  public onNavigationHelperDestroy(): void {
    const state = {productionSchedulePhase: this.currentProductionSchedulePhase};

    const productionSchedule = this.initialPreparationsComponent?.getConfiguredProductionSchedule();
    if (this.currentProductionSchedulePhase === ProductionSchedulePhase.CONFIGURE) {
      state['productionSchedule'] = productionSchedule;
    }

    const productionOrderName = this.productionScheduleInitialPreparationsForm?.value?.name;
    if (!AssertionUtils.isNullOrUndefined(productionOrderName) && productionOrderName !== '' && productionSchedule?.name !== productionOrderName) {
      state['name'] = productionOrderName;
    }

    this.navigationHelperService.savePartialState<Partial<AddProductionScheduleNavigationData>>(state);
  }

  public canShowInitialPreparationsForProductionSchedule(): boolean {
    return this.currentProductionSchedulePhase === ProductionSchedulePhase.CONFIGURE && this.listOfParametersForDefaultParameters.length > 0 && this.listOfMachineQualityCustomSettings.length > 0;
  }

  public canShowDesignsForProductionSchedule(): boolean {
    return this.currentProductionSchedulePhase === ProductionSchedulePhase.PRESELECTION;
  }

  public selectedDrawingsChanged(listDrawings: ListDrawing[]): void {
    this.productionScheduleToAdd.designs = [];

    listDrawings.forEach((listDrawing: ListDrawing) => {
      if (listDrawing instanceof ListDrawingConfiguration) {
        this.productionScheduleToAdd.designs.push(listDrawing.drawing);
      }
    });

    this.initialPreparationsChanged();
    this.addProductionSchedule.notifyChangesToInitialPreparationsForm(true);
  }

  public selectedOrderlinesChanged(orderlineIds: number[]): void {
    this.preselectedOrderlineIds = orderlineIds;
  }

  public restZoneLoaded(): void {
    this.initialRestZoneLoaded = true;
  }

  public freeZoneLoaded(): void {
    this.initialFreeZoneLoaded = true;
  }

  public pathZoneLoaded(): void {
    this.initialPathZoneLoaded = true;
  }

  public juteZoneLoaded(): void {
    this.initialJuteZoneLoaded = true;
  }

  public setHeaderVisibility(hideHeader: boolean): void {
    this.headerVisible = !hideHeader;
  }

  public productionScheduleInitialised(productionSchedule: ProductionSchedule): void {
    const navigationState: {completingPartially?: boolean; phase?: ProductionSchedulePhase} = this.navigationHistory.getState();

    const completingPartially = !!navigationState?.completingPartially;
    if (!AssertionUtils.isNullOrUndefined(productionSchedule.finishing)) {
      this.finishings.getOverviewListFinishingById(productionSchedule.finishing.id).subscribe((overviewListFinishing: OverviewListFinishing) => {
        productionSchedule.updateFinishing(FinishingForProductionSchedule.fromOverviewListFinishing(overviewListFinishing));
      });
    }

    this.showSkeletonViewForToolbar = false;
    this.productionScheduleToAdd = productionSchedule;
    this.productionScheduleToAdd.completingPartially = completingPartially;

    this.isLoadingProductionSchedule = false;

    const emptyProductionSchedulePhaseState = {productionSchedulePhase: null} as ProductionSchedulePhaseData;
    const productionSchedulePhaseState = this.navigationHelperService.getPartialState<ProductionSchedulePhaseData>(Object.keys(emptyProductionSchedulePhaseState));

    if (!AssertionUtils.isNullOrUndefined(productionSchedulePhaseState) && !ObjectUtils.isDeepEqual(emptyProductionSchedulePhaseState, productionSchedulePhaseState)) {
      this.currentProductionSchedulePhase = productionSchedulePhaseState.productionSchedulePhase;
    } else if (navigationState.phase) {
      this.currentProductionSchedulePhase = navigationState.phase;
    } else if (this.isCurrentRouteDuplicateProductionScheduleRoute()) {
      this.currentProductionSchedulePhase = ProductionSchedulePhase.CONFIGURE;
      this.productionScheduleToAdd.status = ProductionScheduleStatus.DRAFT;
    } else {
      if (!this.productionScheduleToAdd.isDraft()) {
        this.currentProductionSchedulePhase = ProductionSchedulePhase.VERIFY;
      } else if (!this.productionScheduleToAdd.hasBeenConfigured()) {
        this.currentProductionSchedulePhase = ProductionSchedulePhase.CONFIGURE;
      } else if (
        this.productionScheduleToAdd.hasAtLeastOneProductionScheduleItemPerColoredYarnSet() ||
        this.productionScheduleToAdd.hasAddedDesigns() ||
        this.productionScheduleToAdd.hasOrderLines()
      ) {
        this.currentProductionSchedulePhase = ProductionSchedulePhase.BUILD;
      } else {
        this.currentProductionSchedulePhase = ProductionSchedulePhase.PRESELECTION;
      }
    }

    this.updateProducedAmount();
    this.updateProductionScheduleItemInPathGroupStatus();
    this.setProductionScheduleInitialPreparationsFormFieldsValues(this.productionScheduleToAdd);
  }

  public initialPreparationsChanged(): void {
    const productionSchedulePhaseOrderMap = new BidirectionalMap<ProductionSchedulePhase, number>([
      [ProductionSchedulePhase.CONFIGURE, 0],
      [ProductionSchedulePhase.PRESELECTION, 1],
      [ProductionSchedulePhase.BUILD, 2],
      [ProductionSchedulePhase.VERIFY, 3],
      [ProductionSchedulePhase.COMPLETE, 4]
    ]);

    this.lastProductionSchedulePhase = productionSchedulePhaseOrderMap.getKey(
      min([productionSchedulePhaseOrderMap.getValue(this.lastProductionSchedulePhase), productionSchedulePhaseOrderMap.getValue(ProductionSchedulePhase.BUILD)])
    );
  }

  public canShowProductionSchedulePlan(): boolean {
    return (
      !AssertionUtils.isNullOrUndefined(this.productionScheduleToAdd) &&
      !this.isLoadingProductionSchedule &&
      this.currentProductionSchedulePhase !== ProductionSchedulePhase.CONFIGURE &&
      this.currentProductionSchedulePhase !== ProductionSchedulePhase.PRESELECTION
    );
  }

  public setProductionSchedulePhase(phase: ProductionSchedulePhase): void {
    this.nextButtonTouched = false;
    this.currentProductionSchedulePhase = phase;
    if (this.lastProductionSchedulePhase < phase) {
      this.lastProductionSchedulePhase = phase;
    }
  }

  public productionSchedulePlanChanged(): void {
    this.lastProductionSchedulePhase = ProductionSchedulePhase.BUILD;
  }

  public productionScheduleVerified({confirmImmediately, error}: {confirmImmediately: boolean; error: boolean}): void {
    this.verifyingProductionSchedule = false;
    if (!error) {
      this.setProductionSchedulePhase(ProductionSchedulePhase.VERIFY);

      if (confirmImmediately) {
        this.nextButtonTouched = true;

        if (this.productionScheduleToAdd.isProductionScheduleVerifiedWithNoErrors() && !this.productionScheduleToAdd.hasProductionScheduleErrors()) {
          this.confirmProductionSchedule();
        }
      }
    }
  }

  public navigateToOverview(): void {
    this.router.navigateByUrl(AddProductionSchedulePageComponent.PRODUCTION_SCHEDULE_OVERVIEW_URL);
  }

  public navigateBack(): void {
    if (this.productionScheduleToAdd?.canBeModified()) {
      switch (this.currentProductionSchedulePhase) {
        case ProductionSchedulePhase.CONFIGURE:
          this.navigateToPreviousRoute();
          break;
        case ProductionSchedulePhase.PRESELECTION:
          this.setProductionSchedulePhase(ProductionSchedulePhase.CONFIGURE);
          break;
        case ProductionSchedulePhase.BUILD:
        case ProductionSchedulePhase.VERIFY:
          this.productionSchedulePlanComponent.back();
          break;
      }
    } else {
      this.navigateToPreviousRoute();
    }
  }

  public canShowToDesignsButton(): boolean {
    return this.currentProductionSchedulePhase === ProductionSchedulePhase.CONFIGURE;
  }

  public canShowVerifyAndConfirmButton(): boolean {
    return this.currentProductionSchedulePhase === ProductionSchedulePhase.BUILD;
  }

  public verifyAndConfirm(): void {
    this.nextButtonTouched = true;

    if (this.productionSchedulePlan.canVerify()) {
      this.verifyingProductionSchedule = true;
      this.productionSchedulePlanComponent.verifyProductionSchedule(true);
    }
  }

  public getMenuItemsLoadingProperties(): MenuItemsLoadingProperties {
    return {
      isLoading: this.isLoadingProductionSchedule,
      preselectionLoading: this.isRunningInitialPreparationsToDesigns,
      nextPhaseLoading: this.isNextPhaseLoading(),
      completeLoading: this.completingProductionSchedule,
      decreateLoading: this.decreatingProductionOrder,
      initialRestZoneLoaded: this.initialRestZoneLoaded,
      initialFreeZoneLoaded: this.initialFreeZoneLoaded,
      initialPathZoneLoaded: this.initialPathZoneLoaded,
      initialJuteZoneLoaded: this.initialJuteZoneLoaded
    };
  }

  public getMenuItemsCanShowProperties(): MenuItemsCanShowProperties {
    return {
      canShowInvalidFormMessageError: this.canShowInvalidFormMessageError(),
      canShowNavigateToNextPhaseButton: this.canShowProductionScheduleNavigationForPhases(),
      canShowCompleteButton: this.productionScheduleToAdd?.completingPartially,
      canShowDecreatedButton: this.canBeDecreated(),
      canShowCancelButton: false
    };
  }

  public confirmProductionSchedule(): void {
    if (!this.confirmingProductionSchedule) {
      this.confirmingProductionSchedule = true;

      this.confirmProductionScheduleOnDesktopCommand
        .execute(this.productionScheduleToAdd, this.listOfParametersForDefaultParameters)
        .pipe(finalize(() => (this.confirmingProductionSchedule = false)))
        .subscribe((confirmProductionScheduleOnDesktopResult: ConfirmProductionScheduleOnDesktopResult) => {
          if (confirmProductionScheduleOnDesktopResult === ConfirmProductionScheduleOnDesktopResult.CONFIRMED) {
            this.navigateToPreviousRoute();
          } else if (confirmProductionScheduleOnDesktopResult === ConfirmProductionScheduleOnDesktopResult.PLAN_ADJUSTMENT_NEEDED) {
            this.productionScheduleToAdd.status = ProductionScheduleStatus.DRAFT;
            this.productionSchedules.updateWithMachineQualityChanged(this.productionScheduleToAdd).subscribe(noop);
            if (this.currentProductionSchedulePhase === ProductionSchedulePhase.VERIFY) {
              this.navigateBack();
            }
          }
        });
    }
  }

  public canShowSkeletonViewForToolbar(): boolean {
    return this.showSkeletonViewForToolbar;
  }

  public canConfirmProductionSchedule(): boolean {
    return this.productionScheduleToAdd.isProductionScheduleVerifiedWithNoErrors() && !this.productionScheduleToAdd.hasProductionScheduleErrors();
  }

  public isDirty(): boolean {
    return this.addProductionSchedule.hasUnsavedFormChanges();
  }

  public notifyChangesToForm(isDirty: boolean): void {
    this.addProductionSchedule.notifyChangesToInitialPreparationsForm(isDirty);
  }

  public canShowProductionScheduleNavigationForPhases(): boolean {
    return this.canShowSkeletonViewForToolbar() || this.productionScheduleToAdd?.canBeModified();
  }

  public canShowInvalidFormMessageError(): boolean {
    const canShowInvalidFormMessageError = this.nextButtonTouched && !this.canNavigateToNextPhase();

    if (this.nextButtonTouched && !canShowInvalidFormMessageError) {
      this.nextButtonTouched = false;
    }

    return canShowInvalidFormMessageError;
  }

  public getInvalidFormMessageError(): string {
    switch (this.currentProductionSchedulePhase) {
      case ProductionSchedulePhase.VERIFY:
      case ProductionSchedulePhase.CONFIGURE:
        return 'GENERAL.ERRORS.CHECK_INVALID_FIELDS';
      case ProductionSchedulePhase.PRESELECTION:
        return this.translate.instant('GENERAL.ERRORS.NO_OBJECT_CHOSEN', {object: this.translate.instant('DESIGN_LIBRARY.DESIGN', {count: 2}).toLowerCase()});
      case ProductionSchedulePhase.BUILD:
        return this.productionSchedulePlan.hasUnsavedChanges(false)
          ? 'PRODUCTION_ORDER.ERRORS.CHANGES_IN_PROGRESS'
          : this.translate.instant('GENERAL.ERRORS.NO_OBJECT_ADDED', {object: this.translate.instant('DESIGN_LIBRARY.DESIGN', {count: 2}).toLowerCase()});
    }
  }

  public navigateToNextPhase(): void {
    this.nextButtonTouched = true;

    let formsAreValid = true;
    if (this.currentProductionSchedulePhase === ProductionSchedulePhase.CONFIGURE) {
      const zonesLoaded = this.initialRestZoneLoaded && this.initialFreeZoneLoaded && this.initialJuteZoneLoaded && this.initialPathZoneLoaded;
      formsAreValid = this.initialPreparationsComponent.validateForms() && zonesLoaded;
    }

    if (this.canNavigateToNextPhase() && formsAreValid) {
      switch (this.currentProductionSchedulePhase) {
        case ProductionSchedulePhase.CONFIGURE:
          this.productionScheduleToAdd = this.initialPreparationsComponent.getProductionSchedule();
          this.isRunningInitialPreparations = true;
          this.isCreatingNewProductionSchedule()
            ? this.createProductionSchedule(ProductionSchedulePhase.BUILD, this.qualityHasChanged())
            : this.initialPreparationsCompleted(ProductionSchedulePhase.BUILD, this.qualityHasChanged());
          break;
        case ProductionSchedulePhase.PRESELECTION:
          this.isRunningInitialPreparations = true;
          this.initialPreparationsCompleted(ProductionSchedulePhase.BUILD);
          break;
        case ProductionSchedulePhase.BUILD:
          this.productionSchedulePlanComponent.verifyProductionSchedule();
          break;
        case ProductionSchedulePhase.VERIFY:
          this.confirmProductionSchedule();
      }
    }

    this.addProductionSchedule.notifyChangesToInitialPreparationsForm(false);
  }

  public isNextPhaseLoading(): boolean {
    return this.productionSchedulePlanComponent?.isVerifyingProductionSchedule || this.verifyingProductionSchedule || this.isRunningInitialPreparations || this.confirmingProductionSchedule;
  }

  public navigateToDesigns(): void {
    this.nextButtonTouched = true;

    const zonesLoaded = this.initialRestZoneLoaded && this.initialFreeZoneLoaded && this.initialJuteZoneLoaded && this.initialPathZoneLoaded;
    const formsAreValid = this.initialPreparationsComponent.validateForms() && zonesLoaded;

    if (this.canNavigateToNextPhase() && formsAreValid) {
      this.productionScheduleToAdd = this.initialPreparationsComponent.getProductionSchedule();
      this.isRunningInitialPreparationsToDesigns = true;

      this.isCreatingNewProductionSchedule()
        ? this.createProductionSchedule(ProductionSchedulePhase.PRESELECTION, this.qualityHasChanged())
        : this.initialPreparationsCompleted(ProductionSchedulePhase.PRESELECTION, this.qualityHasChanged());
    }

    this.addProductionSchedule.notifyChangesToInitialPreparationsForm(false);
  }

  public completeProductionSchedule(): void {
    this.completingProductionSchedule = true;
    this.productionOrders
      .completeProductionOrder(Number(this.productionScheduleToAdd.id), ProductionScheduleStatus.EXECUTED_PARTIAL)
      .pipe(
        finalize(() => (this.completingProductionSchedule = false)),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe(() => {
        this.navigateToOverview();
      });
  }

  public decreateProductionOrder(): void {
    this.decreatingProductionOrder = true;

    const decreatedProductionOrder = cloneDeep(this.productionScheduleToAdd);
    decreatedProductionOrder.id = `${decreatedProductionOrder.id}`;
    decreatedProductionOrder.status = ProductionScheduleStatus.DRAFT;
    decreatedProductionOrder.removePathLabelsAndNonProductionItemsFromProductionSchedulePathsOfColoredYarnSets();

    this.productionSchedules
      .decreateProductionOrder(decreatedProductionOrder)
      .pipe(
        finalize(() => (this.decreatingProductionOrder = false)),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe(() => {
        this.productionScheduleToAdd = decreatedProductionOrder;
        this.currentProductionSchedulePhase = ProductionSchedulePhase.CONFIGURE;
      });
  }

  public canBeDecreated(): boolean {
    return (
      this.productionScheduleToAdd?.canBeDecreated() ||
      [ProductionScheduleStatus.ARRIVED, ProductionScheduleStatus.SENDING, ProductionScheduleStatus.QUEUING, ProductionScheduleStatus.QUEUED].includes(this.productionScheduleToAdd?.status)
    );
  }

  public onClickEvent(type: ProductionScheduleClickEvent): void {
    switch (type) {
      case ProductionScheduleClickEvent.PRESELECTION:
        this.navigateToDesigns();
        break;
      case ProductionScheduleClickEvent.NEXT_PHASE:
        this.navigateToNextPhase();
        break;
      case ProductionScheduleClickEvent.VERIFY_AND_CONFIRM:
        this.verifyAndConfirm();
        break;
      case ProductionScheduleClickEvent.COMPLETE:
        this.completeProductionSchedule();
        break;
      case ProductionScheduleClickEvent.DECREATE:
        this.decreateProductionOrder();
        break;
    }
  }

  public isCreatingNewProductionSchedule(): boolean {
    return !this.isCurrentRouteEditProductionScheduleRoute() && !this.isCurrentRouteDuplicateProductionScheduleRoute();
  }

  public getHeaderIcon(): string {
    return this.isEditingProductionOrder() ? 'details-blue' : 'add-blue';
  }

  public getActionText(): string {
    const title = this.isCurrentRouteEditProductionScheduleRoute() ? 'GENERAL.ACTIONS.EDIT_OBJECT' : 'GENERAL.ACTIONS.CREATE_OBJECT';
    return this.translate.instant(title, {object: this.translate.instant(this.PRODUCTION_ORDER_TRANSLATION_KEY, {count: 1}).toLowerCase()});
  }

  private qualityHasChanged(): boolean {
    const oldQualityId = this.initialPreparationsComponent.oldMachineQuality?.id;
    return !AssertionUtils.isNullOrUndefined(oldQualityId) && oldQualityId !== this.productionScheduleToAdd.machineQuality.id;
  }

  private canNavigateToNextPhase(): boolean {
    switch (this.currentProductionSchedulePhase) {
      case ProductionSchedulePhase.CONFIGURE:
        return this.initialPreparationsComponent?.getConfiguredProductionSchedule()?.hasBeenConfigured();
      case ProductionSchedulePhase.PRESELECTION:
        return true;
      case ProductionSchedulePhase.BUILD:
        return this.productionSchedulePlan.canVerify();
      case ProductionSchedulePhase.VERIFY:
        return !this.productionSchedulePlanComponent?.isVerifyingProductionSchedule && this.canConfirmProductionSchedule();
      default:
        return false;
    }
  }

  private createProductionSchedule(nextPhase: ProductionSchedulePhase, isMachineQualityChanged: boolean = false): void {
    const practicalPickDensityUnit: Unit = Unit[this.getDefaultParameterForPropertyNameOfMachineQualitySettings('pickDensityUnit').propertyValue as string];
    const practicalPickDensityInPicksInMM = convertUnit({
      from: {
        value: this.productionScheduleInitialPreparationsForm.get('practicalPickDensity').value,
        unit: practicalPickDensityUnit
      },
      to: Unit.PICKS_PER_MILLIMETER
    });

    this.productionSchedules
      .createProductionSchedule(
        this.productionScheduleToAdd.machine,
        this.productionScheduleToAdd.machineQuality,
        practicalPickDensityInPicksInMM,
        this.productionScheduleToAdd.coloredYarnSets,
        this.productionScheduleToAdd.creel,
        this.productionScheduleToAdd.runId,
        this.productionScheduleToAdd.mappingForCurrentPositionOnMachine,
        this.productionScheduleInitialPreparationsForm.value.productConfiguration,
        this.productionScheduleToAdd.isSample,
        this.productionScheduleToAdd.canAddJute,
        this.productionScheduleToAdd.hmiVersion,
        this.productionScheduleToAdd.addToPlanboard,
        null
      )
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((productionSchedule: ProductionSchedule) => {
        this.productionScheduleToAdd.id = productionSchedule.id;
        this.productionScheduleToAdd.coloredYarnSets = productionSchedule.coloredYarnSets;
        this.productionScheduleToAdd.dateCreated = new Date();

        if (this.productionScheduleToAdd.productionSchedulePathsOfColoredYarnSets.length > 0) {
          this.initialiseProductionSchedulePathsOfColoredYarnSets(productionSchedule);
        }

        this.initialPreparationsCompleted(nextPhase, isMachineQualityChanged);
      });
  }

  private navigateToEditProductionSchedule(id: string, phase: ProductionSchedulePhase): void {
    let path = RouteUtils.paths.texFab.productionOrder.editProductionOrder.absolutePath.replace(':id', id);
    if (this.hasPermissionToNavigateToNewBuilder()) {
      path = path + '/new';
    }

    this.navigationHistory.replaceState(path, null, {state: {phase}});
  }

  private hasPermissionToNavigateToNewBuilder(): boolean {
    return this.currentSubscription?.hasPermission(Permission.TEXFAB_NEW_BUILDER);
  }

  private initialiseProductionSchedulePathsOfColoredYarnSets(productionSchedule: ProductionSchedule): void {
    this.productionSchedules
      .setProductionSchedulePlan(`${this.productionScheduleToAdd.id}`, this.productionScheduleToAdd.productionSchedulePathsOfColoredYarnSets, productionSchedule.machineQuality.reedDensity * 1000)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe();
  }

  private initialPreparationsCompleted(nextPhase: ProductionSchedulePhase, isMachineQualityChanged: boolean = false): void {
    forkJoin([
      this.machineQualities.getFactorForConversionFromMillimetersToPicks(this.productionScheduleToAdd.machineQuality.id),
      this.machineQualities.getFactorForConversionFromMillimetersToDents(this.productionScheduleToAdd.machineQuality.id),
      this.machineQualities.getPathWidths(this.productionScheduleToAdd.machineQuality.id),
      this.productionSchedules.updateWithMachineQualityChanged(this.productionScheduleToAdd, isMachineQualityChanged)
    ])
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: ([factorForConversionFromMillimetersToPicks, factorForConversionFromMillimetersToDents, pathWidths]: [number, number, PathWidth[], void]) => {
          this.factorForConversionFromMillimetersToPicks = factorForConversionFromMillimetersToPicks;
          this.factorForConversionFromMillimetersToDents = factorForConversionFromMillimetersToDents;
          this.pathWidths = pathWidths;

          const requests: Observable<ProductionSchedule | void>[] = [this.productionSchedules.getById(this.productionScheduleToAdd.id)];

          if (nextPhase === ProductionSchedulePhase.BUILD) {
            requests.push(
              this.productionSchedules.saveDesignsAndOrderLines(
                this.productionScheduleToAdd.id,
                this.productionScheduleToAdd.designs,
                this.productionScheduleToAdd.orderLines,
                this.productionScheduleToAdd.name
              )
            );
          }

          forkJoin(requests)
            .pipe(takeUntil(this.unSubscribeOnViewDestroy))
            .subscribe({
              next: ([productionSchedule]: [ProductionSchedule]) => {
                this.productionScheduleToAdd.warnings = productionSchedule.warnings;
                this.productionScheduleToAdd.errors = productionSchedule.errors;
                this.productionScheduleToAdd.coloredYarnSets = productionSchedule.coloredYarnSets;
                if (productionSchedule.coloredYarnSets.length === productionSchedule.productionSchedulePathsOfColoredYarnSets.length) {
                  this.productionScheduleToAdd.productionSchedulePathsOfColoredYarnSets = productionSchedule.productionSchedulePathsOfColoredYarnSets;
                }

                if (nextPhase !== ProductionSchedulePhase.VERIFY) {
                  this.productionScheduleToAdd.removePathLabelsAndNonProductionItemsFromProductionSchedulePathsOfColoredYarnSets();
                }

                if (!this.isEditingProductionOrder()) {
                  this.addProductionSchedule.notifyChangesToInitialPreparationsForm(false);

                  this.toastService.showInfo({
                    tapToDismiss: false,
                    timeOut: 2000,
                    message: this.translate.instant('GENERAL.ACTIONS.CREATED_OBJECT', {
                      object: this.translate.instant('PRODUCTION_ORDER.PRODUCTION_ORDER', {count: 1}),
                      name: '',
                      count: 1
                    })
                  });

                  this.navigateToEditProductionSchedule(`${this.productionScheduleToAdd.id}`, nextPhase);
                }

                this.setProductionSchedulePhase(nextPhase);
                this.isRunningInitialPreparations = false;
                this.isRunningInitialPreparationsToDesigns = false;
              },
              error: this.stopRunningInitialPreparationsAndThrowError()
            });
        },
        error: this.stopRunningInitialPreparationsAndThrowError()
      });
  }

  private initialiseAddProductionSchedule(): void {
    forkJoin([this.machineQualities.getListOfCustomSettings(), this.productionOrders.getListOfCustomSettings(), this.productionOrders.hasAlreadyAdjustedCustomSettings()])
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: ([listOfMachineQualityCustomSettings, listOfProductionOrdersCustomSettings, alreadyAdjustedDefaultParameters]: [PropertyValue[], PropertyValue[], boolean]) => {
          this.listOfMachineQualityCustomSettings = listOfMachineQualityCustomSettings;

          this.customSettingsForProductionScheduleChosen(null, listOfProductionOrdersCustomSettings);

          this.alreadySetUpDefaultParameters = alreadyAdjustedDefaultParameters;

          const emptyProductionScheduleState = {productionSchedule: null} as ProductionScheduleData;
          const productionScheduleState = this.navigationHelperService.getPartialState<ProductionScheduleData>(Object.keys(emptyProductionScheduleState));
          this.hasProductionScheduleState = !AssertionUtils.isNullOrUndefined(productionScheduleState) && !ObjectUtils.isDeepEqual(emptyProductionScheduleState, productionScheduleState);

          if (this.isCreatingNewProductionSchedule()) {
            this.currentProductionSchedulePhase = ProductionSchedulePhase.CONFIGURE;

            if (this.hasProductionScheduleState) {
              this.productionScheduleToAdd = productionScheduleState.productionSchedule;
              this.setProductionScheduleInitialPreparationsFormFieldsValues(this.productionScheduleToAdd);
            } else {
              this.productionScheduleToAdd = ProductionSchedule.createEmptyProductionSchedule();
            }

            this.isLoadingProductionSchedule = false;
          } else {
            this.initialiseExistingProductionSchedule(this.hasProductionScheduleState ? productionScheduleState.productionSchedule : null);
          }
        },
        error: this.navigateToOverviewAndThrowError()
      });

    this.productionSchedulePlan.currentUpdatedProductionSchedule.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((productionSchedule: ProductionSchedule) => {
      this.productionScheduleToAdd = productionSchedule;

      this.setProductionScheduleInitialPreparationsFormFieldsValues(productionSchedule);
    });
  }

  private setProductionScheduleInitialPreparationsFormFieldsValues(productionSchedule: ProductionSchedule): void {
    const emptyProductionScheduleNameState = {name: null} as ProductionScheduleNameData;
    const productionScheduleNameState = this.navigationHelperService.getPartialState<ProductionScheduleNameData>(Object.keys(emptyProductionScheduleNameState));
    const hasProductionScheduleNameState = !AssertionUtils.isNullOrUndefined(productionScheduleNameState) && !ObjectUtils.isDeepEqual(emptyProductionScheduleNameState, productionScheduleNameState);

    this.productionScheduleInitialPreparationsForm?.patchValue({
      name: hasProductionScheduleNameState ? productionScheduleNameState.name : productionSchedule.name,
      machine: productionSchedule.machine,
      machineQuality: productionSchedule.machineQuality,
      practicalPickDensity: productionSchedule.practicalPickDensityInPicksPerMM,
      productConfiguration: productionSchedule.productConfiguration,
      creel: productionSchedule.creel
    });

    if (hasProductionScheduleNameState) {
      this.productionScheduleInitialPreparationsForm.controls.name.markAsTouched();
    }
  }

  private setProductionScheduleInitialPreparationsFormFields(): void {
    this.productionScheduleInitialPreparationsForm = this.formBuilder.group({
      name: this.formBuilder.control(null, [Validators.required, Validators.maxLength(this.MAX_LENGTH_FOR_PRODUCTION_ORDER_NAME)]),
      machine: this.formBuilder.control(null, Validators.required),
      machineQuality: this.formBuilder.control(null, Validators.required),
      practicalPickDensity: this.formBuilder.control(null, [Validators.required, Validators.min(1)]),
      productConfiguration: this.formBuilder.control(null),
      creel: this.formBuilder.control(null, Validators.required)
    });
  }

  private initialiseExistingProductionSchedule(productionScheduleState: ProductionSchedule): void {
    const isDuplicatingProductionSchedule = this.isCurrentRouteDuplicateProductionScheduleRoute();
    const getProductionScheduleObservable = isDuplicatingProductionSchedule
      ? this.productionSchedules.getDuplicatedProductionOrder(this.activatedRoute.snapshot.params.id)
      : this.productionSchedules.getById(this.activatedRoute.snapshot.params.id);

    this.isLoadingProductionSchedule = true;

    if (productionScheduleState) {
      this.setExistingProductionSchedule(productionScheduleState);
    } else {
      getProductionScheduleObservable.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe({
        next: (productionSchedule: ProductionSchedule) => this.setExistingProductionSchedule(productionSchedule),
        error: this.navigateToOverviewAndThrowError()
      });
    }
  }

  private setExistingProductionSchedule(productionSchedule: ProductionSchedule): void {
    const isDuplicatingProductionSchedule = this.isCurrentRouteDuplicateProductionScheduleRoute();

    forkJoin([
      this.productionSchedules.getDesignsAndOrderLines(isDuplicatingProductionSchedule ? this.activatedRoute.snapshot.params.id : productionSchedule.id, productionSchedule.status),
      this.machineQualities.getFactorForConversionFromMillimetersToPicks(productionSchedule.machineQuality.id),
      this.machineQualities.getFactorForConversionFromMillimetersToDents(productionSchedule.machineQuality.id),
      this.machineQualities.getPathWidths(productionSchedule.machineQuality.id)
    ])
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: ([{designs, orderLines}, factorForConversionFromMillimetersToPicks, factorForConversionFromMillimetersToDents, pathWidths]: [
          {designs: Drawing[]; orderLines: ProductionScheduleOrderLine[]},
          number,
          number,
          PathWidth[]
        ]) => {
          productionSchedule.designs = designs;
          productionSchedule.orderLines = orderLines;

          productionSchedule.updateOrderLineQuantities();
          this.factorForConversionFromMillimetersToPicks = factorForConversionFromMillimetersToPicks;
          this.factorForConversionFromMillimetersToDents = factorForConversionFromMillimetersToDents;
          this.pathWidths = pathWidths;
          this.productionSchedulePlan.applyMeasurementsCalculationsFactor(factorForConversionFromMillimetersToDents);

          if (isDuplicatingProductionSchedule) {
            this.duplicateProductionSchedule(productionSchedule);
          } else {
            this.productionScheduleInitialised(productionSchedule);
          }
        },
        error: this.navigateToOverviewAndThrowError()
      });
  }

  private isCurrentRouteEditProductionScheduleRoute(): boolean {
    return this.activatedRoute.snapshot.routeConfig?.path === AddProductionSchedulePageComponent.EDIT_PRODUCTION_SCHEDULE_URL;
  }

  private isCurrentRouteDuplicateProductionScheduleRoute(): boolean {
    return this.activatedRoute.snapshot.routeConfig.path === AddProductionSchedulePageComponent.DUPLICATE_PRODUCTION_SCHEDULE_URL;
  }

  private customSettingsForProductionScheduleChosen(adjusted: boolean, listOfParametersForDefaultParameters?: PropertyValue[]): void {
    if (!AssertionUtils.isNullOrUndefined(listOfParametersForDefaultParameters)) {
      this.listOfParametersForDefaultParameters = listOfParametersForDefaultParameters;
      this.alreadySetUpDefaultParameters = adjusted;
    } else {
      if (!this.alreadySetUpDefaultParameters) {
        this.router.navigateByUrl(AddProductionSchedulePageComponent.PRODUCTION_SCHEDULE_OVERVIEW_URL);
      }
    }
  }

  public isAddingProductionOrderForProductConfiguration(): boolean {
    return this.activatedRoute.snapshot.routeConfig.path === RouteUtils.paths.texFab.productionOrder.addProductionOrderForProductConfiguration.path;
  }

  private updateProducedAmount(): void {
    if (this.productionScheduleToAdd.completingPartially) {
      this.productionScheduleToAdd.productionSchedulePathsOfColoredYarnSets.forEach((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
        productionSchedulePathsOfColoredYarnSet.productionSchedulePaths.forEach((productionSchedulePath: ProductionSchedulePath) => {
          productionSchedulePath.pathGroups.forEach((pathGroup: ProductionScheduleItemInPathGroup) => {
            pathGroup.amountProduced = pathGroup.quantity;
          });
        });
      });
    }
  }

  private updateProductionScheduleItemInPathGroupStatus(): void {
    if (this.productionScheduleToAdd.completingPartially) {
      this.productionScheduleToAdd.productionSchedulePathsOfColoredYarnSets.forEach((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
        productionSchedulePathsOfColoredYarnSet.productionSchedulePaths.forEach((productionSchedulePath: ProductionSchedulePath) => {
          productionSchedulePath.pathGroups.forEach((pathGroup: ProductionScheduleItemInPathGroup) => {
            pathGroup.status = ProductionScheduleItemInPathGroupStatus.NORMAL;
          });
        });
      });
    }
  }

  private navigateToOverviewAndThrowError(): (error: any) => void {
    return ErrorHandlers.navigateToOverviewAndThrowError(this.router, AddProductionSchedulePageComponent.PRODUCTION_SCHEDULE_OVERVIEW_URL, this.navigationHelperService);
  }

  private stopRunningInitialPreparationsAndThrowError(): (error: UnhandledBackendError) => void {
    return (error: UnhandledBackendError): void => {
      this.isRunningInitialPreparations = false;
      this.isRunningInitialPreparationsToDesigns = false;
      throw error;
    };
  }

  private duplicateProductionSchedule(productionSchedule: ProductionSchedule): void {
    productionSchedule.id = null;
    productionSchedule.name = '';
    productionSchedule.runId = null;

    this.productionSchedules
      .duplicateProductionOrder(productionSchedule)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: (duplicatedProductionSchedule: ProductionSchedule) => {
          this.productionScheduleInitialised(duplicatedProductionSchedule);
        },
        error: this.navigateToOverviewAndThrowError()
      });
  }

  private navigateToPreviousRoute(): void {
    this.navigationHelperService.navigateToPreviousRoute(AddProductionSchedulePageComponent.PRODUCTION_SCHEDULE_OVERVIEW_URL);
  }
}
