<div class="full-width pl-8 pr-8">
  @if (selectedPropertyType() === nameGeneratorPartTypeEnum.ALPHABETIC_SERIAL_NUMBER || selectedPropertyType() === nameGeneratorPartTypeEnum.NUMERIC_SERIAL_NUMBER) {
    <div [formGroup]="partForm.controls.serialNumber" class="flex-row gap-12" data-testid="serial-number-formgroup">
      <mat-form-field class="flex-grow">
        <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.MAX_LENGTH' | bmsTranslate }}</mat-label>
        <input [formControl]="partForm.controls.serialNumber.controls.maxLength" matInput vdwL10nDecimal min="0" type="number" />
        <mat-error *vdwCanShowErrorForFormControl="'maxLength'; error: 'required'">{{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}</mat-error>
        <mat-error *vdwCanShowErrorForFormControl="'maxLength'; error: 'min'">{{ 'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: '1'} }}</mat-error>
        <mat-error *vdwCanShowErrorForFormControl="'maxLength'; error: 'max'">{{ 'GENERAL.ERRORS.MAX_VALUE' | bmsTranslate: {max: '5'} }}</mat-error>
      </mat-form-field>
      <mat-form-field class="flex-grow">
        <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.START_VALUE' | bmsTranslate }}</mat-label>
        <input [formControl]="partForm.controls.serialNumber.controls.startValue" matInput type="text" />
        <mat-error *vdwCanShowErrorForFormControl="'startValue'; error: 'maxlength'">
          {{ 'GENERAL.ERRORS.LENGTH_LIMIT' | bmsTranslate: {max: partForm.value.serialNumber.maxLength ?? '5'} }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="flex-grow">
        <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.STEP_SIZE' | bmsTranslate }}</mat-label>
        <input [formControl]="partForm.controls.serialNumber.controls.stepSize" matInput vdwL10nDecimal min="0" type="number" />
        <mat-error *vdwCanShowErrorForFormControl="'stepSize'; error: 'min'">{{ 'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: '1'} }}</mat-error>
      </mat-form-field>
    </div>
  } @else if (selectedPropertyType() === nameGeneratorPartTypeEnum.PROPERTY_VALUE) {
    <div [formGroup]="partForm.controls.entityProperty" class="flex-row gap-12" data-testid="entity-property-formgroup">
      <mat-form-field class="flex-grow" data-testid="entity-property-formfield">
        <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.CUT_FROM.LABEL' | bmsTranslate }}</mat-label>
        <mat-select [formControl]="partForm.controls.entityProperty.controls.cutFrom" disableOptionCentering>
          @for (cutFrom of cutFromEnum | keyvalue; track cutFrom) {
            <mat-option [attr.value]="cutFrom.key" [value]="cutFrom.key">
              {{ 'GENERAL.PLACEHOLDER.PARAMETERS.CUT_FROM.' + cutFrom.value | bmsTranslate }}
            </mat-option>
          }
        </mat-select>
        <mat-error *vdwCanShowErrorForFormControl="'cutFrom'; error: 'required'">{{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}</mat-error>
      </mat-form-field>
      @if (selectedCutFrom() === cutFromEnum.LEFT || selectedCutFrom() === cutFromEnum.RIGHT) {
        <mat-form-field class="flex-grow" data-testid="entity-property-formfield">
          <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.START_CHARACTER' | bmsTranslate }}</mat-label>
          <input [formControl]="partForm.controls.entityProperty.controls.startCharacter" matInput vdwL10nDecimal type="number" />
          <mat-error *vdwCanShowErrorForFormControl="'startCharacter'; error: 'required'">{{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}</mat-error>
          <mat-error *vdwCanShowErrorForFormControl="'startCharacter'; error: 'min'">{{ 'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: '1'} }}</mat-error>
        </mat-form-field>
        <mat-form-field class="flex-grow" data-testid="entity-property-formfield">
          <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.LENGTH' | bmsTranslate }}</mat-label>
          <input [formControl]="partForm.controls.entityProperty.controls.length" matInput vdwL10nDecimal type="number" />
          <mat-error *vdwCanShowErrorForFormControl="'length'; error: 'required'">{{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}</mat-error>
          <mat-error *vdwCanShowErrorForFormControl="'length'; error: 'min'">{{ 'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: '1'} }}</mat-error>
          <mat-error *vdwCanShowErrorForFormControl="'length'; error: 'max'">{{ 'GENERAL.ERRORS.MAX_VALUE' | bmsTranslate: {max: '20'} }}</mat-error>
        </mat-form-field>
      }
    </div>
  } @else if (selectedPropertyType() === nameGeneratorPartTypeEnum.CUSTOM_TEXT) {
    <div [formGroup]="partForm.controls.customText" class="flex-row" data-testid="custom-text-formgroup">
      <mat-form-field class="flex-grow">
        <mat-label>{{ 'GENERAL.PLACEHOLDER.PARAMETERS.VALUE' | bmsTranslate }}</mat-label>
        <input [formControl]="partForm.controls.customText.controls.customText" matInput type="text" />
        <mat-error *vdwCanShowErrorForFormControl="'customText'; error: 'required'">{{ 'GENERAL.ERRORS.REQUIRED' | bmsTranslate }}</mat-error>
        <mat-error *vdwCanShowErrorForFormControl="'customText'; error: 'maxlength'">{{ 'GENERAL.ERRORS.LENGTH_LIMIT' | bmsTranslate: {max: '20'} }}</mat-error>
      </mat-form-field>
    </div>
  }
</div>
