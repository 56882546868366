import {Injectable} from '@angular/core';
import {intComparator} from '@application/helper/int-comparator';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {
  ColDefBuilderFactoryService,
  GridOptionsBuilder,
  LinkIconRendererComponent,
  NoDataOverlayComponentParams,
  OverlayComponentParams,
  StringUtils,
  TranslateService
} from '@vdw/angular-component-library';
import {ColDef, ICellRendererParams} from 'ag-grid-community';

@Injectable()
export class CustomerOverviewService {
  public constructor(
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly translate: TranslateService
  ) {}

  public getColumnDefs(withLinkIcon: boolean = true): ColDef[] {
    let nameColDef = this.colDefBuilderFactoryService.getBuilder().withColIdAndField('name', true).withHeaderName('GENERAL.NAME').withComparator(StringUtils.stringComparator);

    if (withLinkIcon) {
      nameColDef = nameColDef.withCellRenderer(LinkIconRendererComponent, (params: ICellRendererParams) => {
        return {
          params,
          editPageUrl: LastModifiedCardUtils.getPageUrls('customer').edit
        };
      });
    }

    return [
      this.colDefBuilderFactoryService.getBuilder().withField('number', true).withHeaderName('CUSTOMERS.NUMBER').withComparator(intComparator).build(),
      nameColDef.build(),
      this.colDefBuilderFactoryService.getBuilder().withField('addressDetails.streetName', true).withHeaderName('CUSTOMERS.STREET_NAME').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService.getBuilder().withField('contactDetails.email', true).withHeaderName('CUSTOMERS.EMAIL').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService.getBuilder().withField('contactDetails.telephone', true).withHeaderName('CUSTOMERS.TELEPHONE').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService.getBuilder().withField('comments', true).withHeaderName('CUSTOMERS.COMMENTS').withComparator(StringUtils.stringComparator).build()
    ];
  }

  public getDefaultGridOptionsBuilder(builder: GridOptionsBuilder): GridOptionsBuilder {
    return builder
      .withLoadingOverlay({
        scale: 0.7
      } as OverlayComponentParams)
      .withNoRowsOverlay({
        scale: 0.7,
        titleParam: this.translate.instant('CUSTOMERS.CUSTOMER', {count: 1}),
        hideDescription: true
      } as NoDataOverlayComponentParams);
  }
}
