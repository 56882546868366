import {Component, Inject, OnInit} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {Customer} from '@domain/customer/customer';
import {Customers, CUSTOMERS} from '@infrastructure/http/customer/customers';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {BaseComponent} from '@vdw/angular-component-library';
import {ColDef} from 'ag-grid-community';
import {takeUntil} from 'rxjs/operators';
import {CustomerOverviewService} from './customer-overview.service';

@Component({
  templateUrl: './customer-overview-page.component.html'
})
export class CustomerOverviewPageComponent extends BaseComponent implements OnInit {
  public listOfCustomers: Customer[];

  public readonly textileDataType = TextileDataType;
  public readonly GRID_IDENTIFIER = GridIdentifier.CUSTOMER_OVERVIEW;

  public constructor(
    @Inject(CUSTOMERS) public readonly customers: Customers,
    private readonly customerOverviewService: CustomerOverviewService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.customers
      .getAll()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((customers: Customer[]) => {
        this.listOfCustomers = customers;
      });
  }

  public getColumnDefs(): ColDef[] {
    return this.customerOverviewService.getColumnDefs();
  }
}
