export enum NameGeneratorSeparator {
  NO_SEPARATOR = 'NO_SEPARATOR',
  POINT = 'POINT',
  COMMA = 'COMMA',
  DASH = 'DASH',
  UNDERSCORE = 'UNDERSCORE',
  SPACE = 'SPACE',
  VERTICAL_BAR = 'VERTICAL_BAR',
  TAB = 'TAB'
}
