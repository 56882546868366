import {Inject, Injectable, Renderer2} from '@angular/core';
import {DraggingService} from '@application/services/dragging/dragging.service';
import {ProductionSchedule} from '@domain/production-schedule/production-schedule';
import {ProductionScheduleItemInPathGroup} from '@domain/production-schedule/production-schedule-item-in-path-group';
import {ProductionScheduleOrderLine} from '@domain/production-schedule/production-schedule-order-line';
import {ProductionSchedulePath} from '@domain/production-schedule/production-schedule-path';
import {ProductionSchedulePathsOfColoredYarnSet} from '@domain/production-schedule/production-schedule-paths-of-colored-yarn-set';
import {PropertyValue} from '@domain/property-value';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {Finishing} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing';
import {CarpetWeaveQuality} from '@domain/textile-data/machine-quality/carpet-weave-quality';
import {PathWidth} from '@domain/textile-data/machine-quality/path-width';
import {ListDrawingOrderLine} from '@presentation/components/drawing-list/list-drawing-order-line';
import {
  AgGridUtils,
  AssertionUtils,
  BaseComponent,
  convertCommercialUnit,
  convertDentsToPixels,
  convertHeightInMeterToPicks,
  convertPicksToPixels,
  convertWidthInMeterToDents,
  EmptyCellRendererComponent,
  EnumUtils,
  NoDataOverlayComponentParams,
  OverlayComponentParamsAction,
  TranslateService,
  Unit
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {
  CellEditingStoppedEvent,
  CellRendererSelectorResult,
  EditableCallbackParams,
  ICellRendererParams,
  IServerSideGetRowsParams,
  RowNode,
  RowSelectedEvent,
  ValueGetterParams,
  ValueSetterParams
} from 'ag-grid-community';
import {L10nIntlService} from 'angular-l10n';
import {AddProductionScheduleNavigationState} from '../../add-production-schedule-navigation-state.interface';
import {PRODUCTION_SCHEDULE_PLAN, ProductionSchedulePlan} from '../../plan/production-schedule-plan';
import {ProductionScheduleItemInPathGroupWithIdentification} from './order-lines-view/production-schedule-item-in-path-group-with-identification';
import {ProductionScheduleOrderLineWithQuantity} from './order-lines-view/production-schedule-order-line-with-quantity';

type RowData = ProductionScheduleOrderLineWithQuantity | ProductionScheduleItemInPathGroupWithIdentification;

@Injectable()
export class OrderLinesHelper extends BaseComponent {
  public displayTechnicalUnit: boolean;
  public commercialUnit: Unit;
  public savedFilterModel: any;

  public constructor(
    @Inject(PRODUCTION_SCHEDULE_PLAN) private readonly productionSchedulePlan: ProductionSchedulePlan,
    private readonly translate: TranslateService,
    private readonly l10nIntlService: L10nIntlService
  ) {
    super();
  }

  public hasActiveFilters(orderLinesGrid: AgGridAngular): boolean {
    return !AssertionUtils.isNullOrUndefined(orderLinesGrid) && Object.values(orderLinesGrid?.api?.getFilterModel())?.length > 0;
  }

  public onServerSideDataSourceError(params: IServerSideGetRowsParams, error: any): void {
    params.fail();
    params.api.showNoRowsOverlay();
    throw error;
  }

  public buildAgGridCellTextWithUnitForCommercialDimension(commercialDimension: number, unit: Unit, forceUnit: boolean = false, decimals?: number): string {
    const selectedUnit = this.getSelectedUnit(unit, forceUnit);
    return AgGridUtils.buildAgGridCellTextWithUnit(commercialDimension, this.translate.instant(`GENERAL.UNIT.${EnumUtils.getKeyFromValue(Unit, selectedUnit)}`), this.l10nIntlService, decimals);
  }

  public buildAgGridCellTooltipWithUnitForCommercialDimension(commercialDimension: number, unit: Unit, forceUnit: boolean = false, decimals?: number): string {
    const selectedUnit = this.getSelectedUnit(unit, forceUnit);
    return AgGridUtils.buildAgGridCellTooltipWithUnit(
      commercialDimension,
      this.translate.instant(`GENERAL.UNIT.${EnumUtils.getKeyFromValue(Unit, selectedUnit)}`),
      this.l10nIntlService,
      false,
      decimals
    );
  }

  public getTotalCount(params: ValueGetterParams<RowData>): number {
    if (!(params.data instanceof ProductionScheduleOrderLineWithQuantity)) {
      return undefined;
    }

    return params.data.amount;
  }

  public getInitializationFilterModel(
    showPreselectedItems: boolean,
    productionSchedule: ProductionSchedule,
    selectedPath: ProductionSchedulePath,
    selectedRestPathColoredYarnSet: OverviewListColoredYarnSetWithStartDent,
    preselectedOrderlineIds: number[],
    addProductionScheduleNavigationState: AddProductionScheduleNavigationState,
    listOfProductionOrderCustomSettings: PropertyValue[],
    alternativeQualityNames: string[],
    qualityNames: string[],
    alternativeColorSetNames: string[],
    colorSetNames: string[],
    showOnlyQueryCompatibleOrderlines: boolean,
    isPreSelection: boolean,
    selectedPathContentRowData: ProductionScheduleItemInPathGroup
  ): any {
    const widthFilter = this.getWidthFilterForPath(productionSchedule, selectedPath, selectedRestPathColoredYarnSet);
    const filterType = AssertionUtils.isNullOrUndefined(selectedPath) ? 'lessThanOrEqual' : 'equals';

    let filterModel: any;

    if (!AssertionUtils.isNullOrUndefined(this.savedFilterModel)) {
      filterModel = this.savedFilterModel;
    }

    if (!AssertionUtils.isNullOrUndefined(productionSchedule?.machineQuality?.id) || !AssertionUtils.isNullOrUndefined(addProductionScheduleNavigationState.machineQualityId)) {
      const newFilterModel = {
        machineQualityName: {
          filterModels: [
            null,
            {
              values: this.canPreFilterOnAlternativeQualitiesAndColoredYarnSets(listOfProductionOrderCustomSettings) ? alternativeQualityNames : qualityNames,
              filterType: 'set'
            }
          ],
          filterType: 'multi'
        },
        colorSetName: {
          filterModels: [
            null,
            {
              values: this.canPreFilterOnAlternativeQualitiesAndColoredYarnSets(listOfProductionOrderCustomSettings) ? alternativeColorSetNames : colorSetNames,
              filterType: 'set'
            }
          ],
          filterType: 'multi'
        },
        technicalWidthInDents: {
          filterModels: [{filter: widthFilter, filterType: 'number', type: filterType}, null],
          filterType: 'multi'
        },
        amountLeft: {
          filterModels: [{filter: 0, filterType: 'number', type: 'greaterThan'}, null],
          filterType: 'multi'
        }
      };

      filterModel = {...filterModel, ...newFilterModel};
    } else {
      const newFilterModel = {
        amountLeft: {
          filterModels: [{filter: 0, filterType: 'number', type: 'greaterThan'}, null],
          filterType: 'multi'
        }
      };

      filterModel = {...filterModel, ...newFilterModel};
    }

    if (showPreselectedItems && !AssertionUtils.isEmpty(preselectedOrderlineIds)) {
      const orderlineIds = preselectedOrderlineIds.map((id: number) => `${id}`);

      const preselectionFilterModel = {
        id: {
          filterModels: [null, {values: orderlineIds, filterType: 'set'}],
          filterType: 'multi'
        }
      };

      filterModel = {...filterModel, ...preselectionFilterModel};
    } else {
      delete filterModel.id;
    }

    if (showOnlyQueryCompatibleOrderlines && !isPreSelection) {
      const colorSetId = AssertionUtils.isNullOrUndefined(selectedPath)
        ? selectedRestPathColoredYarnSet.coloredYarnSet.overviewListColorSet.id
        : this.getColorSetIdOfSelectedPath(productionSchedule, selectedPath);

      const compatibleFilter = {
        compatibleColorSetId: {
          filterModels: [{filter: colorSetId, filterType: 'number', type: 'equals'}, null],
          filterType: 'multi'
        }
      };

      filterModel = {...filterModel, ...compatibleFilter};
    } else {
      delete filterModel.compatibleColorSetId;
    }

    if (!AssertionUtils.isNullOrUndefined(selectedPathContentRowData) && !AssertionUtils.isNullOrUndefined(selectedPathContentRowData?.orderLineId)) {
      const orderlineId = [`${selectedPathContentRowData.orderLineId}`];

      const selectedOrderlineIdFilterModel = {
        id: {
          filterModels: [null, {values: orderlineId, filterType: 'set'}],
          filterType: 'multi'
        }
      };

      filterModel = {...filterModel, ...selectedOrderlineIdFilterModel};
    }

    return filterModel;
  }

  public setAmountInProductionSchedule(params: ValueSetterParams<ProductionScheduleItemInPathGroupWithIdentification>, productionSchedule: ProductionSchedule): boolean {
    if (params.data.productionScheduleOrderLine.article.design.repeated) {
      const {commercialDimensionsInMM, technicalDimensions} = params.data.productionScheduleItemInPathGroup;
      const pickDensity = technicalDimensions.heightInPicks / commercialDimensionsInMM.heightInMM;
      params.data.productionScheduleItemInPathGroup.commercialDimensionsInMM.heightInMM = params.newValue / productionSchedule.getProductionScheduleItemMultiplier();
      params.data.productionScheduleItemInPathGroup.technicalDimensions.heightInPicks = Math.round((params.newValue * pickDensity) / productionSchedule.getProductionScheduleItemMultiplier());
    } else {
      params.data.productionScheduleItemInPathGroup.quantity = Math.floor(params.newValue / productionSchedule.getProductionScheduleItemMultiplier());
    }

    this.productionSchedulePlan.editProductionScheduleItem(
      params.data.productionScheduleItemInPathGroup,
      params.data.productionScheduleItemInPathGroupIndex,
      params.data.productionSchedulePathIndex,
      params.data.startDentOfColoredYarnSet
    );
    return true;
  }

  public getProductionScheduleItemsInPathGroupWithIdentification(orderLineId: number, productionSchedule: ProductionSchedule): ProductionScheduleItemInPathGroupWithIdentification[] {
    const productionScheduleItemsInPathGroupWithIdentification = productionSchedule.productionSchedulePathsOfColoredYarnSets.flatMap(
      (productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) =>
        productionSchedulePathsOfColoredYarnSet.productionSchedulePaths.flatMap((productionSchedulePath: ProductionSchedulePath, productionSchedulePathIndex: number) =>
          productionSchedulePath.pathGroups.flatMap(
            (productionScheduleItemInPathGroup: ProductionScheduleItemInPathGroup, productionScheduleItemInPathGroupIndex: number) =>
              new ProductionScheduleItemInPathGroupWithIdentification(
                productionSchedulePath,
                productionScheduleItemInPathGroup,
                productionScheduleItemInPathGroupIndex,
                productionSchedulePathIndex,
                productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet.startDent,
                productionSchedule.orderLines.find((productionScheduleOrderLine: ProductionScheduleOrderLine) => productionScheduleOrderLine.id === orderLineId)
              )
          )
        )
    );

    return productionScheduleItemsInPathGroupWithIdentification.filter(
      (productionScheduleItemInPathGroupWithIdentification: ProductionScheduleItemInPathGroupWithIdentification) =>
        productionScheduleItemInPathGroupWithIdentification.productionScheduleItemInPathGroup.orderLineId === orderLineId
    );
  }

  public onRowDrag(
    params: {rowNode: RowNode; dragEvent: DragEvent},
    productionSchedule: ProductionSchedule,
    draggingService: DraggingService,
    renderer: Renderer2,
    pathWidths: PathWidth[],
    marginInDentsOfMissingDesigns: number
  ): void {
    if (!(params.rowNode.data instanceof ProductionScheduleOrderLineWithQuantity)) {
      return;
    }
    const listDrawingOrderLine = this.createListDrawingOrderLine(
      productionSchedule,
      params.rowNode.data,
      productionSchedule.machineQuality.weaveStructure.picksPerColorLine,
      marginInDentsOfMissingDesigns,
      pathWidths,
      false
    );
    if (listDrawingOrderLine.amount > 0 && (listDrawingOrderLine.drawing.repeated ? listDrawingOrderLine.commercialLengthInMM > 0 : true)) {
      draggingService.setData(listDrawingOrderLine);
    } else {
      draggingService.setData({quantity: params.rowNode.data.amountLeft - params.rowNode.data.amountInProductionSchedule * productionSchedule.getProductionScheduleItemMultiplier()});
    }

    const dragPreview = renderer.createElement('img');
    renderer.setAttribute(dragPreview, 'src', '/assets/images/image-placeholder.svg');

    params.dragEvent.dataTransfer.setDragImage(dragPreview, 0, 0);
  }

  public createListDrawingOrderLine(
    productionSchedule: ProductionSchedule,
    productionScheduleOrderLine: ProductionScheduleOrderLineWithQuantity,
    picksPerColorLine: number,
    marginInDentsOfMissingDesigns: number,
    pathWidths: PathWidth[],
    isAutoFilling: boolean,
    selectedPath?: ProductionSchedulePath,
    availableSpaceInBuggyInPicks?: number,
    buggyLengthMaxInPicks?: number,
    fullQualities?: CarpetWeaveQuality[]
  ): ListDrawingOrderLine {
    const listDrawingOrderLine = ListDrawingOrderLine.fromProductionScheduleOrderLine(
      productionScheduleOrderLine,
      this.getListDrawingOrderLineAmount(productionSchedule, productionScheduleOrderLine, selectedPath, availableSpaceInBuggyInPicks, buggyLengthMaxInPicks, fullQualities, isAutoFilling),
      productionScheduleOrderLine.article.design.repeated ? productionScheduleOrderLine.article.commercialHeightInMM : null,
      picksPerColorLine,
      productionScheduleOrderLine.rollLengthInMeter
    );

    if (listDrawingOrderLine.drawing.missingDesign) {
      listDrawingOrderLine.orderLine.article.design.image.data = '..assets/images/missing-design.svg';
      listDrawingOrderLine.orderLine.article.design.dimensionsInPixels = {
        widthInPx:
          this.productionSchedulePlan.lookupCommercialWidthInPathWidths(listDrawingOrderLine.orderLine.article.commercialWidthInMM, pathWidths) ??
          convertDentsToPixels(
            convertWidthInMeterToDents(
              convertCommercialUnit({from: {unit: Unit.MILLIMETER, value: listDrawingOrderLine.orderLine.article.commercialWidthInMM}, to: Unit.METER}),
              productionSchedule?.machineQuality?.reedDensity * 1000,
              marginInDentsOfMissingDesigns
            ),
            listDrawingOrderLine.drawing.threadByThread,
            listDrawingOrderLine.drawing.nrColumnsForWeftSelection,
            productionSchedule?.machineQuality?.weaveStructure?.numberOfCloths ?? 2,
            productionSchedule?.machineQuality?.weaveStructure?.numberOfGroundYarns
          ),
        heightInPx: convertPicksToPixels(
          convertHeightInMeterToPicks(
            convertCommercialUnit({from: {unit: Unit.MILLIMETER, value: listDrawingOrderLine.orderLine.article.commercialHeightInMM}, to: Unit.METER}),
            productionSchedule?.machineQuality?.pickDensity * 1000,
            productionSchedule?.machineQuality?.weaveStructure
          ),
          listDrawingOrderLine.picksPerColorLine
        )
      };
    }

    return listDrawingOrderLine;
  }

  public isRollLengthEditable(params: EditableCallbackParams<ProductionScheduleOrderLineWithQuantity>): boolean {
    return params.data.article.design.repeated;
  }

  public getMaxQuantityToPlan(productionScheduleOrderLineWithQuantity: ProductionScheduleOrderLineWithQuantity, productionSchedule: ProductionSchedule): number {
    return productionScheduleOrderLineWithQuantity.amountLeft - this.getAmountInProductionSchedule(productionScheduleOrderLineWithQuantity, productionSchedule);
  }

  public isQuantityEditable(params: EditableCallbackParams<RowData>, productionSchedule: ProductionSchedule, listOfProductionOrderCustomSettings: PropertyValue[]): boolean {
    if (params.node.level !== 0 || !(params.data instanceof ProductionScheduleOrderLineWithQuantity)) {
      return false;
    }
    return params.data.article.design.repeated
      ? this.canExceedRequestedAmountOfOrderLineItems(listOfProductionOrderCustomSettings) ||
          params.data.getHeightLeftInMMForProductionSchedule(productionSchedule.getProductionScheduleItemMultiplier()) > 0
      : this.canExceedRequestedAmountOfOrderLineItems(listOfProductionOrderCustomSettings) ||
          params.data.getAmountLeftForProductionSchedule(productionSchedule.getProductionScheduleItemMultiplier()) > productionSchedule.getProductionScheduleItemMultiplier();
  }

  public getListDrawingOrderLineAmount(
    productionSchedule: ProductionSchedule,
    productionScheduleOrderLine: ProductionScheduleOrderLineWithQuantity,
    selectedPath?: ProductionSchedulePath,
    availableSpaceInBuggyInPicks?: number,
    buggyLengthMaxInPicks?: number,
    fullQualities?: CarpetWeaveQuality[],
    isAutoFilling?: boolean
  ): number {
    const productionScheduleItemMultiplier = productionSchedule.getProductionScheduleItemMultiplier();
    const amountLeftForProductionSchedule = productionScheduleOrderLine.getAmountLeftForProductionSchedule(productionScheduleItemMultiplier);

    if (isAutoFilling) {
      return (
        this.makeQuantitySuggestionCalculation(productionScheduleOrderLine, productionSchedule, selectedPath, availableSpaceInBuggyInPicks, buggyLengthMaxInPicks, fullQualities, isAutoFilling) /
        productionScheduleItemMultiplier
      );
    } else {
      return Math.ceil((productionScheduleOrderLine.quantity ?? amountLeftForProductionSchedule) / productionScheduleItemMultiplier);
    }
  }

  public canExceedRequestedAmountOfOrderLineItems(listOfProductionOrderCustomSettings: PropertyValue[]): boolean {
    return listOfProductionOrderCustomSettings.find((propertyValue: PropertyValue) => propertyValue.propertyName === 'allowExceedingRequestedAmountOfOrderLineItems')?.propertyValue;
  }

  public canPreFilterOnAlternativeQualitiesAndColoredYarnSets(listOfProductionOrderCustomSettings: PropertyValue[]): boolean {
    return listOfProductionOrderCustomSettings.find((propertyValue: PropertyValue) => propertyValue.propertyName === 'allowPreFilterOnQualitiesAndColoredYarnSets')?.propertyValue;
  }

  public getColorSetIdOfSelectedPath(productionSchedule: ProductionSchedule, selectedPath: ProductionSchedulePath): number {
    let colorSetId: number;

    productionSchedule.productionSchedulePathsOfColoredYarnSets.forEach((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
      productionSchedulePathsOfColoredYarnSet.productionSchedulePaths.forEach((productionSchedulePath: ProductionSchedulePath) => {
        if (productionSchedulePath.pathNumber === selectedPath.pathNumber) {
          colorSetId = productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet.coloredYarnSet.overviewListColorSet.id;
        }
      });
    });

    return colorSetId;
  }

  public getNoRowsOverlayForPreselection(orderLinesNewViewGrid: AgGridAngular): NoDataOverlayComponentParams {
    return {
      scale: 0.7,
      titleParam: 'SALES_ORDERS.ORDER_LINES.ORDER_LINE',
      hideDescription: true,
      isAnyFilterPresent: () => this.hasActiveFilters(orderLinesNewViewGrid)
    } as NoDataOverlayComponentParams;
  }

  public getNoRowsOverlayForBuilder(noOverlayAction: OverlayComponentParamsAction[]): NoDataOverlayComponentParams {
    return {
      scale: 0.4,
      hideDescription: false,
      titleKey: 'PRODUCTION_ORDER.WEAVE_PLAN.PRESELECTION.NO_ITEMS_SELECTED',
      descriptionKey: 'PRODUCTION_ORDER.WEAVE_PLAN.PRESELECTION.GO_TO_PRESELECTION',
      actions: noOverlayAction,
      hideActions: false
    } as NoDataOverlayComponentParams;
  }

  public getWidthFilterForPath(productionSchedule: ProductionSchedule, selectedPath: ProductionSchedulePath, selectedRestPathColoredYarnSet: OverviewListColoredYarnSetWithStartDent): number {
    let widthFilter: number;

    if (AssertionUtils.isNullOrUndefined(selectedRestPathColoredYarnSet)) {
      const nrColumnsForWeftSelection = productionSchedule?.machineQuality?.numberOfColumnsToDropForWeftSelection ?? 0;

      widthFilter = selectedPath?.technicalWidthInDents + nrColumnsForWeftSelection;
    } else {
      let totalWidthOfPathsOfColoredYarnSetInDents = 0;

      const pathsOfSelectedRestPath = productionSchedule?.productionSchedulePathsOfColoredYarnSets?.filter(
        (productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) =>
          productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet.startDent === selectedRestPathColoredYarnSet.startDent
      );

      pathsOfSelectedRestPath[0]?.productionSchedulePaths?.forEach((productionSchedulePath: ProductionSchedulePath) => {
        totalWidthOfPathsOfColoredYarnSetInDents += productionSchedulePath?.technicalWidthInDents;
      });

      widthFilter = pathsOfSelectedRestPath[0]?.productionScheduleColoredYarnSet?.technicalWidthInDents - totalWidthOfPathsOfColoredYarnSetInDents;

      if (widthFilter === 0) {
        widthFilter = pathsOfSelectedRestPath[0].productionScheduleColoredYarnSet.technicalWidthInDents;
      }
    }

    return widthFilter;
  }

  public getAmountLeft(orderline: ProductionScheduleOrderLineWithQuantity | ProductionScheduleItemInPathGroupWithIdentification, productionSchedule: ProductionSchedule): number {
    if (!(orderline instanceof ProductionScheduleOrderLineWithQuantity)) {
      return undefined;
    }

    const numberOfCloths = productionSchedule.getProductionScheduleItemMultiplier();
    const maxQuantityToPlan = this.getMaxQuantityToPlan(orderline, productionSchedule);
    return maxQuantityToPlan === 1 ? numberOfCloths : maxQuantityToPlan;
  }

  public setQuantitySuggestionForPath(
    event: RowSelectedEvent,
    productionSchedule: ProductionSchedule,
    selectedPath: ProductionSchedulePath,
    availableSpaceInBuggyInPicks: number,
    buggyLengthMaxInPicks: number,
    fullQualities: CarpetWeaveQuality[],
    listOfProductionOrderCustomSettings: PropertyValue[]
  ): void {
    const quantity = this.makeQuantitySuggestionCalculation(event.data, productionSchedule, selectedPath, availableSpaceInBuggyInPicks, buggyLengthMaxInPicks, fullQualities);

    if (!isNaN(quantity) || quantity < 0) {
      event.node.setDataValue('quantity', quantity);
    } else {
      event.node.setDataValue('quantity', 0);
    }
  }

  public getQuantitySuggestionForPath(
    orderline: ProductionScheduleOrderLineWithQuantity,
    productionSchedule: ProductionSchedule,
    selectedPath: ProductionSchedulePath,
    availableSpaceInBuggyInPicks: number,
    buggyLengthMaxInPicks: number,
    fullQualities: CarpetWeaveQuality[],
    shouldMakeSuggestion: boolean = false,
    productionScheduleMultiplier: number = 2
  ): number {
    if (!shouldMakeSuggestion) {
      orderline.quantity = AssertionUtils.isNullOrUndefined(orderline.quantity) ? productionScheduleMultiplier : orderline.quantity;
      return orderline.quantity;
    } else if (!AssertionUtils.isNullOrUndefined(orderline)) {
      orderline.quantity = this.makeQuantitySuggestionCalculation(orderline, productionSchedule, selectedPath, availableSpaceInBuggyInPicks, buggyLengthMaxInPicks, fullQualities);
      return orderline?.quantity;
    }
  }

  public getLongestPathLengthInPicks(productionSchedule: ProductionSchedule): number {
    const productionSchedulePathLenths =
      productionSchedule?.productionSchedulePathsOfColoredYarnSets
        .flatMap((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => productionSchedulePathsOfColoredYarnSet?.productionSchedulePaths ?? [])
        .map((productionSchedulePath: ProductionSchedulePath) => productionSchedulePath?.totalLengthInPicks ?? 0) ?? [];

    return Math.max(0, ...productionSchedulePathLenths);
  }

  public getProductionScheduleItemsInPathGroup(productionSchedule: ProductionSchedule): ProductionScheduleItemInPathGroup[] {
    return this.getProductionSchedulePaths(productionSchedule).flatMap((productionSchedulePath: ProductionSchedulePath) => productionSchedulePath.pathGroups);
  }

  public getProductionSchedulePaths(productionSchedule: ProductionSchedule): ProductionSchedulePath[] {
    return productionSchedule.productionSchedulePathsOfColoredYarnSets.flatMap(
      (productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => productionSchedulePathsOfColoredYarnSet.productionSchedulePaths
    );
  }

  public getAmountInProductionSchedule(orderline: ProductionScheduleOrderLineWithQuantity | ProductionScheduleItemInPathGroupWithIdentification, productionSchedule: ProductionSchedule): number {
    if (orderline instanceof ProductionScheduleOrderLineWithQuantity) {
      let amount = 0;

      productionSchedule.productionSchedulePathsOfColoredYarnSets.forEach((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
        productionSchedulePathsOfColoredYarnSet.productionSchedulePaths.forEach((productionSchedulePath: ProductionSchedulePath) => {
          productionSchedulePath.pathGroups.forEach((pathItem: ProductionScheduleItemInPathGroup) => {
            if (orderline instanceof ProductionScheduleOrderLineWithQuantity && pathItem.orderLineId === orderline.id) {
              amount += pathItem.quantity * productionSchedule.getProductionScheduleItemMultiplier();
            }
          });
        });
      });

      orderline.amountInProductionSchedule = amount;

      return amount;
    }

    return orderline.productionScheduleItemInPathGroup.quantity * productionSchedule.getProductionScheduleItemMultiplier();
  }

  public getProductionScheduleItemsFromProductionSchedule(productionSchedule: ProductionSchedule): ProductionScheduleItemInPathGroup[] {
    return productionSchedule.productionSchedulePathsOfColoredYarnSets.flatMap((productionSchedulePathOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) =>
      productionSchedulePathOfColoredYarnSet.productionSchedulePaths.flatMap((productionSchedulePath: ProductionSchedulePath) => productionSchedulePath.pathGroups)
    );
  }

  public getMaxBuggyLengthOrPathThatIsLonger(productionSchedule: ProductionSchedule, buggyLengthMaxInPicks: number): number {
    let longestPathLengthInPicks = this.getLongestPathLengthInPicks(productionSchedule);

    return longestPathLengthInPicks > buggyLengthMaxInPicks ? longestPathLengthInPicks : buggyLengthMaxInPicks;
  }

  public recalculateQuantitySuggestionWhenRollLengthIsChanged(
    event: CellEditingStoppedEvent,
    rollLengthColumnId: string,
    productionSchedule: ProductionSchedule,
    selectedPath: ProductionSchedulePath,
    buggyLengthMaxInPicks: number,
    availableSpaceInBuggyInPicks: number,
    fullQualities: CarpetWeaveQuality[]
  ): void {
    if (event.column.getColId() === rollLengthColumnId) {
      const value = this.getQuantitySuggestionForPath(event.node.data, productionSchedule, selectedPath, availableSpaceInBuggyInPicks, buggyLengthMaxInPicks, fullQualities, true);
      event.node.setDataValue('quantity', value);
    }
  }

  public cellRendererSelectorForOrderLinesOnly<TData = any, TValue = any>(
    cellRenderer: any = null,
    cellRendererParams: any = null
  ): (params: ICellRendererParams<TData, TValue>) => CellRendererSelectorResult {
    return (params: ICellRendererParams<TData, TValue>): CellRendererSelectorResult =>
      params.node.level === 0 ? {component: cellRenderer ?? params.colDef.cellRenderer, params: cellRendererParams} : {component: EmptyCellRendererComponent};
  }

  public getTotalCutbarLengthInPicks(orderline: ProductionScheduleOrderLineWithQuantity, fullQualities: CarpetWeaveQuality[]): number {
    let finishing: Finishing;
    let totalCutbarLengthInPicks = 0;

    if (!AssertionUtils.isNullOrUndefined(orderline) && !AssertionUtils.isNullOrUndefined(orderline.article) && !AssertionUtils.isNullOrUndefined(orderline.article.finishing)) {
      finishing = fullQualities.flatMap((quality: CarpetWeaveQuality) => quality.finishings).find((qualityFinishing: Finishing) => qualityFinishing.id === orderline.article.finishing.id);
    }

    if (!AssertionUtils.isNullOrUndefined(finishing)) {
      const cutbarBeforeLengthInPicks = isNaN(finishing?.cutbarsInformation?.cutbarBeforeProperties?.technicalLengthInPicks)
        ? 0
        : finishing?.cutbarsInformation?.cutbarBeforeProperties?.technicalLengthInPicks;
      const cutbarAfterLengthInPicks = isNaN(finishing?.cutbarsInformation?.cutbarAfterProperties?.technicalLengthInPicks)
        ? 0
        : finishing?.cutbarsInformation?.cutbarAfterProperties?.technicalLengthInPicks;
      totalCutbarLengthInPicks = cutbarBeforeLengthInPicks + cutbarAfterLengthInPicks;
    }

    return totalCutbarLengthInPicks;
  }

  private getMaxBuggyLengthOrLongestPath(productionSchedule: ProductionSchedule, buggyLengthMaxInPicks: number): number {
    let longestPathLengthInPicks = this.getLongestPathLengthInPicks(productionSchedule);

    return longestPathLengthInPicks !== 0 ? longestPathLengthInPicks : buggyLengthMaxInPicks;
  }

  private getSelectedUnit(unit: Unit, forceUnit: boolean): Unit {
    if (forceUnit || this.displayTechnicalUnit) {
      return unit;
    }
    return this.commercialUnit;
  }

  private makeQuantitySuggestionCalculation(
    orderline: ProductionScheduleOrderLineWithQuantity,
    productionSchedule: ProductionSchedule,
    selectedPath: ProductionSchedulePath,
    availableSpaceInBuggyInPicks: number,
    buggyLengthMaxInPicks: number,
    fullQualities: CarpetWeaveQuality[],
    isAutoFilling: boolean = false
  ): number {
    let availableSpace: number;
    const numberOfCloths = productionSchedule.machine.jacquard.isSisal ? 1 : productionSchedule.machineQuality?.weaveStructure?.numberOfCloths ?? 2;
    let orderlineHeightInPicks = orderline?.article?.technicalHeightInPicks ?? 0;
    const toPlanAmount = this.getAmountLeft(orderline, productionSchedule);

    // For repeated designs we need to take into account the roll Length instead of taking the height of the drawing.
    if (orderline?.article?.design?.repeated && !AssertionUtils.isNullOrUndefined(orderline.rollLengthInMeter)) {
      orderlineHeightInPicks = convertHeightInMeterToPicks(orderline?.rollLengthInMeter, productionSchedule?.machineQuality.pickDensity * 1000, productionSchedule?.machineQuality?.weaveStructure);
    }

    if (AssertionUtils.isNullOrUndefined(selectedPath)) {
      availableSpace = Math.ceil(this.getMaxBuggyLengthOrLongestPath(productionSchedule, availableSpaceInBuggyInPicks ?? buggyLengthMaxInPicks));
    } else {
      let longestPathHeight = 0;

      productionSchedule.productionSchedulePathsOfColoredYarnSets?.forEach((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
        productionSchedulePathsOfColoredYarnSet?.productionSchedulePaths.forEach((productionSchedulePath: ProductionSchedulePath) => {
          if (productionSchedulePath?.totalLengthInPicks > longestPathHeight) {
            longestPathHeight = productionSchedulePath?.totalLengthInPicks;
          }
        });
      });

      const isLargestPath = longestPathHeight <= selectedPath?.totalLengthInPicks;

      if (isLargestPath) {
        if (!AssertionUtils.isNullOrUndefined(availableSpaceInBuggyInPicks)) {
          availableSpace = availableSpaceInBuggyInPicks;
        } else {
          availableSpace = buggyLengthMaxInPicks - selectedPath.totalLengthInPicks;
        }
      } else {
        if (isAutoFilling) {
          availableSpace = availableSpaceInBuggyInPicks;
        } else if (!AssertionUtils.isNullOrUndefined(availableSpaceInBuggyInPicks)) {
          availableSpace = Math.ceil(this.getMaxBuggyLengthOrLongestPath(productionSchedule, availableSpaceInBuggyInPicks));
        } else {
          availableSpace = Math.ceil(this.getMaxBuggyLengthOrLongestPath(productionSchedule, buggyLengthMaxInPicks)) - selectedPath.totalLengthInPicks;
        }
      }
    }

    const totalHeightInPicks = this.getTotalCutbarLengthInPicks(orderline, fullQualities) + orderlineHeightInPicks;

    let quantity = Math.floor((availableSpace * numberOfCloths) / totalHeightInPicks);

    if (quantity > toPlanAmount) {
      quantity = toPlanAmount;
    }

    quantity -= quantity % numberOfCloths;

    if (toPlanAmount < 2 && numberOfCloths !== 1) {
      quantity = 0;
    }

    return quantity;
  }
}
