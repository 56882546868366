import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PlanningItemHelper} from '@application/helper/planning-prototype/planning-item-helper';
import {GenericOrder} from '@domain/planning-prototype/generic-order.interface';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {AssertionUtils} from '@vdw/angular-component-library';
import {PlanningPrototypeContextService} from '../utilities/planning-prototype-context.service';

@Component({
  selector: 'app-planning-order-tooltip',
  templateUrl: './planning-order-tooltip.component.html',
  styleUrls: ['./planning-order-tooltip.component.scss']
})
export class PlanningOrderTooltipComponent {
  public readonly order: GenericOrder;
  public readonly runTitle: string;
  public readonly differentConfig: boolean;
  public readonly status: ProductionScheduleStatus;
  public readonly statusName: string;
  public readonly completed: boolean;
  public readonly pastDue: boolean;

  public constructor(
    @Inject(MAT_DIALOG_DATA)
    data: {
      planningOrder: GenericOrder;
      runTitle: string;
    },
    context: PlanningPrototypeContextService
  ) {
    this.order = data.planningOrder;
    this.runTitle = data.runTitle;
    this.status = this.order.productionOrder?.status ?? PlanningItemHelper.getItemStatus(this.order);
    this.completed = this.order.isCompleted;
    this.statusName = ProductionScheduleStatus.getStatusIconName(this.status);
    this.differentConfig = context.productChanges.includes(this.order.draftId);
    this.pastDue =
      !AssertionUtils.isNullOrUndefined(this.order.productionOrder?.dueDate) &&
      PlanningItemHelper.getTimelineEndDate(this.order, context.sortedPlanningItemForecasts[this.order.draftId]) > this.order.productionOrder.dueDate;
  }
}
