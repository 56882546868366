import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {SuffixIconCellRendererParams} from './suffix-icon-cell-renderer-params.interface';

@Component({
  templateUrl: './suffix-icon-cell-renderer.component.html',
  styleUrl: './suffix-icon-cell-renderer.component.scss'
})
export class SuffixIconCellRendererComponent implements ICellRendererAngularComp {
  public params: SuffixIconCellRendererParams;

  public agInit(params: SuffixIconCellRendererParams): void {
    this.params = params;
  }

  public refresh(params: SuffixIconCellRendererParams): boolean {
    return false;
  }
}
