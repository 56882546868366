import {Conflict} from '../../common/data/conflict';

export class ConflictsDialogData {
  private readonly _titleKey: string;
  private readonly _descriptionKey: string;
  private readonly _entityType: string;
  private readonly _entityName: string | string[];
  private readonly _conflicts: Conflict[] | Conflict[][];
  private readonly _conflictType: any;
  private readonly _allowUnlock: boolean;
  private readonly _currentSubscription: any;

  public constructor(
    titleKey: string,
    descriptionKey: string,
    entityType: string,
    entityName: string | string[],
    conflicts: Conflict[] | Conflict[][],
    conflictType: any = null,
    allowUnlock: boolean = false,
    currentSubscription?: any
  ) {
    this._titleKey = titleKey;
    this._descriptionKey = descriptionKey;
    this._entityType = entityType;
    this._entityName = entityName;
    this._conflicts = conflicts;
    this._conflictType = conflictType;
    this._allowUnlock = allowUnlock;
    this._currentSubscription = currentSubscription;
  }

  public static createCannotDeleteData(entityType: string, entityName: string | string[], conflicts: Conflict[] | Conflict[][], conflictType: any, currentSubscription?: any): ConflictsDialogData {
    return new ConflictsDialogData('GENERAL.ACTIONS.DELETE_OBJECT', 'CONFLICTS.DIALOG.CANNOT_BE_DELETED', entityType, entityName, conflicts, conflictType, false, currentSubscription);
  }

  public static createInUseData(entityType: string, entityName: string, conflicts: Conflict[], conflictType: any, currentSubscription?: any): ConflictsDialogData {
    return new ConflictsDialogData('CONFLICTS.DIALOG.IN_USE', 'CONFLICTS.DIALOG.USED_BY', entityType, entityName, conflicts, conflictType, false, currentSubscription);
  }

  public static createUnlockData(entityType: string, entityName: string, conflicts: Conflict[], conflictType: any, currentSubscription?: any): ConflictsDialogData {
    return new ConflictsDialogData('CONFLICTS.DIALOG.IN_USE', 'CONFLICTS.DIALOG.USED_BY', entityType, entityName, conflicts, conflictType, true, currentSubscription);
  }

  public get titleKey(): string {
    return this._titleKey;
  }

  public get descriptionKey(): string {
    return this._descriptionKey;
  }

  public get entityType(): string {
    return this._entityType;
  }

  public get entityName(): string | string[] {
    return this._entityName;
  }

  public get conflicts(): Conflict[] | Conflict[][] {
    return this._conflicts;
  }

  public get conflictType(): any {
    return this._conflictType;
  }

  public get allowUnlock(): boolean {
    return this._allowUnlock;
  }

  public get currentSubscription(): any {
    return this._currentSubscription;
  }
}
