<vdw-header [title]="runTitle" svgIcon="planning-blue"></vdw-header>

<article class="p-24 flex-column gap-8">
  <h3 class="font-weight-semibold">{{ order.productionOrder?.name ?? order.name }}</h3>
  <div class="flex-row gap-16">
    <div class="flex-row gap-8">
      <mat-icon [svgIcon]="statusName"></mat-icon>
      <p class="b1">{{ 'PRODUCTION_ORDER.STATUS.' + status | bmsTranslate }}</p>
    </div>

    @if (!completed) {
      @if (differentConfig) {
        <div class="flex-row gap-8">
          <mat-icon class="icon-different-config" svgIcon="deviating"></mat-icon>
          <p class="b1">{{ 'PLANNING.STATUS.DIFFERENT_CONFIGURATION' | bmsTranslate }}</p>
        </div>
      }
      @if (pastDue) {
        <div class="flex-row gap-8">
          <mat-icon svgIcon="clock"></mat-icon>
          <p class="b1">{{ 'PLANNING.STATUS.DUE' | bmsTranslate }}</p>
        </div>
      }
    }
  </div>
</article>
