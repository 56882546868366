import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {MachineType} from '@domain/machine/machine-type.enum';
import {ReportItem} from '@domain/machine/report-item';
import {OverviewListReportItem} from '@presentation/pages/settings/report-item/overview/overview-list-report-item';
import {map, Observable} from 'rxjs';
import {Repository} from '../repository';
import {UniqueIdentifierObjectRepository} from '../unique-identifier-object-repository';

@Injectable({providedIn: 'root'})
export class HttpReportItemsService implements Repository<ReportItem, ReportItem>, UniqueIdentifierObjectRepository {
  private readonly error = 'Method not implemented.';

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public getAll(): Observable<ReportItem[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getWorkerEndpoint()}report-items`)
      .pipe(map((reportItemsJSON: any) => reportItemsJSON.map((reportItemJSON: any) => ReportItem.fromJSON(reportItemJSON))));
  }

  public delete(id: string | number): Observable<void> {
    return this.httpClient.delete<void>(`${this.backendConfiguration.getWorkerEndpoint()}report-items/${id}`);
  }

  public getById(id: string | number): Observable<ReportItem> {
    return this.httpClient.get(`${this.backendConfiguration.getWorkerEndpoint()}report-items/${id}`).pipe(map((reportItemJSON: any) => ReportItem.fromJSON(reportItemJSON)));
  }

  public save(item: ReportItem): Observable<number> {
    throw new Error(this.error);
  }

  public update(item: ReportItem): Observable<void> {
    return this.httpClient.put<void>(`${this.backendConfiguration.getWorkerEndpoint()}report-items/${item.id}`, item.toJSON());
  }

  public isIdentifierAvailable(identifier: string): Observable<boolean> {
    return this.httpClient.get(`${this.backendConfiguration.getWorkerEndpoint()}report-items/check-name?name=${identifier}`).pipe(map((response: {isAvailable: boolean}) => response.isAvailable));
  }

  public getEnabled(): Observable<ReportItem[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getWorkerEndpoint()}report-items/enabled`)
      .pipe(map((reportItemsJSON: any) => reportItemsJSON.map((reportItemJSON: any) => ReportItem.fromJSON(reportItemJSON))));
  }

  public getEnabledAndByMachineTypes(machineTypes: MachineType[]): Observable<ReportItem[]> {
    return this.httpClient
      .post(`${this.backendConfiguration.getWorkerEndpoint()}report-items/enabled`, {machineTypes: machineTypes})
      .pipe(map((reportItemsJSON: any) => reportItemsJSON.map((reportItemJSON: any) => ReportItem.fromJSON(reportItemJSON))));
  }

  public getReportItemsByMachineTypes(machineTypes: MachineType[]): Observable<ReportItem[]> {
    return this.httpClient
      .post(`${this.backendConfiguration.getWorkerEndpoint()}report-items/by-machine-types`, {machineTypes: machineTypes})
      .pipe(map((reportItemsJSON: any) => reportItemsJSON.map((reportItemJSON: any) => ReportItem.fromJSON(reportItemJSON))));
  }

  public getAllFromCatalog(machineTypes: MachineType[]): Observable<OverviewListReportItem[]> {
    return this.httpClient
      .post(`${this.backendConfiguration.getWorkerEndpoint()}report-items/catalog`, {machineTypes: machineTypes})
      .pipe(map((reportItemsJSON: any) => reportItemsJSON.map((reportItemJSON: any) => OverviewListReportItem.fromJSON(reportItemJSON))));
  }

  public addFromCatalog(ids: number[]): Observable<ReportItem[]> {
    return this.httpClient
      .post(`${this.backendConfiguration.getWorkerEndpoint()}report-items/catalog-import`, {ids})
      .pipe(map((reportItemsJSON: any) => reportItemsJSON.map((reportItemJSON: any) => ReportItem.fromJSON(reportItemJSON))));
  }
}
