import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {IdNameDescription} from '@domain/id-name-description';
import {GridModel} from '@vdw/angular-component-library';
import {map, Observable} from 'rxjs';
import {Repository} from '../repository';

@Injectable({providedIn: 'root'})
export class HttpPlasticProductGroupsService implements Repository<IdNameDescription, IdNameDescription> {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public getAll(): Observable<IdNameDescription[]> {
    return this.httpClient
      .get(`${this.backendConfiguration.getEndpoint()}plastic-product-groups`)
      .pipe(map((plasticProductGroupsJSON: any) => plasticProductGroupsJSON.map((plasticProductGroupJSON: any) => IdNameDescription.fromJSON(plasticProductGroupJSON))));
  }

  public delete(id: string | number): Observable<void> {
    throw new Error('Method not implemented.');
  }

  public getById(id: number): Observable<IdNameDescription> {
    throw new Error('Method not implemented.');
  }

  public save(item: IdNameDescription): Observable<number> {
    throw new Error('Method not implemented.');
  }

  public update(item: IdNameDescription): Observable<void | number> {
    throw new Error('Method not implemented.');
  }

  public getPossibleValues(columnIdentifier: string): Observable<string[]> {
    return this.httpClient.get(`${this.backendConfiguration.getEndpoint()}plastic-product-groups/${columnIdentifier}/possible-values`).pipe(map((response: string[]) => response));
  }

  public getSlice(gridModel: GridModel): Observable<IdNameDescription[]> {
    return this.httpClient
      .post(`${this.backendConfiguration.getEndpoint()}plastic-product-groups/get`, gridModel)
      .pipe(map((plasticProductGroupsJSON: any) => plasticProductGroupsJSON.map((plasticProductGroupJSON: any) => IdNameDescription.fromJSON(plasticProductGroupJSON))));
  }
}
