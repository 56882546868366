import {NgComponentOutlet} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CrudOverviewDataModule} from '../crud-overview-data/crud-overview-data.module';
import {LabelModule} from '../ellipsis-label/label.module';
import {ContentSwitcherDialogComponent} from './content-switcher-dialog.component';

@NgModule({
  imports: [NgComponentOutlet, CrudOverviewDataModule, MatIconModule, MatButtonModule, MatTooltipModule, LabelModule],
  declarations: [ContentSwitcherDialogComponent],
  exports: [ContentSwitcherDialogComponent]
})
export class ContentSwitcherModule {}
