import {leastCommonMultiple} from '@application/helper/textile-data/colored-yarn-set/least-common-multiple';
import {WeaveStructureForProductionSchedule} from '@domain/production-schedule/weave-structure-for-production-schedule';

export function convertHeightInMeterToPicks(heightInMeter: number, pickDensityInPicksPerMeter: number, weaveStructure: WeaveStructureForProductionSchedule): number {
  const commonMultiple = leastCommonMultiple(weaveStructure?.picksPerColorLine ?? 1, weaveStructure?.sendRepeat ?? 2);
  if (Math.ceil(heightInMeter * pickDensityInPicksPerMeter) % commonMultiple !== 0) {
    return Math.ceil(heightInMeter * pickDensityInPicksPerMeter);
  } else {
    return Math.ceil(heightInMeter * pickDensityInPicksPerMeter) + (Math.ceil(heightInMeter * pickDensityInPicksPerMeter) % commonMultiple);
  }
}
