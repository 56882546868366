import {ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthorizedRouteFactory as Factory} from '@application/helper/routing/authorized-route-factory';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {canDeactivateRouteWithUnsavedChanges} from '@application/security/can-deactivate-unsaved-changes-notifier.guard';
import {PlanningPrototypeComponent} from './planning-prototype.component';

export const planningPrototypeRoutes = [
  Factory.createPrototypeComponentRoute(
    PlanningPrototypeComponent,
    {route: '', requiredPermission: null, id: NavigationId.PLANNING_PROTOTYPE, parentId: NavigationId.PLANNING_PROTOTYPE_MODULE},
    [],
    [canDeactivateRouteWithUnsavedChanges]
  )
];

export const planningPrototypeRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(planningPrototypeRoutes);
