import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {NavigationUtils} from '@application/helper/routing/navigation-utils';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {AssertionUtils, LocalStorageService, MenuItemEntry, TranslateService} from '@vdw/angular-component-library';

@Injectable({providedIn: 'root'})
export class NavigationService {
  public cachedMenuItems: MenuItemEntry[];
  private readonly IS_SWITCHING_SUBSCRIPTION_KEY = 'isSwitchingSubscription';

  public constructor(
    private readonly router: Router,
    private readonly localStorage: LocalStorageService,
    private readonly translate: TranslateService
  ) {
    this.cachedMenuItems = this.composeMenuItems(null);
  }

  public getDefaultRoute(subscription: Subscription): string {
    const navigationBarRouteLinkItems = this.cachedMenuItems
      .flatMap((item: MenuItemEntry) => item.childEntries)
      .filter((item: MenuItemEntry) => this.hasRequiredPermission(item, subscription) && !AssertionUtils.isNullOrUndefined(item.route));

    const texStateNavigationItem = navigationBarRouteLinkItems.find((item: MenuItemEntry) => item.route === RouteUtils.paths.texState.absolutePath);

    return texStateNavigationItem ? texStateNavigationItem.route : navigationBarRouteLinkItems[0]?.route;
  }

  public composeMenuItems(subscription: Subscription): MenuItemEntry[] {
    return [
      {navigationKey: 'GENERAL.NAVIGATION.FAVOURITES', childEntries: [], isExpanded: true},
      {
        navigationKey: 'GENERAL.NAVIGATION.MONITORING',
        childEntries: [
          {
            navigationKey: 'GENERAL.NAVIGATION.PLANT_VIEW',
            icon: 'solid-monitoring',
            route: RouteUtils.paths.texState.absolutePath,
            requiredPermission: RouteUtils.paths.texState.requiredPermission
          },
          {
            navigationKey: 'GENERAL.NAVIGATION.EVENT_BOARD',
            icon: 'solid-analytics',
            route: RouteUtils.paths.texBi.machineEventBoard.absolutePath,
            requiredPermission: RouteUtils.paths.texBi.machineEventBoard.requiredPermission
          },
          {
            navigationKey: 'GENERAL.NAVIGATION.TRENDS_REPORTS',
            icon: 'solid-insights',
            childEntries: [
              {navigationKey: 'TEXBI.REPORTS', route: RouteUtils.paths.texBi.reports.absolutePath, requiredPermission: RouteUtils.paths.texBi.reports.requiredPermission},
              {
                navigationKey: 'TEXBI.WINDERVIBRATIONREPORT',
                route: RouteUtils.paths.texBi.windervibrationreport.absolutePath,
                requiredPermission: RouteUtils.paths.texBi.windervibrationreport.requiredPermission
              },
              {
                navigationKey: 'TEXBI.WMREPORTS',
                route: RouteUtils.paths.texBi.wmreports.absolutePath,
                requiredPermission: RouteUtils.paths.texBi.wmreports.requiredPermission
              },
              {
                navigationKey: 'TEXBI.QMREPORTS',
                route: RouteUtils.paths.texBi.qmreports.absolutePath,
                requiredPermission: RouteUtils.paths.texBi.qmreports.requiredPermission
              },
              {
                navigationKey: 'TEXBI.EMREPORTS',
                route: RouteUtils.paths.texBi.emreports.absolutePath,
                requiredPermission: RouteUtils.paths.texBi.emreports.requiredPermission
              },
              {
                navigationKey: 'MACHINE.WINDER_VIBRATION_FORM.TITLE',
                route: RouteUtils.paths.texBi.winderVibrationForm.absolutePath,
                requiredPermission: RouteUtils.paths.texBi.winderVibrationForm.requiredPermission
              }
            ]
          }
        ]
      },
      {
        navigationKey: 'GENERAL.NAVIGATION.SCHEDULING',
        childEntries: [
          {
            ...(NavigationUtils.getAbsoluteNavigationData(NavigationId.PLANNING_MODULE) as MenuItemEntry),
            shouldHide: subscription?.hasPermission(RouteUtils.paths.texFab.planningPrototype.requiredPermission)
              ? true
              : !subscription?.hasPermission(RouteUtils.paths.texFab.planning.requiredPermission)
          },
          NavigationUtils.getAbsoluteNavigationData(NavigationId.PLANNING_PROTOTYPE_MODULE) as MenuItemEntry,
          {
            navigationKey: 'PRODUCTION_ORDER.PRODUCTION_ORDER',
            icon: 'solid-list-alt',
            shouldHide: !subscription?.hasAtLeastOneOfThePermissions([
              NavigationUtils.getAbsoluteNavigationData(NavigationId.PRODUCTION_SCHEDULE_MODULE).requiredPermission,
              NavigationUtils.getAbsoluteNavigationData(NavigationId.WEAVING_PRODUCTION_ORDER_MODULE).requiredPermission,
              NavigationUtils.getAbsoluteNavigationData(NavigationId.TUFT_PRODUCTION_ORDER_MODULE).requiredPermission,
              NavigationUtils.getAbsoluteNavigationData(NavigationId.PLASTIC_PRODUCTION_ORDER_MODULE).requiredPermission
            ]),
            childEntries: [
              NavigationUtils.getAbsoluteNavigationData(NavigationId.PRODUCTION_SCHEDULE_MODULE) as MenuItemEntry,
              NavigationUtils.getAbsoluteNavigationData(NavigationId.WEAVING_PRODUCTION_ORDER_MODULE) as MenuItemEntry,
              NavigationUtils.getAbsoluteNavigationData(NavigationId.TUFT_PRODUCTION_ORDER_MODULE) as MenuItemEntry,
              NavigationUtils.getAbsoluteNavigationData(NavigationId.PLASTIC_PRODUCTION_ORDER_MODULE) as MenuItemEntry
            ]
          },
          {
            navigationKey: 'GENERAL.NAVIGATION.DEFINITIONS',
            icon: 'solid-edit-document',
            childEntries: [
              {
                navigationKey: 'TEXTILE_DATA.PLASTIC_PRODUCT.PRODUCT',
                childEntries: [
                  NavigationUtils.getAbsoluteNavigationData(NavigationId.WEAVE_PRODUCT_MODULE) as MenuItemEntry,
                  NavigationUtils.getAbsoluteNavigationData(NavigationId.TUFT_PRODUCT_MODULE) as MenuItemEntry,
                  NavigationUtils.getAbsoluteNavigationData(NavigationId.PLASTIC_PRODUCT_MODULE) as MenuItemEntry
                ]
              },
              {
                navigationKey: 'GENERAL.NAVIGATION.QUALITY_RELATED',
                childEntries: [
                  {
                    navigationKey: 'TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY',
                    route: RouteUtils.paths.texStyle.machineQuality.absolutePath,
                    requiredPermission: RouteUtils.paths.texStyle.machineQuality.requiredPermission
                  },
                  {
                    navigationKey: 'TEXTILE_DATA.WEAVE_STRUCTURE.WEAVE_STRUCTURE',
                    route: RouteUtils.paths.texStyle.weaveStructure.absolutePath,
                    requiredPermission: RouteUtils.paths.texStyle.weaveStructure.requiredPermission
                  },
                  {
                    navigationKey: 'TEXTILE_DATA.COMPANY_WEAVE_STRUCTURE.COMPANY_WEAVE_STRUCTURE',
                    route: RouteUtils.paths.texStyle.companyWeaveStructure.absolutePath,
                    requiredPermission: RouteUtils.paths.texStyle.companyWeaveStructure.requiredPermission
                  },
                  {
                    navigationKey: 'TEXTILE_DATA.WEAVE_STRUCTURE.MANAGE_WEAVE_STRUCTURE',
                    route: RouteUtils.paths.texStyle.weaveStructure.manageOverviewWeaveStructure.absolutePath,
                    requiredPermission: RouteUtils.paths.texStyle.weaveStructure.manageOverviewWeaveStructure.requiredPermission
                  },
                  {
                    navigationKey: 'TEXTILE_DATA.GROUPED_WEAVESTRUCTURE.GROUPED_WEAVESTRUCTURE',
                    route: RouteUtils.paths.texStyle.groupedWeaveStructure.overviewGroupedWeaveStructure.absolutePath,
                    requiredPermission: RouteUtils.paths.texStyle.groupedWeaveStructure.overviewGroupedWeaveStructure.requiredPermission
                  },
                  {
                    navigationKey: 'TEXTILE_DATA.FINISHING_TEMPLATE.FINISHING_TEMPLATE',
                    route: RouteUtils.paths.texStyle.finishingTemplate.absolutePath,
                    requiredPermission: RouteUtils.paths.texStyle.finishingTemplate.requiredPermission
                  },
                  NavigationUtils.getAbsoluteNavigationData(NavigationId.PATH_LAYOUT_TEMPLATE_MODULE) as MenuItemEntry,
                  {
                    navigationKey: 'TEXTILE_DATA.FIXED_ORDER.FIXED_ORDER',
                    route: RouteUtils.paths.texStyle.fixedSchedule.absolutePath,
                    requiredPermission: RouteUtils.paths.texStyle.fixedSchedule.requiredPermission
                  }
                ]
              },
              {
                navigationKey: 'GENERAL.NAVIGATION.YARN_RELATED',
                childEntries: [
                  {
                    navigationKey: 'TEXTILE_DATA.COLOR.COLOR',
                    route: RouteUtils.paths.texStyle.color.absolutePath,
                    requiredPermission: RouteUtils.paths.texStyle.color.requiredPermission
                  },
                  {
                    navigationKey: 'TEXTILE_DATA.COLOR_SET.COLOR_SET',
                    route: RouteUtils.paths.texStyle.colorSet.absolutePath,
                    requiredPermission: RouteUtils.paths.texStyle.colorSet.requiredPermission
                  },
                  {
                    navigationKey: 'TEXTILE_DATA.YARN_TYPE.YARN_TYPE',
                    route: RouteUtils.paths.texStyle.yarn.absolutePath,
                    requiredPermission: RouteUtils.paths.texStyle.yarn.requiredPermission
                  },
                  {
                    navigationKey: 'TEXTILE_DATA.YARN_SET.YARN_SET',
                    route: RouteUtils.paths.texStyle.yarnSet.absolutePath,
                    requiredPermission: RouteUtils.paths.texStyle.yarnSet.requiredPermission
                  },
                  {
                    navigationKey: 'TEXTILE_DATA.COLORED_YARN.COLORED_YARN',
                    route: RouteUtils.paths.texStyle.coloredYarn.absolutePath,
                    requiredPermission: RouteUtils.paths.texStyle.coloredYarn.requiredPermission
                  },
                  {
                    navigationKey: 'TEXTILE_DATA.COLORED_YARN_SET.COLORED_YARN_SET',
                    route: RouteUtils.paths.texStyle.coloredYarnSet.absolutePath,
                    requiredPermission: RouteUtils.paths.texStyle.coloredYarnSet.requiredPermission
                  },
                  {
                    navigationKey: 'TEXTILE_DATA.WEFT_COLORED_YARN_SET.WEFT_COLORED_YARN_SET',
                    route: RouteUtils.paths.texStyle.weftColoredYarnSet.absolutePath,
                    requiredPermission: RouteUtils.paths.texStyle.weftColoredYarnSet.requiredPermission
                  },
                  {
                    navigationKey: 'TEXTILE_DATA.CREEL.CREEL',
                    route: RouteUtils.paths.texStyle.creel.absolutePath,
                    requiredPermission: RouteUtils.paths.texStyle.creel.requiredPermission
                  }
                ]
              }
            ]
          },
          {
            navigationKey: 'GENERAL.NAVIGATION.DESIGNS',
            icon: 'solid-image',
            shouldHide: !subscription?.hasPermission(Permission.DESIGNS_VIEW),
            childEntries: [
              {
                navigationKey: 'DESIGN_LIBRARY.DESIGN_LIBRARY',
                route: RouteUtils.paths.texEdit.designLibrary.absolutePath,
                requiredPermission: RouteUtils.paths.texEdit.designLibrary.requiredPermission
              },
              {
                navigationKey: 'TECHNICAL_DATASHEETS.DATASHEET',
                route: RouteUtils.paths.texEdit.dataSheets.absolutePath,
                requiredPermission: RouteUtils.paths.texEdit.dataSheets.requiredPermission
              },
              {
                navigationKey: 'TEXEDIT_CARDS.EXTERNAL_APPLICATIONS.TUFTLINK.TITLE',
                route: RouteUtils.paths.texEdit.tuftLink.absolutePath,
                requiredPermission: RouteUtils.paths.texEdit.tuftLink.requiredPermission
              },
              {
                navigationKey: 'PRODUCT_CONFIGURATION.PRODUCT_CONFIGURATION',
                route: RouteUtils.paths.texEdit.productConfiguration.absolutePath,
                requiredPermission: RouteUtils.paths.texEdit.productConfiguration.requiredPermission
              }
            ]
          },
          {
            navigationKey: 'GENERAL.NAVIGATION.SALES',
            icon: 'solid-storefront',
            shouldHide: !subscription?.hasPermission(Permission.SALES_VIEW),
            childEntries: [
              NavigationUtils.getAbsoluteNavigationData(NavigationId.CUSTOMER_MODULE) as MenuItemEntry,
              NavigationUtils.getAbsoluteNavigationData(NavigationId.ARTICLE_MODULE) as MenuItemEntry,
              NavigationUtils.getAbsoluteNavigationData(NavigationId.SALES_ORDER_MODULE) as MenuItemEntry,
              NavigationUtils.getAbsoluteNavigationData(NavigationId.ORDERBOOK_MODULE) as MenuItemEntry
            ]
          }
        ]
      },
      {
        navigationKey: 'GENERAL.NAVIGATION.RESOURCES',
        childEntries: [
          {
            navigationKey: 'GENERAL.NAVIGATION.CALENDAR',
            icon: 'solid-calendar-month',
            childEntries: [
              {
                navigationKey: 'CALENDAR.SHIFT.SHIFT',
                route: RouteUtils.paths.calendar.shift.absolutePath,
                requiredPermission: RouteUtils.paths.calendar.shift.requiredPermission
              }
            ]
          },
          {
            navigationKey: 'GENERAL.NAVIGATION.DOCUMENTS_AND_TRAINING',
            icon: 'solid-article',
            requiredPermission: RouteUtils.paths.texBot.requiredPermission,
            childEntries: [
              {
                navigationKey: 'MACHINE_DOCUMENTATION.DOCUMENTATION',
                route: RouteUtils.paths.texBot.machineDocumentation.absolutePath,
                requiredPermission: RouteUtils.paths.texBot.machineDocumentation.requiredPermission
              },
              {
                navigationKey: 'TRAINING.TITLE',
                route: RouteUtils.paths.texBot.training.absolutePath,
                requiredPermission: RouteUtils.paths.texBot.training.requiredPermission
              },
              {
                navigationKey: this.translate.instant('GENERAL.ACTIONS.MANAGE_OBJECT', {object: 'TRAINING.TITLE'}),
                route: RouteUtils.paths.texBot.training.manage.absolutePath,
                requiredPermission: RouteUtils.paths.texBot.training.manage.requiredPermission
              }
            ]
          },
          {
            navigationKey: 'GENERAL.NAVIGATION.EQUIPMENT',
            icon: 'solid-forklift',
            requiredPermission: RouteUtils.paths.utilities.requiredPermission,
            childEntries: [
              NavigationUtils.getAbsoluteNavigationData(NavigationId.TOOL_MODULE) as MenuItemEntry,
              {
                navigationKey: 'PLANNING.BUGGY.BUGGIES',
                route: RouteUtils.paths.texStyle.buggy.absolutePath,
                requiredPermission: RouteUtils.paths.texStyle.buggy.requiredPermission
              }
            ]
          }
        ]
      }
    ];
  }

  public composeProcessItem(): MenuItemEntry {
    return {navigationKey: 'BACKGROUND_JOBS.PROCESSES', requiredPermission: Permission.JOB_VIEW};
  }

  public setIsSwitchingSubscription(): void {
    this.localStorage.set(this.IS_SWITCHING_SUBSCRIPTION_KEY, true);
  }

  public removeIsSwitchingSubscription(): void {
    this.localStorage.remove(this.IS_SWITCHING_SUBSCRIPTION_KEY);
  }

  public checkIsSwitchingSubscription(subscription: Subscription): boolean {
    if (this.localStorage.get(this.IS_SWITCHING_SUBSCRIPTION_KEY)) {
      this.removeIsSwitchingSubscription();
      this.router.navigateByUrl(this.getDefaultRoute(subscription));
      return true;
    }
    return false;
  }

  private hasRequiredPermission(item: MenuItemEntry, subscription: Subscription): boolean {
    return AssertionUtils.isNullOrUndefined(item.requiredPermission) || subscription?.hasPermission(item.requiredPermission);
  }
}
