import {FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {NameGeneratorProperty} from '@domain/custom-settings/name-generation-property';
import {NameGeneratorPartType} from '@domain/custom-settings/name-generator-part-type.enum';
import {NameGeneratorPartForm} from './name-generation-form-type';

export function serialNumberNotInMiddleValidator(partId: number, partsFormGroup: NameGeneratorPartForm[]): ValidatorFn {
  return (property: FormControl<NameGeneratorProperty>): ValidationErrors | null => {
    if (property.value.type !== NameGeneratorPartType.ALPHABETIC_SERIAL_NUMBER && property.value.type !== NameGeneratorPartType.NUMERIC_SERIAL_NUMBER) {
      return null;
    }
    const index = partsFormGroup.findIndex((part: NameGeneratorPartForm) => part.value.id === partId);
    return index !== 0 && index !== partsFormGroup.length - 1 ? {serialNumberInMiddle: true} : null;
  };
}
