<div class="container bms-theme">
  <button
    class="selected-text font-weight-semibold"
    mat-button
    [matTooltip]="'ANGULAR_COMPONENT_LIBRARY.GRID_SELECTION_OPTIONS.DESELECT_ITEMS' | bmsTranslate"
    matTooltipPosition="above"
    (click)="deselect()"
  >
    {{ selectedNodes }} {{ 'ANGULAR_COMPONENT_LIBRARY.GRID_SELECTION_OPTIONS.SELECTED' | bmsTranslate }}
  </button>
  <div class="divider">|</div>
  <button
    *ngFor="let button of buttons"
    [class.no-display]="shouldHideButton(button)"
    mat-icon-button
    [matTooltip]="button.title | bmsTranslate"
    matTooltipPosition="above"
    [matTooltipDisabled]="!button.withTooltip ?? true"
    class="mat-icon-action-button-lg-with-border button"
    (click)="button.onClick({gridApi: grid?.api})"
  >
    <mat-icon [svgIcon]="button.icon"></mat-icon>
  </button>
  <vdw-dialog-button
    *ngIf="hasCustomActions"
    class="dialog-button"
    #extraOptionsButton
    [componentType]="extraOptionsComponent"
    [dialogData]="{extraActions, grid: data.grid}"
    [dialogClass]="'no-border'"
    [verticaloffset]="2"
    [horizontalOffset]="86"
  >
    <button
      mat-icon-button
      class="mat-icon-action-button-lg-with-border"
      [class.open]="extraOptionsButton.open"
      (click)="extraOptionsButton.toggleDialog()"
      [matTooltip]="'ANGULAR_COMPONENT_LIBRARY.GRID_SELECTION_OPTIONS.MORE_ACTIONS' | bmsTranslate"
      matTooltipPosition="above"
      matTooltipClass="bms-theme"
    >
      <mat-icon svgIcon="solid-more-vert"></mat-icon>
    </button>
  </vdw-dialog-button>
  <vdw-slide-toggle
    class="toggle"
    [label]="'ANGULAR_COMPONENT_LIBRARY.GRID_SELECTION_OPTIONS.ONLY_SHOW_SELECTED' | bmsTranslate"
    [checked]="onlyShowSelected"
    (checkedChange)="toggleShowOnlySelected($event)"
  ></vdw-slide-toggle>
</div>
