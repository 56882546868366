import {AbstractControl} from '@angular/forms';
import {Router} from '@angular/router';
import {AssertionUtils, UnhandledBackendError} from '@vdw/angular-component-library';
import {NavigationHelperService} from './navigation-helper/navigation-helper.service';

export class ErrorHandlers {
  public static navigateToOverviewAndThrowError(router: Router, overviewPath: string, navigationHelperService: NavigationHelperService<any> = null): (error: any) => void {
    return (error: any): void => {
      if (!AssertionUtils.isNullOrUndefined(navigationHelperService)) {
        navigationHelperService.navigateToPreviousRoute(overviewPath);
      } else {
        router.navigateByUrl(overviewPath);
      }
      if (error instanceof UnhandledBackendError) {
        error.autoNavigated = true;
      }
      throw error;
    };
  }

  public static getLoadLink(editUrl: string, formControl: AbstractControl): string {
    return editUrl.replace(':id', formControl.errors.entityId);
  }
}
