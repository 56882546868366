import {DimensionsInPx} from '@domain/dimensions-in-px';
import {Drawing} from '@domain/production-schedule/drawing';
import {DrawingConfiguration} from '@domain/production-schedule/drawing-configuration';
import {DrawingConfigurationWithColoredYarnSet} from '@domain/production-schedule/drawing-configuration-with-colored-yarn-set';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {Finishing} from '@domain/textile-data/finishing-and-finishing-template/finishing/finishing';
import {isNil, map} from 'lodash-es';
import {DrawingConfigurationWithColorAndYarnSet} from './drawing-configuration-with-color-and-yarn-set';
import {DrawingImage} from './drawing-image';
import {MappingForDesign} from './mapping-for-design';

export class BmpDrawing extends Drawing {
  private _finishing: Finishing;

  public constructor(
    id: number,
    name: string,
    image: DrawingImage,
    dimensionsInPixels: DimensionsInPx,
    finishing: Finishing,
    shape: string,
    used: boolean,
    repeated: boolean,
    mappingForDesign: MappingForDesign[] = [],
    code?: string,
    configurations: DrawingConfiguration[] = [],
    threadByThread: boolean = false,
    nrColumnsForWeftSelection: number = 0,
    sizeInKB?: number,
    dateModified?: Date,
    missingDesign?: boolean,
    shapeId?: number
  ) {
    super(
      id,
      name,
      DrawingType.BMP,
      image,
      dimensionsInPixels,
      shape,
      used,
      repeated,
      mappingForDesign,
      code,
      configurations,
      threadByThread,
      nrColumnsForWeftSelection,
      sizeInKB,
      dateModified,
      missingDesign,
      shapeId
    );

    this._finishing = finishing;
  }

  public get finishing(): Finishing {
    return this._finishing;
  }

  public set finishing(value: Finishing) {
    this._finishing = value;
  }

  public static fromJSON(drawingJSON: any): BmpDrawing {
    const image: DrawingImage = isNil(drawingJSON.image)
      ? {}
      : {
          id: drawingJSON.image.id || null,
          data: drawingJSON.image.data || null,
          signature: drawingJSON.image.signature || null
        };

    let configurations: DrawingConfiguration[] = [];
    if (!isNil(drawingJSON.configuration)) {
      configurations = [this.drawingConfigurationFromJSON(drawingJSON.configuration)];
    } else if (!isNil(drawingJSON.configurations)) {
      configurations = map(drawingJSON.configurations, (configurationJSON: any) => this.drawingConfigurationFromJSON(configurationJSON));
    }

    return new BmpDrawing(
      drawingJSON.id,
      drawingJSON.name,
      image,
      {
        widthInPx: drawingJSON.dimensionsInPx.widthInPx,
        heightInPx: drawingJSON.dimensionsInPx.heightInPx
      },
      null,
      drawingJSON.shape,
      drawingJSON.used,
      drawingJSON.repeated,
      map(drawingJSON.mappingForDesign, (mappingForDesignJSON: MappingForDesign) => MappingForDesign.fromJSON(mappingForDesignJSON)),
      drawingJSON.drawingCode ?? drawingJSON.code,
      configurations,
      drawingJSON.threadByThread,
      drawingJSON.nrColumnsForWeftSelection,
      drawingJSON.sizeInKB,
      drawingJSON.dateModified,
      drawingJSON.missingDesign,
      drawingJSON.shapeId
    );
  }

  private static drawingConfigurationFromJSON(configurationJSON: any): DrawingConfiguration {
    return !isNil(configurationJSON.coloredYarnSet) ? DrawingConfigurationWithColoredYarnSet.fromJSON(configurationJSON) : DrawingConfigurationWithColorAndYarnSet.fromJSON(configurationJSON);
  }
}
