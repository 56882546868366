import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {dateComparator} from '@application/helper/date-comparator';
import {CrudActions} from '@application/helper/grid-action-buttons-helper/crud-actions.interface';
import {GridActionButtonsHelper} from '@application/helper/grid-action-buttons-helper/grid-action-buttons-helper';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {NavigationUtils} from '@application/helper/routing/navigation-utils';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {PlasticProductionOrder} from '@domain/texfab/plastic-production-order/plastic-production-order';
import {HttpPlasticProductionOrdersService} from '@infrastructure/http/plastic-production-order/http-plastic-production-orders.service';
import {TextileService} from '@presentation/pages/textile-data/textile-data-overview/textile.service';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {
  AgGridUtils,
  BaseComponent,
  ColDefBuilderFactoryService,
  CrudOverviewDataBuilderFactoryService,
  CrudOverviewDataConfig,
  DialogBuilderFactoryService,
  GridOptionsBuilder,
  LinkIconRendererComponent,
  NoDataOverlayComponentParams,
  OverlayComponentParams,
  StringUtils,
  TranslateService
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ICellRendererParams, IRowNode, ITooltipParams, ValueGetterParams} from 'ag-grid-community';
import {ProductionScheduleOverviewStatusComponent} from '../../production-schedule/overview/status/production-schedule-overview-status.component';
import {PlasticProductionOrderCustomSettingsComponent} from '../custom-settings/plastic-production-order-custom-settings.component';

@Component({
  templateUrl: './plastic-production-order-overview-page.component.html'
})
export class PlasticProductionOrderOverviewPageComponent extends BaseComponent implements OnInit {
  public config: CrudOverviewDataConfig<PlasticProductionOrder>;
  private readonly pageUrls = LastModifiedCardUtils.getPageUrls('plasticProductionOrder');

  public constructor(
    private readonly colDefBuilderFactory: ColDefBuilderFactoryService,
    private readonly crudOverviewDataBuilderFactory: CrudOverviewDataBuilderFactoryService<PlasticProductionOrder>,
    private readonly plasticProductionOrders: HttpPlasticProductionOrdersService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly gridActionButtonsHelper: GridActionButtonsHelper,
    private readonly textileService: TextileService,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService
  ) {
    super();
  }

  public ngOnInit(): void {
    const colDefs = [
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('GENERAL.STATUS')
        .withColIdAndField('status')
        .withCellRenderer(ProductionScheduleOverviewStatusComponent)
        .withTooltipValueGetter((params: ITooltipParams) => this.translate.instant(`PRODUCTION_ORDER.STATUS.${(params.data as PlasticProductionOrder).status}`))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('GENERAL.NAME')
        .withColIdAndField('name', true)
        .withComparator(StringUtils.stringComparator)
        .withCellRenderer(LinkIconRendererComponent, (params: ICellRendererParams) => {
          return {
            params,
            editPageUrl: this.pageUrls.edit
          };
        })
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('GENERAL.DATETIME.DUE_DATE')
        .withColIdAndField('dueDate')
        .withCellRenderer((params: ICellRendererParams) => AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.getValue()))
        .withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellDateAsDDMMYYYY(params.value))
        .withComparator(dateComparator)
        .withDateMultiFilter()
        .build(),
      this.colDefBuilderFactory.getBuilder().withHeaderName('TEXTILE_DATA.PLASTIC_PRODUCT.PRODUCT').withColIdAndField('plasticProduct.name', true).withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('GENERAL.QUANTITY')
        .withColId('plannedQuantity')
        .withValueGetter(
          (params: ValueGetterParams) =>
            `${params.data.plannedQuantity} ${this.translate.instant(`TEXTILE_DATA.PLASTIC_PRODUCT.UNIT_OF_MEASUREMENT.${params.data.plasticProduct.unitOfMeasurement}`).toLowerCase()}`,
          true
        )
        .withCellClass('right')
        .build(),
      this.colDefBuilderFactory.getBuilder().withHeaderName('MACHINE.MACHINE').withColIdAndField('machine.name', true).withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactory.getBuilder().withHeaderName('UTILITIES.TOOL.TOOL').withColIdAndField('tool.name', true).withComparator(StringUtils.stringComparator).build()
    ];

    this.config = this.crudOverviewDataBuilderFactory
      .getBuilder()
      .withDefaultButtons(
        this.gridActionButtonsHelper.getActionButtonsForGridSelection({
          requiredPermissionToEdit: RouteUtils.paths.texFab.plastic.editProductionOrder.requiredPermission,
          withEdit: (grid: AgGridAngular | IRowNode) => this.navigateToGivenRoute(grid, 'edit'),
          withDuplicate: (grid: AgGridAngular | IRowNode) => this.navigateToGivenRoute(grid, 'duplicate'),
          withDelete: (grid: AgGridAngular | IRowNode) => this.delete(grid),
          allowDeleteMultiple: false
        } as CrudActions)
      )
      .withGridOptions(colDefs, GridIdentifier.PLASTIC_PRODUCTION_ORDER_OVERVIEW, null, (builder: GridOptionsBuilder) =>
        builder
          .withRowSelection(false, false, true)
          .withLoadingOverlay({
            scale: 0.7
          } as OverlayComponentParams)
          .withNoRowsOverlay({
            scale: 0.7,
            titleParam: this.translate.instant(NavigationUtils.getNavigationData(NavigationId.PLASTIC_PRODUCTION_ORDER_MODULE).navigationKey, {count: 1}),
            hideDescription: true
          } as NoDataOverlayComponentParams)
          .build()
      )
      .withRowData(this.plasticProductionOrders.getAll())
      .withEntityName(this.translate.instant(NavigationUtils.getNavigationData(NavigationId.PLASTIC_PRODUCTION_ORDER_MODULE).navigationKey, {count: 1}))
      .withCustomSettingsClicked(() => this.openCustomSettings())
      .withNewClicked(() => this.router.navigateByUrl(this.pageUrls.add))
      .build();
  }

  private navigateToGivenRoute(grid: AgGridAngular | IRowNode, action: string): void {
    this.router.navigateByUrl(this.pageUrls[action].replace(':id', grid instanceof AgGridAngular ? grid?.api?.getSelectedRows()[0].id : grid.id));
  }

  private delete(grid: AgGridAngular | IRowNode): void {
    this.textileService.removeConfirmation(
      grid instanceof AgGridAngular ? grid?.api?.getSelectedRows()[0] : grid.data,
      TextileDataType.PLASTIC_PRODUCTION_ORDER,
      false,
      null,
      this.plasticProductionOrders
    );
  }

  private openCustomSettings(): void {
    this.dialogBuilderFactoryService.getBuilder().withClass('large-modal').openDialog(PlasticProductionOrderCustomSettingsComponent);
  }
}
