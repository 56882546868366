<section class="flex-column full-height bms-theme-content">
  <vdw-editor-header
    [identifier]="HEADER_IDENTIFIER"
    [menuItems]="menuItems"
    [selectedMenuItem]="selectedMenuItem"
    [canShowSave]="!isDialog"
    [canShowSettings]="false"
    [isCreateNewPage]="!isEditingPlasticProduct()"
    [inProgress]="saving"
    (duplicateEvent)="duplicatePlasticProduct()"
    (saveEvent)="savePlasticProduct(SAVE_TYPE.SAVE)"
    (saveAndCloseEvent)="savePlasticProduct(SAVE_TYPE.SAVE_AND_CLOSE)"
    (saveAndCreateNewEvent)="savePlasticProduct(SAVE_TYPE.SAVE_AND_CREATE_NEW)"
    (cancelEvent)="navigateBack()"
    (deleteEvent)="deletePlasticProduct()"
    (selectedMenuItemChange)="selectedMenuItem = $event"
  ></vdw-editor-header>
  <div class="bms-theme flex-columns p-48 overflow-auto">
    @if (canShowForm(VIEW_MODE.GENERAL)) {
      <form [formGroup]="generalForm" class="flex-column" vdwBp.gt-sm="pr-64 half-width" vdwBp.lt-sm="full-width" appBreadcrumbFormLinker>
        <vdw-string-input
          [label]="'GENERAL.NAME' | bmsTranslate"
          [placeholder]="'GENERAL.NAME_PLACEHOLDER' | bmsTranslate: {object: 'TEXTILE_DATA.PLASTIC_PRODUCT.PRODUCT' | bmsTranslate: {count: 1} | lowercase}"
          formControlName="name"
        >
          <ng-container error>
            <vdw-invalid-form-message [message]="'GENERAL.ERRORS.REQUIRED' | bmsTranslate" [compact]="true" *vdwCanShowErrorForFormControl="'name'; error: 'required'"></vdw-invalid-form-message>
            <vdw-invalid-form-message
              [message]="'GENERAL.ERRORS.NAME_DUPLICATE' | bmsTranslate"
              [link]="getLoadLink()"
              [compact]="true"
              *vdwCanShowErrorForFormControl="'name'; error: 'identifierTaken'"
            ></vdw-invalid-form-message>
          </ng-container>
        </vdw-string-input>
        <mat-form-field class="full-width">
          <mat-label>{{ 'TEXTILE_DATA.PLASTIC_PRODUCT.PRODUCT_GROUP' | bmsTranslate: {count: 1} }}</mat-label>
          <vdw-object-selection
            formControlName="productGroup"
            [objectName]="'TEXTILE_DATA.PLASTIC_PRODUCT.PRODUCT_GROUP' | bmsTranslate | lowercase"
            [canShowMultiSelectPlaceHolder]="false"
            [canShowBMSTheme]="true"
            [withDialogObjectSelection]="true"
            (selectClicked)="selectProductGroup()"
          ></vdw-object-selection>
        </mat-form-field>
        <div class="flex-row gap-16">
          <mat-form-field class="full-width">
            <mat-label>{{ 'TEXTILE_DATA.PLASTIC_PRODUCT.UNIT_OF_MEASUREMENT.UNIT_OF_MEASUREMENT' | bmsTranslate }}</mat-label>
            <mat-select disableOptionCentering formControlName="unitOfMeasurement">
              @for (unit of unitOfMeasurement; track $index) {
                <mat-option [value]="unit"> {{ 'TEXTILE_DATA.PLASTIC_PRODUCT.UNIT_OF_MEASUREMENT.' + unit | bmsTranslate }} </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>{{ 'UTILITIES.TOOL.WEIGHT' | bmsTranslate }}</mat-label>
            <input matInput type="number" vdwL10nDecimal [decimals]="true" min="0" formControlName="weight" placeholder="0" />
            @if (generalForm.controls?.weight.value !== null) {
              <button class="clear-input-button" tabindex="-1" mat-icon-button type="button" (click)="generalForm.controls?.weight.reset()">
                <mat-icon svgIcon="solid-close" class="icon-12"></mat-icon>
              </button>
            }
            <span matSuffix>
              {{ getWeightSuffixTranslationKey() | bmsTranslate }}
            </span>
            <mat-error *vdwCanShowErrorForFormControl="'weight'; error: 'greaterThan'">
              <vdw-invalid-form-message [message]="'GENERAL.ERRORS.GREATER_THAN' | bmsTranslate: {greaterThan: 0}" [compact]="true"></vdw-invalid-form-message>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex-row gap-16">
          <mat-form-field class="full-width">
            <mat-label>{{ 'TEXTILE_DATA.PLASTIC_PRODUCT.MATERIAL' | bmsTranslate }}</mat-label>
            <vdw-object-selection
              formControlName="material"
              [objectName]="'TEXTILE_DATA.PLASTIC_PRODUCT.MATERIAL' | bmsTranslate | lowercase"
              [canShowMultiSelectPlaceHolder]="false"
              [canShowBMSTheme]="true"
              [withDialogObjectSelection]="true"
              (selectClicked)="selectMaterial()"
            ></vdw-object-selection>
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>{{ 'TEXTILE_DATA.COLOR.COLOR' | bmsTranslate: {count: 1} }}</mat-label>
            <vdw-object-selection
              formControlName="color"
              [objectName]="'TEXTILE_DATA.COLOR.COLOR' | bmsTranslate: {count: 1} | lowercase"
              [canShowMultiSelectPlaceHolder]="false"
              [canShowBMSTheme]="true"
              [withDialogObjectSelection]="true"
              (selectClicked)="selectColor()"
            ></vdw-object-selection>
          </mat-form-field>
        </div>
        <mat-form-field class="full-width">
          <mat-label>{{ 'CUSTOMERS.CUSTOMER' | bmsTranslate: {count: 1} }}</mat-label>
          <vdw-object-selection
            formControlName="customer"
            [objectName]="'CUSTOMERS.CUSTOMER' | bmsTranslate: {count: 1} | lowercase"
            [canShowMultiSelectPlaceHolder]="false"
            [canShowBMSTheme]="true"
            [withDialogObjectSelection]="true"
            (selectClicked)="selectCustomer()"
          ></vdw-object-selection>
        </mat-form-field>
        <div class="flex-row gap-16">
          <mat-form-field class="full-width">
            <mat-label>{{ 'MACHINE.DETAILS.STANDARD_EFFICIENCY' | bmsTranslate }}</mat-label>
            <input matInput type="number" vdwL10nDecimal [decimals]="true" min="0" formControlName="standardEfficiency" placeholder="0" />
            @if (generalForm.controls?.standardEfficiency.value !== null) {
              <button class="clear-input-button" tabindex="-1" mat-icon-button type="button" (click)="generalForm.controls?.standardEfficiency.reset()">
                <mat-icon svgIcon="solid-close" class="icon-12"></mat-icon>
              </button>
            }
            <span matSuffix>
              {{ 'GENERAL.UNIT.PERCENTAGE' | bmsTranslate }}
            </span>
            <mat-error *vdwCanShowErrorForFormControl="'standardEfficiency'; error: 'min'">
              <vdw-invalid-form-message [message]="'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: 0}" [compact]="true"></vdw-invalid-form-message>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>{{ 'UTILITIES.TOOL.SPEED' | bmsTranslate }}</mat-label>
            <input matInput type="number" vdwL10nDecimal min="0" formControlName="standardCycleTime" placeholder="0" />
            @if (generalForm.controls?.standardCycleTime.value !== null) {
              <button class="clear-input-button" tabindex="-1" mat-icon-button type="button" (click)="generalForm.controls?.standardCycleTime.reset()">
                <mat-icon svgIcon="solid-close" class="icon-12"></mat-icon>
              </button>
            }
            <mat-error *vdwCanShowErrorForFormControl="'standardCycleTime'; error: 'min'">
              <vdw-invalid-form-message [message]="'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: 0}" [compact]="true"></vdw-invalid-form-message>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>{{ 'UTILITIES.TOOL.SPEED_UNIT.SPEED_UNIT' | bmsTranslate }}</mat-label>
            <mat-select disableOptionCentering formControlName="speedUnit" panelClass="bms-theme">
              @for (speedUnit of speedUnits; track $index) {
                <mat-option [value]="speedUnit"> {{ 'UTILITIES.TOOL.SPEED_UNIT.' + speedUnit | bmsTranslate }} </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <vdw-string-input [label]="'GENERAL.DESCRIPTION' | bmsTranslate" [placeholder]="'GENERAL.DESCRIPTION_PLACEHOLDER' | bmsTranslate" formControlName="description" formControlName="description">
        </vdw-string-input>
      </form>
    } @else if (canShowForm(VIEW_MODE.SETUP)) {
      <app-plastic-product-setup [addPlasticProductSetupForm]="setupForm"></app-plastic-product-setup>
    } @else if (canShowForm(VIEW_MODE.MATCHING_CRITERIA)) {
      <app-matching-criteria
        [form]="matchingCriteriaForm"
        [matchingcriteriaData]="matchingCriteriaData"
        [matchingCriteria]="matchingCriteria"
        [enableDragHandle]="true"
        [enableMultiRowSelect]="false"
        [enableMultiRowSelectWithClick]="false"
        [enableMultiRowSelectWithCheckbox]="false"
        [showStandardEfficiencyColumn]="true"
        [editPermission]="getPlasticProductPermission()"
        (matchingCriteriaChange)="matchingCriteria = $event"
        class="full-width full-height"
      ></app-matching-criteria>
    }
  </div>
</section>
