import {Component, HostListener, OnInit} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {isClickOutsideGivenElements} from '@application/helper/is-click-outside-given-elements';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {PlanningEquipment} from '@domain/planning-prototype/planning-equipment';
import {PlanningItem} from '@domain/planning-prototype/planning-item';
import {AssertionUtils, BaseComponent} from '@vdw/angular-component-library';
import {takeUntil} from 'rxjs';
import {PlanningPrototypeContextService, SelectedItemType} from '../utilities/planning-prototype-context.service';
import {NavigationPlanningPrototypeSidebar} from './planning-order-sidebar/navigation-planning-prototype-sidebar.interface';

@Component({
  selector: 'app-planning-prototype-sidebar',
  templateUrl: './planning-prototype-sidebar.component.html'
})
export class PlanningPrototypeSidebarComponent extends BaseComponent implements OnInit {
  private readonly CLASSES_THAT_SHOULD_NOT_CLOSE_SIDEBAR = [
    'maintenance',
    'mat-sidebar',
    'needs-attention',
    'fixed-schedules',
    'order',
    'row-indicator',
    'run',
    'mat-drawer-inner-container',
    'cdk-overlay-container'
  ];

  public selectedItemIsMachine = false;
  public selectedItemIsPlanningItem = false;
  public showOrdersToPlan = false;
  public showItemCreation = false;
  public showFixedSchedulesToPlan = false;

  public constructor(
    private readonly planningContext: PlanningPrototypeContextService,
    private readonly sideNav: MatSidenav,
    private readonly navigationService: NavigationHelperService<NavigationPlanningPrototypeSidebar>
  ) {
    super();
  }

  public ngOnInit(): void {
    this.planningContext.selectedItemChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(this.onSelectedItemChanged);
    let obj = {
      selectedItemIsMachine: null,
      selectedItemIsPlanningItem: null,
      showOrdersToPlan: null,
      showItemCreation: null,
      showFixedSchedulesToPlan: null
    };
    let prevState = this.navigationService.getPartialState<NavigationPlanningPrototypeSidebar>(Object.keys(obj));
    if (!AssertionUtils.isNullOrUndefined(prevState)) {
      this.selectedItemIsMachine = prevState.selectedItemIsMachine;
      this.selectedItemIsPlanningItem = prevState.selectedItemIsPlanningItem;
      this.showOrdersToPlan = prevState.showOrdersToPlan;
      this.showItemCreation = prevState.showItemCreation;
      this.showFixedSchedulesToPlan = prevState.showFixedSchedulesToPlan;
    }
  }

  public onNavigationHelperDestroy(): void {
    this.navigationService.savePartialState<NavigationPlanningPrototypeSidebar>({
      selectedItemIsMachine: this.selectedItemIsMachine,
      selectedItemIsPlanningItem: this.selectedItemIsPlanningItem,
      showOrdersToPlan: this.showOrdersToPlan,
      showItemCreation: this.showItemCreation,
      showFixedSchedulesToPlan: this.showFixedSchedulesToPlan
    });
  }

  @HostListener('document:click', ['$event'])
  public onClickOutsideSidebar(event: MouseEvent): void {
    if (isClickOutsideGivenElements(event.target as HTMLElement, this.CLASSES_THAT_SHOULD_NOT_CLOSE_SIDEBAR)) {
      this.planningContext.selectItem(null);
    }
  }

  private onSelectedItemChanged = (selectedItem: SelectedItemType): void => {
    if (AssertionUtils.isNullOrUndefined(selectedItem)) {
      this.sideNav.close();
    } else {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      this.sideNav.open();
    }
    this.selectedItemIsMachine = selectedItem instanceof PlanningEquipment;
    this.selectedItemIsPlanningItem = selectedItem instanceof PlanningItem;
    this.showOrdersToPlan = selectedItem === this.planningContext.ordersToPlan;
    this.showItemCreation = selectedItem === this.planningContext.itemCreationPlaceholder;
    this.showFixedSchedulesToPlan = selectedItem === this.planningContext.fixedSchedulesToPlan;
    if (!this.showItemCreation) {
      this.planningContext.itemCreationPlaceholder = null;
    }
  };
}
