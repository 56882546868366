import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {NameGeneratorSeparator} from '@domain/name-generator/enums/name-generator-separator.enum';
import {NameGenerator} from '@domain/name-generator/name-generator';
import {getProperty, getPropertyValue, PropertyValue} from '@domain/property-value';
import {HttpPathLayoutTemplatesService} from '@infrastructure/http/path-layout-template/http-path-layout-templates.service';
import {BaseComponent, EnumUtils, ObjectActionType, ToastHelperService, TranslateService} from '@vdw/angular-component-library';
import {finalize, forkJoin, takeUntil} from 'rxjs';

@Component({
  templateUrl: './path-layout-template-settings.component.html'
})
export class PathLayoutTemplateSettingsComponent extends BaseComponent implements OnInit {
  public nameGenerationProperties: string[];
  public generateNameConfiguration: NameGenerator;
  public pathLayoutTemplateCustomSettingsForm: FormGroup<{namePlaceholderSeparator: FormControl<NameGeneratorSeparator>}>;
  public saving = false;

  private listOfCustomSettings: PropertyValue[] = [];

  public constructor(
    private readonly pathLayoutTemplates: HttpPathLayoutTemplatesService,
    private readonly formBuilder: FormBuilder,
    private readonly navigationHelperService: NavigationHelperService<PropertyValue[]>,
    private readonly toastHelperService: ToastHelperService,
    private readonly translate: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.pathLayoutTemplateCustomSettingsForm = this.formBuilder.group({namePlaceholderSeparator: this.formBuilder.control(NameGeneratorSeparator.NO_SEPARATOR)});
    this.getCustomSettingsAndNameGenerationProperties();
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.onDestroy();
  }

  public navigateBack(): void {
    this.navigationHelperService.navigateToPreviousRoute(RouteUtils.paths.texFab.pathLayoutTemplate.absolutePath);
  }

  public saveCustomSettings(): void {
    this.saving = true;
    this.generateNameConfiguration.version = this.generateNameConfiguration.version ?? 1;
    this.generateNameConfiguration.separator = this.pathLayoutTemplateCustomSettingsForm.value.namePlaceholderSeparator;
    getProperty(this.listOfCustomSettings, 'generateNameConfiguration').propertyValue = JSON.stringify(this.generateNameConfiguration);

    this.pathLayoutTemplates
      .saveCustomSettings(this.listOfCustomSettings)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving()))
      .subscribe(() => {
        this.toastHelperService.showToastForObjectAction(ObjectActionType.SAVE, this.translate.instant('GENERAL.CUSTOM_SETTINGS.CUSTOM_SETTINGS').toLowerCase(), null);
        this.navigateBack();
      });
  }

  public getKeysOfNameGeneratorSeparator(): string[] {
    return EnumUtils.getEnumNames(NameGeneratorSeparator);
  }

  private getCustomSettingsAndNameGenerationProperties(): void {
    forkJoin([this.pathLayoutTemplates.getListOfCustomSettings(), this.pathLayoutTemplates.getNameGenerationProperties()])
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(([listOfCustomSettings, nameGenerationProperties]: [PropertyValue[], string[]]) => {
        this.listOfCustomSettings = listOfCustomSettings;
        this.nameGenerationProperties = nameGenerationProperties;
        this.generateNameConfiguration = JSON.parse(getPropertyValue(this.listOfCustomSettings, 'generateNameConfiguration'));
        this.pathLayoutTemplateCustomSettingsForm.controls.namePlaceholderSeparator.patchValue(this.generateNameConfiguration.separator ?? NameGeneratorSeparator.NO_SEPARATOR);
      });
  }
}
