import {NameGeneratorPartType} from './name-generator-part-type.enum';

export class NameGeneratorProperty {
  private _type: NameGeneratorPartType;
  private _name: string;

  public constructor(type: NameGeneratorPartType, name: string = null) {
    this.type = type;
    this.name = name;
  }

  public get type(): NameGeneratorPartType {
    return this._type;
  }

  public set type(value: NameGeneratorPartType) {
    this._type = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public static fromJSON(json: any): NameGeneratorProperty {
    return new NameGeneratorProperty(NameGeneratorPartType[json.type], json.name);
  }
}
