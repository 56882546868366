import {NameGeneratorPart} from '../name-generator-part';
import {NameGeneratorPartType} from '../name-generator-part-type.enum';

export class CalculatedValuePart extends NameGeneratorPart {
  private _name: string;

  public constructor(name: string) {
    super();
    this.type = NameGeneratorPartType.CALCULATED_VALUE;
    this.name = name;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public toJSON(): JSON {
    return {
      type: this.type,
      name: this.name
    } as any as JSON;
  }
}
