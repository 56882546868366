import {Inject, Injectable, Injector} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {Customer} from '@domain/customer/customer';
import {Customers, CUSTOMERS} from '@infrastructure/http/customer/customers';
import {
  AssertionUtils,
  ContentSwitcherDialogService,
  CrudOverviewDataBuilderFactoryService,
  CrudOverviewDataConfig,
  CrudOverviewDataPageComponent,
  GridOptionsBuilder,
  TranslateService
} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {CustomerOverviewService} from './overview/customer-overview.service';

@Injectable()
export class CustomerSelectionService {
  private config: CrudOverviewDataConfig<Customer>;

  public constructor(
    @Inject(CUSTOMERS) public readonly customers: Customers,
    private readonly injector: Injector,
    private readonly translate: TranslateService,
    private readonly overview: CustomerOverviewService,
    private readonly contentSwitcher: ContentSwitcherDialogService,
    private readonly builderFactoryService: CrudOverviewDataBuilderFactoryService<Customer>
  ) {
    this.init();
  }

  public init(): void {
    this.config = this.builderFactoryService
      .getBuilder()
      .withEntityName('CUSTOMERS.CUSTOMER')
      .withRowData(this.customers.getAll())
      .withContentSwitcher(null)
      .withGridOptions(this.overview.getColumnDefs(false), GridIdentifier.CUSTOMER_OVERVIEW, null, (builder: GridOptionsBuilder) =>
        this.overview.getDefaultGridOptionsBuilder(builder).withRowSelection(false, false, true, false).build()
      )
      .build();
  }

  public openContentSwitcherDialog(inputValue: Customer, data: any): Observable<Customer[]> {
    this.contentSwitcher.parentInjector = this.injector;

    if (!AssertionUtils.isNullOrUndefined(this.contentSwitcher.activeEntry)) {
      this.contentSwitcher.activeEntry.data = data;
    }
    this.config.selectedObjects = [inputValue];

    const entry = {key: 'customer', component: CrudOverviewDataPageComponent, data: null};

    this.contentSwitcher.navigateForward(entry, {config: this.config});
    return this.builderFactoryService.openContentSwitcherDialog();
  }
}
