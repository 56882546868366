import {ChangeDetectionStrategy, Component, computed, Inject, OnInit, Signal, signal, WritableSignal} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {NameGeneratorProperty} from '@domain/custom-settings/name-generation-property';
import {NameGenerator} from '@domain/custom-settings/name-generator';
import {NameGeneratorPart} from '@domain/custom-settings/name-generator-part';
import {NameGeneratorPartType} from '@domain/custom-settings/name-generator-part-type.enum';
import {CalculatedValuePart} from '@domain/custom-settings/name-generator-parts/calculated-value-part';
import {CustomTextPart} from '@domain/custom-settings/name-generator-parts/custom-text-part';
import {EntityPropertyPart} from '@domain/custom-settings/name-generator-parts/entity-property-part';
import {SerialNumberPart} from '@domain/custom-settings/name-generator-parts/serial-number-part';
import {NameGeneratorPropertyTranslate} from '@domain/custom-settings/name-generator-property-translate';
import {CutFrom} from '@domain/name-generator/enums/cut-from.enum';
import {NameGeneratorSeparator} from '@domain/name-generator/enums/name-generator-separator.enum';
import {
  AssertionUtils,
  BaseComponent,
  ColDefBuilderFactoryService,
  EnumUtils,
  GridOptionsBuilderFactoryService,
  OverlayActionsService,
  TabsMenuItem,
  TranslateService
} from '@vdw/angular-component-library';
import {ColDef, GetRowIdParams, GridApi, GridOptions, GridReadyEvent, IRowDragItem, IRowNode, RowDragEvent} from 'ag-grid-community';
import {combineLatest, filter, pairwise, startWith, Subject, take, takeUntil} from 'rxjs';
import {getPreviewText} from '../name-generation-pattern-preview';
import {PartValuesCellRendererComponent} from './form-cell-renderers/part-values-cell-renderer.component';
import {PropertySelectCellRendererParams} from './form-cell-renderers/property-select-cell-renderer-params';
import {PropertySelectCellRendererComponent} from './form-cell-renderers/property-select-cell-renderer.component';
import {NameGeneratorForm, NameGeneratorPartForm} from './name-generation-form-type';
import {PatternBuilderInput} from './pattern-builder-input';
import {serialNumberNotInMiddleValidator} from './serial-not-in-middle-validator';

@Component({
  selector: 'app-pattern-builder',
  templateUrl: './pattern-builder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatternBuilderComponent extends BaseComponent implements OnInit {
  protected readonly nameGeneratorProperties: NameGeneratorProperty[];
  private readonly nameGeneratorInput: NameGenerator;

  private currentSeparator: WritableSignal<NameGeneratorSeparator> = signal(NameGeneratorSeparator.NO_SEPARATOR);
  private currentParts: WritableSignal<NameGeneratorPart[]> = signal([]);
  protected patternPreview: Signal<string> = computed(() => {
    return getPreviewText(this.currentParts(), this.currentSeparator(), this.translate);
  });

  protected showPatternPreview: Signal<boolean> = computed(() => !AssertionUtils.isEmpty(this.patternPreview()));

  protected separatorValues = EnumUtils.getEnumNames(NameGeneratorSeparator);
  protected separatorEnum = NameGeneratorSeparator;

  private nextPartId = 0;
  protected nameGeneratorForm: NameGeneratorForm = new FormGroup({
    parts: new FormArray([]),
    separator: new FormControl<NameGeneratorSeparator>(NameGeneratorSeparator.NO_SEPARATOR)
  });

  private gridApi: Subject<GridApi> = new Subject();
  protected patternGridOptions: GridOptions;
  protected generalMenuTab: TabsMenuItem = {
    value: 'general',
    translationKey: 'GENERAL.GENERAL'
  };

  public constructor(
    @Inject(MAT_DIALOG_DATA) data: PatternBuilderInput,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly translate: TranslateService,
    protected readonly dialogRef: MatDialogRef<PatternBuilderComponent>,
    private readonly overlayActionsService: OverlayActionsService
  ) {
    super();

    this.nameGeneratorProperties = data.nameGeneratorProperties;
    this.nameGeneratorInput = data.nameGenerator;
  }

  public ngOnInit(): void {
    this.setFormChangeListeners();
    this.patternGridOptions = this.getGridOptions();

    this.nameGeneratorForm.controls.separator.patchValue(this.nameGeneratorInput.separatorName);
    this.nameGeneratorInput.parts.forEach((part: NameGeneratorPart) => this.nameGeneratorForm.controls.parts.push(this.getPartForm(part), {emitEvent: false}));
    this.nameGeneratorForm.controls.parts.updateValueAndValidity();

    this.gridApi.pipe(takeUntil(this.unSubscribeOnViewDestroy), take(1)).subscribe((gridApi: GridApi) => gridApi.setGridOption('loading', false));

    this.overlayActionsService.actionTriggeredEmitter.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((_: string) => this.addEmptyPartForm());
  }

  private getGridOptions(): GridOptions {
    const columnDefs: ColDef[] = [
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('GENERAL.PLACEHOLDER.PROPERTY')
        .withoutFilter()
        .withCellRenderer<PropertySelectCellRendererParams>(PropertySelectCellRendererComponent, {nameGeneratorProperties: this.nameGeneratorProperties})
        .withFlex(1)
        .build(),
      this.colDefBuilderFactoryService
        .getBuilder()
        .withHeaderName('GENERAL.PLACEHOLDER.VALUES')
        .withoutFilter()
        .withCellRenderer(PartValuesCellRendererComponent)
        .withFlex(3)
        .withResizable(false)
        .build()
    ];

    return this.gridOptionsBuilderFactoryService
      .getBuilder(columnDefs, GridIdentifier.PATTERN_BUILDER)
      .withOnGridReady((event: GridReadyEvent) => this.gridApi.next(event.api))
      .withGetRowId((params: GetRowIdParams) => params.data.controls.id.value.toString())
      .withoutColumnMenu()
      .withoutMovableColumns()
      .withoutSorting()
      .withSuppressRowClickSelection()
      .withNoRowsOverlay({
        scale: 0.7,
        titleParam: this.translate.instant('GENERAL.PLACEHOLDER.RULE', {count: 2}),
        hideDescription: true,
        actions: [
          {
            key: 'addRule',
            titleKey: this.translate.instant('GENERAL.ACTIONS.ADD_OBJECT', {object: this.translate.instant('GENERAL.PLACEHOLDER.RULE', {count: 1}).toLowerCase()}),
            isPrimary: true
          }
        ]
      })
      .withRowActions({
        buttons: [
          {
            title: 'GENERAL.ACTIONS.DELETE',
            icon: 'solid-delete',
            withTooltip: true,
            onlyEnabledOnSingleRowSelection: true,
            onClick: (rowNode: IRowNode) => this.nameGeneratorForm.controls.parts.removeAt(rowNode.rowIndex)
          }
        ]
      })
      .withRowDraggingInsideGrid((params: IRowDragItem) => {
        const rowData = params.rowNode?.data as NameGeneratorPartForm;
        return this.translate.instant(NameGeneratorPropertyTranslate.getKey(rowData.controls.property.value), {count: 1});
      })
      .withOnRowDragEndOrLeave((params: RowDragEvent) => this.partMoved(params.node.data.controls.id.value, params.node.rowIndex))
      .build();
  }

  private setFormChangeListeners(): void {
    this.nameGeneratorForm.controls.separator.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((separator: NameGeneratorSeparator) => {
      this.currentSeparator.update(() => separator);
    });

    const partsChanged = this.nameGeneratorForm.controls.parts.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy), startWith([]), pairwise());
    partsChanged.subscribe((_: any) => {
      this.currentParts.update(() => this.getCurrentNameGeneratorParts());
    });

    combineLatest([partsChanged.pipe(filter(([oldParts, newParts]: [any[], any[]]) => oldParts.length !== newParts.length)), this.gridApi])
      .pipe(
        takeUntil(this.unSubscribeOnViewDestroy),
        filter(([_, gridApi]: [any, GridApi]) => !AssertionUtils.isNullOrUndefined(gridApi))
      )
      .subscribe(([_, gridApi]: [any, GridApi]) => {
        gridApi.setGridOption('rowData', this.nameGeneratorForm.controls.parts.controls);
      });
  }

  protected confirmPattern(): void {
    this.validateParts();
    this.nameGeneratorForm.markAllAsTouched();
    if (this.nameGeneratorForm.valid) {
      const nameGenerator = new NameGenerator(undefined, this.currentSeparator().toString(), undefined, this.currentParts());
      this.dialogRef.close(nameGenerator);
    }
  }

  protected addEmptyPartForm(): void {
    const part = this.getEmptyPartForm();
    this.nameGeneratorForm.controls.parts.push(part);
    this.validateParts();
  }

  private partMoved(id: number, toIndex: number): void {
    const fromIndex = this.nameGeneratorForm.controls.parts.controls.findIndex((part: NameGeneratorPartForm) => part.controls.id.value === id);
    if (fromIndex !== toIndex) {
      const partToMove = this.nameGeneratorForm.controls.parts.at(fromIndex);
      this.nameGeneratorForm.controls.parts.removeAt(fromIndex, {emitEvent: false});
      this.nameGeneratorForm.controls.parts.insert(toIndex, partToMove, {emitEvent: false});
      this.validateParts();
    }
  }

  private getPartForm(part: NameGeneratorPart): NameGeneratorPartForm {
    const partForm = this.getEmptyPartForm();

    switch (part.type) {
      case NameGeneratorPartType.ALPHABETIC_SERIAL_NUMBER:
      case NameGeneratorPartType.NUMERIC_SERIAL_NUMBER:
        const serialNumberPart = part as SerialNumberPart;
        partForm.patchValue({
          property: new NameGeneratorProperty(part.type, null),
          serialNumber: {
            maxLength: serialNumberPart.maxLength,
            startValue: serialNumberPart.startValue,
            stepSize: serialNumberPart.stepSize
          }
        });
        break;
      case NameGeneratorPartType.PROPERTY_VALUE:
        const entityPropertyPart = part as EntityPropertyPart;
        partForm.patchValue({
          property: new NameGeneratorProperty(part.type, entityPropertyPart.name),
          entityProperty: {
            cutFrom: entityPropertyPart.cutFromDirection,
            startCharacter: entityPropertyPart.cutStartCharacter,
            length: entityPropertyPart.cutLength
          }
        });
        break;
      case NameGeneratorPartType.CUSTOM_TEXT:
        const customTextPart = part as CustomTextPart;
        partForm.patchValue({
          property: new NameGeneratorProperty(part.type, null),
          customText: {
            customText: customTextPart.customText
          }
        });
        break;
      case NameGeneratorPartType.CALCULATED_VALUE:
        const calculatedValuePart = part as CalculatedValuePart;
        partForm.patchValue({
          property: new NameGeneratorProperty(part.type, calculatedValuePart.name)
        });
        break;
    }

    return partForm;
  }

  private getEmptyPartForm(): NameGeneratorPartForm {
    const partId = this.nextPartId++;
    const newPartForm: NameGeneratorPartForm = new FormGroup({
      id: new FormControl(partId),
      property: new FormControl<NameGeneratorProperty>(new NameGeneratorProperty(NameGeneratorPartType.CUSTOM_TEXT, null)),
      serialNumber: new FormGroup({
        maxLength: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(5)]),
        startValue: new FormControl(null, Validators.maxLength(5)),
        stepSize: new FormControl(null, Validators.min(1))
      }),
      entityProperty: new FormGroup({
        cutFrom: new FormControl<CutFrom>(CutFrom.NONE),
        startCharacter: new FormControl(null, [Validators.required, Validators.min(1)]),
        length: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(20)])
      }),
      customText: new FormGroup({
        customText: new FormControl(null, [Validators.required, Validators.maxLength(20)])
      })
    });

    newPartForm.controls.property.addValidators(serialNumberNotInMiddleValidator(partId, this.nameGeneratorForm.controls.parts.controls));

    return this.setPartFormChangeListeners(newPartForm);
  }

  private setPartFormChangeListeners(partForm: NameGeneratorPartForm): NameGeneratorPartForm {
    partForm.controls.property.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy), startWith(partForm.controls.property.value)).subscribe((property: NameGeneratorProperty) => {
      partForm.controls.serialNumber.disable({emitEvent: false});
      partForm.controls.entityProperty.disable({emitEvent: false});
      partForm.controls.customText.disable({emitEvent: false});

      switch (property.type) {
        case NameGeneratorPartType.ALPHABETIC_SERIAL_NUMBER:
        case NameGeneratorPartType.NUMERIC_SERIAL_NUMBER:
          partForm.controls.serialNumber.enable({emitEvent: false});
          partForm.controls.serialNumber.controls.startValue.patchValue('', {emitEvent: false});
          break;
        case NameGeneratorPartType.PROPERTY_VALUE:
          partForm.controls.entityProperty.enable({emitEvent: false});
          break;
        case NameGeneratorPartType.CUSTOM_TEXT:
          partForm.controls.customText.enable({emitEvent: false});
          break;
      }
    });

    partForm.controls.serialNumber.controls.maxLength.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((maxLenght: number) => {
      partForm.controls.serialNumber.controls.startValue.setValidators(Validators.maxLength(maxLenght ?? 5));
      partForm.controls.serialNumber.controls.startValue.updateValueAndValidity({onlySelf: false});
    });

    partForm.controls.serialNumber.controls.startValue.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((startValue: string) => {
      if (partForm.value.property.type === NameGeneratorPartType.ALPHABETIC_SERIAL_NUMBER) {
        partForm.controls.serialNumber.controls.startValue.patchValue(startValue?.replace(/[^A-Za-z]/g, '').toUpperCase() ?? null, {emitEvent: false});
      } else if (partForm.value.property.type === NameGeneratorPartType.NUMERIC_SERIAL_NUMBER) {
        partForm.controls.serialNumber.controls.startValue.patchValue(startValue?.replace(/\D/g, '') ?? null, {emitEvent: false});
      }
    });

    partForm.controls.entityProperty.controls.cutFrom.valueChanges
      .pipe(takeUntil(this.unSubscribeOnViewDestroy), startWith(partForm.controls.entityProperty.controls.cutFrom.value))
      .subscribe((cutFrom: CutFrom) => {
        if (cutFrom === CutFrom.NONE) {
          partForm.controls.entityProperty.controls.startCharacter.disable({emitEvent: false});
          partForm.controls.entityProperty.controls.length.disable({emitEvent: false});
        } else {
          partForm.controls.entityProperty.controls.startCharacter.enable({emitEvent: false});
          partForm.controls.entityProperty.controls.length.enable({emitEvent: false});
        }
      });

    return partForm;
  }

  private getCurrentNameGeneratorParts(): NameGeneratorPart[] {
    return this.nameGeneratorForm.controls.parts.controls.map((partForm: NameGeneratorPartForm) => {
      switch (partForm.value.property.type) {
        case NameGeneratorPartType.ALPHABETIC_SERIAL_NUMBER:
        case NameGeneratorPartType.NUMERIC_SERIAL_NUMBER:
          return new SerialNumberPart(partForm.value.property.type, partForm.value.serialNumber.maxLength, partForm.value.serialNumber.startValue, partForm.value.serialNumber.stepSize);
        case NameGeneratorPartType.CUSTOM_TEXT:
          return new CustomTextPart(partForm.value.customText.customText);
        case NameGeneratorPartType.PROPERTY_VALUE:
          const cutFromNone = partForm.value.entityProperty.cutFrom === CutFrom.NONE;
          return new EntityPropertyPart(
            partForm.value.property.name,
            partForm.value.entityProperty.cutFrom,
            cutFromNone ? null : partForm.value.entityProperty.startCharacter,
            cutFromNone ? null : partForm.value.entityProperty.length
          );
        case NameGeneratorPartType.CALCULATED_VALUE:
          return new CalculatedValuePart(partForm.value.property.name);
      }
    });
  }

  private validateParts(): void {
    this.nameGeneratorForm.controls.parts.updateValueAndValidity({onlySelf: true});
    this.nameGeneratorForm.controls.parts.controls.forEach((part: NameGeneratorPartForm) => {
      part.controls.property.markAllAsTouched();
      part.controls.property.updateValueAndValidity({onlySelf: true});
    });
  }
}
