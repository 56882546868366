import {Component, Injector, OnInit, Optional, Type} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {debounceTime, takeUntil} from 'rxjs';
import {BaseComponent} from '../../base-component';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {PrototypeRouteUtils} from '../../common/utils/prototype-route-utils';
import {TranslateService} from '../../translation/translate.service';
import {CrudGridContextService} from '../crud-overview-data/crud-grid-context-service/crud-grid.context.service';
import {CrudObjectSelectionDialogComponent} from '../crud-overview-data/crud-object-selection-dialog/crud-object-selection-dialog.component';
import {CrudOverviewDataPageComponent} from '../crud-overview-data/crud-overview-data-page/crud-overview-data-page.component';
import {ContentSwitcherDialogService} from './content-switcher-dialog.service';

@Component({
  templateUrl: './content-switcher-dialog.component.html',
  styleUrls: ['./content-switcher-dialog.component.scss']
})
export class ContentSwitcherDialogComponent extends BaseComponent implements OnInit {
  protected injector: Injector;
  protected creatingObjectName: string;

  public get component(): Type<any> {
    if (this.contentSwitcher.activeEntry?.component === CrudOverviewDataPageComponent || this.contentSwitcher.activeEntry?.routeData?.route === '') {
      return AssertionUtils.isNullOrUndefined(this.dialog) ? CrudOverviewDataPageComponent : CrudObjectSelectionDialogComponent;
    }

    return this.contentSwitcher.activeEntry?.component;
  }

  public get showHeader(): boolean {
    return this.component !== CrudObjectSelectionDialogComponent && !AssertionUtils.isNullOrUndefined(this.contentSwitcher.dialog);
  }

  public get headerText(): string {
    return `${this.getHeaderPrefix()} ${this.translate.instant(this.contentSwitcher.activeEntry?.entityName, {count: 1}).toLowerCase()}`;
  }

  public get createdObject(): string {
    return AssertionUtils.isEmpty(this.creatingObjectName) ? '' : `: ${this.creatingObjectName}`;
  }

  public get componentInputs(): Record<string, unknown> {
    return this.contentSwitcher.getInputs() ?? {};
  }

  public constructor(
    private readonly translate: TranslateService,
    @Optional() private readonly dialog: MatDialogRef<any>,
    protected readonly contentSwitcher: ContentSwitcherDialogService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.contentSwitcher.dialog = this.dialog;
    this.contentSwitcher.creatingObjectNameChanged.pipe(takeUntil(this.unSubscribeOnViewDestroy), debounceTime(0)).subscribe((name: string) => (this.creatingObjectName = name));

    this.dialog
      ?.beforeClosed()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => this.contentSwitcher.reset());

    this.injector = Injector.create({providers: [{provide: MatDialogRef, useValue: this.dialog}, CrudGridContextService], parent: this.contentSwitcher.parentInjector});
  }

  public getHeaderPrefix(): string {
    const path = AssertionUtils.isNullOrUndefined(this.contentSwitcher.activeEntry?.routeData) ? this.contentSwitcher.activeEntry?.key : this.contentSwitcher.activeEntry?.routeData?.route;

    if (PrototypeRouteUtils.isAddPath(path) || PrototypeRouteUtils.isDuplicatePath(path)) {
      return this.translate.instant('ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.NEW');
    }

    if (PrototypeRouteUtils.isEditPath(path)) {
      return this.translate.instant('ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.EDIT');
    }
  }
}
