import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {AutomaticStopGroup} from '@domain/machine/automatic-stop-group';
import {EventDefinition} from '@domain/machine/event-definition';
import {MachineType} from '@domain/machine/machine-type.enum';
import {map, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class HttpAutomaticStopGroupsService {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public getAll(): Observable<AutomaticStopGroup[]> {
    return this.httpClient
      .get<AutomaticStopGroup[]>(`${this.backendConfiguration.getMachineOverviewEndpoint()}automatic-stop-groups`)
      .pipe(map((declarationsJSON: any) => declarationsJSON.map((automaticStopGroupJSON: any) => AutomaticStopGroup.fromJSON(automaticStopGroupJSON))));
  }

  public getStopReasonTextsPerMachineType(): Observable<Record<MachineType, string[]>> {
    return this.httpClient
      .get(`${this.backendConfiguration.getIotConfigEndpoint()}stop-reason-texts/per-machine-type`)
      .pipe(map((stopReasonTextsPerMachineTypeJSON: Record<MachineType, string[]>) => stopReasonTextsPerMachineTypeJSON));
  }

  public getEventDefinitionsPreview(automaticStopGroups: AutomaticStopGroup[]): Observable<EventDefinition[]> {
    return this.httpClient.post<EventDefinition[]>(`${this.backendConfiguration.getWorkerEndpoint()}event-definitions/preview`, automaticStopGroups).pipe(
      map((eventDefinitionsJSON: any) => {
        return eventDefinitionsJSON.map((eventDefinitionJSON: any) => EventDefinition.fromJSON(eventDefinitionJSON));
      })
    );
  }

  public updateAutomaticStopGroups(automaticStopGroups: AutomaticStopGroup[]): Observable<void> {
    return this.httpClient
      .post(
        `${this.backendConfiguration.getMachineOverviewEndpoint()}automatic-stop-groups`,
        automaticStopGroups.map((group: AutomaticStopGroup) => group.toJSON())
      )
      .pipe(map(() => null));
  }

  public resetAutomaticStopGroups(): Observable<AutomaticStopGroup[]> {
    return this.httpClient
      .put<AutomaticStopGroup[]>(`${this.backendConfiguration.getMachineOverviewEndpoint()}automatic-stop-groups/reset`, null)
      .pipe(map((declarationsJSON: any) => declarationsJSON.map((automaticStopGroupJSON: any) => AutomaticStopGroup.fromJSON(automaticStopGroupJSON))));
  }

  public getAutomaticStopGroupsForMachineTypes(machineTypes: MachineType[]): Observable<AutomaticStopGroup[]> {
    return this.httpClient
      .post(`${this.backendConfiguration.getMachineOverviewEndpoint()}automatic-stop-groups/for-machine-types`, {machineTypes: machineTypes})
      .pipe(map((groupsJSON: any) => groupsJSON.map((groupJSON: any) => AutomaticStopGroup.fromJSON(groupJSON))));
  }
}
