import {Injectable} from '@angular/core';
import {ColDefBuilderFactoryService, GridOptionsBuilder, NoDataOverlayComponentParams, OverlayComponentParams, StringUtils, TranslateService} from '@vdw/angular-component-library';
import {ColDef} from 'ag-grid-community';

@Injectable()
export class PlasticProductGroupOverviewService {
  public constructor(
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly translate: TranslateService
  ) {}

  public getColumnDefs(): ColDef[] {
    return [
      this.colDefBuilderFactoryService.getBuilder().withField('id').withHide().build(),
      this.colDefBuilderFactoryService.getBuilder().withField('name', true).withHeaderName('GENERAL.NAME').withComparator(StringUtils.stringComparator).build(),
      this.colDefBuilderFactoryService.getBuilder().withField('description', true).withHeaderName('GENERAL.DESCRIPTION').withComparator(StringUtils.stringComparator).build()
    ];
  }

  public getDefaultGridOptionsBuilder(builder: GridOptionsBuilder): GridOptionsBuilder {
    return builder
      .withLoadingOverlay({
        scale: 0.7
      } as OverlayComponentParams)
      .withNoRowsOverlay({
        scale: 0.7,
        titleParam: this.translate.instant('TEXTILE_DATA.PLASTIC_PRODUCT.PRODUCT_GROUP'),
        hideDescription: true
      } as NoDataOverlayComponentParams);
  }
}
