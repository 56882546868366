import {Injectable, Injector} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {NavigationUtils} from '@application/helper/routing/navigation-utils';
import {RouteData} from '@application/helper/routing/route-data.interface';
import {PlasticProduct} from '@domain/textile-data/plastic-product/plastic-product';
import {HttpPlasticProductsService} from '@infrastructure/http/plastic-product/http-plastic-products.service';
import {AssertionUtils, ContentSwitcherDialogService, CrudOverviewDataBuilderFactoryService, CrudOverviewDataConfig, GridOptionsBuilder, TranslateService} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {PlasticProductOverviewService} from './overview/plastic-product-overview.service';

@Injectable()
export class PlasticProductSelectionService {
  private config: CrudOverviewDataConfig<PlasticProduct>;

  public constructor(
    private readonly injector: Injector,
    private readonly plasticProducts: HttpPlasticProductsService,
    private readonly translate: TranslateService,
    private readonly overview: PlasticProductOverviewService,
    private readonly contentSwitcher: ContentSwitcherDialogService,
    private readonly builderFactoryService: CrudOverviewDataBuilderFactoryService<PlasticProduct>
  ) {
    this.init();
  }

  public init(): void {
    const addRoute = NavigationUtils.getNavigationRoute(NavigationId.ADD_PLASTIC_PRODUCT);

    this.config = this.builderFactoryService
      .getBuilder()
      .withEntityName('TEXTILE_DATA.PLASTIC_PRODUCT.PLASTIC_PRODUCT')
      .withRowData(this.plasticProducts.getAll())
      .withNewClicked((): void =>
        this.contentSwitcher.navigateForward(
          {
            key: NavigationId.ADD_PLASTIC_PRODUCT.toString(),
            routeData: addRoute.data as RouteData,
            data: null,
            component: addRoute.component,
            entityName: 'TEXTILE_DATA.PLASTIC_PRODUCT.PLASTIC_PRODUCT'
          },
          {}
        )
      )
      .withContentSwitcher()
      .withGridOptions(this.overview.getColumnDefs(false), GridIdentifier.PLASTIC_PRODUCT_OVERVIEW, null, (builder: GridOptionsBuilder) => builder.withRowSelection(true, true, true).build())
      .build();
  }

  public openContentSwitcherDialog(inputValue: PlasticProduct[], data: any): Observable<PlasticProduct[]> {
    this.contentSwitcher.parentInjector = this.injector;

    if (!AssertionUtils.isNullOrUndefined(this.contentSwitcher.activeEntry)) {
      this.contentSwitcher.activeEntry.data = data;
    }
    this.config.selectedObjects = inputValue;

    const navigationRoute = NavigationUtils.getNavigationRoute(NavigationId.PLASTIC_PRODUCT);
    const entry = {key: NavigationId.PLASTIC_PRODUCT.toString(), component: navigationRoute.component, data: null, routeData: navigationRoute.data as RouteData};

    this.contentSwitcher.navigateForward(entry, {config: this.config});
    return this.builderFactoryService.openContentSwitcherDialog();
  }

  public setSelected(inputValue: PlasticProduct): void {
    this.config.selectedObjects = Array.isArray(this.config.selectedObjects) ? [inputValue, ...this.config.selectedObjects] : [inputValue];
  }
}
