import {Inject, Injectable} from '@angular/core';
import {FixedShape} from '@domain/drawing-library/fixed-shape.enum';
import {OrderLineAdditionalInfo} from '@domain/orderbook/order-line-additional-info';
import {Permission} from '@domain/profile/permission.enum';
import {PropertyValue} from '@domain/property-value';
import {Authentication, AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {Orderbook, ORDERBOOK} from '@infrastructure/http/orderbook/orderbook';
import {GridOrderLineStatusComponent} from '@presentation/components/order-lines-grid/grid-order-line-status/grid-order-line-status.component';
import {AdvancedSearchInput} from '@presentation/components/search-filters/advanced-search/advanced-search-input.enum';
import {BaseComponent, ColDefBuilderFactoryService, convertCommercialUnit, GridTagComponent, Priority, StringUtils, TagColor, TagSize, TranslateService, Unit} from '@vdw/angular-component-library';
import {ColDef, ITooltipParams, ValueGetterParams} from 'ag-grid-community';
import {find, get, isNil, merge} from 'lodash-es';
import {BehaviorSubject, map, Observable, of, Subject, switchMap, takeUntil} from 'rxjs';

@Injectable()
export class OrderLinesGridService extends BaseComponent {
  private readonly isFillingPath = new BehaviorSubject<boolean>(false);
  private readonly autoFillButtonClicked = new Subject<boolean>();

  private readonly booleanFalseTranslation = 'GENERAL.CONDITION.NO';
  private readonly booleanTrueTranslation = 'GENERAL.CONDITION.YES';

  public constructor(
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    @Inject(ORDERBOOK) private readonly orderbook: Orderbook,
    private readonly colDefBuilderFactory: ColDefBuilderFactoryService,
    private readonly translate: TranslateService
  ) {
    super();
  }

  public getAutoFillButtonClicked(): Observable<boolean> {
    return this.autoFillButtonClicked.asObservable();
  }

  public setAutoFillButtonClicked(buttonClicked: boolean): void {
    this.autoFillButtonClicked.next(buttonClicked);
  }

  public getIsFillingPath(): Observable<boolean> {
    return this.isFillingPath.asObservable();
  }

  public setIsFillingPath(filling: boolean): void {
    this.isFillingPath.next(filling);
  }

  public getAdvancedSearchFiltersUpdatedWithFloatingFilters(advancedSearchFilters: PropertyValue[], floatingFilters: PropertyValue[], defaultUnit: Unit = Unit.CENTIMETER): PropertyValue[] {
    return advancedSearchFilters.map((advancedSearchFilter: PropertyValue) => {
      if (
        advancedSearchFilter.propertyName !== AdvancedSearchInput.MACHINE_QUALITY &&
        advancedSearchFilter.propertyName !== AdvancedSearchInput.COLORED_YARN_SET &&
        advancedSearchFilter.propertyName !== AdvancedSearchInput.MAIN_COLOR &&
        advancedSearchFilter.propertyName !== AdvancedSearchInput.BORDER_COLOR
      ) {
        const floatingFilterToSyncWith = floatingFilters.find((floatingFilter: PropertyValue) => floatingFilter.propertyName === advancedSearchFilter.propertyName);

        if (!isNil(floatingFilterToSyncWith)) {
          if (floatingFilterToSyncWith.propertyName === AdvancedSearchInput.WIDTH && !isNil(floatingFilterToSyncWith.propertyValue)) {
            advancedSearchFilter.propertyValue = convertCommercialUnit({from: {unit: defaultUnit, value: Number(floatingFilterToSyncWith.propertyValue)}, to: Unit.MILLIMETER});
          } else {
            advancedSearchFilter.propertyValue = floatingFilterToSyncWith.propertyValue;
          }
        }
      }

      return advancedSearchFilter;
    });
  }

  public getFilterModelUpdatedWithAdvancedSearchFilters(
    columnDefs: ColDef[],
    advancedSearchFilters: PropertyValue[],
    currentFilterModel: Record<string, PropertyValue>,
    defaultUnit: Unit = Unit.CENTIMETER
  ): Record<string, PropertyValue> {
    const filterModelFromAdvancedSearchFilters = advancedSearchFilters.reduce((filterModel: Record<string, PropertyValue>, advancedSearchFilter: PropertyValue) => {
      const columnDef = find(columnDefs, ['floatingFilterComponentParams.property', advancedSearchFilter.propertyName]);

      if (!isNil(columnDef)) {
        if (advancedSearchFilter.propertyName === AdvancedSearchInput.MACHINE_QUALITY) {
          filterModel[columnDef.colId] = {propertyName: advancedSearchFilter.propertyName, propertyValue: get(advancedSearchFilter.propertyValue, 'technicalName', null)};
        } else if (
          advancedSearchFilter.propertyName === AdvancedSearchInput.COLORED_YARN_SET ||
          advancedSearchFilter.propertyName === AdvancedSearchInput.MAIN_COLOR ||
          advancedSearchFilter.propertyName === AdvancedSearchInput.BORDER_COLOR
        ) {
          filterModel[columnDef.colId] = {propertyName: advancedSearchFilter.propertyName, propertyValue: get(advancedSearchFilter.propertyValue, 'name', null)};
        } else if (advancedSearchFilter.propertyName === AdvancedSearchInput.WIDTH && !isNil(advancedSearchFilter.propertyValue)) {
          filterModel[columnDef.colId] = {
            propertyName: advancedSearchFilter.propertyName,
            propertyValue: `${convertCommercialUnit({from: {unit: Unit.MILLIMETER, value: Number(advancedSearchFilter.propertyValue)}, to: defaultUnit})}`
          };
        } else {
          filterModel[columnDef.colId] = advancedSearchFilter;
        }
      }

      return filterModel;
    }, {});

    return merge({}, currentFilterModel, filterModelFromAdvancedSearchFilters);
  }

  public getAdditionalInfoColumnDefs(): Observable<ColDef[]> {
    return this.authentication.getCurrentSubscription().hasPermission(Permission.ACCESS_ALL)
      ? this.orderbook.getListOfCustomSettings().pipe(
          map((settings: PropertyValue[]) => {
            return settings.map(({propertyName, propertyValue}: PropertyValue) => {
              return this.colDefBuilderFactory
                .getBuilder()
                .withColId(propertyName)
                .withValueGetter((params: ValueGetterParams) => this.getAdditionalInfoValueFromKey(params.data, propertyName), true)
                .withHeaderName(propertyValue)
                .withComparator(StringUtils.stringComparator)
                .withHide(true)
                .build();
            });
          })
        )
      : of([]);
  }

  public getColumnDefs(unit: Unit = Unit.CENTIMETER, isSelectedOrderLines: boolean = false): Observable<ColDef[]> {
    const commercialWidthInMM = this.colDefBuilderFactory
      .getBuilder()
      .withColId('commercialWidthInCM')
      .withField('article.commercialWidthInMM')
      .withHeaderName('GENERAL.DIMENSIONS.WIDTH')
      .withPinned(false)
      .withNumericMultiFilter(this.getPossibleColumnsValues('commercialWidthInCM'))
      .withMMConversion(unit);

    const commercialHeightInMM = this.colDefBuilderFactory
      .getBuilder()
      .withColId('commercialHeightInCM')
      .withField('article.commercialHeightInMM')
      .withHeaderName('GENERAL.DIMENSIONS.HEIGHT')
      .withPinned(false)
      .withNumericMultiFilter(this.getPossibleColumnsValues('commercialHeightInCM'))
      .withMMConversion(unit);

    const commercialLengthInMM = this.colDefBuilderFactory
      .getBuilder()
      .withColId('commercialLengthInCM')
      .withField('commercialLengthInMM')
      .withHeaderName('GENERAL.DIMENSIONS.LENGTH')
      .withPinned(false)
      .withNumericMultiFilter(this.getPossibleColumnsValues('commercialLengthInCM'))
      .withMMConversion(unit);

    if (isSelectedOrderLines) {
      commercialWidthInMM.withValueGetter((params: ValueGetterParams) => {
        return `${convertCommercialUnit({from: {unit: Unit.MILLIMETER, value: params.data.article.commercialWidthInMM}, to: unit})}`;
      });

      commercialHeightInMM.withValueGetter((params: ValueGetterParams) => {
        return `${convertCommercialUnit({from: {unit: Unit.MILLIMETER, value: params.data.article.commercialHeightInMM}, to: unit})}`;
      });

      commercialLengthInMM.withValueGetter((params: ValueGetterParams) => {
        return `${convertCommercialUnit({from: {unit: Unit.MILLIMETER, value: params.data.commercialLengthInMM}, to: unit})}`;
      });
    }

    const colDefsBeforeAdditionalInfo = [
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('status')
        .withHeaderName('GENERAL.STATUS')
        .withPinned(false)
        .withCellRenderer(GridOrderLineStatusComponent)
        .withTextMultiFilter(this.getPossibleColumnsValues('status'))
        .withTooltipValueGetter((params: ITooltipParams) => this.translate.instant(`ORDERBOOK.ORDER_LINE_STATUS.${params.value}`).toUpperCase())
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('priority')
        .withHeaderName('SALES_ORDERS.PRIORITY.PRIORITY')
        .withPinned(false)
        .withCellRenderer(GridTagComponent, {
          translationKey: 'SALES_ORDERS.PRIORITY.',
          size: TagSize.SMALL,
          colorGetter: {
            [Priority.LOW]: TagColor.PRIMARY,
            [Priority.MEDIUM]: TagColor.WARNING,
            [Priority.HIGH]: TagColor.ERROR
          }
        })
        .withTextMultiFilter(this.getPossibleColumnsValues('priority'))
        .withTooltipValueGetter((params: ITooltipParams) => this.translate.instant(`SALES_ORDERS.PRIORITY.${params.value}`).toUpperCase())
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('salesOrderReference', true)
        .withHeaderName('ORDERBOOK.ORDERBOOK_REFERENCE')
        .withPinned(true)
        .withLockVisible()
        .withLockPinned(true)
        .withTextMultiFilter(this.getPossibleColumnsValues('salesOrderReference'))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('salesOrderLineReference', true)
        .withHeaderName('ORDERBOOK.ORDERLINE_REFERENCE')
        .withTextMultiFilter(this.getPossibleColumnsValues('salesOrderLineReference'))
        .withLockVisible()
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColId('finishingName')
        .withField('article.finishing.nameWithVersion', true)
        .withHeaderName('TEXTILE_DATA.FINISHING.FINISHING')
        .withComparator(StringUtils.stringComparator)
        .withTextMultiFilter(this.getPossibleColumnsValues('finishingName'))
        .build()
    ];
    const colDefsAfterAdditionalInfo = [
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('customerReference', true)
        .withHeaderName('ORDERBOOK.CUSTOMER_REFERENCE')
        .withTextMultiFilter(this.getPossibleColumnsValues('customerReference'))
        .withComparator(StringUtils.stringComparator)
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColId('colorSet')
        .withField('article.colorSet.name', true)
        .withHeaderName('TEXTILE_DATA.COLOR_SET.COLOR_SET')
        .withComparator(StringUtils.stringComparator)
        .withTextMultiFilter(this.getPossibleColumnsValues('colorSet'))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColId('mainColor')
        .withField('article.mainColor.name', true)
        .withHeaderName('GENERAL.ADVANCED_SEARCH.MAIN_COLOR')
        .withComparator(StringUtils.stringComparator)
        .withTextMultiFilter(this.getPossibleColumnsValues('mainColor'))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColId('borderColor')
        .withField('article.borderColor.name', true)
        .withHeaderName('GENERAL.ADVANCED_SEARCH.BORDER_COLOR')
        .withComparator(StringUtils.stringComparator)
        .withTextMultiFilter(this.getPossibleColumnsValues('borderColor'))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColId('machineQualityName')
        .withField('article.machineQuality.nameWithVersion')
        .withHeaderName('TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY')
        .withTooltipField('article.machineQuality.name')
        .withComparator(StringUtils.stringComparator)
        .withTextMultiFilter(this.getPossibleColumnsValues('machineQualityName'))
        .build(),
      this.colDefBuilderFactory.getBuilder().withColIdAndField('orderType').withHeaderName('ORDERBOOK.ORDER_TYPE').withTextMultiFilter(this.getPossibleColumnsValues('orderType')).build(),
      commercialWidthInMM.build(),
      commercialHeightInMM.build(),
      commercialLengthInMM.build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColId('totalCount')
        .withField('amount')
        .withHeaderName('ORDERBOOK.REQUESTED_AMOUNT')
        .withValueGetter((params: ValueGetterParams) => {
          return `${params.data.amount}`;
        })
        .withNumericMultiFilter(this.getPossibleColumnsValues('totalCount'))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('amountPlanned')
        .withHeaderName('ORDERBOOK.PLANNED_AMOUNT')
        .withValueGetter((params: ValueGetterParams) => {
          return `${params.data.amountPlanned}`;
        })
        .withNumericMultiFilter(this.getPossibleColumnsValues('amountPlanned'))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('amountLeft')
        .withHeaderName('ORDERBOOK.TO_PLAN_AMOUNT')
        .withValueGetter((params: ValueGetterParams) => {
          return `${params.data.amountLeft}`;
        })
        .withNumericMultiFilter(this.getPossibleColumnsValues('amountLeft'))
        .withLockVisible()
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('amountProduced')
        .withHeaderName('ORDERBOOK.PRODUCED_AMOUNT')
        .withValueGetter((params: ValueGetterParams) => {
          return `${params.data.amountProduced}`;
        })
        .withNumericMultiFilter(this.getPossibleColumnsValues('amountProduced'))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('desiredDate')
        .withHeaderName('SALES_ORDERS.DESIRED_DATE')
        .withValueGetter((params: ValueGetterParams) => {
          return `${params.data.desiredDate}`;
        })
        .withDateMultiFilter(this.getPossibleColumnsValues('desiredDate'))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColId('article')
        .withField('article.name', true)
        .withHeaderName('ARTICLES.ARTICLE')
        .withComparator(StringUtils.stringComparator)
        .withTextMultiFilter(this.getPossibleColumnsValues('article'))
        .withLockVisible()
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColId('article')
        .withField('article.name', true)
        .withHeaderName('ARTICLES.ARTICLE')
        .withComparator(StringUtils.stringComparator)
        .withTextMultiFilter(this.getPossibleColumnsValues('article'))
        .withLockVisible()
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColId('designName')
        .withField('article.design.name', true)
        .withHeaderName('DESIGN_LIBRARY.DETAILS.DESIGN_NAME')
        .withComparator(StringUtils.stringComparator)
        .withTextMultiFilter(this.getPossibleColumnsValues('designName'))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColId('shape')
        .withField('article.design.shape', true)
        .withHeaderName('GENERAL.SHAPE')
        .withComparator(StringUtils.stringComparator)
        .withValueGetter((params: ValueGetterParams) => this.getShape(params.data.article?.design?.shape), true)
        .withTextMultiFilter(this.getPossibleColumnsValues('shape'))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColId('designCode')
        .withField('article.design.code', true)
        .withHeaderName('DESIGN_LIBRARY.DETAILS.DESIGN_CODE')
        .withComparator(StringUtils.stringComparator)
        .withTextMultiFilter(this.getPossibleColumnsValues('designCode'))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('missingDesign')
        .withHeaderName('ORDERBOOK.MISSING_DESIGN')
        .withValueGetter((params: ValueGetterParams) => (params.data.missingDesign ? this.translate.instant(this.booleanTrueTranslation) : this.translate.instant(this.booleanFalseTranslation)), true)
        .withBooleanFilter(this.translate.instant(this.booleanFalseTranslation), this.translate.instant(this.booleanTrueTranslation))
        .build()
    ];

    return this.getAdditionalInfoColumnDefs().pipe(
      switchMap((additionalInfoColDefs: ColDef[]) => {
        return of([...colDefsBeforeAdditionalInfo, ...additionalInfoColDefs, ...colDefsAfterAdditionalInfo]);
      })
    );
  }

  private getPossibleColumnsValues(columnIdentifier: string): Observable<string[]> {
    return this.orderbook.getPossibleValues(columnIdentifier).pipe(takeUntil(this.unSubscribeOnViewDestroy));
  }

  private getAdditionalInfoValueFromKey(data: any, key: string): string {
    return 'additionalInfo' in data ? data.additionalInfo.find((additionalInfoItem: OrderLineAdditionalInfo) => additionalInfoItem.key === key)?.value ?? '' : '';
  }

  private getShape(shape: string): string {
    return FixedShape.getTranslationKeyForShape(shape, this.translate);
  }
}
