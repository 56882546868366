import {DOCUMENT} from '@angular/common';
import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HeaderIdentifier} from '@application/headers/header-identifier.enum';
import {ErrorHandlers} from '@application/helper/error-handlers';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {NavigationNewItemData} from '@application/helper/navigation-helper/navigation-new-item-data.interface';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {AsyncUniqueValidator} from '@application/validators/async-unique-validator';
import {Article} from '@domain/article/article';
import {FinishingForArticle} from '@domain/article/finishing-for-article';
import {ConflictType} from '@domain/conflicts/conflict-type';
import {Drawing} from '@domain/production-schedule/drawing';
import {DrawingConfigurationWeaveQuality} from '@domain/production-schedule/drawing-configuration-weave-quality';
import {DrawingConfigurationWithColoredYarnSet} from '@domain/production-schedule/drawing-configuration-with-colored-yarn-set';
import {DrawingImage} from '@domain/production-schedule/drawing-image';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription as ProfileSubscription} from '@domain/profile/subscription';
import {ARTICLES, Articles} from '@infrastructure/http/article/articles';
import {Authentication, AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {DRAWINGS, Drawings} from '@infrastructure/http/drawing/drawings';
import {SelectFinishingComponent} from '@presentation/components/select-finishing/select-finishing.component';
import {ConfigurationForm} from '@presentation/pages/texedit/drawing-library/drawing-form/drawing-form';
import {SelectDesignComponent} from '@presentation/pages/texfab/article/add/select-design/select-design.component';
import {OverviewListFinishing} from '@presentation/pages/textile-data/finishing/overview/overview-list-finishing';
import {TextileService} from '@presentation/pages/textile-data/textile-data-overview/textile.service';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {
  AgGridRowSelection,
  AssertionUtils,
  BackendError,
  BaseComponent,
  Conflict,
  ConflictsDialogComponent,
  ConflictsDialogData,
  convertToCommercialUnitCentimeter,
  convertToCommercialUnitMillimeter,
  DialogBuilderFactoryService,
  DialogComponentData,
  DialogType,
  FormValidationHelper,
  SaveType,
  skeletonViewAnimation,
  TranslateService,
  Unit
} from '@vdw/angular-component-library';
import {isEmpty, isEqual, isUndefined, lowerCase, some} from 'lodash-es';
import {Observable} from 'rxjs';
import {finalize, switchMap, takeUntil} from 'rxjs/operators';
import {NavigationArticleData} from './navigation-article-data.interface';

@Component({
  templateUrl: './add-article-page.component.html',
  styleUrls: ['./add-article-page.component.scss'],
  animations: [skeletonViewAnimation('.form-field-skeleton-wrapper, .mat-card-header, .ag-root, .button-skeleton-wrapper')]
})
export class AddArticlePageComponent extends BaseComponent implements OnInit {
  private static readonly articleTranslationKey = 'ARTICLES.ARTICLE';
  public addArticleForm: FormGroup<{
    name: FormControl<string>;
    design: FormControl<Drawing>;
    finishing: FormControl<FinishingForArticle | OverviewListFinishing>;
    configuration?: ConfigurationForm;
  }>;

  public isLoadingArticle = true;
  public isSelectingDesign = false;
  public isSelectingFinishing = false;
  public selectedMachineQualityId: number = null;
  public newItemId: number;
  public dialogComponent: any;

  private articleToSave: Article = Article.createEmptyArticle();
  private readonly urlToEditArticle = RouteUtils.paths.texFab.article.editArticle.path;
  private readonly urlToDuplicateArticle = RouteUtils.paths.texFab.article.duplicateArticle.path;
  private readonly urlToArticleOverview = RouteUtils.paths.texFab.article.absolutePath;
  private saveButtonTouched = false;
  private articleEditPermission: Permission = LastModifiedCardUtils.getPermissionToModifyItems('article');
  private currentSubscription: ProfileSubscription;
  public readonly FINISHING_CHIP_VALUE_KEY = '%(name) (v%(version))';
  public finishingBaseRouterLink = RouteUtils.paths.texStyle.machineQuality.editFinishingFromQuality.absolutePath;
  public readonly SAVE_TYPE = SaveType;
  public readonly HEADER_IDENTIFIER = HeaderIdentifier.ADD_ARTICLE;
  public readonly FINISHING_VIEW_PERMISSION = RouteUtils.paths.texStyle.finishingTemplate.editFinishingTemplate.requiredPermission;

  public constructor(
    @Inject(ARTICLES) private readonly articles: Articles,
    @Inject(AUTHENTICATION) private authentication: Authentication,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(DRAWINGS) private readonly drawings: Drawings,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly translate: TranslateService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly textileService: TextileService,
    private readonly navigationHelperService: NavigationHelperService<NavigationArticleData>
  ) {
    super();
  }

  public ngOnInit(): void {
    this.currentSubscription = this.authentication.getCurrentSubscription();

    this.initializeAddArticleFormFields();
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.savePartialState<NavigationArticleData>({article: this.getCurrentArticle()});
  }

  public isEditingArticle(): boolean {
    return isEqual(this.activatedRoute.snapshot.routeConfig.path, this.urlToEditArticle);
  }

  public getActionText(): string {
    const title = this.isEditingArticle() ? 'GENERAL.ACTIONS.EDIT_OBJECT' : 'GENERAL.ACTIONS.CREATE_OBJECT';
    return this.translate.instant(title, {object: (this.translate.instant(AddArticlePageComponent.articleTranslationKey, {count: 1}) as string).toLowerCase()});
  }

  public cancel(): void {
    this.navigationHelperService.navigateToPreviousRoute(this.urlToArticleOverview);
  }

  public hasEditPermission(): boolean {
    return this.currentSubscription?.hasPermission(this.articleEditPermission);
  }

  public canEditArticle(): boolean {
    return this.hasEditPermission() && !this.isArticleBeingUsed();
  }

  public isArticleBeingUsed(): boolean {
    return !isUndefined(this.articleToSave) && this.articleToSave.used && !this.isDuplicatingArticle();
  }

  public canShowInvalidFormMessageError(): boolean {
    const isFormInvalid = some(this.addArticleForm.controls, (control: UntypedFormControl) => control.invalid && control.touched);
    if (!isFormInvalid) {
      this.saveButtonTouched = false;
    }
    return isFormInvalid && this.saveButtonTouched;
  }

  public openConflictsDialog(): void {
    this.articles
      .getConflicts(this.articleToSave.id)
      .pipe(
        switchMap((conflicts: Conflict[]) => {
          return this.dialogBuilderFactoryService
            .getBuilder()
            .withClass('basic-dialog-panel')
            .withWidth('500px')
            .withHeight('auto')
            .openDialog(ConflictsDialogComponent, ConflictsDialogData.createInUseData('ARTICLES.ARTICLE', this.articleToSave.name, conflicts, ConflictType));
        }),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe();
  }

  public selectDesign(): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .withClass('overflow-hidden-dialog')
      .withWidth('906px')
      .openDialog(SelectDesignComponent)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((designs: Drawing[]) => {
        if (!isEmpty(designs)) {
          this.addArticleForm.controls.design.setValue(designs[0]);

          this.addDesignConfiguration(null);
        }
      });
  }

  public canShowDesignConfiguration(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.addArticleForm.controls.configuration) && !AssertionUtils.isNullOrUndefined(this.addArticleForm.getRawValue().design);
  }

  public hasChosenFinishing(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.addArticleForm.getRawValue().finishing);
  }

  public getRouterLink(): string {
    return this.finishingBaseRouterLink;
  }

  public selectFinishing(): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .openSelectGridDialog(SelectFinishingComponent, {
        title: this.translate.instant('GENERAL.ACTIONS.SELECT_OBJECT', {
          object: lowerCase(this.translate.instant('TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY', {count: 1}))
        }),
        rowSelection: AgGridRowSelection.SINGLE,
        machineQualityId: this.selectedMachineQualityId ?? null
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((finishing: OverviewListFinishing) => {
        if (!AssertionUtils.isNullOrUndefined(finishing)) {
          this.finishingBaseRouterLink = RouteUtils.paths.texStyle.machineQuality.editFinishingFromQuality.absolutePath.replace(':qualityId', finishing.machineQuality.id.toString());
          this.addArticleForm.controls.finishing.setValue(finishing);
          if (this.addArticleForm.controls.configuration) {
            const quality = new DrawingConfigurationWeaveQuality(finishing.machineQuality.id, finishing.machineQuality.name, finishing.machineQuality.version);
            this.addArticleForm.controls.configuration.controls.quality.setValue(quality);
            this.selectedMachineQualityId = finishing.machineQuality.id;
          }
        }
      });
  }

  public onQualityChanged(quality: DrawingConfigurationWeaveQuality): void {
    this.addArticleForm.controls.finishing.setValue(null);
    this.selectedMachineQualityId = quality?.id;
  }

  public saveArticle(saveType: SaveType): void {
    this.saveButtonTouched = true;
    const isValid = new FormValidationHelper().checkForm(this.addArticleForm, this.document);

    if (isValid) {
      const articleToSave: Article = this.getCurrentArticle();
      this.saving = true;

      const request: Observable<void | number> = this.isEditingArticle() ? this.articles.update(articleToSave) : this.articles.save(articleToSave);
      request.pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving())).subscribe({
        next: (id: number) =>
          this.textileService.navigateAndShowToast(saveType, TextileDataType.ARTICLE, AddArticlePageComponent.articleTranslationKey, this.isEditingArticle(), articleToSave.name, id),
        error: (errorMessage: BackendError) => this.showErrorDialogForBackendError(this.isEditingArticle() ? 'GENERAL.ACTIONS.EDIT_OBJECT' : 'GENERAL.ACTIONS.CREATE_OBJECT', errorMessage.message)
      });
    }
  }

  public deleteArticle(): void {
    this.textileService.removeConfirmation(this.articleToSave, TextileDataType.ARTICLE, false, null, this.articles);
  }

  public duplicateArticle(): void {
    this.router.navigateByUrl(RouteUtils.paths.texFab.article.duplicateArticle.absolutePath.replace(':id', this.articleToSave.id.toString()));
  }

  public canShowArticleForm(): boolean {
    return !this.isSelectingDesign && !this.isSelectingFinishing;
  }

  private isDuplicatingArticle(): boolean {
    return isEqual(this.activatedRoute.snapshot.routeConfig.path, this.urlToDuplicateArticle);
  }

  private initializeAddArticleFormFields(): void {
    const urlParams: Params = this.activatedRoute.snapshot.params;

    this.articleToSave = Article.createEmptyArticle();
    this.setFormFields();

    const emptyArticleState = {article: null} as NavigationArticleData;
    const articleState = this.navigationHelperService.getPartialState<NavigationArticleData>(Object.keys(emptyArticleState));
    if (!AssertionUtils.isNullOrUndefined(articleState)) {
      this.setArticle(articleState.article);
    } else if (isUndefined(urlParams.id)) {
      this.isLoadingArticle = false;
    } else {
      this.articles
        .getById(Number(urlParams.id))
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe({
          next: (article: Article) => {
            this.setArticle(article);
          },
          error: ErrorHandlers.navigateToOverviewAndThrowError(this.router, this.urlToArticleOverview)
        });
    }
  }

  private setFormFields(): void {
    this.addArticleForm = this.formBuilder.group({
      name: this.formBuilder.control({value: '', disabled: this.isArticleBeingUsed()}, Validators.required, AsyncUniqueValidator.createValidator(this.articles, null)),
      design: this.formBuilder.control({value: null, disabled: this.isArticleBeingUsed() || !this.canEditArticle()}, Validators.required),
      finishing: this.formBuilder.control({value: null, disabled: this.isArticleBeingUsed() || !this.canEditArticle()})
    });
  }

  private setArticle(article: Article): void {
    this.articleToSave = article;
    this.isLoadingArticle = false;
    this.setArticleFormValues();
    if (article.design) {
      let drawingConfiguration = article.design.configurations[0] as DrawingConfigurationWithColoredYarnSet;
      this.addDesignConfiguration(drawingConfiguration);
      this.finishingBaseRouterLink = RouteUtils.paths.texStyle.machineQuality.editFinishingFromQuality.absolutePath.replace(':qualityId', drawingConfiguration.quality.id.toString());
    }
  }

  private setArticleFormValues(): void {
    const name = this.isDuplicatingArticle() ? null : this.articleToSave.name;
    this.addArticleForm.setValue({
      name,
      design: this.articleToSave.design,
      finishing: this.articleToSave.finishing
    });

    if (this.isArticleBeingUsed()) {
      this.addArticleForm.controls.name.disable();
    } else {
      this.addArticleForm.controls.name.enable();
    }

    if (this.isArticleBeingUsed() || !this.canEditArticle()) {
      this.addArticleForm.controls.design.disable();
      this.addArticleForm.controls.finishing.disable();
    } else {
      this.addArticleForm.controls.design.enable();
      this.addArticleForm.controls.finishing.enable();
    }

    this.addArticleForm.controls.name.setAsyncValidators(AsyncUniqueValidator.createValidator(this.articles, name));
    this.addArticleForm.controls.name.updateValueAndValidity();

    this.navigationHelperService.setNewItemOrReopenDialogIfPresent(
      ({newItemId}: NavigationNewItemData, {dialogComponent}: DialogComponentData) => {
        if (dialogComponent === SelectDesignComponent) {
          this.setDesign(newItemId);
        } else {
          this.newItemId = newItemId;
          this.dialogComponent = dialogComponent;
        }
      },
      ({dialogComponent}: DialogComponentData) => {
        if (dialogComponent === SelectDesignComponent) {
          this.selectDesign();
        } else {
          this.dialogComponent = dialogComponent;
        }
      }
    );
  }

  private setDesign(id: number): void {
    this.drawings
      .getById(id)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((design: Drawing) => {
        this.addArticleForm.controls.design.setValue(design);
        this.addDesignConfiguration(null);
      });
  }

  private addDesignConfiguration(designConfiguration: DrawingConfigurationWithColoredYarnSet): void {
    const design: Drawing = this.addArticleForm.getRawValue().design;

    const configuration = this.formBuilder.group({
      quality: this.formBuilder.control(this.getQuality(designConfiguration), Validators.required),
      coloredYarnSet: this.formBuilder.control(!AssertionUtils.isNullOrUndefined(designConfiguration?.coloredYarnSet?.id) ? designConfiguration.coloredYarnSet : null, Validators.required),
      mainColorsCreelPosition: this.formBuilder.control(!AssertionUtils.isNullOrUndefined(designConfiguration) ? designConfiguration.mainColorsCreelPosition : null, Validators.required),
      borderColorsCreelPosition: this.formBuilder.control(!AssertionUtils.isNullOrUndefined(designConfiguration) ? designConfiguration.borderColorsCreelPosition : null, Validators.required),
      image: this.formBuilder.control({id: design.image.id, data: design.image.data} as DrawingImage, Validators.required),
      heightInCm: this.formBuilder.control(
        !AssertionUtils.isNullOrUndefined(designConfiguration)
          ? convertToCommercialUnitCentimeter({
              unit: Unit.MILLIMETER,
              value: designConfiguration.commercialDimensionsInMm.heightInMM
            })
          : null,
        Validators.compose([Validators.required, Validators.min(1)])
      ),
      widthInCm: this.formBuilder.control(
        !AssertionUtils.isNullOrUndefined(designConfiguration)
          ? convertToCommercialUnitCentimeter({
              unit: Unit.MILLIMETER,
              value: designConfiguration.commercialDimensionsInMm.widthInMM
            })
          : null,
        Validators.compose([Validators.required, Validators.min(1)])
      )
    }) as ConfigurationForm;

    this.addArticleForm.removeControl('configuration');
    this.changeDetectorRef.detectChanges();

    this.addArticleForm.addControl('configuration', configuration);
  }

  private getQuality(designConfiguration: DrawingConfigurationWithColoredYarnSet): DrawingConfigurationWeaveQuality {
    let result: DrawingConfigurationWeaveQuality = null;

    if (AssertionUtils.isNullOrUndefined(designConfiguration) && this.hasChosenFinishing()) {
      const finishing = this.addArticleForm.getRawValue().finishing as OverviewListFinishing;
      result = new DrawingConfigurationWeaveQuality(finishing.machineQuality.id, finishing.machineQuality.name, finishing.machineQuality.version);
    } else if (!AssertionUtils.isNullOrUndefined(designConfiguration)) {
      result = designConfiguration.quality;
    }

    this.selectedMachineQualityId = result?.id;

    return result;
  }

  private getCurrentArticle(): Article {
    const name: string = this.addArticleForm.getRawValue().name;
    const design: Drawing = this.addArticleForm.getRawValue().design;
    const finishing = this.hasChosenFinishing() ? FinishingForArticle.fromOverviewListFinishing(this.addArticleForm.getRawValue().finishing as OverviewListFinishing) : null;

    if (!AssertionUtils.isNullOrUndefined(this.addArticleForm.controls.configuration)) {
      const configurationForm = this.addArticleForm.controls.configuration.value;

      const configuration: DrawingConfigurationWithColoredYarnSet = new DrawingConfigurationWithColoredYarnSet(
        configurationForm.image,
        configurationForm.mainColorsCreelPosition,
        configurationForm.borderColorsCreelPosition,
        configurationForm.quality,
        {
          heightInMM: convertToCommercialUnitMillimeter({
            unit: Unit.CENTIMETER,
            value: configurationForm.heightInCm
          }),
          widthInMM: convertToCommercialUnitMillimeter({
            unit: Unit.CENTIMETER,
            value: configurationForm.widthInCm
          })
        },
        configurationForm.coloredYarnSet
      );
      design.configurations = [configuration];
    }

    return new Article(this.articleToSave.id, name, design, finishing, this.articleToSave.used);
  }

  private showErrorDialogForBackendError(translationKey: string, message: string): void {
    this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
      titleText: this.translate.instant(translationKey, {
        object: this.translate.instant(AddArticlePageComponent.articleTranslationKey, {
          count: 1
        })
      }),
      messageText: message,
      type: DialogType.INFORMATION
    });
  }
}
