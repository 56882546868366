import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormControl} from '@angular/forms';
import {NameGeneratorProperty} from '@domain/custom-settings/name-generation-property';
import {NameGeneratorPropertyTranslate} from '@domain/custom-settings/name-generator-property-translate';
import {TranslateService} from '@vdw/angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {PropertySelectCellRendererParams} from './property-select-cell-renderer-params';

@Component({
  templateUrl: './property-select-cell-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertySelectCellRendererComponent implements ICellRendererAngularComp {
  protected nameGeneratorProperties: NameGeneratorProperty[];
  protected selectedProperty: FormControl<NameGeneratorProperty>;

  public constructor(private readonly translate: TranslateService) {}

  public agInit(params: PropertySelectCellRendererParams): void {
    this.nameGeneratorProperties = params.nameGeneratorProperties;
    this.selectedProperty = params.data.controls.property;
  }

  public refresh(_: PropertySelectCellRendererParams): boolean {
    return false;
  }

  protected compareProperties(property1: NameGeneratorProperty, property2: NameGeneratorProperty): boolean {
    return property1.type === property2.type && property1.name === property2.name;
  }

  protected getPropertyTranslation(property: NameGeneratorProperty): string {
    let translationKey = NameGeneratorPropertyTranslate.getKey(property);
    return translationKey ? this.translate.instant(translationKey, {count: 1}) : translationKey;
  }
}
