<div class="flex-column flex-center gap-32">
  @if (shouldDisplayImage) {
    <img [src]="image" alt="illustration" [style.height.px]="scaled(heightImage)" />
  }
  @if (shouldDisplayIcon) {
    <mat-icon [svgIcon]="icon"></mat-icon>
  }
  @if (shouldDisplaySpinner) {
    <mat-spinner diameter="72" mode="indeterminate"></mat-spinner>
  }

  <div class="flex-column flex-center gap-8 bms-theme">
    @if (shouldDisplayTitle) {
      <h2 class="overlay-title" [innerHTML]="title"></h2>
    }
    @if (shouldDisplayDescription) {
      <p class="b1 description flex" [style.fontSize.px]="scaled(descriptionFontSize)">{{ description }}</p>
    }
  </div>

  <div class="flex-row gap-16 flex-start-center" *ngIf="shouldDisplayActions">
    <div *ngFor="let action of actions">
      <button mat-flat-button type="button" color="primary" *ngIf="getActionClass(action)" (click)="onActionClicked(action.key)" [disabled]="isActionDisabled(action.disabled)">
        {{ action.titleKey | bmsTranslate: {count: 1} }}
      </button>
      <button mat-button type="button" *ngIf="!getActionClass(action)" (click)="onActionClicked(action.key)">{{ action.titleKey | bmsTranslate: {count: 1} }}</button>
    </div>
  </div>
</div>
