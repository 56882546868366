import {Inject, Injectable} from '@angular/core';
import {Routes} from '@angular/router';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription as ProfileSubscription} from '@domain/profile/subscription';
import {Authentication, AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {AssertionUtils, BaseComponent, ContentSwitcherDialogService, ContentSwitcherEntry, CrudOverviewButtonConfig, PrototypeRouteUtils} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {IRowNode} from 'ag-grid-community';
import {cloneDeep} from 'lodash-es';
import {NavigationUtils} from '../routing/navigation-utils';
import {RouteData} from '../routing/route-data.interface';
import {CrudActions} from './crud-actions.interface';

@Injectable({providedIn: 'root'})
export class GridActionButtonsHelper extends BaseComponent {
  private deleteButton: CrudOverviewButtonConfig = {title: 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.DELETE', icon: 'solid-delete', withTooltip: true, onlyEnabledOnSingleRowSelection: true};
  private editButton: CrudOverviewButtonConfig = {title: 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.EDIT', icon: 'solid-edit', onlyEnabledOnSingleRowSelection: true, withTooltip: true};
  private duplicateButton: CrudOverviewButtonConfig = {
    title: 'ANGULAR_COMPONENT_LIBRARY.CRUD_OVERVIEW_DATA.DUPLICATE',
    icon: 'solid-file-copy',
    onlyEnabledOnSingleRowSelection: true,
    withTooltip: true
  };

  private currentSubscription: ProfileSubscription;

  public constructor(
    @Inject(AUTHENTICATION) private authentication: Authentication,
    private readonly contentSwitcher: ContentSwitcherDialogService
  ) {
    super();
  }

  public getActionButtonsForGridSelection(crudActions: CrudActions): CrudOverviewButtonConfig[] {
    let actionButtons: CrudOverviewButtonConfig[] = [];
    if (this.hasPermissionForAction(crudActions?.requiredPermissionToEdit)) {
      if (!AssertionUtils.isNullOrUndefined(crudActions?.withEdit)) {
        this.editButton.onClick = crudActions.withEdit;
        actionButtons.push(this.editButton);
      }

      if (!AssertionUtils.isNullOrUndefined(crudActions?.withDuplicate)) {
        this.duplicateButton.onClick = crudActions.withDuplicate;
        actionButtons.push(this.duplicateButton);
      }

      if (!AssertionUtils.isNullOrUndefined(crudActions?.withDelete)) {
        this.deleteButton.onClick = crudActions.withDelete;
        if (crudActions.allowDeleteMultiple) {
          this.deleteButton.onlyEnabledOnSingleRowSelection = false;
        }
        actionButtons.push(this.deleteButton);
      }

      return cloneDeep(actionButtons);
    } else {
      return actionButtons;
    }
  }

  public getActionButtonsWithRouting(routes: Routes, editPermission: Permission, withDelete: (event: AgGridAngular | IRowNode) => void): CrudOverviewButtonConfig[] {
    const editRoute = PrototypeRouteUtils.getPage(routes, 'edit/:id');
    const duplicateRoute = PrototypeRouteUtils.getPage(routes, 'add/:id');

    return this.getActionButtonsForGridSelection({
      withDelete: withDelete,
      requiredPermissionToEdit: editPermission,
      withEdit: (event: IRowNode) =>
        this.contentSwitcher.navigateForward(
          {key: NavigationUtils.getAbsolutePath(editRoute.data.id), component: editRoute.component, routeData: editRoute.data as RouteData, data: {routeId: event.data.id}},
          {}
        ),
      withDuplicate: (event: IRowNode) =>
        this.contentSwitcher.navigateForward(
          {key: NavigationUtils.getAbsolutePath(duplicateRoute.data.id), component: duplicateRoute.component, routeData: duplicateRoute.data as RouteData, data: {routeId: event.data.id}},
          {}
        )
    });
  }

  public getActionButtonsWithEntries(
    editEntry: ContentSwitcherEntry,
    duplicateEntry: ContentSwitcherEntry,
    editPermission: Permission,
    withDelete: (event: AgGridAngular | IRowNode) => void
  ): CrudOverviewButtonConfig[] {
    return this.getActionButtonsForGridSelection({
      withDelete: withDelete,
      requiredPermissionToEdit: editPermission,
      withEdit: (event: IRowNode) =>
        this.contentSwitcher.navigateForward({key: editEntry.key, component: editEntry.component, routeData: editEntry.data as RouteData, data: {routeId: event.data.id}}, {})
    });
  }

  private hasPermissionForAction(permission: Permission): boolean {
    this.currentSubscription = this.authentication.getCurrentSubscription();

    return this.currentSubscription?.hasPermission(permission);
  }
}
