import {Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {NavigationBreadcrumbsService} from '@application/services/navigation-breadcrumbs/navigation-breadcrumbs.service';
import {AssertionUtils, BaseComponent, ContentSwitcherDialogService} from '@vdw/angular-component-library';
import {startWith, takeUntil} from 'rxjs';

@Directive({
  selector: '[appBreadcrumbFormLinker]'
})
export class BreadcrumbFormLinkerDirective extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input('appBreadcrumbFormLinker') public formControlName: string;
  @Input() public name: string;

  public constructor(
    private readonly formGroupDirective: FormGroupDirective,
    private readonly contentSwitcher: ContentSwitcherDialogService,
    private readonly navigationBreadcrumbsService: NavigationBreadcrumbsService
  ) {
    super();
  }

  public ngOnInit(): void {
    const formGroup = this.formGroupDirective.form;

    if (formGroup) {
      this.navigationBreadcrumbsService.updateBreadcrumbFormLinkerUsed(true);
      const formControl = formGroup.get(AssertionUtils.isEmpty(this.formControlName) ? 'name' : this.formControlName);

      formControl.valueChanges.pipe(startWith(formControl.value), takeUntil(this.unSubscribeOnViewDestroy)).subscribe((value: string | number) => {
        if (typeof value === 'number') {
          value = value.toString();
        }

        this.isDialog() ? this.contentSwitcher.setCreatingObjectName(value ?? '') : this.navigationBreadcrumbsService.onNameChanged(value);
      });
      return;
    }

    if (this.isDialog()) {
      this.contentSwitcher.setCreatingObjectName(this.name);
    } else {
      this.navigationBreadcrumbsService.updateBreadcrumbFormLinkerUsed(true);
      this.navigationBreadcrumbsService.onNameChanged(this.name ?? '');
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.isDialog()) {
      return;
    }

    if ('name' in changes && !changes.name.isFirstChange()) {
      this.navigationBreadcrumbsService.onNameChanged(this.name);
    }
  }

  public ngOnDestroy(): void {
    if (this.isDialog()) {
      return;
    }

    this.navigationBreadcrumbsService.updateBreadcrumbFormLinkerUsed(false);
  }

  private isDialog(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.contentSwitcher.dialog);
  }
}
