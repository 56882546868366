import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {AssertionUtils} from '@vdw/angular-component-library';
import {Observable, Subject, takeUntil} from 'rxjs';
import {NavigationHelperService} from '../navigation-helper/navigation-helper.service';
import {NavigationUnsavedChangesNotifier} from './navigation-unsaved-changes-notifier.interface';

@Injectable()
export class UnsavedChangesNotifierService implements OnDestroy {
  private isFormDirty = false;
  private saveEventEmitter = new EventEmitter<{action: boolean; navigateToSelectedRoute: string}>();

  public constructor(private readonly navigationHelperService: NavigationHelperService<NavigationUnsavedChangesNotifier>) {
    this.initializeBeforeUnloadListener();
  }

  public ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.onBeforeUnload.bind(this));
  }

  public hasUnsavedFormChanges(): boolean {
    return this.isFormDirty;
  }

  public notifyChangesToForm(hasFormChanged: boolean): void {
    this.isFormDirty = hasFormChanged;
  }

  public notifyChangesToOthers(): void {
    if (!this.hasUnsavedFormChanges()) {
      this.notifyChangesToForm(true);
    }
  }

  public setSaveEventEmitter(action: boolean, navigateToSelectedRoute: string): void {
    this.saveEventEmitter.emit({action, navigateToSelectedRoute});
  }

  public getSaveEventEmitter(): Observable<{action: boolean; navigateToSelectedRoute: string}> {
    return this.saveEventEmitter.asObservable();
  }

  public notifyChanges(form: UntypedFormGroup, unSubscribeOnViewDestroy: Subject<boolean>): void {
    form.valueChanges.pipe(takeUntil(unSubscribeOnViewDestroy)).subscribe(() => {
      if (form.dirty && !this.hasUnsavedFormChanges()) {
        this.notifyChangesToForm(true);
      }
    });
  }

  public savePartialStateOfUnsavedChangesNotifier(): void {
    this.navigationHelperService.savePartialState<NavigationUnsavedChangesNotifier>({
      isFormDirty: this.hasUnsavedFormChanges()
    });
    this.notifyChangesToForm(false);
  }

  public getPartialStateOfUnsavedChangesNotifier(): void {
    let obj: NavigationUnsavedChangesNotifier = {isFormDirty: null};
    let prevState = this.navigationHelperService.getPartialState<NavigationUnsavedChangesNotifier>(Object.keys(obj));
    if (!AssertionUtils.isNullOrUndefined(prevState)) {
      this.notifyChangesToForm(prevState.isFormDirty);
    }
  }

  private onBeforeUnload(event: BeforeUnloadEvent): void {
    if (this.hasUnsavedFormChanges()) {
      event.preventDefault();
      event.returnValue = '';
    }
  }

  private initializeBeforeUnloadListener(): void {
    window.addEventListener('beforeunload', this.onBeforeUnload.bind(this));
  }
}
