import {Injectable, Injector} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {cloneDeep} from 'lodash-es';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {ContentSwitcherEntry} from './content-switcher-entry.interface';

@Injectable({providedIn: 'root'})
export class ContentSwitcherDialogService<T = unknown> {
  public headerTitle: string;
  public parentInjector: Injector;
  public dialog: MatDialogRef<any>;
  public activeEntry: ContentSwitcherEntry;
  public navigationHistory: {entry: ContentSwitcherEntry; inputs: Record<string, any>}[] = [];

  private inputs: Record<string, unknown>;
  private cachedPreviousEntry: ContentSwitcherEntry;

  public setHeaderTitle(title: string): void {
    this.headerTitle = title;
  }

  public reset(): void {
    this.dialog = null;
    this.activeEntry = null;
    this.navigationHistory = [];
  }

  public getInputs(): Record<string, unknown> {
    return this.inputs;
  }

  public getActiveEntryData(): T {
    return this.activeEntry ? this.activeEntry.data : null;
  }

  public getCachedPreviousEntry(): ContentSwitcherEntry {
    return this.cachedPreviousEntry;
  }

  public getEntry(key: string): ContentSwitcherEntry {
    if (AssertionUtils.isEmpty(this.navigationHistory)) {
      return null;
    }

    const foundEntry = this.navigationHistory.find((history: {entry: ContentSwitcherEntry; inputs: Record<string, any>}) => history.entry.key === key);

    if (!AssertionUtils.isNullOrUndefined(foundEntry)) {
      return foundEntry.entry;
    }

    return this.cachedPreviousEntry?.key === key ? this.cachedPreviousEntry : null;
  }

  public navigateBack(): void {
    this.cachedPreviousEntry = cloneDeep(this.activeEntry);

    const previous = this.navigationHistory.pop();

    if (!AssertionUtils.isNullOrUndefined(previous)) {
      this.inputs = previous.inputs;
      this.activeEntry = previous.entry;
    } else {
      this.dialog.close();
    }
  }

  public navigateForward(entry: ContentSwitcherEntry, newInputs?: Record<string, unknown>): void {
    if (!AssertionUtils.isNullOrUndefined(this.dialog)) {
      this.cachedPreviousEntry = cloneDeep(this.activeEntry);

      if (!AssertionUtils.isNullOrUndefined(this.activeEntry)) {
        this.navigationHistory.push({entry: this.activeEntry, inputs: this.inputs});
      }
    }

    this.activeEntry = entry;
    this.inputs = newInputs;
  }
}
