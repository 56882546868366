<mat-form-field class="bms-theme full-width">
  <mat-label>{{ 'PRODUCTION_ORDER.CUSTOM_SETTINGS.NAME_GENERATION' | bmsTranslate }}</mat-label>
  <vdw-object-selection
    [formControl]="patternPreview"
    [placeHolderText]="'GENERAL.ACTIONS.CREATE_OBJECT' | bmsTranslate: {object: 'GENERAL.PLACEHOLDER.PATTERN' | bmsTranslate: {count: 1} | lowercase}"
    [canShowMultiSelectPlaceHolder]="false"
    [canShowBMSTheme]="true"
    [withDialogObjectSelection]="true"
    (selectClicked)="selectNameGenerationPattern()"
    (clearClicked)="clearNameGeneratorParts()"
  />
</mat-form-field>
