@if (loadingForTheFirstTime) {
  <div class="flex-column flex-center gap-32 bms-theme">
    <mat-spinner class="m-12" diameter="72" mode="indeterminate"></mat-spinner>
    <div class="flex-column flex-center gap-8">
      <h2>{{ 'ANGULAR_COMPONENT_LIBRARY.OVERLAY.LOADING.TITLE' | bmsTranslate }}</h2>
      <p class="b1">{{ 'ANGULAR_COMPONENT_LIBRARY.OVERLAY.LOADING.DATA_BEING_FETCHED' | bmsTranslate }}</p>
    </div>
  </div>
} @else {
  <div class="loading-icon-wrapper">
    <mat-spinner diameter="16" mode="indeterminate"></mat-spinner>
  </div>
}
