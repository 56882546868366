import {Injectable, Injector} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {IdNameDescription} from '@domain/id-name-description';
import {HttpPlasticProductGroupsService} from '@infrastructure/http/plastic-product-group/http-plastic-product-groups.service';
import {
  AssertionUtils,
  ContentSwitcherDialogService,
  CrudOverviewDataBuilderFactoryService,
  CrudOverviewDataConfig,
  CrudOverviewDataPageComponent,
  GridOptionsBuilder,
  TranslateService
} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {PlasticProductGroupOverviewService} from './plastic-product-group-overview.service';

@Injectable()
export class PlasticProductGroupSelectionService {
  private config: CrudOverviewDataConfig<IdNameDescription>;

  public constructor(
    private readonly injector: Injector,
    private readonly plasticProductGroups: HttpPlasticProductGroupsService,
    private readonly translate: TranslateService,
    private readonly overview: PlasticProductGroupOverviewService,
    private readonly contentSwitcher: ContentSwitcherDialogService,
    private readonly builderFactoryService: CrudOverviewDataBuilderFactoryService<IdNameDescription>
  ) {
    this.init();
  }

  public init(): void {
    this.config = this.builderFactoryService
      .getBuilder()
      .withEntityName('TEXTILE_DATA.PLASTIC_PRODUCT.PRODUCT_GROUP')
      .withRowData(this.plasticProductGroups.getAll())
      .withContentSwitcher(null)
      .withGridOptions(this.overview.getColumnDefs(), GridIdentifier.PLASTIC_PRODUCT_GROUP_OVERVIEW, null, (builder: GridOptionsBuilder) =>
        this.overview.getDefaultGridOptionsBuilder(builder).withRowSelection(false, false, true, false).build()
      )
      .build();
  }

  public openContentSwitcherDialog(inputValue: IdNameDescription, data: any): Observable<IdNameDescription[]> {
    this.contentSwitcher.parentInjector = this.injector;

    if (!AssertionUtils.isNullOrUndefined(this.contentSwitcher.activeEntry)) {
      this.contentSwitcher.activeEntry.data = data;
    }
    this.config.selectedObjects = [inputValue];

    const entry = {key: 'plastic-product-group', component: CrudOverviewDataPageComponent, data: null};

    this.contentSwitcher.navigateForward(entry, {config: this.config});
    return this.builderFactoryService.openContentSwitcherDialog();
  }
}
