import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthorizedRouteFactory as Factory} from '@application/helper/routing/authorized-route-factory';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {Permission} from '@domain/profile/permission.enum';
import {NotFoundPageComponent} from '../status-codes/404/not-found-page.component';

export const textileDataRoutes: Routes = [
  {path: '', component: NotFoundPageComponent},
  Factory.createModuleRoute(RouteUtils.paths.texStyle.finishingTemplate, (): any =>
    import('@presentation/pages/textile-data/finishing-template/finishing-template.module').then((m: any) => m.FinishingTemplateModule)
  ),
  Factory.createModuleRoute(RouteUtils.paths.texStyle.color, (): any => import('@presentation/pages/textile-data/color/color.module').then((m: any) => m.ColorModule)),
  Factory.createModuleRoute(RouteUtils.paths.texStyle.colorSet, (): any => import('@presentation/pages/textile-data/color-set/color-set.module').then((m: any) => m.ColorSetModule)),
  Factory.createModuleRoute(RouteUtils.paths.texStyle.coloredYarn, (): any => import('@presentation/pages/textile-data/colored-yarn/colored-yarn.module').then((m: any) => m.ColoredYarnModule)),
  Factory.createModuleRoute(RouteUtils.paths.texStyle.yarnSet, (): any => import('@presentation/pages/textile-data/yarn-set/yarn-set.module').then((m: any) => m.YarnSetModule)),
  Factory.createModuleRoute(RouteUtils.paths.texStyle.coloredYarnSet, (): any =>
    import('@presentation/pages/textile-data/colored-yarn-set/colored-yarn-set.module').then((m: any) => m.ColoredYarnSetModule)
  ),
  Factory.createModuleRoute(RouteUtils.paths.texStyle.weftColoredYarnSet, (): any =>
    import('@presentation/pages/textile-data/weft-colored-yarn-set/weft-colored-yarn-set.module').then((m: any) => m.WeftColoredYarnSetModule)
  ),
  Factory.createModuleRoute(RouteUtils.paths.texStyle.yarn, (): any => import('@presentation/pages/textile-data/yarn-type/yarn-type.module').then((m: any) => m.YarnTypeModule)),
  Factory.createModuleRoute(RouteUtils.paths.texStyle.machineQuality, (): any =>
    import('@presentation/pages/textile-data/machine-quality/machine-quality.module').then((m: any) => m.MachineQualityModule)
  ),
  Factory.createModuleRoute(RouteUtils.paths.texStyle.creel, (): any => import('@presentation/pages/textile-data/creel/creel.module').then((m: any) => m.CreelModule)),
  Factory.createPrototypeModuleRoute(
    {
      route: 'weave-product',
      parentId: NavigationId.TEXSTYLE,
      navigationKey: 'TEXTILE_DATA.WEAVE_PRODUCT.WEAVE_PRODUCT',
      id: NavigationId.WEAVE_PRODUCT_MODULE,
      requiredPermission: Permission.TEXSTYLE_WEAVE_PRODUCT_VIEW
    },
    import('@presentation/pages/textile-data/weave-product/weave-product.module')
  ),
  Factory.createModuleRoute(RouteUtils.paths.texStyle.weaveStructure, (): any =>
    import('@presentation/pages/textile-data/weave-structure/weave-structure.module').then((m: any) => m.WeaveStructureModule)
  ),
  Factory.createModuleRoute(RouteUtils.paths.texStyle.groupedWeaveStructure, (): any =>
    import('@presentation/pages/textile-data/grouped-weave-structure/grouped-weave-structure.module').then((m: any) => m.GroupedWeaveStructureModule)
  ),
  Factory.createModuleRoute(RouteUtils.paths.texStyle.companyWeaveStructure, (): any =>
    import('@presentation/pages/textile-data/company-weave-structure/company-weave-structure.module').then((m: any) => m.CompanyWeaveStructureModule)
  ),
  Factory.createPrototypeModuleRoute(
    {
      route: 'tuft-product',
      parentId: NavigationId.TEXSTYLE,
      navigationKey: 'TEXTILE_DATA.TUFT_PRODUCT.TUFT_PRODUCT',
      id: NavigationId.TUFT_PRODUCT_MODULE,
      requiredPermission: Permission.TEXSTYLE_TUFT_PRODUCT_VIEW
    },
    import('@presentation/pages/textile-data/tuft-product/tuft-product.module')
  ),
  Factory.createPrototypeModuleRoute(
    {
      route: 'plastic-product',
      parentId: NavigationId.TEXSTYLE,
      navigationKey: 'TEXTILE_DATA.PLASTIC_PRODUCT.PLASTIC_PRODUCT',
      id: NavigationId.PLASTIC_PRODUCT_MODULE,
      requiredPermission: Permission.TEXSTYLE_PLASTIC_PRODUCT_VIEW
    },
    import('@presentation/pages/textile-data/plastic-product/plastic-product.module')
  ),
  Factory.createModuleRoute(RouteUtils.paths.texStyle.buggy, (): any => import('@presentation/pages/textile-data/buggy/buggy.module').then((m: any) => m.BuggyModule)),
  Factory.createModuleRoute(RouteUtils.paths.texStyle.fixedSchedule, (): any => import('@presentation/pages/textile-data/fixed-schedule/fixed-schedule.module').then((m: any) => m.FixedScheduleModule))
];

export const textileDataRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(textileDataRoutes);
