import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Conflict} from '../../common/data/conflict';
import {WINDOW} from '../../window/window.service';
import {ConflictsDialogData} from './conflicts-dialog-data';

@Component({
  templateUrl: './conflicts-dialog.component.html',
  styleUrls: ['./conflicts-dialog.component.scss']
})
export class ConflictsDialogComponent {
  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConflictsDialogData,
    private readonly router: Router,
    @Inject(WINDOW) private readonly window: Window
  ) {}

  public getConflictTypeTranslationKey(type: string): string {
    return this.data.conflictType.getTranslationKeyForConflictType(type);
  }

  public hasMultipleConflicts(): boolean {
    return Array.isArray(this.data.entityName);
  }

  public getTitleCount(): number {
    return this.hasMultipleConflicts() ? 2 : 1;
  }

  public navigateToConflictUrl(conflict: Conflict): void {
    const conflictUrl = this.data.conflictType.getPathForConflictType(conflict, this.router, this.data?.currentSubscription);

    this.window.open(conflictUrl);
  }

  public getButtonText(): string {
    return this.data.allowUnlock ? 'CONFLICTS.UNLOCK' : 'GENERAL.OK';
  }

  public getChooseDialogCloseValue(): boolean {
    return this.data.allowUnlock ? true : undefined;
  }
}
