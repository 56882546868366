import {ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthorizedRouteFactory} from '@application/helper/routing/authorized-route-factory';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {Permission} from '@domain/profile/permission.enum';
import {AddPlasticProductPageComponent} from './add/add-plastic-product-page.component';
import {PlasticProductOverviewPageComponent} from './overview/plastic-product-overview-page.component';

const shared = {parentId: NavigationId.TOOL_MODULE};

export const plasticProductRoutes = [
  AuthorizedRouteFactory.createPrototypeComponentRoute(PlasticProductOverviewPageComponent, {
    route: '',
    requiredPermission: Permission.TEXSTYLE_PLASTIC_PRODUCT_VIEW,
    reuse: true,
    id: NavigationId.PLASTIC_PRODUCT,
    ...shared
  }),
  AuthorizedRouteFactory.createPrototypeComponentRoute(AddPlasticProductPageComponent, {
    route: 'add',
    requiredPermission: Permission.TEXSTYLE_PLASTIC_PRODUCT_EDIT,
    reuse: true,
    id: NavigationId.ADD_PLASTIC_PRODUCT,
    ...shared
  }),
  AuthorizedRouteFactory.createPrototypeComponentRoute(AddPlasticProductPageComponent, {
    route: 'edit/:id',
    requiredPermission: Permission.TEXSTYLE_PLASTIC_PRODUCT_VIEW,
    id: NavigationId.EDIT_PLASTIC_PRODUCT,
    ...shared
  }),
  AuthorizedRouteFactory.createPrototypeComponentRoute(AddPlasticProductPageComponent, {
    route: 'add/:id',
    requiredPermission: Permission.TEXSTYLE_PLASTIC_PRODUCT_EDIT,
    id: NavigationId.DUPLICATE_PLASTIC_PRODUCT,
    ...shared
  })
];

export const plasticProductRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(plasticProductRoutes);
