<div class="bms-theme split-button" [class.in-progress]="inProgress">
  <ng-container *ngIf="!stroked; else strokedButton">
    <button mat-flat-button [class.compact]="compact" [disabled]="disabled" (click)="onButonClicked()">
      <div class="flex-row gap-6">
        <span>{{ label | bmsTranslate }}</span>
        <vdw-progress-spinner *ngIf="inProgress" [compact]="compact"></vdw-progress-spinner>
      </div>
    </button>
    <mat-divider vertical></mat-divider>
    <button
      mat-flat-button
      #otherOptionsTrigger="matMenuTrigger"
      [matMenuTriggerFor]="otherOptionsMenu"
      [class.compact]="compact"
      [class.open]="otherOptionsTrigger.menuOpen"
      [class.in-progress]="inProgress"
      [disabled]="disabled"
    >
      <mat-icon [svgIcon]="otherOptionsTrigger.menuOpen ? 'solid-keyboard-arrow-up' : 'solid-keyboard-arrow-down'"></mat-icon>
    </button>
  </ng-container>
</div>

<ng-template #strokedButton>
  <button mat-stroked-button [class.compact]="compact" [disabled]="disabled" (click)="onButonClicked()">
    <div class="flex-row gap-6">
      <span>{{ label | bmsTranslate }}</span>
      <vdw-progress-spinner *ngIf="inProgress" [stroked]="true" [compact]="compact"></vdw-progress-spinner>
    </div>
  </button>
  <mat-divider vertical></mat-divider>
  <button
    mat-stroked-button
    #otherOptionsTrigger="matMenuTrigger"
    [matMenuTriggerFor]="otherOptionsMenu"
    [class.compact]="compact"
    [class.open]="otherOptionsTrigger.menuOpen"
    [class.in-progress]="inProgress"
    [disabled]="disabled"
  >
    <mat-icon [svgIcon]="otherOptionsTrigger.menuOpen ? 'solid-keyboard-arrow-up' : 'solid-keyboard-arrow-down'"></mat-icon>
  </button>
</ng-template>

<mat-menu [xPosition]="'before'" #otherOptionsMenu="matMenu" class="bms-theme"><ng-content></ng-content></mat-menu>
