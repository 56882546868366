import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {CustomSettings} from '@domain/custom-settings/custom-settings';
import {NameGeneratorProperty} from '@domain/custom-settings/name-generation-property';
import {PlasticProductionOrder} from '@domain/texfab/plastic-production-order/plastic-production-order';
import {map, Observable} from 'rxjs';
import {CustomSettingsRepository} from '../custom-settings-repository';

@Injectable({providedIn: 'root'})
export class HttpPlasticProductionOrdersCustomSettingsService implements CustomSettingsRepository<CustomSettings, PlasticProductionOrder> {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly backendConfiguration: BackendConfiguration
  ) {}

  public get(): Observable<CustomSettings> {
    return this.httpClient.get(this.baseUrl()).pipe(map((json: any) => CustomSettings.fromJSON(json)));
  }

  public save(customSettings: CustomSettings): Observable<void> {
    return this.httpClient.post<void>(this.baseUrl(), customSettings.toJSON());
  }

  public getNameGenerationProperties(): Observable<NameGeneratorProperty[]> {
    return this.httpClient.get(`${this.baseUrl(2)}/name-generation-properties`).pipe(map((json: any[]) => json.map((jsonElement: any) => NameGeneratorProperty.fromJSON(jsonElement))));
  }

  public generateName(entity: PlasticProductionOrder): Observable<string> {
    return this.httpClient.post<string>(`${this.baseUrl()}/generate-name`, entity?.toJSON() ?? null);
  }

  private baseUrl(version: number = 1): string {
    return `${this.backendConfiguration.getProductionOrderEndpoint(version)}production-orders/plastic/custom-settings`;
  }
}
