import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit, Optional} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {HeaderIdentifier} from '@application/headers/header-identifier.enum';
import {ErrorHandlers} from '@application/helper/error-handlers';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {AsyncUniqueValidator} from '@application/validators/async-unique-validator';
import {IdName} from '@domain/id-name';
import {MachineType} from '@domain/machine/machine-type.enum';
import {PlasticsMachineType} from '@domain/machine/plastics-machine-type';
import {StandardSpeed} from '@domain/machine/standard-speed.enum';
import {Permission} from '@domain/profile/permission.enum';
import {PlasticProduct} from '@domain/textile-data/plastic-product/plastic-product';
import {UnitOfMeasurement} from '@domain/textile-data/plastic-product/unit-of-measurement.enum';
import {MatchingCriteria} from '@domain/utilities/tool/matching-criteria';
import {HttpMachineTypesService} from '@infrastructure/http/machine-type/http-machine-types.service';
import {Machines, MACHINES} from '@infrastructure/http/machine/machines.interface';
import {HttpPlasticProductsService} from '@infrastructure/http/plastic-product/http-plastic-products.service';
import {MachineOverviewList} from '@presentation/pages/machine-overview/machine-overview-list';
import {CustomerSelectionService} from '@presentation/pages/texfab/customer/customer-selection.service';
import {MatchingCriteriaData} from '@presentation/pages/utilities/tool/add/matching-criteria/matching-criteria-data.interface';
import {
  AssertionUtils,
  AsyncGreaterThanValidator,
  BackendError,
  BaseComponent,
  Color,
  ContentSwitcherDialogService,
  DialogBuilderFactoryService,
  DialogType,
  EnumUtils,
  FormValidationHelper,
  PrototypeRouteUtils,
  SaveType,
  TabsMenuItem,
  TimeUtils,
  TranslateService
} from '@vdw/angular-component-library';
import {finalize, forkJoin, map, Observable, takeUntil} from 'rxjs';
import {ColorSelectionService} from '../../color/color-selection.service';
import {TextileService} from '../../textile-data-overview/textile.service';
import {TextileDataType} from '../../textile-data-type.enum';
import {PlasticProductSelectionService} from '../plastic-product-selection.service';
import {MaterialSelectionService} from './material-selection.service';
import {GeneralForm, PlasticProductForm, SetupForm} from './plastic-product-form';
import {PlasticProductGroupSelectionService} from './plastic-product-group-selection.service';
import {PlasticProductViewMode} from './plastic-product-view-mode.enum';

@Component({
  templateUrl: 'add-plastic-product-page.component.html'
})
export class AddPlasticProductPageComponent extends BaseComponent implements OnInit {
  public plasticProductToSave: PlasticProduct;
  public addPlasticProductForm: PlasticProductForm;
  public unitOfMeasurement = EnumUtils.getEnumValues(UnitOfMeasurement);
  public speedUnits = EnumUtils.getEnumValues(StandardSpeed);
  public menuItems: TabsMenuItem[] = [
    {value: PlasticProductViewMode.GENERAL, translationKey: 'GENERAL.GENERAL'},
    {value: PlasticProductViewMode.MATCHING_CRITERIA, translationKey: 'UTILITIES.TOOL.MATCHING_CRITERIA.MATCHING_CRITERIA'},
    {value: PlasticProductViewMode.SETUP, translationKey: 'TEXTILE_DATA.PLASTIC_PRODUCT.SETUP'}
  ];

  public selectedMenuItem = this.menuItems[0];
  public readonly HEADER_IDENTIFIER = HeaderIdentifier.ADD_PLASTIC_PRODUCT;
  public readonly SAVE_TYPE = SaveType;
  public readonly VIEW_MODE = PlasticProductViewMode;
  public matchingCriteriaData: MatchingCriteriaData = {machines: [], machineTypes: []};
  public matchingCriteria: MatchingCriteria[] = [];

  protected isDialog: boolean;

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(MACHINES) private readonly machines: Machines,
    private readonly activatedRoute: ActivatedRoute,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly formBuilder: FormBuilder,
    private readonly plasticProducts: HttpPlasticProductsService,
    private readonly router: Router,
    private readonly textileService: TextileService,
    private readonly translate: TranslateService,
    private readonly contentSwitcher: ContentSwitcherDialogService<{plasticProduct: PlasticProduct}>,
    private readonly plasticProductSelectionService: PlasticProductSelectionService,
    private readonly plasticProductGroupSelectionService: PlasticProductGroupSelectionService,
    private readonly materialSelectionService: MaterialSelectionService,
    private readonly colorSelectionService: ColorSelectionService,
    private readonly customerSelectionService: CustomerSelectionService,
    private readonly machineTypes: HttpMachineTypesService,
    @Optional() private readonly dialog: MatDialogRef<any>
  ) {
    super();
    this.isDialog = !AssertionUtils.isNullOrUndefined(this.dialog?.id);
  }

  public get generalForm(): GeneralForm {
    return this.addPlasticProductForm.controls.generalForm;
  }

  public get matchingCriteriaForm(): FormGroup {
    return this.addPlasticProductForm.controls.matchingCriteriaForm;
  }

  public get setupForm(): SetupForm {
    return this.addPlasticProductForm.controls.setupForm;
  }

  public ngOnInit(): void {
    this.setFormFields();

    const id = PrototypeRouteUtils.isAddPath(this.contentSwitcher.activeEntry?.routeData?.route) ? null : this.activatedRoute.snapshot.params.id;
    if (!AssertionUtils.isNullOrUndefined(this.contentSwitcher.getActiveEntryData()?.plasticProduct)) {
      this.plasticProductToSave = this.contentSwitcher.getActiveEntryData().plasticProduct;
      this.plasticProductToSave.productGroup = this.contentSwitcher.getEntry('plastic-product-group')?.data?.[0] ?? this.plasticProductToSave.productGroup;
      this.plasticProductToSave.material = this.contentSwitcher.getEntry('material')?.data?.[0] ?? this.plasticProductToSave.material;
      this.plasticProductToSave.color = this.contentSwitcher.getEntry('color')?.data?.[0] ?? this.plasticProductToSave.color;
      this.plasticProductToSave.customer = this.contentSwitcher.getEntry('customer')?.data?.[0] ?? this.plasticProductToSave.customer;
      this.setFormValues();
    } else if (!AssertionUtils.isNullOrUndefined(id)) {
      this.plasticProducts
        .getById(parseInt(id))
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((plasticProduct: PlasticProduct) => {
          this.plasticProductToSave = plasticProduct;
          this.matchingCriteria = this.plasticProductToSave.matchingCriteria.toSorted((a: MatchingCriteria, b: MatchingCriteria) => a.orderIndex - b.orderIndex);
          this.setFormValues();
        });
    }

    forkJoin([this.machines.getAll(), this.machineTypes.getAll(MachineType.PLASTIC_MACHINE)])
      .pipe(
        map(([machines, machineTypes]: [MachineOverviewList[], PlasticsMachineType[]]) => [
          machines.filter((machine: MachineOverviewList) => machine.machineType === MachineType.PLASTIC_MACHINE),
          machineTypes
        ]),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe(([machines, machineTypes]: [MachineOverviewList[], PlasticsMachineType[]]) => (this.matchingCriteriaData = {machines, machineTypes}));
  }

  public getWeightSuffixTranslationKey(): string {
    return `GENERAL.UNIT.${this.generalForm.value.unitOfMeasurement === UnitOfMeasurement.PIECES ? 'KILOGRAM' : 'KILOGRAM_PER_METER'}`;
  }

  public savePlasticProduct(saveType: SaveType): void {
    const isValid = new FormValidationHelper().checkForms([this.generalForm, this.matchingCriteriaForm, this.setupForm], this.document);
    if (isValid) {
      const plasticProductToSave = this.getCurrentPlasticProduct();
      this.saving = true;

      const request: Observable<void | number> = this.isEditingPlasticProduct() ? this.plasticProducts.update(plasticProductToSave) : this.plasticProducts.save(plasticProductToSave);
      request.pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving())).subscribe({
        next: (id: number) => {
          if (this.isDialog) {
            if (saveType === SaveType.SAVE_AND_CLOSE) {
              plasticProductToSave.id = id;
              this.plasticProductSelectionService.setSelected(plasticProductToSave);
              this.contentSwitcher.navigateBack();
            } else if (saveType === SaveType.SAVE_AND_CREATE_NEW) {
              this.setFormFields();
              this.selectedMenuItem = this.menuItems.find((item: TabsMenuItem) => item.value === PlasticProductViewMode.GENERAL);
            }
            return;
          }

          this.textileService.navigateAndShowToast(
            saveType,
            TextileDataType.PLASTIC_PRODUCT,
            'TEXTILE_DATA.PLASTIC_PRODUCT.PLASTIC_PRODUCT',
            this.isEditingPlasticProduct(),
            plasticProductToSave.name,
            id
          );
        },
        error: (errorMessage: BackendError) =>
          this.showErrorDialogForBackendError(this.isEditingPlasticProduct() ? 'GENERAL.ACTIONS.EDIT_OBJECT' : 'GENERAL.ACTIONS.CREATE_OBJECT', errorMessage.message)
      });
    }
  }

  public duplicatePlasticProduct(): void {
    this.router.navigateByUrl(RouteUtils.paths.texStyle.plasticProduct.duplicatePlasticProduct.absolutePath.replace(':id', this.plasticProductToSave.id.toString()));
  }

  public deletePlasticProduct(): void {
    this.textileService.removeConfirmation(this.plasticProductToSave, TextileDataType.PLASTIC_PRODUCT, false, null, this.plasticProducts);
  }

  public navigateBack(): void {
    if (this.isDialog) {
      this.contentSwitcher.navigateBack();
    } else {
      this.router.navigateByUrl(RouteUtils.paths.texStyle.plasticProduct.absolutePath);
    }
  }

  public isEditingPlasticProduct(): boolean {
    if (this.isDialog) {
      return PrototypeRouteUtils.isEditPath(this.contentSwitcher.activeEntry?.routeData?.route);
    }

    return this.activatedRoute.snapshot.routeConfig.path === RouteUtils.paths.texStyle.plasticProduct.editPlasticProduct.path;
  }

  public canShowForm(viewMode: PlasticProductViewMode): boolean {
    return this.selectedMenuItem.value === viewMode;
  }

  public selectProductGroup(): void {
    this.openContentSwitcherDialog<IdName>(this.generalForm.controls.productGroup, this.plasticProductGroupSelectionService);
  }

  public selectMaterial(): void {
    this.openContentSwitcherDialog<IdName>(this.generalForm.controls.material, this.materialSelectionService);
  }

  public selectColor(): void {
    this.openContentSwitcherDialog<Color>(this.generalForm.controls.color, this.colorSelectionService);
  }

  public selectCustomer(): void {
    this.openContentSwitcherDialog<IdName>(this.generalForm.controls.customer, this.customerSelectionService);
  }

  public getLoadLink(): string {
    return ErrorHandlers.getLoadLink(RouteUtils.paths.texStyle.plasticProduct.editPlasticProduct.absolutePath, this.generalForm.controls['name']);
  }

  public getPlasticProductPermission(): Permission {
    return RouteUtils.paths.texStyle.plasticProduct.editPlasticProduct.requiredPermission;
  }

  private setFormFields(): void {
    const generalForm = this.formBuilder.group({
      name: this.formBuilder.control(null, Validators.required, AsyncUniqueValidator.createValidator(this.plasticProducts, null)),
      productGroup: this.formBuilder.control(null),
      unitOfMeasurement: this.formBuilder.control(UnitOfMeasurement.PIECES),
      weight: this.formBuilder.control(null, [], AsyncGreaterThanValidator.createGreaterThanValidator(0)),
      material: this.formBuilder.control(null),
      color: this.formBuilder.control(null),
      customer: this.formBuilder.control(null),
      standardEfficiency: this.formBuilder.control(null, Validators.min(0)),
      standardCycleTime: this.formBuilder.control(null, Validators.min(0)),
      speedUnit: this.formBuilder.control(StandardSpeed.CYCLE_TIME),
      description: this.formBuilder.control(null)
    });

    const setupForm = this.formBuilder.group({
      mountTimeInMinutes: this.formBuilder.control(null, Validators.min(0)),
      dismountTimeInMinutes: this.formBuilder.control(null, Validators.min(0)),
      loadTimeInMinutes: this.formBuilder.control(null, Validators.min(0)),
      unloadTimeInMinutes: this.formBuilder.control(null, Validators.min(0))
    });

    this.addPlasticProductForm = this.formBuilder.group({
      generalForm,
      matchingCriteriaForm: this.formBuilder.group({}),
      setupForm
    });

    this.menuItems[0].form = this.generalForm;
    this.menuItems[1].form = this.matchingCriteriaForm;
    this.menuItems[2].form = this.setupForm;
  }

  private setFormValues(): void {
    this.generalForm.reset({
      name: this.isDuplicatingPlasticProduct() ? null : this.plasticProductToSave.name,
      unitOfMeasurement: this.plasticProductToSave.unitOfMeasurement,
      weight: this.plasticProductToSave.weightInKg,
      standardEfficiency: this.plasticProductToSave.standardEfficiency,
      standardCycleTime:
        this.plasticProductToSave.speedUnit === StandardSpeed.CYCLE_TIME
          ? this.plasticProductToSave.standardCycleTimeInSeconds
          : TimeUtils.SECONDS_IN_A_MINUTE / this.plasticProductToSave.standardCycleTimeInSeconds,
      speedUnit: this.plasticProductToSave.speedUnit,
      description: this.plasticProductToSave.description,
      productGroup: this.plasticProductToSave.productGroup,
      material: this.plasticProductToSave.material,
      color: this.plasticProductToSave.color,
      customer: this.plasticProductToSave.customer
    });

    this.setupForm.reset({
      mountTimeInMinutes: this.plasticProductToSave.mountTimeInMinutes,
      dismountTimeInMinutes: this.plasticProductToSave.dismountTimeInMinutes,
      loadTimeInMinutes: this.plasticProductToSave.loadTimeInMinutes,
      unloadTimeInMinutes: this.plasticProductToSave.unloadTimeInMinutes
    });

    if (this.isEditingPlasticProduct()) {
      this.generalForm.controls.name.setAsyncValidators([AsyncUniqueValidator.createValidator(this.plasticProducts, this.plasticProductToSave.name)]);
      this.generalForm.controls.name.updateValueAndValidity();
    }
  }

  private getCurrentPlasticProduct(): PlasticProduct {
    return new PlasticProduct(
      this.isEditingPlasticProduct() ? this.plasticProductToSave.id : null,
      this.generalForm.value.name,
      this.generalForm.value.unitOfMeasurement,
      this.generalForm.value.weight,
      this.generalForm.value.standardEfficiency,
      this.generalForm.value.speedUnit === StandardSpeed.CYCLE_TIME ? this.generalForm.value.standardCycleTime : TimeUtils.SECONDS_IN_A_MINUTE / this.generalForm.value.standardCycleTime,
      this.generalForm.value.speedUnit,
      this.generalForm.value.description,
      this.setupForm.value.mountTimeInMinutes,
      this.setupForm.value.dismountTimeInMinutes,
      this.setupForm.value.loadTimeInMinutes,
      this.setupForm.value.unloadTimeInMinutes,
      this.generalForm.value.productGroup,
      this.generalForm.value.material,
      this.generalForm.value.color,
      this.generalForm.value.customer,
      this.matchingCriteria
    );
  }

  private showErrorDialogForBackendError(translationKey: string, message: string): void {
    this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
      titleText: this.translate.instant(translationKey, {
        object: this.translate.instant('TEXTILE_DATA.PLASTIC_PRODUCT.PLASTIC_PRODUCT', {count: 1})
      }),
      messageText: message,
      type: DialogType.INFORMATION
    });
  }

  private openContentSwitcherDialog<T>(field: AbstractControl<T>, selectionService: any): void {
    selectionService
      .openContentSwitcherDialog(field.value, {plasticProduct: this.getCurrentPlasticProduct()})
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((object: T[]) => field.setValue(AssertionUtils.isEmpty(object) ? field.value : object[0]));
  }

  private isDuplicatingPlasticProduct(): boolean {
    if (AssertionUtils.isNullOrUndefined(this.activatedRoute.snapshot.routeConfig?.path)) {
      return PrototypeRouteUtils.isDuplicatePath(this.contentSwitcher.activeEntry?.routeData?.route) && this.contentSwitcher.getCachedPreviousEntry()?.routeData?.id === NavigationId.PLASTIC_PRODUCT;
    }

    return this.activatedRoute.snapshot.routeConfig?.path === RouteUtils.paths.utilities.tool.duplicateTool.path;
  }
}
