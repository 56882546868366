import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {debounceTime, takeUntil} from 'rxjs';
import {BaseComponent} from '../../../base-component';
import {AssertionUtils} from '../../../common/utils/assertion-utils';
import {DialogBuilder} from '../../../dialogs/dialog-builder/dialog-builder';
import {DialogBuilderFactoryService} from '../../../dialogs/dialog-builder/dialog-builder-factory.service';
import {ArrowPosition} from '../../../dialogs/dialog-reposition/arrow-position.enum';
import {ContentSwitcherDialogService} from '../../content-switcher/content-switcher-dialog.service';
import {GridSelectionOptionsComponent} from '../../grid-selection-options/grid-selection-options.component';
import {CrudGridContextService} from '../crud-grid-context-service/crud-grid.context.service';
import {CrudOverviewDataConfig} from '../interfaces/crud-overview-data-config.interface';

@Component({
  selector: 'vdw-crud-overview-data-page',
  templateUrl: './crud-overview-data-page.component.html',
  styleUrls: ['./crud-overview-data-page.component.scss']
})
export class CrudOverviewDataPageComponent<T = unknown> extends BaseComponent implements OnInit, OnDestroy {
  @Input() public config: CrudOverviewDataConfig<T> = {};
  @Input() public canShowActions = true;

  protected showCustomSettingsButton: boolean = false;

  private gridRect: DOMRect;
  private selectedItemCount = 0;
  private selectOptionsOpen = false;
  private dialogBuilder: DialogBuilder;
  private readonly DIALOG_VERTICAL_OFFSET = 28;

  public constructor(
    private readonly context: CrudGridContextService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly contentSwitcher: ContentSwitcherDialogService,
    private readonly dialogBuilderFactory: DialogBuilderFactoryService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.showCustomSettingsButton = !AssertionUtils.isNullOrUndefined(this.config.withCustomSettingsClicked);

    this.dialogBuilder = this.dialogBuilderFactory.getBuilder();
    this.config.dataObservable?.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((data: T[]) => (this.config.rowData = data));

    this.context.gridReady.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => this.handleSelectionDialog());
  }

  private handleSelectionDialog(): void {
    this.context.grid?.rowSelected?.pipe(takeUntil(this.unSubscribeOnViewDestroy), debounceTime(0)).subscribe(() => {
      setTimeout(() => {
        if (!AssertionUtils.isNullOrUndefined(this.contentSwitcher.dialog)) {
          return;
        }

        this.gridRect = this.context.gridContainer.nativeElement.getBoundingClientRect();
        this.selectedItemCount = this.context.grid.api.getSelectedRows()?.length ?? 0;
        const top = this.gridRect.top - this.DIALOG_VERTICAL_OFFSET;

        if (this.selectedItemCount !== 0 && !this.selectOptionsOpen) {
          this.selectOptionsOpen = true;
          this.dialogBuilder
            .withoutBackdrop()
            .withAutoWidth()
            .withRefData({grid: this.context.grid, config: this.config})
            .openAtPosition(this.gridRect.left, top, ArrowPosition.LEFT, GridSelectionOptionsComponent, {}, this.changeDetector, false)
            .subscribe();
        } else if (this.selectedItemCount === 0) {
          this.dialogBuilder.close();
          this.selectOptionsOpen = false;
        }
      });
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.dialogBuilder?.close();
  }
}
