import {calculateDrawingWidthInDents} from '@application/helper/production-schedule-builder/calculate-drawing-width-in-dents';
import {getListDrawingFromProductionScheduleItem} from '@application/helper/production-schedule/get-list-drawing-from-production-schedule-item';
import {isMultipleOf} from '@application/validators/is-multiple-of-validator';
import {IdName} from '@domain/id-name';
import {BmpDrawing} from '@domain/production-schedule/bmp-drawing';
import {CreelForProductionSchedule} from '@domain/production-schedule/creel-for-production-schedule';
import {Drawing} from '@domain/production-schedule/drawing';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {FinishingForProductionSchedule} from '@domain/production-schedule/finishing-for-production-schedule';
import {FreeZone} from '@domain/production-schedule/free-zone/free-zone';
import {InitialFreeZone} from '@domain/production-schedule/free-zone/initial-free-zone';
import {JuteInformation} from '@domain/production-schedule/jute-information';
import {JuteInformationForPart} from '@domain/production-schedule/jute-information-for-part';
import {MachineQualityForProductionSchedule} from '@domain/production-schedule/machine-quality-for-production-schedule';
import {MachineSummary} from '@domain/production-schedule/machine-summary';
import {PathLabelDrawing} from '@domain/production-schedule/path-label-drawing';
import {PathLabelInformation} from '@domain/production-schedule/path-label-information';
import {ProductionScheduleErrors} from '@domain/production-schedule/production-schedule-errors';
import {ProductionScheduleItemInPathGroup} from '@domain/production-schedule/production-schedule-item-in-path-group';
import {ProductionSchedulePath} from '@domain/production-schedule/production-schedule-path';
import {ProductionSchedulePathsOfColoredYarnSet} from '@domain/production-schedule/production-schedule-paths-of-colored-yarn-set';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {ProductionScheduleWarnings} from '@domain/production-schedule/production-schedule-warnings';
import {InitialRestZone} from '@domain/production-schedule/rest-zone/initial-rest-zone';
import {RestZone} from '@domain/production-schedule/rest-zone/rest-zone';
import {CreelMap} from '@domain/textile-data/creel/creel-map';
import {OverviewListColoredYarnSetWithStartDent} from '@domain/textile-data/creel/overview-list-colored-yarn-set-with-start-dent';
import {GeneralJuteSettings} from '@domain/textile-data/machine-quality/general-jute-settings';
import {ListDrawing} from '@presentation/components/drawing-list/list-drawing';
import {ListDrawingOrderLine} from '@presentation/components/drawing-list/list-drawing-order-line';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {AssertionUtils, convertCommercialUnit, moment, Unit} from '@vdw/angular-component-library';
import {isEqual, maxBy, reject} from 'lodash-es';
import {BuggyForProductionSchedule} from './buggy-for-production-schedule';
import {NonProductionItemInPathGroup} from './non-production-item-path-group';
import {PathLabelProductionScheduleItemInPathGroup} from './path-label-production-schedule-item-in-path-group';
import {ProductionScheduleOrderLine} from './production-schedule-order-line';
import {ProductionSchedulePhase} from './production-schedule-phase.enum';
import {ProductionScheduleProductConfiguration} from './production-schedule-product-configuration';

export class ProductionSchedule {
  private _id: string | number;
  private _name: string;
  private _comment: string;
  private _machine: MachineSummary;
  private _machineQuality: MachineQualityForProductionSchedule;
  private _practicalPickDensityInPicksPerMM: number;
  private _coloredYarnSets: OverviewListColoredYarnSetWithStartDent[];
  private _initialRestZones: InitialRestZone;
  private _restZones: RestZone[];
  private _initialFreeZones: InitialFreeZone;
  private _freeZones: FreeZone[];
  private _finishing: FinishingForProductionSchedule;
  private _status: ProductionScheduleStatus;
  private _designs: Drawing[];
  private _productionSchedulePathsOfColoredYarnSets: ProductionSchedulePathsOfColoredYarnSet[];
  private _dateCreated: Date;
  private _dueDate: Date;
  private _pathLabelInformation: PathLabelInformation;
  private _warnings: ProductionScheduleWarnings;
  private _errors: ProductionScheduleErrors;
  private _loaded = false;
  private _mappingForCurrentPositionOnMachine: CreelMap[];
  private _creel: CreelForProductionSchedule;
  private _orderLines: ProductionScheduleOrderLine[] = [];
  private _runId: number;
  private _juteInformation: JuteInformation;
  private _completingPartially = false;
  private _buggy: BuggyForProductionSchedule;
  private _productConfiguration: ProductionScheduleProductConfiguration;
  private _numberOfRepeats: number;
  private _missingDesigns: boolean;
  private _isSample: boolean;
  private _canAddJute: boolean;
  private _hmiVersion: string;
  private _addToPlanboard?: boolean;
  private _juteGeneralSettings: GeneralJuteSettings;
  private _preselectionId: number;
  private _step: ProductionSchedulePhase;
  private _hasSavedManually: boolean;

  public constructor(
    id: string | number,
    name: string,
    comment: string,
    machine: MachineSummary,
    machineQuality: MachineQualityForProductionSchedule,
    practicalPickDensityInPicksPerMM: number,
    coloredYarnSets: OverviewListColoredYarnSetWithStartDent[],
    initialRestZones: InitialRestZone,
    restZones: RestZone[],
    initialFreeZones: InitialFreeZone,
    freeZones: FreeZone[],
    finishing: FinishingForProductionSchedule,
    status: ProductionScheduleStatus,
    designs: Drawing[],
    productionSchedulePathsOfColoredYarnSets: ProductionSchedulePathsOfColoredYarnSet[],
    dateCreated: Date,
    dueDate: Date,
    pathLabelInformation: PathLabelInformation,
    warnings: ProductionScheduleWarnings,
    errors: ProductionScheduleErrors,
    mappingForCurrentPositionOnMachine: CreelMap[],
    creel: CreelForProductionSchedule,
    runId: number,
    juteInformation: JuteInformation,
    buggy: BuggyForProductionSchedule,
    productConfiguration: ProductionScheduleProductConfiguration,
    numberOfRepeats: number,
    hmiVersion: string,
    missingDesigns: boolean,
    juteGeneralSettings: GeneralJuteSettings,
    preselectionId?: number,
    isSample: boolean = false,
    canAddJute: boolean = true,
    addToPlanBoard: boolean = undefined,
    step?: ProductionSchedulePhase,
    hasSavedManually?: boolean
  ) {
    this._id = id;
    this._name = name;
    this._comment = comment;
    this._machine = machine;
    this._machineQuality = machineQuality;
    this._practicalPickDensityInPicksPerMM = practicalPickDensityInPicksPerMM;
    this._coloredYarnSets = coloredYarnSets;
    this._initialRestZones = initialRestZones;
    this._restZones = restZones;
    this._initialFreeZones = initialFreeZones;
    this._freeZones = freeZones;
    this._finishing = finishing;
    this._status = status;
    this._designs = designs;
    this._productionSchedulePathsOfColoredYarnSets = productionSchedulePathsOfColoredYarnSets;
    this._dateCreated = dateCreated;
    this._dueDate = dueDate;
    this._pathLabelInformation = pathLabelInformation;
    this._warnings = warnings;
    this._errors = errors;
    this._mappingForCurrentPositionOnMachine = mappingForCurrentPositionOnMachine;
    this._creel = creel;
    this._runId = runId;
    this._juteInformation = juteInformation;
    this._buggy = buggy;
    this._productConfiguration = productConfiguration;
    this._numberOfRepeats = numberOfRepeats;
    this._missingDesigns = missingDesigns;
    this._isSample = isSample;
    this._canAddJute = canAddJute;
    this._hmiVersion = hmiVersion;
    this._addToPlanboard = addToPlanBoard;
    this._juteGeneralSettings = juteGeneralSettings;
    this._preselectionId = preselectionId;
    this._step = step;
    this._hasSavedManually = hasSavedManually;
  }

  public get id(): string | number {
    return this._id;
  }

  public set id(value: string | number) {
    this._id = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get comment(): string {
    return this._comment;
  }

  public set comment(value: string) {
    this._comment = value;
  }

  public get machine(): MachineSummary {
    return this._machine;
  }

  public set machine(value: MachineSummary) {
    this._machine = value;
  }

  public get machineQuality(): MachineQualityForProductionSchedule {
    return this._machineQuality;
  }

  public set machineQuality(value: MachineQualityForProductionSchedule) {
    this._machineQuality = value;
  }

  public get practicalPickDensityInPicksPerMM(): number {
    return this._practicalPickDensityInPicksPerMM;
  }

  public set practicalPickDensityInPicksPerMM(value: number) {
    this._practicalPickDensityInPicksPerMM = value;
  }

  public get coloredYarnSets(): OverviewListColoredYarnSetWithStartDent[] {
    return this._coloredYarnSets;
  }

  public set coloredYarnSets(value: OverviewListColoredYarnSetWithStartDent[]) {
    this._coloredYarnSets = value;
  }

  public get initialRestZones(): InitialRestZone {
    return this._initialRestZones;
  }

  public set initialRestZones(value: InitialRestZone) {
    this._initialRestZones = value;
  }

  public get restZones(): RestZone[] {
    return this._restZones;
  }

  public set restZones(value: RestZone[]) {
    this._restZones = value;
  }

  public get initialFreeZones(): InitialFreeZone {
    return this._initialFreeZones;
  }

  public set initialFreeZones(value: InitialFreeZone) {
    this._initialFreeZones = value;
  }

  public get freeZones(): FreeZone[] {
    return this._freeZones;
  }

  public set freeZones(value: FreeZone[]) {
    this._freeZones = value;
  }

  public get finishing(): FinishingForProductionSchedule {
    return this._finishing;
  }

  public set finishing(value: FinishingForProductionSchedule) {
    this._finishing = value;
  }

  public get status(): ProductionScheduleStatus {
    return this._status;
  }

  public set status(value: ProductionScheduleStatus) {
    this._status = value;
  }

  public get designs(): Drawing[] {
    return this._designs;
  }

  public set designs(value: Drawing[]) {
    this._designs = value;
  }

  public get productionSchedulePathsOfColoredYarnSets(): ProductionSchedulePathsOfColoredYarnSet[] {
    return this._productionSchedulePathsOfColoredYarnSets;
  }

  public set productionSchedulePathsOfColoredYarnSets(value: ProductionSchedulePathsOfColoredYarnSet[]) {
    this._productionSchedulePathsOfColoredYarnSets = value;
  }

  public get dateCreated(): Date {
    return this._dateCreated;
  }

  public set dateCreated(value: Date) {
    this._dateCreated = value;
  }

  public get dueDate(): Date {
    return this._dueDate;
  }

  public set dueDate(value: Date) {
    this._dueDate = value;
  }

  public get pathLabelInformation(): PathLabelInformation {
    return this._pathLabelInformation;
  }

  public set pathLabelInformation(value: PathLabelInformation) {
    this._pathLabelInformation = value;
  }

  public get warnings(): ProductionScheduleWarnings {
    return this._warnings;
  }

  public set warnings(value: ProductionScheduleWarnings) {
    this._warnings = value;
  }

  public get errors(): ProductionScheduleErrors {
    return this._errors;
  }

  public set errors(value: ProductionScheduleErrors) {
    this._errors = value;
  }

  public set loaded(value: boolean) {
    this._loaded = value;
  }

  public get mappingForCurrentPositionOnMachine(): CreelMap[] {
    return this._mappingForCurrentPositionOnMachine;
  }

  public set mappingForCurrentPositionOnMachine(value: CreelMap[]) {
    this._mappingForCurrentPositionOnMachine = value;
  }

  public get creel(): CreelForProductionSchedule {
    return this._creel;
  }

  public set creel(value: CreelForProductionSchedule) {
    this._creel = value;
  }

  public get orderLines(): ProductionScheduleOrderLine[] {
    return this._orderLines;
  }

  public set orderLines(orderLines: ProductionScheduleOrderLine[]) {
    this._orderLines = orderLines;
  }

  public get runId(): number {
    return this._runId;
  }

  public set runId(runId: number) {
    this._runId = runId;
  }

  public get juteInformation(): JuteInformation {
    return this._juteInformation;
  }

  public set juteInformation(value: JuteInformation) {
    this._juteInformation = value;
  }

  public get completingPartially(): boolean {
    return this._completingPartially;
  }

  public set completingPartially(value: boolean) {
    this._completingPartially = value;
  }

  public get buggy(): BuggyForProductionSchedule {
    return this._buggy;
  }

  public set buggy(value: BuggyForProductionSchedule) {
    this._buggy = value;
  }

  public get productConfiguration(): ProductionScheduleProductConfiguration {
    return this._productConfiguration;
  }

  public set productConfiguration(productConfiguration: ProductionScheduleProductConfiguration) {
    this._productConfiguration = productConfiguration;
  }

  public get numberOfRepeats(): number {
    return this._numberOfRepeats;
  }

  public set numberOfRepeats(numberOfRepeats: number) {
    this._numberOfRepeats = numberOfRepeats;
  }

  public get missingDesigns(): boolean {
    return this._missingDesigns;
  }

  public set missingDesigns(missingDesigns: boolean) {
    this._missingDesigns = missingDesigns;
  }

  public get isSample(): boolean {
    return this._isSample;
  }

  public set isSample(isSample: boolean) {
    this._isSample = isSample;
  }

  public get canAddJute(): boolean {
    return this._canAddJute;
  }

  public set canAddJute(canAddJute: boolean) {
    this._canAddJute = canAddJute;
  }

  public get hmiVersion(): string {
    return this._hmiVersion;
  }

  public set hmiVersion(hmiVersion: string) {
    this._hmiVersion = hmiVersion;
  }

  public get addToPlanboard(): boolean {
    return this._addToPlanboard;
  }

  public set addToPlanboard(addToPlanboard: boolean) {
    this._addToPlanboard = addToPlanboard;
  }

  public get juteGeneralSettings(): GeneralJuteSettings {
    return this._juteGeneralSettings;
  }

  public set juteGeneralSettings(value: GeneralJuteSettings) {
    this._juteGeneralSettings = value;
  }

  public get preselectionId(): number {
    return this._preselectionId;
  }

  public set preselectionId(value: number) {
    this._preselectionId = value;
  }

  public get step(): ProductionSchedulePhase {
    return this._step;
  }

  public get hasSavedManually(): boolean {
    return this._hasSavedManually;
  }

  public set hasSavedManually(value: boolean) {
    this._hasSavedManually = value;
  }

  public static createEmptyProductionSchedule(): ProductionSchedule {
    const productionSchedule = new ProductionSchedule(
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      null,
      [],
      null,
      [],
      null,
      ProductionScheduleStatus.DRAFT,
      [],
      [],
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      1,
      null,
      false,
      null,
      0,
      false
    );
    productionSchedule.loaded = true;
    return productionSchedule;
  }

  public static fromJSON(productionScheduleJSON: any): ProductionSchedule {
    const coloredYarnSetsWithStartDent = productionScheduleJSON.coloredYarnSets.map((coloredYarnSetJSON: any) => OverviewListColoredYarnSetWithStartDent.fromJSON(coloredYarnSetJSON));
    let productionSchedulePathsOfColoredYarnSets = productionScheduleJSON.coloredYarnSetPaths.map((coloredYarnSetPathJSON: any) =>
      ProductionSchedulePathsOfColoredYarnSet.fromJSON(coloredYarnSetPathJSON)
    );

    const coloredYarnSetsChanged = coloredYarnSetsWithStartDent.some((coloredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent, index: number) => {
      const productionSchedulePathsOfColoredYarnSet = productionSchedulePathsOfColoredYarnSets[index];

      return (
        AssertionUtils.isNullOrUndefined(productionSchedulePathsOfColoredYarnSet) ||
        productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet.coloredYarnSet.id !== coloredYarnSetWithStartDent.coloredYarnSet.id
      );
    });

    if (coloredYarnSetsChanged) {
      productionSchedulePathsOfColoredYarnSets = [];
    } else {
      productionSchedulePathsOfColoredYarnSets.forEach((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet, index: number) => {
        productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet = coloredYarnSetsWithStartDent[index];
      });
    }

    return new ProductionSchedule(
      productionScheduleJSON.id,
      productionScheduleJSON.name,
      productionScheduleJSON.comment,
      MachineSummary.fromJSON(productionScheduleJSON.machine, true),
      MachineQualityForProductionSchedule.fromJSON(productionScheduleJSON.machineQuality),
      productionScheduleJSON.practicalPickDensityInPicksPerMM,
      coloredYarnSetsWithStartDent,
      !AssertionUtils.isNullOrUndefined(productionScheduleJSON.initialRestZones) ? InitialRestZone.fromJSON(productionScheduleJSON.initialRestZones) : null,
      productionScheduleJSON.restZones.map((restZoneJSON: any) => RestZone.fromJSON(restZoneJSON)),
      !AssertionUtils.isNullOrUndefined(productionScheduleJSON.initialFreeZones) ? InitialFreeZone.fromJSON(productionScheduleJSON.initialFreeZones) : null,
      productionScheduleJSON.freeZones.map((freeZoneJSON: any) => FreeZone.fromJSON(freeZoneJSON)),
      FinishingForProductionSchedule.fromJSON(productionScheduleJSON.finishing),
      productionScheduleJSON.status,
      productionScheduleJSON.designs.map((drawingJSON: any) => (drawingJSON.drawingType === DrawingType.BMP ? BmpDrawing.fromJSON(drawingJSON) : null)),
      productionSchedulePathsOfColoredYarnSets,
      new Date(productionScheduleJSON.dateCreated),
      !AssertionUtils.isNullOrUndefined(productionScheduleJSON.dueDate) ? moment(productionScheduleJSON.dueDate).add(new Date().getTimezoneOffset(), 'minutes').toDate() : null,
      PathLabelInformation.fromJSON(productionScheduleJSON.pathLabelInformation),
      productionScheduleJSON.warnings,
      productionScheduleJSON.errors,
      productionScheduleJSON.mappingForCurrentPositionOnMachine,
      CreelForProductionSchedule.fromJSON(productionScheduleJSON.creel),
      productionScheduleJSON.runId ?? null,
      !AssertionUtils.isNullOrUndefined(productionScheduleJSON.juteInformation) ? JuteInformation.fromJSON(productionScheduleJSON.juteInformation) : null,
      !AssertionUtils.isNullOrUndefined(productionScheduleJSON.buggy) ? BuggyForProductionSchedule.fromJSONForSchedule(productionScheduleJSON.buggy) : null,
      !AssertionUtils.isNullOrUndefined(productionScheduleJSON.productConfiguration) ? ProductionScheduleProductConfiguration.fromJSON(productionScheduleJSON.productConfiguration) : null,
      !AssertionUtils.isNullOrUndefined(productionScheduleJSON.numberOfRepeats) ? productionScheduleJSON.numberOfRepeats : 1,
      productionScheduleJSON.hmiVersion,
      productionScheduleJSON.missingDesigns,
      !AssertionUtils.isNullOrUndefined(productionScheduleJSON.juteGeneralSettings) ? GeneralJuteSettings.fromJSON(productionScheduleJSON.juteGeneralSettings) : null,
      productionScheduleJSON.preselectionId,
      productionScheduleJSON.isSample,
      productionScheduleJSON.canAddJute,
      productionScheduleJSON.addToPlanboard,
      productionScheduleJSON.step,
      productionScheduleJSON.hasSavedManually
    );
  }

  public static modifyProductionScheduleAfterVerify(productionSchedule: ProductionSchedule, newProductionSchedule: ProductionSchedule): ProductionSchedule {
    productionSchedule.restZones = newProductionSchedule.restZones;
    productionSchedule.freeZones = newProductionSchedule.freeZones;
    productionSchedule.status = newProductionSchedule.status;

    productionSchedule.productionSchedulePathsOfColoredYarnSets.forEach(
      (productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet, productionSchedulePathsOfColoredYarnSetIndex: number) => {
        productionSchedulePathsOfColoredYarnSet.errors = newProductionSchedule.productionSchedulePathsOfColoredYarnSets[productionSchedulePathsOfColoredYarnSetIndex].errors;

        productionSchedulePathsOfColoredYarnSet.productionSchedulePaths.forEach((productionSchedulePath: ProductionSchedulePath, productionSchedulePathIndex: number) => {
          productionSchedulePath.startDent =
            newProductionSchedule.productionSchedulePathsOfColoredYarnSets[productionSchedulePathsOfColoredYarnSetIndex].productionSchedulePaths[productionSchedulePathIndex].startDent;

          productionSchedulePath.totalLengthInPicks =
            newProductionSchedule.productionSchedulePathsOfColoredYarnSets[productionSchedulePathsOfColoredYarnSetIndex].productionSchedulePaths[productionSchedulePathIndex].totalLengthInPicks;

          productionSchedulePath.totalLengthInMM =
            newProductionSchedule.productionSchedulePathsOfColoredYarnSets[productionSchedulePathsOfColoredYarnSetIndex].productionSchedulePaths[productionSchedulePathIndex].totalLengthInMM;

          const updatedPathGroups =
            newProductionSchedule.productionSchedulePathsOfColoredYarnSets[productionSchedulePathsOfColoredYarnSetIndex].productionSchedulePaths[productionSchedulePathIndex].pathGroups;

          if (productionSchedulePath.pathGroups.length !== updatedPathGroups.length) {
            productionSchedulePath.pathGroups = updatedPathGroups;
          }
        });
      }
    );

    productionSchedule.warnings = newProductionSchedule.warnings;
    productionSchedule.errors = newProductionSchedule.errors;

    productionSchedule.missingDesigns = newProductionSchedule.missingDesigns;

    return productionSchedule;
  }

  public getProductionSchedulePathsOfColoredYarnSetAtStartDent(startDent: number): ProductionSchedulePathsOfColoredYarnSet {
    return this.productionSchedulePathsOfColoredYarnSets.find(
      (productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet.startDent === startDent
    );
  }

  public getProductionSchedulePathsOfColoredYarnSetFromDent(dent: number): ProductionSchedulePathsOfColoredYarnSet {
    return this.productionSchedulePathsOfColoredYarnSets.find(
      (productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) =>
        productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet.startDent <= dent &&
        dent < productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet.startDent + productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet.technicalWidthInDents
    );
  }

  public getProductionSchedulePath(pathNumber: number): ProductionSchedulePath {
    return this.productionSchedulePathsOfColoredYarnSets
      ?.flatMap((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => productionSchedulePathsOfColoredYarnSet.productionSchedulePaths)
      ?.find((productionSchedulePath: ProductionSchedulePath) => productionSchedulePath.pathNumber === pathNumber);
  }

  public getProductionSchedulePathsOfColoredYarnSetAt(index: number): ProductionSchedulePathsOfColoredYarnSet {
    return this.productionSchedulePathsOfColoredYarnSets.length > index ? this.productionSchedulePathsOfColoredYarnSets[index] : null;
  }

  public getProductionScheduleColoredYarnSetAtStartDent(startDent: number): OverviewListColoredYarnSetWithStartDent {
    return this.coloredYarnSets.find((overviewListColoredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent) => overviewListColoredYarnSetWithStartDent.startDent === startDent);
  }

  public isProductionScheduleWithEmptyContent(): boolean {
    const emptyProductionSchedule = ProductionSchedule.createEmptyProductionSchedule();
    emptyProductionSchedule.id = this.id;
    emptyProductionSchedule.name = '';
    emptyProductionSchedule.comment = '';
    emptyProductionSchedule.machine = this.machine;
    emptyProductionSchedule.coloredYarnSets = this.coloredYarnSets;
    emptyProductionSchedule.creel = this.creel;
    emptyProductionSchedule.machineQuality = this.machineQuality;
    emptyProductionSchedule.practicalPickDensityInPicksPerMM = this.practicalPickDensityInPicksPerMM;
    emptyProductionSchedule.dateCreated = this.dateCreated;
    emptyProductionSchedule.loaded = this._loaded;
    emptyProductionSchedule.orderLines = this._orderLines;
    emptyProductionSchedule.runId = this._runId;
    emptyProductionSchedule.mappingForCurrentPositionOnMachine = this._mappingForCurrentPositionOnMachine;
    emptyProductionSchedule.productConfiguration = this._productConfiguration;
    emptyProductionSchedule.missingDesigns = this._missingDesigns;
    emptyProductionSchedule.isSample = this._isSample;
    emptyProductionSchedule.canAddJute = this._canAddJute;
    emptyProductionSchedule.juteGeneralSettings = this._juteGeneralSettings;

    return isEqual(this, emptyProductionSchedule);
  }

  public getTotalProductionScheduleWidthInDents(): number {
    return this._coloredYarnSets.reduce(
      (technicalWidthInDents: number, overviewListColoredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent) =>
        technicalWidthInDents + overviewListColoredYarnSetWithStartDent.technicalWidthInDents,
      0
    );
  }

  public getProductionScheduleLengthInMillimeters(): number {
    let productionScheduleLengthInMillimeters = 0;

    this.productionSchedulePathsOfColoredYarnSets.forEach((productionSchedulePathOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
      productionSchedulePathOfColoredYarnSet.productionSchedulePaths.forEach((productionSchedulePath: ProductionSchedulePath) => {
        const currentPathLength = productionSchedulePath.totalLengthInMM;

        if (AssertionUtils.isNullOrUndefined(productionScheduleLengthInMillimeters) || currentPathLength > productionScheduleLengthInMillimeters) {
          productionScheduleLengthInMillimeters = currentPathLength;
        }
      });
    });

    return productionScheduleLengthInMillimeters;
  }

  public getProductionScheduleLengthInPicks(): number {
    let productionScheduleLengthInPicks = 0;

    this.productionSchedulePathsOfColoredYarnSets.forEach((productionSchedulePathOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
      productionSchedulePathOfColoredYarnSet.productionSchedulePaths.forEach((productionSchedulePath: ProductionSchedulePath) => {
        const currentPathLength = productionSchedulePath.totalLengthInPicks;

        if (AssertionUtils.isNullOrUndefined(productionScheduleLengthInPicks) || currentPathLength > productionScheduleLengthInPicks) {
          productionScheduleLengthInPicks = currentPathLength;
        }
      });
    });

    return productionScheduleLengthInPicks;
  }

  public initProductionSchedulePathsForEachColoredYarnSet(productionScheduleColoredYarnSets: OverviewListColoredYarnSetWithStartDent[]): void {
    this.productionSchedulePathsOfColoredYarnSets = [];

    productionScheduleColoredYarnSets.forEach((productionScheduleColoredYarnSet: OverviewListColoredYarnSetWithStartDent) =>
      this.productionSchedulePathsOfColoredYarnSets.push(new ProductionSchedulePathsOfColoredYarnSet(productionScheduleColoredYarnSet, [], null))
    );
  }

  public toJSON(complete: boolean = false, isMachineQualityChanged: boolean = false): JSON {
    const json = {
      name: this._name,
      status: this._status,
      machine: this._machine.toJSON(),
      machineQuality: this._machineQuality.toJSON(),
      practicalPickDensityInPicksPerMM: this._practicalPickDensityInPicksPerMM,
      coloredYarnSets: this._coloredYarnSets.map((productionScheduleColoredYarnSet: OverviewListColoredYarnSetWithStartDent) => productionScheduleColoredYarnSet.toJSON()),
      initialRestZones: this._initialRestZones?.toJSON() ?? null,
      initialFreeZones: this._initialFreeZones?.toJSON() ?? null,
      finishing: this._finishing?.toJSON() ?? null,
      comment: this._comment,
      dueDate: !AssertionUtils.isNullOrUndefined(this._dueDate) ? moment(this._dueDate).startOf('day').subtract(new Date().getTimezoneOffset(), 'minutes').toISOString() : null,
      pathLabelInformation: this._pathLabelInformation?.toJSON() ?? null,
      freeZones: this._freeZones.map((freeZone: FreeZone) => freeZone.toJSON()),
      restZones: this._restZones.map((restZone: RestZone) => restZone.toJSON()),
      warnings: this._warnings,
      errors: this._errors,
      mappingForCurrentPositionOnMachine: this._mappingForCurrentPositionOnMachine,
      creel: this._creel.toJSON(),
      runId: this._runId,
      juteInformation: this.juteInformation?.toJSON() ?? null,
      buggy: this._buggy?.toJSON() ?? null,
      productConfiguration: this._productConfiguration?.toJSON() ?? null,
      numberOfRepeats: this._numberOfRepeats,
      missingDesigns: this._missingDesigns,
      isSample: this._isSample,
      hmiVersion: this._hmiVersion,
      canAddJute: this._canAddJute,
      addToPlanboard: this._addToPlanboard,
      juteGeneralSettings: this._juteGeneralSettings,
      preselectionId: this._preselectionId
    } as any as JSON;

    if (complete) {
      json['id'] = this._id;
      json['coloredYarnSetPaths'] = this._productionSchedulePathsOfColoredYarnSets.map((productionSchedulePaths: ProductionSchedulePathsOfColoredYarnSet) => productionSchedulePaths.toJSON());
    } else if (isMachineQualityChanged) {
      json['coloredYarnSetPaths'] = [];
    } else {
      json['dateCreated'] = this._dateCreated.toISOString();
    }

    return json;
  }

  public toNameGenerationJSON(): JSON {
    return {
      machine: new IdName(this._machine.id, this._machine.name),
      machineQuality: this._machineQuality.toJSON(),
      creel: new IdName(this._creel.id, this._creel.name),
      buggy: this._buggy?.toJSON() ?? new BuggyForProductionSchedule(0, null)
    } as any as JSON;
  }

  public getNonRecoloredProductionScheduleItemsPerColoredYarnSet(): Map<OverviewListColoredYarnSetWithStartDent, ProductionScheduleItemInPathGroup[]> {
    return new Map<OverviewListColoredYarnSetWithStartDent, ProductionScheduleItemInPathGroup[]>(
      this.productionSchedulePathsOfColoredYarnSets.map((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => [
        productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet,
        productionSchedulePathsOfColoredYarnSet.getNonRecoloredProductionScheduleItems()
      ])
    );
  }

  public getAmountOfNonRecoloredProductionScheduleItems(): number {
    return this.productionSchedulePathsOfColoredYarnSets.reduce(
      (amountOfNonRecoloredProductionScheduleItems: number, productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) =>
        amountOfNonRecoloredProductionScheduleItems + productionSchedulePathsOfColoredYarnSet.getNonRecoloredProductionScheduleItems().length,
      0
    );
  }

  public getUniquePathLabelDrawingsPerColoredYarnSet(): Map<OverviewListColoredYarnSetWithStartDent, PathLabelDrawing[]> {
    return new Map<OverviewListColoredYarnSetWithStartDent, PathLabelDrawing[]>(
      this.productionSchedulePathsOfColoredYarnSets.map((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
        const pathLabelDrawings = productionSchedulePathsOfColoredYarnSet.getUniquePathLabelDrawings();

        return [productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet, pathLabelDrawings];
      })
    );
  }

  public hasAtLeastOneProductionScheduleItemPerColoredYarnSet(): boolean {
    return (
      this.productionSchedulePathsOfColoredYarnSets.length > 0 &&
      this.productionSchedulePathsOfColoredYarnSets.every((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
        return productionSchedulePathsOfColoredYarnSet.productionSchedulePaths.some((productionSchedulePath: ProductionSchedulePath) => productionSchedulePath.pathGroups.length > 0);
      })
    );
  }

  public updateLoadedPathLabelDrawing(pathLabelDrawing: PathLabelDrawing): void {
    this.productionSchedulePathsOfColoredYarnSets.forEach((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) =>
      productionSchedulePathsOfColoredYarnSet.updateLoadedPathLabelDrawing(pathLabelDrawing)
    );
  }

  public hasZoneErrors(): boolean {
    return this.restZones.some((restZone: RestZone) => restZone.hasErrors()) || this.freeZones.some((freeZone: FreeZone) => freeZone.hasErrors());
  }

  public isProductionScheduleVerified(): boolean {
    return this.status === ProductionScheduleStatus.VERIFIED || this.status === ProductionScheduleStatus.VERIFIED_WITH_WARNINGS || this.status === ProductionScheduleStatus.VERIFIED_WITH_ERRORS;
  }

  public isProductionScheduleVerifiedWithNoErrors(): boolean {
    return (
      !this.hasZoneErrors() &&
      (this.status === ProductionScheduleStatus.VERIFIED || this.status === ProductionScheduleStatus.VERIFIED_WITH_WARNINGS || this.status === ProductionScheduleStatus.CREATED)
    );
  }

  public hasProductionScheduleErrors(): boolean {
    return this.errors !== null && Object.values(this.errors).some((error: number) => !AssertionUtils.isNullOrUndefined(error));
  }

  public getColoredYarnSetForProductionScheduleItem(productionScheduleItem: ProductionScheduleItemInPathGroup): OverviewListColoredYarnSet {
    return this.productionSchedulePathsOfColoredYarnSets.find((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) =>
      productionSchedulePathsOfColoredYarnSet.hasProductionScheduleItem(productionScheduleItem)
    ).productionScheduleColoredYarnSet.coloredYarnSet;
  }

  public getProductionScheduleColoredYarnSetForProductionSchedulePath(productionSchedulePath: ProductionSchedulePath): OverviewListColoredYarnSetWithStartDent {
    return this.productionSchedulePathsOfColoredYarnSets.find((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) =>
      productionSchedulePathsOfColoredYarnSet.hasProductionSchedulePath(productionSchedulePath)
    ).productionScheduleColoredYarnSet;
  }

  public canMoveProductionScheduleItemToProductionSchedulePath(
    indexInSourceProductionSchedulePath: number,
    sourceProductionSchedulePathNumber: number,
    indexInDestinationProductionSchedulePath: number,
    destinationProductionSchedulePathNumber: number,
    productionScheduleItem: ProductionScheduleItemInPathGroup
  ): boolean {
    const isInSamePath = sourceProductionSchedulePathNumber === destinationProductionSchedulePathNumber;
    let result = false;

    if (isInSamePath) {
      const isOnSamePositionOfSamePath = indexInSourceProductionSchedulePath === indexInDestinationProductionSchedulePath;
      const sourceProductionSchedulePath = this.getProductionSchedulePath(sourceProductionSchedulePathNumber);
      const hasOnlyOneProductionScheduleItemInSamePath = sourceProductionSchedulePath.pathGroups.length === 1;

      result = !isOnSamePositionOfSamePath && !hasOnlyOneProductionScheduleItemInSamePath;
    } else {
      const destinationProductionSchedulePath = this.getProductionSchedulePath(destinationProductionSchedulePathNumber);
      const listDrawing = getListDrawingFromProductionScheduleItem(productionScheduleItem, this);
      const destinationColoredYarnSetWithStartDent = this.getProductionScheduleColoredYarnSetForProductionSchedulePath(destinationProductionSchedulePath);

      if (this._machine.allowsEmptySpaceInPaths() || destinationProductionSchedulePath.technicalWidthInDents === productionScheduleItem.technicalDimensions.widthInDents) {
        const startDentOfSourceColoredYarnSet = this.getProductionScheduleColoredYarnSetForProductionSchedulePath(this.getProductionSchedulePath(sourceProductionSchedulePathNumber)).startDent;

        if (destinationColoredYarnSetWithStartDent.startDent === startDentOfSourceColoredYarnSet) {
          const productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet = this.getProductionSchedulePathsOfColoredYarnSetAtStartDent(startDentOfSourceColoredYarnSet);
          const sourceProductionSchedulePath: ProductionSchedulePath = productionSchedulePathsOfColoredYarnSet.getProductionSchedulePath(sourceProductionSchedulePathNumber);

          const widerProductionScheduleItemInSourceProductionSchedulePath = maxBy(
            reject(sourceProductionSchedulePath.pathGroups, {uuid: productionScheduleItem.uuid}),
            'technicalDimensions.widthInDents'
          );

          const widthOfWiderProductionScheduleItemInSourceProductionSchedulePath = widerProductionScheduleItemInSourceProductionSchedulePath?.technicalDimensions.widthInDents ?? 0;

          const differenceBetweenProductionScheduleItemWidthAndDestinationProductionSchedulePathWidth = Math.max(
            productionScheduleItem.technicalDimensions.widthInDents - destinationProductionSchedulePath.technicalWidthInDents,
            0
          );

          const differenceBetweenProductionScheduleItemWidthAndWiderProductionScheduleItemInSourceProductionSchedulePathWidth = Math.max(
            productionScheduleItem.technicalDimensions.widthInDents - widthOfWiderProductionScheduleItemInSourceProductionSchedulePath,
            0
          );

          const newProductionSchedulePathsWidthInDents =
            productionSchedulePathsOfColoredYarnSet.getProductionSchedulePathsWidthInDents() +
            differenceBetweenProductionScheduleItemWidthAndDestinationProductionSchedulePathWidth -
            differenceBetweenProductionScheduleItemWidthAndWiderProductionScheduleItemInSourceProductionSchedulePathWidth;

          result = newProductionSchedulePathsWidthInDents <= productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet.technicalWidthInDents;
        } else {
          result = this.canAddProductionScheduleItemToProductionSchedulePath(listDrawing, destinationProductionSchedulePathNumber, destinationColoredYarnSetWithStartDent);
        }
      }
    }
    return result;
  }

  public canMoveProductionScheduleItemToColoredYarnSet(
    sourceProductionSchedulePathNumber: number,
    startDentOfDestinationColoredYarnSet: number,
    productionScheduleItem: ProductionScheduleItemInPathGroup
  ): boolean {
    let result = false;
    const listDrawing = getListDrawingFromProductionScheduleItem(productionScheduleItem, this);

    if (this.isLessThanAmountOfPossiblePaths()) {
      const sourceProductionSchedulePath = this.getProductionSchedulePath(sourceProductionSchedulePathNumber);
      const sourceColoredYarnSetWithStartDent = this.getProductionScheduleColoredYarnSetForProductionSchedulePath(sourceProductionSchedulePath);

      if (sourceColoredYarnSetWithStartDent.startDent === startDentOfDestinationColoredYarnSet) {
        const destinationColoredYarnSetWithStartDent = sourceColoredYarnSetWithStartDent;
        const productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet = this.getProductionSchedulePathsOfColoredYarnSetAtStartDent(startDentOfDestinationColoredYarnSet);
        const widerProductionScheduleItemInSourceProductionSchedulePath = maxBy(
          reject(sourceProductionSchedulePath.pathGroups, {uuid: productionScheduleItem.uuid}),
          'technicalDimensions.widthInDents'
        );

        const widthOfWiderProductionScheduleItemInSourceProductionSchedulePath = widerProductionScheduleItemInSourceProductionSchedulePath?.technicalDimensions.widthInDents ?? 0;

        const differenceBetweenProductionScheduleItemWidthAndWiderProductionScheduleItemInSourceProductionSchedulePathWidth = Math.max(
          productionScheduleItem.technicalDimensions.widthInDents - widthOfWiderProductionScheduleItemInSourceProductionSchedulePath,
          0
        );

        result =
          productionSchedulePathsOfColoredYarnSet.getProductionSchedulePathsWidthInDents() +
            productionScheduleItem.technicalDimensions.widthInDents -
            differenceBetweenProductionScheduleItemWidthAndWiderProductionScheduleItemInSourceProductionSchedulePathWidth <=
          destinationColoredYarnSetWithStartDent.technicalWidthInDents;
      } else {
        result = this.canAddProductionScheduleItemToColoredYarnSet(startDentOfDestinationColoredYarnSet, listDrawing);
      }
    }
    return result;
  }

  public canMoveProductionSchedulePathToColoredYarnSet(sourceProductionSchedulePathNumber: number, startDentOfSourceColoredYarnSet: number, startDentOfDestinationColoredYarnSet: number): boolean {
    let result = false;

    const destinationColoredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent = this.getProductionScheduleColoredYarnSetAtStartDent(startDentOfDestinationColoredYarnSet);

    if (startDentOfSourceColoredYarnSet === startDentOfDestinationColoredYarnSet) {
      result = true;
    } else {
      const sourceProductionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet = this.getProductionSchedulePathsOfColoredYarnSetAtStartDent(startDentOfSourceColoredYarnSet);
      const destinationProductionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet =
        this.getProductionSchedulePathsOfColoredYarnSetAtStartDent(startDentOfDestinationColoredYarnSet);
      const productionSchedulePath = sourceProductionSchedulePathsOfColoredYarnSet.getProductionSchedulePath(sourceProductionSchedulePathNumber);

      result =
        destinationProductionSchedulePathsOfColoredYarnSet.getProductionSchedulePathsWidthInDents() + productionSchedulePath.technicalWidthInDents <=
        destinationColoredYarnSetWithStartDent.technicalWidthInDents;
    }
    return result;
  }

  public canAddProductionScheduleItemToProductionSchedulePath(
    listDrawing: ListDrawing,
    productionSchedulePathNumber: number,
    productionScheduleColoredYarnSet: OverviewListColoredYarnSetWithStartDent
  ): boolean {
    let result = false;
    if (!listDrawing) {
      return result;
    }

    const drawingWidthInDents =
      listDrawing instanceof ListDrawingOrderLine ? listDrawing.orderLine?.article?.technicalWidthInDents : calculateDrawingWidthInDents(listDrawing.drawing, this.machineQuality);

    const drawingHasSameWidthAsProductionSchedulePath = this.getProductionSchedulePath(productionSchedulePathNumber)?.technicalWidthInDents === drawingWidthInDents;

    if (this._machine.allowsEmptySpaceInPaths() || drawingHasSameWidthAsProductionSchedulePath) {
      result = this.enoughWidthToAddProductionScheduleItemToProductionSchedulePath(listDrawing, productionSchedulePathNumber, productionScheduleColoredYarnSet);
    }
    return result;
  }

  public canAddProductionScheduleItemToProductionSchedulePathForNewBuilder(productionScheduleItem: ProductionScheduleItemInPathGroup | ListDrawing, technicalWidthInDents: number): boolean {
    let result = false;
    let listDrawing: ListDrawing;
    if (productionScheduleItem instanceof ProductionScheduleItemInPathGroup) {
      listDrawing = getListDrawingFromProductionScheduleItem(productionScheduleItem, this);
    } else {
      listDrawing = productionScheduleItem;
    }
    if (!listDrawing) {
      return result;
    }

    result = this.hasExcactWidthOfProductionSchedulePath(listDrawing, technicalWidthInDents);

    return result;
  }

  public enoughWidthToAddProductionScheduleItemToProductionSchedulePath(
    listDrawing: ListDrawing,
    productionSchedulePathNumber: number,
    productionScheduleColoredYarnSet: OverviewListColoredYarnSetWithStartDent
  ): boolean {
    const drawingWidthInDents =
      listDrawing instanceof ListDrawingOrderLine ? listDrawing.orderLine?.article.technicalWidthInDents : calculateDrawingWidthInDents(listDrawing.drawing, this.machineQuality);
    const productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet = this.getProductionSchedulePathsOfColoredYarnSetAtStartDent(productionScheduleColoredYarnSet?.startDent);
    const productionSchedulePath: ProductionSchedulePath = productionSchedulePathsOfColoredYarnSet.getProductionSchedulePath(productionSchedulePathNumber);

    const differenceBetweenDrawingWidthAndPathWidth = Math.max(drawingWidthInDents - productionSchedulePath.technicalWidthInDents, 0);

    return (
      productionSchedulePathsOfColoredYarnSet.productionSchedulePaths.reduce(
        (sumTechnicalWidthInDents: number, path: ProductionSchedulePath) => sumTechnicalWidthInDents + path.technicalWidthInDents,
        0
      ) +
        differenceBetweenDrawingWidthAndPathWidth <=
      productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet.technicalWidthInDents
    );
  }

  public hasExcactWidthOfProductionSchedulePath(listDrawing: ListDrawing, technicalWidthInDents: number): boolean {
    const drawingWidthInDents =
      listDrawing instanceof ListDrawingOrderLine ? listDrawing.orderLine?.article.technicalWidthInDents : calculateDrawingWidthInDents(listDrawing.drawing, this.machineQuality);

    const differenceBetweenDrawingWidthAndPathWidth = Math.abs(drawingWidthInDents - technicalWidthInDents);

    return differenceBetweenDrawingWidthAndPathWidth === 0;
  }

  public canAddProductionScheduleItemToColoredYarnSet(startDentOfColoredYarnSet: number, listDrawing: ListDrawing): boolean {
    return this.isLessThanAmountOfPossiblePaths() ? this.enoughWidthToAddProductionScheduleItemToColoredYarnSet(startDentOfColoredYarnSet, listDrawing) : false;
  }

  public enoughWidthToAddProductionScheduleItemToColoredYarnSet(startDentOfColoredYarnSet: number, listDrawing: ListDrawing): boolean {
    const {technicalWidthInDents} = this.getProductionScheduleColoredYarnSetAtStartDent(startDentOfColoredYarnSet);
    const totalWidthOfProductionSchedulePathsInDents = this.getProductionSchedulePathsOfColoredYarnSetAtStartDent(startDentOfColoredYarnSet).getProductionSchedulePathsWidthInDents();
    const drawingWidthInDents =
      listDrawing instanceof ListDrawingOrderLine ? listDrawing.orderLine?.article.technicalWidthInDents : calculateDrawingWidthInDents(listDrawing.drawing, this.machineQuality);
    return totalWidthOfProductionSchedulePathsInDents + drawingWidthInDents <= technicalWidthInDents;
  }

  public canBeModified(): boolean {
    return (
      this._status === ProductionScheduleStatus.DRAFT ||
      this._status === ProductionScheduleStatus.VERIFIED ||
      this._status === ProductionScheduleStatus.VERIFIED_WITH_ERRORS ||
      this._status === ProductionScheduleStatus.VERIFIED_WITH_WARNINGS ||
      this._status === ProductionScheduleStatus.CREATED
    );
  }

  public isDraft(): boolean {
    return this._status === ProductionScheduleStatus.DRAFT;
  }

  public hasBeenConfigured(): boolean {
    let isPathLabelInformationValid = true;
    let isJuteInformationValid = true;

    if (!AssertionUtils.isNullOrUndefined(this.pathLabelInformation) && this.pathLabelInformation.hasAddedPathLabelBefore() && this.pathLabelInformation.hasAddedPathLabelAfter()) {
      let areFontSettingsValid = true;

      if (this.pathLabelInformation.pathLabelBefore.isText() || this.pathLabelInformation.pathLabelAfter.isText()) {
        const {fontStyle, fontWeight, fontFamily} = this.pathLabelInformation.fontSettings;
        areFontSettingsValid = !AssertionUtils.isNullOrUndefined(fontStyle) && !AssertionUtils.isNullOrUndefined(fontWeight) && !AssertionUtils.isNullOrUndefined(fontFamily);
      }

      isPathLabelInformationValid =
        !AssertionUtils.isNullOrUndefined(this.pathLabelInformation.labelHeightInPicks) &&
        isMultipleOf(this.pathLabelInformation.labelHeightInPicks, this.machineQuality?.weaveStructure?.sendRepeat) &&
        !AssertionUtils.isNullOrUndefined(this.pathLabelInformation.labelHeightInMM) &&
        this.pathLabelInformation.labelFontSizeInMM &&
        !AssertionUtils.isNullOrUndefined(this.pathLabelInformation.pattern) &&
        areFontSettingsValid;
    }

    if (!AssertionUtils.isNullOrUndefined(this.juteInformation)) {
      const sendRepeat = this.machineQuality?.weaveStructure?.sendRepeat;

      if (!AssertionUtils.isNullOrUndefined(this.juteInformation.juteBefore)) {
        isJuteInformationValid = JuteInformationForPart.isValid(this.juteInformation.juteBefore, sendRepeat);
      }

      if (!AssertionUtils.isNullOrUndefined(this.juteInformation.juteAfter)) {
        isJuteInformationValid = isJuteInformationValid && JuteInformationForPart.isValid(this.juteInformation.juteAfter, sendRepeat);
      }
    }

    return (
      !AssertionUtils.isNullOrUndefined(this.name) &&
      !AssertionUtils.isNullOrUndefined(this.machine) &&
      this.coloredYarnSets.length > 0 &&
      !AssertionUtils.isNullOrUndefined(this.machineQuality) &&
      !AssertionUtils.isNullOrUndefined(this.practicalPickDensityInPicksPerMM) &&
      this.areInitialFreeZonesValid() &&
      this.areInitialRestZonesValid() &&
      isPathLabelInformationValid &&
      isJuteInformationValid
    );
  }

  public isRestZoneConfigurable(): boolean {
    return this.coloredYarnSets.length === 1;
  }

  public hasAddedDesigns(): boolean {
    return this.designs.length > 0;
  }

  public updateFinishing(finishing: FinishingForProductionSchedule): void {
    this.finishing = finishing;
    this.productionSchedulePathsOfColoredYarnSets.forEach((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
      productionSchedulePathsOfColoredYarnSet.updateFinishing(finishing);
    });
  }

  public canPrintWeavingOrderForProductionSchedule(): boolean {
    const productionScheduleStatusesThatAllowPrintingWeavingOrder: ProductionScheduleStatus[] = [
      ProductionScheduleStatus.PROCESSED,
      ProductionScheduleStatus.FINALIZING,
      ProductionScheduleStatus.FINALIZED,
      ProductionScheduleStatus.TRANSLATING,
      ProductionScheduleStatus.TRANSLATED,
      ProductionScheduleStatus.SENDING,
      ProductionScheduleStatus.ARRIVED,
      ProductionScheduleStatus.EXECUTING,
      ProductionScheduleStatus.EXECUTED,
      ProductionScheduleStatus.EXECUTED_COMPLETE,
      ProductionScheduleStatus.EXECUTED_PARTIAL,
      ProductionScheduleStatus.CANCELLED,
      ProductionScheduleStatus.QUEUING,
      ProductionScheduleStatus.QUEUED
    ];

    return productionScheduleStatusesThatAllowPrintingWeavingOrder.includes(this.status);
  }

  public isExecuted(): boolean {
    const executedProductionScheduleStatus: ProductionScheduleStatus[] = [ProductionScheduleStatus.EXECUTED, ProductionScheduleStatus.EXECUTED_COMPLETE, ProductionScheduleStatus.EXECUTED_PARTIAL];

    return executedProductionScheduleStatus.includes(this.status);
  }

  public isPartiallyOrCompletelyExecuted(): boolean {
    const executedProductionScheduleStatus: ProductionScheduleStatus[] = [ProductionScheduleStatus.EXECUTED_COMPLETE, ProductionScheduleStatus.EXECUTED_PARTIAL];

    return executedProductionScheduleStatus.includes(this.status);
  }

  public isCreelMappingApplied(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.mappingForCurrentPositionOnMachine);
  }

  public hasOrderLines(): boolean {
    return this._orderLines.length > 0;
  }

  public updateOrderLineQuantities(): void {
    if (this.hasOrderLines()) {
      this._orderLines.forEach((orderLine: ProductionScheduleOrderLine) => {
        orderLine.amountInProductionSchedule = this.getOrderLineQuantityInProductionSchedulePathsColoredYarnSets(orderLine.id);
        orderLine.heightInProductionScheduleInMM = this.getOrderLineHeightInMMInProductionSchedulePathsColoredYarnSets(orderLine.id);
      });
    }
  }

  public updatePathLengths(productionSchedule: ProductionSchedule): void {
    this.productionSchedulePathsOfColoredYarnSets.forEach((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) =>
      productionSchedulePathsOfColoredYarnSet.updatePathLengths(productionSchedule)
    );
  }

  public getOrderLineQuantityInProductionSchedulePathsColoredYarnSets(orderLineId: number): number {
    return this._productionSchedulePathsOfColoredYarnSets.reduce(
      (quantity: number, productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) =>
        quantity + productionSchedulePathsOfColoredYarnSet.getOrderLineQuantityInProductionSchedulePaths(orderLineId),
      0
    );
  }

  public getOrderLineHeightInMMInProductionSchedulePathsColoredYarnSets(orderLineId: number): number {
    return this._productionSchedulePathsOfColoredYarnSets.reduce(
      (lengthInMM: number, productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) =>
        lengthInMM + productionSchedulePathsOfColoredYarnSet.getOrderLineHeightInMMInProductionSchedulePaths(orderLineId),
      0
    );
  }

  public getProductionScheduleItemsForListDrawing(listDrawing: ListDrawing): ProductionScheduleItemInPathGroup[] {
    return this._productionSchedulePathsOfColoredYarnSets.reduce(
      (productionScheduleItems: ProductionScheduleItemInPathGroup[], productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
        productionScheduleItems.push(...productionSchedulePathsOfColoredYarnSet.getProductionScheduleItemsForListDrawing(listDrawing));

        return productionScheduleItems;
      },
      []
    );
  }

  public getProductionScheduleItemsForOrderLine(orderLineId: number): ProductionScheduleItemInPathGroup[] {
    return this._productionSchedulePathsOfColoredYarnSets.reduce(
      (productionScheduleItems: ProductionScheduleItemInPathGroup[], productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
        productionScheduleItems.push(...productionSchedulePathsOfColoredYarnSet.getProductionScheduleItemsForOrderLine(orderLineId));

        return productionScheduleItems;
      },
      []
    );
  }

  public getProductionScheduleItemsForOrderLineIdAndColoredYarnSet(orderLineId: number, coloredYarnSetWithStartDent: OverviewListColoredYarnSetWithStartDent): ProductionScheduleItemInPathGroup[] {
    return this._productionSchedulePathsOfColoredYarnSets.reduce(
      (productionScheduleItems: ProductionScheduleItemInPathGroup[], productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
        if (
          productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet.startDent === coloredYarnSetWithStartDent.startDent &&
          productionSchedulePathsOfColoredYarnSet.productionScheduleColoredYarnSet.coloredYarnSet.id === coloredYarnSetWithStartDent.coloredYarnSet.id
        ) {
          productionScheduleItems.push(...productionSchedulePathsOfColoredYarnSet.getProductionScheduleItemsForOrderLine(orderLineId));
        }

        return productionScheduleItems;
      },
      []
    );
  }

  public getProductionSchedulePathsOfColoredYarnSetForProductionScheduleItem(productionScheduleItem: ProductionScheduleItemInPathGroup): ProductionSchedulePathsOfColoredYarnSet {
    return this._productionSchedulePathsOfColoredYarnSets.find((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) =>
      productionSchedulePathsOfColoredYarnSet.hasProductionScheduleItem(productionScheduleItem)
    );
  }

  public isRelatedToRun(): boolean {
    return !AssertionUtils.isNullOrUndefined(this._runId);
  }

  public canBeDecreated(): boolean {
    return (
      this._status === ProductionScheduleStatus.CREATED ||
      this._status === ProductionScheduleStatus.PROCESSED ||
      this._status === ProductionScheduleStatus.TRANSLATED ||
      this._status === ProductionScheduleStatus.FAILURE ||
      this._status === ProductionScheduleStatus.ARRIVED ||
      this._status === ProductionScheduleStatus.QUEUED ||
      this._status === ProductionScheduleStatus.CREATING ||
      this._status === ProductionScheduleStatus.PROCESSING ||
      this._status === ProductionScheduleStatus.TRANSLATING ||
      this._status === ProductionScheduleStatus.TO_RECONFIRM ||
      this._status === ProductionScheduleStatus.TO_PLAN
    );
  }

  public isBeingDecreated(): boolean {
    return !AssertionUtils.isNullOrUndefined(this._id) && !isNaN(Number(this._id)) && this.canBeModified();
  }

  public removePathLabelsAndNonProductionItemsFromProductionSchedulePathsOfColoredYarnSets(): void {
    this._productionSchedulePathsOfColoredYarnSets.forEach((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) =>
      productionSchedulePathsOfColoredYarnSet.removePathLabelsAndNonProductionItemsFromProductionSchedulePaths()
    );
  }

  public canCalculateYarnConsumption(): boolean {
    const productionScheduleStatusesThatAllowToCalculateYarnConsumption = [
      ProductionScheduleStatus.TRANSLATED,
      ProductionScheduleStatus.SENDING,
      ProductionScheduleStatus.ARRIVED,
      ProductionScheduleStatus.EXECUTING,
      ProductionScheduleStatus.EXECUTED,
      ProductionScheduleStatus.EXECUTED_COMPLETE,
      ProductionScheduleStatus.EXECUTED_PARTIAL,
      ProductionScheduleStatus.CANCELLED,
      ProductionScheduleStatus.QUEUING,
      ProductionScheduleStatus.QUEUED
    ];

    return productionScheduleStatusesThatAllowToCalculateYarnConsumption.includes(this._status);
  }

  public isStatusAtleastProcessed(): boolean {
    const productionScheduleStatuses: ProductionScheduleStatus[] = [
      ProductionScheduleStatus.PROCESSED,
      ProductionScheduleStatus.FINALIZING,
      ProductionScheduleStatus.FINALIZED,
      ProductionScheduleStatus.TRANSLATING,
      ProductionScheduleStatus.TRANSLATED,
      ProductionScheduleStatus.SENDING,
      ProductionScheduleStatus.ARRIVED,
      ProductionScheduleStatus.EXECUTING,
      ProductionScheduleStatus.EXECUTED,
      ProductionScheduleStatus.EXECUTED_COMPLETE,
      ProductionScheduleStatus.EXECUTED_PARTIAL,
      ProductionScheduleStatus.QUEUING,
      ProductionScheduleStatus.QUEUED
    ];

    return productionScheduleStatuses.includes(this.status);
  }

  public getPathLabelsOrNonProductionItemsLengthInPicksFromLongestPath(): {
    pathLabelsOrNonProductionItemsFromLongestPathLengthInPicks: number;
    pathLabelsOrNonProductionItemsFromLongestPathLengthInCommercialUnit: number;
  } {
    let longestProductionSchedulePath: ProductionSchedulePath;
    this.productionSchedulePathsOfColoredYarnSets?.forEach((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
      let longestPathLengthInPicks = 0;
      productionSchedulePathsOfColoredYarnSet?.productionSchedulePaths?.forEach((productionSchedulePath: ProductionSchedulePath) => {
        if (productionSchedulePath?.totalLengthInPicks > longestPathLengthInPicks) {
          longestProductionSchedulePath = productionSchedulePath;
        }
      });
    });

    let pathLabelsOrNonProductionItemsFromLongestPathLengthInPicks = 0;
    let pathLabelsOrNonProductionItemsFromLongestPathLengthInMM = 0;

    longestProductionSchedulePath?.pathGroups?.forEach((pathGroup: ProductionScheduleItemInPathGroup) => {
      if (pathGroup instanceof PathLabelProductionScheduleItemInPathGroup || pathGroup instanceof NonProductionItemInPathGroup) {
        pathLabelsOrNonProductionItemsFromLongestPathLengthInPicks += pathGroup?.technicalDimensions.heightInPicks ?? 0;
        pathLabelsOrNonProductionItemsFromLongestPathLengthInMM += pathGroup?.commercialDimensionsInMM.heightInMM ?? 0;
      }
    });

    const pathLabelsOrNonProductionItemsFromLongestPathLengthInCommercialUnit = Math.round(
      convertCommercialUnit({from: {unit: Unit.MILLIMETER, value: pathLabelsOrNonProductionItemsFromLongestPathLengthInMM}, to: Unit.CENTIMETER})
    );

    return {pathLabelsOrNonProductionItemsFromLongestPathLengthInPicks, pathLabelsOrNonProductionItemsFromLongestPathLengthInCommercialUnit};
  }

  public getProductionScheduleItemMultiplier(): number {
    return this._machineQuality?.weaveStructure?.numberOfCloths ?? 1;
  }

  private isLessThanAmountOfPossiblePaths(): boolean {
    return AssertionUtils.isNullOrUndefined(this.machine.jacquard.amountOfPossiblePaths) || this.calculateTotalAmountOfAllPathsAfterAddingDrawing() <= this.machine.jacquard.amountOfPossiblePaths;
  }

  private calculateTotalAmountOfAllPathsAfterAddingDrawing(): number {
    return this._productionSchedulePathsOfColoredYarnSets.reduce(
      (totalAmountOfAllPaths: number, productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) =>
        totalAmountOfAllPaths + productionSchedulePathsOfColoredYarnSet.productionSchedulePaths.length,
      1
    );
  }

  private areInitialFreeZonesValid(): boolean {
    return !this.machine.canHaveRestAndFreeZonesAndPathLabels() || (!AssertionUtils.isNullOrUndefined(this.initialFreeZones) && this.initialFreeZones.isValid());
  }

  private areInitialRestZonesValid(): boolean {
    return !this.machine.canHaveRestAndFreeZonesAndPathLabels() || (!AssertionUtils.isNullOrUndefined(this.initialRestZones) && this.initialRestZones.isValid(this.isRestZoneConfigurable()));
  }
}
