import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {BreadcrumbItem} from './breadcrumb-item.interface';

@Component({
  selector: 'vdw-breadcrumb',
  styleUrls: ['./breadcrumb.component.scss'],
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent {
  @Output() public itemsChanged = new EventEmitter<BreadcrumbItem | BreadcrumbItem[]>();
  @Input() public items: BreadcrumbItem[];
  @Input() public disablePredicate: (breadcrumb: any) => boolean = (): boolean => false;

  public navigateTo(index: number): void {
    if (!this.disablePredicate(this.items[index])) {
      if (!AssertionUtils.isNullOrUndefined(this.items[index]?.data?.route)) {
        this.itemsChanged.emit(this.items[index]);
      } else {
        this.items.splice(index + 1, this.items.length - index - 1);
        this.itemsChanged.emit(this.items);
      }
    }
  }
}
