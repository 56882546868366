import {Inject, Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {Authentication, AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {SignalrHubConnectionFactoryService} from '@vdw/angular-component-library';
import {Observable, of} from 'rxjs';
import {RealtimeSubscriptions} from './realtime-subscriptions';

@Injectable()
export class SignalrSubscriptionService implements RealtimeSubscriptions {
  public constructor(
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    private readonly backendConfiguration: BackendConfiguration,
    private readonly signalrHubConnectionFactoryService: SignalrHubConnectionFactoryService
  ) {}

  public getSubscriptionCacheCleared(): Observable<void> {
    if (!this.authentication.isAuthenticated()) {
      return of();
    }
    return this.signalrHubConnectionFactoryService.getSignalrHubEvents<void>(`${this.backendConfiguration.getNotificationsHubEndPoint()}notifications`, 'OnSubscriptionCacheCleared');
  }
}
