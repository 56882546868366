import {ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthorizedRouteFactory as Factory} from '@application/helper/routing/authorized-route-factory';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {Permission} from '@domain/profile/permission.enum';
import {AddToolPageComponent} from './add/add-tool-page.component';
import {ToolOverviewPageComponent} from './overview/tool-overview-page.component';

const shared = {parentId: NavigationId.TOOL_MODULE};

export const toolRoutes = [
  Factory.createPrototypeComponentRoute(ToolOverviewPageComponent, {route: '', requiredPermission: Permission.TEXSTYLE_PLASTIC_TOOL_VIEW, reuse: true, id: NavigationId.TOOL, ...shared}),
  Factory.createPrototypeComponentRoute(AddToolPageComponent, {route: 'add', requiredPermission: Permission.TEXSTYLE_PLASTIC_TOOL_EDIT, reuse: true, id: NavigationId.ADD_TOOL, ...shared}),
  Factory.createPrototypeComponentRoute(AddToolPageComponent, {route: 'edit/:id', requiredPermission: Permission.TEXSTYLE_PLASTIC_TOOL_VIEW, id: NavigationId.EDIT_TOOL, ...shared}),
  Factory.createPrototypeComponentRoute(AddToolPageComponent, {route: 'add/:id', requiredPermission: Permission.TEXSTYLE_PLASTIC_TOOL_EDIT, id: NavigationId.DUPLICATE_TOOL, ...shared})
];

export const toolRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(toolRoutes);
