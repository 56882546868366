import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {DrawingImageUploadHelper} from '@application/helper/drawing/drawing-image-upload-helper';
import {StringUtils} from '@application/helper/string-utils';
import {ColorPatternVisualisation} from '@domain/color-pattern-visualisation';
import {Pattern} from '@domain/pattern';
import {PatternVisualisation} from '@domain/pattern-visualisation';
import {PatternVisualisationType} from '@domain/pattern-visualisation-type.enum';
import {DrawingImage} from '@domain/production-schedule/drawing-image';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {GetAllFinishingsParameters} from '@infrastructure/http/finishing/finishings';
import {PatternExampleComponent} from '@presentation/components/select-pattern/pattern-example/pattern-example.component';
import {SelectPatternComponentMatDialogConfigDataInterface} from '@presentation/components/select-pattern/select-pattern-component-mat-dialog-config.interface';
import {
  AgGridUtils,
  AssertionUtils,
  BaseComponent,
  ColDefBuilderFactoryService,
  GridDateTimeComponent,
  GridOptionsBuilderFactoryService,
  NoDataOverlayComponentParams,
  OverlayComponentParams,
  SelectGridDialog,
  TabsMenuItem,
  TranslateService,
  Unit
} from '@vdw/angular-component-library';
import {AgGridAngular} from 'ag-grid-angular';
import {ColDef, FirstDataRenderedEvent, GetRowIdParams, GridApi, GridOptions, ICellRendererParams, ITooltipParams, RowDataUpdatedEvent, RowNode, ValueGetterParams} from 'ag-grid-community';
import {L10nIntlService} from 'angular-l10n';
import {get, isNil} from 'lodash-es';
import moment from 'moment';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AddPatternComponent} from './add-pattern/add-pattern.component';
import {GetPatternsService} from './get-patterns.service';
import {PatternType} from './pattern-type.enum';

@Component({
  templateUrl: './select-pattern.component.html',
  styleUrls: ['./select-pattern.component.scss']
})
export class SelectPatternComponent extends BaseComponent implements OnInit, SelectGridDialog, AfterViewInit {
  @ViewChild('colorPatternsGrid') public colorPatternsGrid: AgGridAngular;
  @ViewChild('drawingPatternsGrid') public drawingPatternsGrid: AgGridAngular;
  public patternType: PatternType;
  public listOfPatterns: Pattern[];
  public listOfGridOptions: GridOptions[];
  public listOfGridApis: GridApi[];
  public allowPatternCreation: boolean;
  public image: DrawingImage;
  public patternTranslationKey: string;
  public showPatternTypeFilter: boolean;
  public addingPattern = false;
  public canShowOnlyDefaultToggle = false;
  public selectedMenuItem: TabsMenuItem;
  public menuItems: TabsMenuItem[] = [
    {
      value: PatternVisualisationType.COLOR,
      translationKey: 'GENERAL.PATTERN.WEAVE_STRUCTURE_PATTERNS'
    },
    {
      value: [PatternVisualisationType.DRAWING, PatternVisualisationType.IMAGE],
      translationKey: 'GENERAL.PATTERN.DRAWINGS'
    }
  ];

  public canSelectPattern: boolean;

  private rowClickedObservable = new Subject<void>();

  private readonly weaveStructureId: number;
  private readonly machineQualityId: number;
  private readonly colorSetId: number;
  private readonly excludeColors: boolean;

  public constructor(
    @Inject(MAT_DIALOG_DATA) data: SelectPatternComponentMatDialogConfigDataInterface<Pattern>,
    private readonly dialogRef: MatDialogRef<SelectPatternComponent>,
    private readonly translate: TranslateService,
    private readonly drawingImageUploadHelper: DrawingImageUploadHelper,
    private readonly colDefBuilderFactory: ColDefBuilderFactoryService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService,
    private readonly getPatternsService: GetPatternsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly l10nIntlService: L10nIntlService
  ) {
    super();
    this.selectedMenuItem = data.selectedMenuItemIndex ? this.menuItems[data.selectedMenuItemIndex] : this.menuItems[0];
    this.selectedMenuItem.translationKey = !data.patternItemTranslationKey ? 'GENERAL.PATTERN.WEAVE_STRUCTURE_PATTERNS' : data.patternItemTranslationKey;
    this.patternTranslationKey = data.patternTranslationKey;
    this.patternType = data.patternType;
    this.weaveStructureId = data.weaveStructureId ?? null;
    this.machineQualityId = data.machineQualityId ?? null;
    this.colorSetId = data.colorSetId ?? null;
    this.excludeColors = data.excludeColors ?? null;
    this.allowPatternCreation = get(data, 'allowPatternCreation', false);
    this.showPatternTypeFilter = get(data, 'showPatternTypeFilter', true);
    this.drawingImageUploadHelper.unSubscribeOnViewDestroy = this.unSubscribeOnViewDestroy;
    this.canShowOnlyDefaultToggle = data.showOnlyDefaultToggle;
  }

  public get gridOptionsForListOfColorPatterns(): GridOptions {
    return !AssertionUtils.isEmpty(this.listOfGridOptions) ? this.listOfGridOptions[0] : null;
  }

  public get gridOptionsForListOfDrawingPatterns(): GridOptions {
    return !AssertionUtils.isEmpty(this.listOfGridOptions) && this.listOfGridOptions.length > 1 ? this.listOfGridOptions[1] : null;
  }

  public ngOnInit(): void {
    this.getPatterns();
    this.setGridOptionsForListOfPatterns();
    this.subscribeToRowClickAndPatternVisualisation();
  }

  public ngAfterViewInit(): void {
    this.listOfGridApis = [this.drawingPatternsGrid?.api, this.colorPatternsGrid?.api];
  }

  public selectPattern(pattern: Pattern = this.getSelectedPattern()): void {
    this.dialogRef.close(pattern);
  }

  public openAddPattern(files: File[]): void {
    this.drawingImageUploadHelper.uploadFilesWithCustomResultAction(files, [DrawingType.BMP], (image: DrawingImage) => {
      if (!isNil(image)) {
        this.addingPattern = true;
        this.image = image;
        this.dialogRef.updateSize();
        this.dialogRef.addPanelClass(AddPatternComponent.CLASS_NAME_DIALOG);
      }
    });
  }

  public closeAddPattern(): void {
    this.addingPattern = false;
    this.dialogRef.removePanelClass(AddPatternComponent.CLASS_NAME_DIALOG);
  }

  public onSelectedMenuItemChange(menuItem: TabsMenuItem): void {
    this.selectedMenuItem = menuItem;
  }

  public showOnlyDefaultsChanged(change: boolean): void {
    this.getPatterns(change);
  }

  public isColorMenuItemSelected(): boolean {
    return this.selectedMenuItem.value === PatternVisualisationType.COLOR;
  }

  public isPatternTypeFolding(): boolean {
    return this.patternType === PatternType.FOLDING_TYPES_PATTERNS;
  }

  private getPatterns(showOnlyDefaults: boolean = true): void {
    const getAllPatternsParameters: Partial<GetAllFinishingsParameters> = {
      onlyRecommended: showOnlyDefaults,
      machineQualityId: this.machineQualityId,
      weaveStructureId: this.machineQualityId ? null : this.weaveStructureId
    };

    const patternsCall = this.getPatternsService.getPatterns(this.patternType, this.machineQualityId, this.colorSetId, this.weaveStructureId, this.excludeColors, getAllPatternsParameters);
    if (patternsCall) {
      patternsCall.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((patterns: Pattern[]) => {
        this.listOfPatterns = patterns;
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  private setGridOptionsForListOfPatterns(): void {
    this.listOfGridOptions = [
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForListOfPatterns(), GridIdentifier.SELECT_COLOR_PATTERN)
        .withHeaderHeight(32)
        .withRowHeight(112)
        .withGetRowId((params: GetRowIdParams) => this.getRowId(params))
        .withOnCellDoubleClicked(() => this.selectPattern())
        .withOnRowDataUpdated(({api}: RowDataUpdatedEvent) => AgGridUtils.onRowDataUpdated(api))
        .withOnRowClicked(() => this.rowClickedObservable.next())
        .withLoadingOverlay({
          scale: 0.7
        } as OverlayComponentParams)
        .withNoRowsOverlay({
          scale: 0.7,
          titleParam: 'GENERAL.PATTERN.PATTERN',
          hideDescription: true
        } as NoDataOverlayComponentParams)
        .withOnFirstDataRendered((params: FirstDataRenderedEvent) => setTimeout(() => params.api.deselectAll()))
        .withIsExternalFilterPresent(() => this.showPatternTypeFilter)
        .withDoesExternalFilterPass((node: RowNode) => this.doesExternalFilterPass(node))
        .build(),
      this.gridOptionsBuilderFactoryService
        .getBuilder(this.getColumnDefsForListOfDrawings(), GridIdentifier.SELECT_DRAWING_PATTERN)
        .withHeaderHeight(32)
        .withRowHeight(112)
        .withGetRowId((params: GetRowIdParams) => this.getRowId(params))
        .withOnCellDoubleClicked(() => this.selectPattern())
        .withOnRowDataUpdated(({api}: RowDataUpdatedEvent) => AgGridUtils.onRowDataUpdated(api))
        .withOnRowClicked(() => this.rowClickedObservable.next())
        .withLoadingOverlay({
          scale: 0.7
        } as OverlayComponentParams)
        .withNoRowsOverlay({
          scale: 0.7,
          titleParam: 'GENERAL.PATTERN.PATTERN',
          hideDescription: true
        } as NoDataOverlayComponentParams)
        .withOnFirstDataRendered((params: FirstDataRenderedEvent) => setTimeout(() => params.api.deselectAll()))
        .withIsExternalFilterPresent(() => this.showPatternTypeFilter)
        .withDoesExternalFilterPass((node: RowNode) => this.doesExternalFilterPass(node))
        .build()
    ];
  }

  private getColumnDefsForListOfPatterns(): ColDef[] {
    return [
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('name', true)
        .withHeaderName('TEXTILE_DATA.WEAVE_STRUCTURE.PATTERN_NUMBER')
        .withComparator(StringUtils.stringEndingWithNumberComparator)
        .withValueGetter((params: ValueGetterParams): string => {
          const pattern: Pattern = params.data;
          return pattern.id.toString();
        })
        .withLockVisible()
        .build(),
      this.colDefBuilderFactory.getBuilder().withHeaderName('GENERAL.TYPE').withField('type', true).build(),
      this.colDefBuilderFactory.getBuilder().withHeaderName('GENERAL.COMMENT').withField('comment', true).build(),
      this.colDefBuilderFactory.getBuilder().withField('weavingChores', true).withHeaderName('TEXTILE_DATA.WEAVE_STRUCTURE.WEAVING_CHORES').build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.WEAVE_STRUCTURE.X_SIZE', 1, false, 'GENERAL.UNIT.DENTS')
        .withField('xSizeInDents', true)
        .withNumericMultiFilter()
        .withRightAlignment()
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.WEAVE_STRUCTURE.Y_SIZE', 1, false, 'GENERAL.UNIT.COLOR_LINES')
        .withField('ySizeInColorlines', true)
        .withNumericMultiFilter()
        .withRightAlignment()
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.WEAVE_STRUCTURE.X_SIZE', 1, false, 'GENERAL.UNIT.FRAMES', 2)
        .withField('xSizeInFrames', true)
        .withNumericMultiFilter()
        .withRightAlignment()
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('TEXTILE_DATA.WEAVE_STRUCTURE.Y_SIZE', 1, false, 'GENERAL.UNIT.PICKS')
        .withField('ySizeInPicks', true)
        .withNumericMultiFilter()
        .withRightAlignment()
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('GENERAL.PATTERN.PREVIEW_CROSS_SECTION')
        .withCellRenderer(PatternExampleComponent, (params: ICellRendererParams) => {
          return {
            weaveStructureId: this.weaveStructureId,
            visualisations: (params.data as Pattern).visualisations.filter((visualisation: PatternVisualisation) => visualisation.type === PatternVisualisationType.PATTERN)
          };
        })
        .withSortable(false)
        .withoutFilter()
        .withSuppressSizeToFit()
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('GENERAL.PATTERN.PREVIEW_MANCHON')
        .withCellRenderer(PatternExampleComponent, (params: ICellRendererParams) => {
          return {
            weaveStructureId: this.weaveStructureId,
            visualisations: (params.data as Pattern).visualisations.filter((visualisation: PatternVisualisation) => visualisation.type === PatternVisualisationType.MANCHON)
          };
        })
        .withSortable(false)
        .withoutFilter()
        .withSuppressSizeToFit()
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('GENERAL.PREVIEW')
        .withCellRenderer(PatternExampleComponent, (params: ICellRendererParams) => {
          return {
            weaveStructureId: this.weaveStructureId,
            visualisations: (params.data as Pattern).visualisations.filter((visualisation: PatternVisualisation) => visualisation.type === PatternVisualisationType.COLOR)
          };
        })
        .withSortable(false)
        .withoutFilter()
        .withSuppressSizeToFit()
        .build()
    ];
  }

  private getColumnDefsForListOfDrawings(): ColDef[] {
    return [
      this.colDefBuilderFactory
        .getBuilder()
        .withColId('name')
        .withHeaderName('GENERAL.NAME')
        .withTooltipField('name')
        .withTooltipValueGetter((params: ITooltipParams) => params.data.name)
        .withComparator(StringUtils.stringEndingWithNumberComparator)
        .withValueGetter((params: ValueGetterParams): string => {
          const drawing: Pattern = params.data;

          return drawing instanceof Pattern && drawing.visualisations[0] instanceof ColorPatternVisualisation
            ? this.translate.instant('PRODUCTION_ORDER.WEAVE_PLAN.FREE_ZONE.PATTERN_NAME', {patternId: drawing.id})
            : drawing.name;
        })
        .withLockVisible()
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('widthInPixels')
        .withHeaderName('GENERAL.DIMENSIONS.WIDTH')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => AgGridUtils.buildAgGridCellTextWithUnit(params.data.widthInPixels, Unit.PIXEL, this.l10nIntlService))
        .withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellTooltipWithUnit(params.value, Unit.PIXEL, this.l10nIntlService))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('heightInPixels')
        .withHeaderName('GENERAL.DIMENSIONS.HEIGHT')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => AgGridUtils.buildAgGridCellTextWithUnit(params.data.heightInPixels, Unit.PIXEL, this.l10nIntlService))
        .withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellTooltipWithUnit(params.value, Unit.PIXEL, this.l10nIntlService))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withColIdAndField('sizeInBytes')
        .withHeaderName('BMSCONFIG.DEVICE_TEMPLATES.COUNTERS.SIZE')
        .withRightAlignment()
        .withCellRenderer((params: ICellRendererParams) => AgGridUtils.buildAgGridCellTextWithUnitConversion(params.data.sizeInBytes, Unit.BYTE, Unit.KILOBYTE, this.l10nIntlService))
        .withTooltipValueGetter((params: ITooltipParams) => AgGridUtils.buildAgGridCellTooltipWithUnitConversion(params.value, Unit.BYTE, Unit.KILOBYTE, this.l10nIntlService))
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withField('dateCreated')
        .withHeaderName('GENERAL.DATETIME.DATE')
        .withCellRenderer(GridDateTimeComponent)
        .withTooltipValueGetter((params: ITooltipParams) => moment(params.data.dateCreated).format('DD/MM/YYYY HH:mm'))
        .withDateMultiFilter()
        .build(),
      this.colDefBuilderFactory
        .getBuilder()
        .withHeaderName('GENERAL.PREVIEW')
        .withCellRenderer(PatternExampleComponent, {weaveStructureId: this.weaveStructureId})
        .withoutFilter()
        .withSortable(false)
        .build()
    ];
  }

  private getSelectedPattern(): Pattern {
    return this.getActiveGridApi().getSelectedRows()[0];
  }

  private getActiveGridApi(): GridApi {
    return this.isPatternTypeFolding() || !this.isColorMenuItemSelected() ? this.drawingPatternsGrid?.api : this.colorPatternsGrid?.api;
  }

  private getRowId(params: GetRowIdParams): string {
    let visualisations = '';
    params.data.visualisations.forEach((patternVisualisation: PatternVisualisation) => {
      visualisations += `_${PatternVisualisationType[patternVisualisation.type]}`;
    });
    return `${params.data.id}${visualisations}`;
  }

  private doesExternalFilterPass(node: RowNode): boolean {
    const pattern = node.data as Pattern;

    if (Array.isArray(this.selectedMenuItem.value)) {
      return this.selectedMenuItem.value.includes(pattern.visualisations[0].type);
    }
    return pattern.visualisations[0].type === this.selectedMenuItem.value;
  }

  private subscribeToRowClickAndPatternVisualisation(): void {
    this.rowClickedObservable
      .asObservable()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => {
        const activeGridApi = this.getActiveGridApi();
        setTimeout(() => (this.canSelectPattern = activeGridApi && activeGridApi.getSelectedRows().length > 0));
      });
  }
}
