import {ChangeDetectionStrategy, Component, input, InputSignal, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {NameGeneratorProperty} from '@domain/custom-settings/name-generation-property';
import {NameGenerator} from '@domain/custom-settings/name-generator';
import {NameGeneratorSeparator} from '@domain/name-generator/enums/name-generator-separator.enum';
import {AssertionUtils, BaseComponent, DialogBuilderFactoryService, TranslateService} from '@vdw/angular-component-library';
import {startWith, takeUntil} from 'rxjs';
import {getPreviewText} from './name-generation-pattern-preview';
import {PatternBuilderInput} from './pattern-builder/pattern-builder-input';
import {PatternBuilderComponent} from './pattern-builder/pattern-builder.component';

@Component({
  selector: 'app-name-generation-pattern-new',
  templateUrl: './name-generation-pattern-new.component.html',
  styleUrl: './name-generation-pattern-new.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NameGenerationPatternNewComponent extends BaseComponent implements OnInit {
  public nameGeneratorFormControl: InputSignal<FormControl<NameGenerator>> = input.required();
  public nameGenerationProperties: InputSignal<NameGeneratorProperty[]> = input.required();

  protected patternPreview = new FormControl<string>(null);

  public constructor(
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly translate: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.nameGeneratorFormControl()
      .valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy), startWith(null))
      .subscribe((nameGenerator: NameGenerator) => {
        if (AssertionUtils.isNullOrUndefined(nameGenerator)) {
          this.patternPreview.disable();
        } else {
          this.patternPreview.enable();
          this.patternPreview.patchValue(getPreviewText(nameGenerator.parts, nameGenerator.separatorName, this.translate));
        }
      });
  }

  protected clearNameGeneratorParts(): void {
    this.nameGeneratorFormControl().patchValue(new NameGenerator(undefined, NameGeneratorSeparator[NameGeneratorSeparator.NO_SEPARATOR], undefined, []));
  }

  protected selectNameGenerationPattern(): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .withClass('large-modal')
      .openDialog<PatternBuilderComponent, PatternBuilderInput, NameGenerator>(PatternBuilderComponent, {
        nameGeneratorProperties: this.nameGenerationProperties(),
        nameGenerator: this.nameGeneratorFormControl().value
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((nameGenerator: NameGenerator) => {
        if (!AssertionUtils.isNullOrUndefined(nameGenerator)) {
          this.nameGeneratorFormControl().patchValue(nameGenerator);
        }
      });
  }
}
