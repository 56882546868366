import {Injectable} from '@angular/core';
import {BackendConfiguration} from '@application/configuration/backend-configuration';
import {EventExecutedSuccessfullyUpdate} from '@domain/event-executed-successfully-update';
import {MachineDirectoryUpdate} from '@domain/machine/machine-information-update/machine-directory-update.interface';
import {MachineEfficiencyUpdate} from '@domain/machine/machine-information-update/machine-efficiency-update.interface';
import {MachineMessageUpdate} from '@domain/machine/machine-information-update/machine-message-update.interface';
import {MachineProductionOrderUpdate} from '@domain/machine/machine-information-update/machine-production-order-update.interface';
import {MachineQueueProgressUpdate} from '@domain/machine/machine-information-update/machine-queue-progress-update.interface';
import {MachineReportItemUpdate} from '@domain/machine/machine-information-update/machine-report-item-update.interface';
import {MachineSpeedUpdate} from '@domain/machine/machine-information-update/machine-speed-update.interface';
import {MachineStatusUpdate} from '@domain/machine/machine-information-update/machine-status-update.interface';
import {HubConnection} from '@microsoft/signalr';
import {SignalrHubConnectionFactoryService} from '@vdw/angular-component-library';
import {concatAll, first, map, Observable, tap} from 'rxjs';
import {MachineReportItemsUpdated} from './events/machine-report-items-updated.interface';
import {RealtimeMachines} from './realtime-machines';

@Injectable()
export class SignalrMachineService implements RealtimeMachines {
  public constructor(
    private readonly backendConfiguration: BackendConfiguration,
    private readonly signalrHubConnectionFactoryService: SignalrHubConnectionFactoryService
  ) {}

  public getReportItemsChanged(): Observable<MachineReportItemUpdate> {
    return this.signalrHubConnectionFactoryService.getSignalrHubEvents(`${this.backendConfiguration.getReportItemsHubEndPoint()}reportitems`, 'OnMachineReportItemChangedLegacy').pipe(
      map((result: MachineReportItemsUpdated) => result.reportItems),
      concatAll()
    );
  }

  public getMachineEfficiency(): Observable<MachineEfficiencyUpdate> {
    return this.signalrHubConnectionFactoryService.getSignalrHubEvents<MachineEfficiencyUpdate>(
      `${this.backendConfiguration.getNotificationsHubEndPoint()}notifications`,
      'OnMachineEfficiencyChanged'
    );
  }

  public getMachineStatus(): Observable<MachineStatusUpdate> {
    return this.signalrHubConnectionFactoryService.getSignalrHubEvents<MachineStatusUpdate>(`${this.backendConfiguration.getNotificationsHubEndPoint()}notifications`, 'OnMachineStatusChanged');
  }

  public getMachineMessage(): Observable<MachineMessageUpdate> {
    return this.signalrHubConnectionFactoryService.getSignalrHubEvents<MachineMessageUpdate>(`${this.backendConfiguration.getNotificationsHubEndPoint()}notifications`, 'OnMachineMessageReceived');
  }

  public getMachineOrderProgress(): Observable<MachineProductionOrderUpdate> {
    return this.signalrHubConnectionFactoryService.getSignalrHubEvents<MachineProductionOrderUpdate>(
      `${this.backendConfiguration.getNotificationsHubEndPoint()}notifications`,
      'OnOrderProgressChanged'
    );
  }

  public getMachineQueueProgress(): Observable<MachineQueueProgressUpdate> {
    return this.signalrHubConnectionFactoryService.getSignalrHubEvents<MachineQueueProgressUpdate>(`${this.backendConfiguration.getNotificationsHubEndPoint()}notifications`, 'OnQueueProgressChanged');
  }

  public getMachineDirectory(): Observable<MachineDirectoryUpdate> {
    return this.signalrHubConnectionFactoryService.getSignalrHubEvents<MachineDirectoryUpdate>(`${this.backendConfiguration.getNotificationsHubEndPoint()}notifications`, 'OnMachineDirectoryReceived');
  }

  public requestForMachineDirectories(orderId: string): Observable<void> {
    return this.signalrHubConnectionFactoryService.getSignalrHubConnection(`${this.backendConfiguration.getNotificationsHubEndPoint()}notifications`).pipe(
      first(),
      tap((hubConnection: HubConnection) => hubConnection.send('GetMachineDirectories', orderId)),
      map(() => null)
    );
  }

  public getMachineSpeed(): Observable<MachineSpeedUpdate> {
    return this.signalrHubConnectionFactoryService.getSignalrHubEvents<MachineSpeedUpdate>(`${this.backendConfiguration.getNotificationsHubEndPoint()}notifications`, 'OnMachineSpeedChanged');
  }

  public getMachineEventExecutedSuccessfully(): Observable<EventExecutedSuccessfullyUpdate> {
    return this.signalrHubConnectionFactoryService.getSignalrHubEvents<EventExecutedSuccessfullyUpdate>(
      `${this.backendConfiguration.getMachineOverviewHubEndPoint()}machineoverview`,
      'OnEventExecutedSuccessfully'
    );
  }
}
