import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {CrudActions} from '@application/helper/grid-action-buttons-helper/crud-actions.interface';
import {GridActionButtonsHelper} from '@application/helper/grid-action-buttons-helper/grid-action-buttons-helper';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {NavigationUtils} from '@application/helper/routing/navigation-utils';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {PlasticProduct} from '@domain/textile-data/plastic-product/plastic-product';
import {HttpPlasticProductsService} from '@infrastructure/http/plastic-product/http-plastic-products.service';
import {
  AssertionUtils,
  BaseComponent,
  CrudOverviewDataBuilderFactoryService,
  CrudOverviewDataConfig,
  GridButtonClickedEvent,
  GridOptionsBuilder,
  TranslateService
} from '@vdw/angular-component-library';
import {TextileService} from '../../textile-data-overview/textile.service';
import {TextileDataType} from '../../textile-data-type.enum';
import {PlasticProductOverviewService} from './plastic-product-overview.service';

@Component({
  templateUrl: './plastic-product-overview-page.component.html'
})
export class PlasticProductOverviewPageComponent extends BaseComponent implements OnInit {
  public config: CrudOverviewDataConfig<PlasticProduct>;
  private readonly pageUrls = LastModifiedCardUtils.getPageUrls('plasticProduct');

  public constructor(
    private readonly crudOverviewDataBuilderFactory: CrudOverviewDataBuilderFactoryService<PlasticProduct>,
    private readonly plasticProducts: HttpPlasticProductsService,
    private readonly router: Router,
    private readonly textileService: TextileService,
    private readonly plasticProductOverviewService: PlasticProductOverviewService,
    private readonly gridActionButtonsHelper: GridActionButtonsHelper,
    private readonly translate: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    const colDefs = this.plasticProductOverviewService.getColumnDefs();

    this.config = this.crudOverviewDataBuilderFactory
      .getBuilder()
      .withRowData(this.plasticProducts.getAll())
      .withDefaultButtons(
        this.gridActionButtonsHelper.getActionButtonsForGridSelection({
          requiredPermissionToEdit: RouteUtils.paths.texStyle.plasticProduct.editPlasticProduct.requiredPermission,
          withEdit: (grid: GridButtonClickedEvent) => this.navigateToGivenRoute(grid, 'edit'),
          withDuplicate: (grid: GridButtonClickedEvent) => this.navigateToGivenRoute(grid, 'duplicate'),
          withDelete: (grid: GridButtonClickedEvent) => this.delete(grid),
          allowDeleteMultiple: false
        } as CrudActions)
      )
      .withGridOptions(colDefs, GridIdentifier.PLASTIC_PRODUCT_OVERVIEW, null, (builder: GridOptionsBuilder) =>
        this.plasticProductOverviewService.getDefaultGridOptionsBuilder(builder).withRowSelection(false, false, true).build()
      )
      .withEntityName(NavigationUtils.getNavigationData(NavigationId.PLASTIC_PRODUCT_MODULE).navigationKey)
      .withNewClicked(() => this.router.navigateByUrl(this.pageUrls.add))
      .build();
  }

  private navigateToGivenRoute({gridApi, rowNode}: GridButtonClickedEvent, action: string): void {
    this.router.navigateByUrl(this.pageUrls[action].replace(':id', AssertionUtils.isNullOrUndefined(rowNode) ? gridApi?.getSelectedRows()[0].id : rowNode.id));
  }

  private delete({gridApi, rowNode}: GridButtonClickedEvent): void {
    this.textileService.removeConfirmation(
      AssertionUtils.isNullOrUndefined(rowNode) ? gridApi?.getSelectedRows()[0] : rowNode.data,
      TextileDataType.PLASTIC_PRODUCT,
      false,
      null,
      this.plasticProducts
    );
  }
}
