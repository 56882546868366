import {ModuleWithProviders} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthorizedRouteFactory as Factory} from '@application/helper/routing/authorized-route-factory';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {canDeactivateRouteWithUnsavedChanges} from '@application/security/can-deactivate-unsaved-changes-notifier.guard';
import {Permission} from '@domain/profile/permission.enum';
import {AddProductionOrderWeavingPageComponent} from './add/add-production-order-weaving-page.component';
import {ProductionOrderWeavingOverviewPageComponent} from './overview/production-order-weaving-overview-page.component';
import {ProductionOrderWeavingSettingsComponent} from './settings/production-order-weaving-settings.component';

const shared = {parentId: NavigationId.WEAVING_PRODUCTION_ORDER_MODULE};

export const productionOrderWeavingRoutes = [
  Factory.createPrototypeComponentRoute(ProductionOrderWeavingOverviewPageComponent, {route: '', requiredPermission: Permission.TEXFAB_WEAVE_ORDER_VIEW, id: NavigationId.WEAVE_ORDER, ...shared}),
  Factory.createPrototypeComponentRoute(
    AddProductionOrderWeavingPageComponent,
    {
      route: 'add',
      requiredPermission: Permission.TEXFAB_WEAVE_ORDER_EDIT,
      reuse: true,
      id: NavigationId.ADD_WEAVE_ORDER,
      ...shared
    },
    [],
    [canDeactivateRouteWithUnsavedChanges]
  ),
  Factory.createPrototypeComponentRoute(
    AddProductionOrderWeavingPageComponent,
    {route: 'add/run', requiredPermission: Permission.TEXFAB_WEAVE_QUEUE, id: NavigationId.ADD_WEAVE_ORDER_RUN, ...shared},
    [],
    [canDeactivateRouteWithUnsavedChanges]
  ),
  Factory.createPrototypeComponentRoute(
    AddProductionOrderWeavingPageComponent,
    {
      route: 'add/machine/:id',
      requiredPermission: Permission.TEXFAB_WEAVE_ORDER_EDIT,
      id: NavigationId.ADD_WEAVE_ORDER_MACHINE,
      ...shared
    },
    [],
    [canDeactivateRouteWithUnsavedChanges]
  ),
  Factory.createPrototypeComponentRoute(
    AddProductionOrderWeavingPageComponent,
    {
      route: 'edit/:id',
      requiredPermission: Permission.TEXFAB_WEAVE_ORDER_VIEW,
      id: NavigationId.EDIT_WEAVE_ORDER,
      ...shared
    },
    [],
    [canDeactivateRouteWithUnsavedChanges]
  ),
  Factory.createPrototypeComponentRoute(
    AddProductionOrderWeavingPageComponent,
    {
      route: 'add/:id',
      requiredPermission: Permission.TEXFAB_WEAVE_ORDER_EDIT,
      id: NavigationId.DUPLICATE_WEAVE_ORDER,
      ...shared
    },
    [],
    [canDeactivateRouteWithUnsavedChanges]
  ),
  Factory.createPrototypeComponentRoute(
    ProductionOrderWeavingSettingsComponent,
    {
      route: 'settings',
      requiredPermission: Permission.TEXFAB_WEAVE_ORDER_VIEW,
      id: NavigationId.WEAVE_ORDER_SETTINGS,
      ...shared
    },
    [],
    [canDeactivateRouteWithUnsavedChanges]
  )
];

export const productionOrderWeavingRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(productionOrderWeavingRoutes);
