<vdw-ellipsis-label class="link-icon-cell-name" [text]="name" [tooltipDisabled]="true"></vdw-ellipsis-label>
<div class="no-flex-shrink bms-theme vertical-center flex-center">
  @if (isContentSwitcher()) {
    <button *ngIf="iconIsVisible()" mat-icon-button matPrefix (click)="navigateForward()" class="mat-icon-action-button-sm-with-border ag-icon-button" type="button">
      <mat-icon svgIcon="solid-open-in-full"></mat-icon>
    </button>
  } @else {
    <a [routerLink]="editPageUrl">
      <button *ngIf="iconIsVisible()" mat-icon-button matPrefix class="mat-icon-action-button-sm-with-border ag-icon-button" type="button">
        <mat-icon svgIcon="solid-open-in-full"></mat-icon>
      </button>
    </a>
  }
</div>
