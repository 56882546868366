import {Inject, Injectable, Injector} from '@angular/core';
import {GridIdentifier} from '@application/grids/grid-identifier.enum';
import {COLORS, Colors} from '@infrastructure/http/color/colors';
import {
  AssertionUtils,
  Color,
  ContentSwitcherDialogService,
  CrudOverviewDataBuilderFactoryService,
  CrudOverviewDataConfig,
  CrudOverviewDataPageComponent,
  GridOptionsBuilder,
  TranslateService
} from '@vdw/angular-component-library';
import {Observable} from 'rxjs';
import {ColorOverviewService} from './overview/color-overview.service';

@Injectable()
export class ColorSelectionService {
  private config: CrudOverviewDataConfig<Color>;

  public constructor(
    @Inject(COLORS) private readonly colors: Colors,
    private readonly injector: Injector,
    private readonly translate: TranslateService,
    private readonly overview: ColorOverviewService,
    private readonly contentSwitcher: ContentSwitcherDialogService,
    private readonly builderFactoryService: CrudOverviewDataBuilderFactoryService<Color>
  ) {
    this.init();
  }

  public init(): void {
    this.config = this.builderFactoryService
      .getBuilder()
      .withEntityName('TEXTILE_DATA.COLOR.COLOR')
      .withRowData(this.colors.getAll())
      .withContentSwitcher(null)
      .withGridOptions(this.overview.getColumnDefs(false), GridIdentifier.COLOR_OVERVIEW, null, (builder: GridOptionsBuilder) =>
        this.overview.getDefaultGridOptionsBuilder(builder).withRowSelection(false, false, true, false).build()
      )
      .build();
  }

  public openContentSwitcherDialog(inputValue: Color, data: any): Observable<Color[]> {
    this.contentSwitcher.parentInjector = this.injector;

    if (!AssertionUtils.isNullOrUndefined(this.contentSwitcher.activeEntry)) {
      this.contentSwitcher.activeEntry.data = data;
    }
    this.config.selectedObjects = [inputValue];

    const entry = {key: 'color', component: CrudOverviewDataPageComponent, data: null};

    this.contentSwitcher.navigateForward(entry, {config: this.config});
    return this.builderFactoryService.openContentSwitcherDialog();
  }
}
