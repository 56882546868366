import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {ConflictType} from '@domain/conflicts/conflict-type';
import {Subscription} from '@domain/profile/subscription';
import {Authentication, AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {ConflictsRepository} from '@infrastructure/http/conflicts-repository';
import {CardInfoMessage} from '@presentation/components/card-info/card-info-message.interface';
import {BaseComponent, Conflict, ConflictsDialogComponent, ConflictsDialogData, DialogBuilderFactoryService, TranslateService} from '@vdw/angular-component-library';
import {takeUntil} from 'rxjs/operators';
import {ProductConfigurationOpenInCadcamDialogComponent} from '../product-configuration-open-in-cadcam/product-configuration-open-in-cadcam-dialog.component';

@Component({
  selector: 'app-unlock-in-use',
  templateUrl: './unlock-in-use.component.html'
})
export class UnlockInUseComponent extends BaseComponent implements OnInit {
  @Input() public locked: boolean;
  @Input() public conflictsRepository: ConflictsRepository;
  @Input() public entityKey: string;
  @Input() public id: number;
  @Input() public name: string;
  @Input() public customMessage: CardInfoMessage;

  @Output() public lockedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly dialogBuilderWithAlertDialogClass = this.dialogBuilderFactoryService.getBuilder().withClass('alert-dialog');
  private currentSubscription: Subscription;

  public constructor(
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly translate: TranslateService,
    @Inject(AUTHENTICATION) public readonly authentication: Authentication
  ) {
    super();
  }

  public ngOnInit(): void {
    this.currentSubscription = this.authentication.getCurrentSubscription();
  }

  public unlock(): void {
    if (this.conflictsRepository !== undefined) {
      this.getConflicts((conflicts: Conflict[]) => {
        this.dialogBuilderWithAlertDialogClass
          .openDialog(ConflictsDialogComponent, ConflictsDialogData.createUnlockData(this.entityKey, this.name, conflicts, ConflictType, this.currentSubscription))
          .pipe(takeUntil(this.unSubscribeOnViewDestroy))
          .subscribe((unlock: boolean) => this.onUnlock(unlock));
      });
    } else {
      this.dialogBuilderWithAlertDialogClass
        .openDialog(ProductConfigurationOpenInCadcamDialogComponent, {name: this.name})
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((unlock: boolean) => this.onUnlock(unlock));
    }
  }

  public viewInUse(): void {
    this.getConflicts((conflicts: Conflict[]) => {
      this.dialogBuilderWithAlertDialogClass.openDialog(ConflictsDialogComponent, ConflictsDialogData.createInUseData(this.entityKey, this.name, conflicts, ConflictType));
    });
  }

  public getInformation(): string {
    return this.translate.instant(this.locked ? 'CONFLICTS.UNLOCK_TO' : 'CONFLICTS.UNLOCKED');
  }

  public getButton(): string {
    return this.translate.instant(this.locked ? 'CONFLICTS.UNLOCK' : 'CONFLICTS.VIEW_IN_USE');
  }

  public getMessage(): CardInfoMessage {
    return this.customMessage ?? {main: this.translate.instant('CONFLICTS.IN_USE', {object: this.translate.instant(this.entityKey, {count: 1})})};
  }

  public clickButton(): void {
    if (this.locked) {
      this.unlock();
    } else {
      this.viewInUse();
    }
  }

  private getConflicts(callback: (conflicts: Conflict[]) => void): void {
    this.conflictsRepository
      .getConflicts(this.id)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((conflicts: Conflict[]) => {
        callback(conflicts);
      });
  }

  private onUnlock(unlock: boolean): void {
    if (unlock) {
      this.locked = false;
      this.lockedChange.emit(this.locked);
    }
  }
}
