import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HeaderIdentifier} from '@application/headers/header-identifier.enum';
import {ErrorHandlers} from '@application/helper/error-handlers';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {NavigationNewItemData} from '@application/helper/navigation-helper/navigation-new-item-data.interface';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {AsyncUniqueValidator} from '@application/validators/async-unique-validator';
import {ConflictType} from '@domain/conflicts/conflict-type';
import {Customer} from '@domain/customer/customer';
import {CustomerAddressDetails} from '@domain/customer/customer-address-details';
import {CustomerContactDetails} from '@domain/customer/customer-contact-details';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {AUTHENTICATION, Authentication} from '@infrastructure/http/authentication/authentication';
import {Customers, CUSTOMERS} from '@infrastructure/http/customer/customers';
import {TextileService} from '@presentation/pages/textile-data/textile-data-overview/textile.service';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {BackendLimitsConstants} from '@shared/constants/backend-limits.constants';
import {
  BackendError,
  BaseComponent,
  canShowErrorForErrorCodeAndFormControlForFormGroup,
  Conflict,
  ConflictsDialogComponent,
  ConflictsDialogData,
  DialogBuilderFactoryService,
  DialogComponentData,
  DialogType,
  FormValidationHelper,
  RESPONSIVENESS_VIEW_MODE,
  ResponsivenessViewMode,
  SaveType,
  skeletonViewAnimation,
  TranslateService
} from '@vdw/angular-component-library';
import {isEmpty, isEqual, isNil, some} from 'lodash-es';
import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, finalize, switchMap, takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss'],
  animations: [skeletonViewAnimation('.form-field-skeleton-wrapper, .button-skeleton-wrapper, checkbox-skeleton')]
})
export class AddCustomerComponent extends BaseComponent implements OnInit {
  public addCustomerForm: FormGroup<{
    number: FormControl<number>;
    name: FormControl<string>;
    comments: FormControl<string>;
    confirm: FormControl<boolean>;
    addressDetails: FormGroup<{
      streetName: FormControl<string>;
      country: FormControl<string>;
      streetNumber: FormControl<number>;
      postalCode: FormControl<string>;
      city: FormControl<string>;
    }>;
    contactDetails: FormGroup<{
      telephone: FormControl<string>;
      fax: FormControl<string>;
      email: FormControl<string>;
    }>;
  }>;

  public saving: boolean;
  public invalidFormFieldMessage = '';
  public readonly SAVE_TYPE = SaveType;
  public readonly HEADER_IDENTIFIER = HeaderIdentifier.ADD_CUSTOMER;

  private readonly EDIT_PERMISSION = Permission.TEXFAB_CUSTOMER_EDIT;
  private urlToCustomerOverview = RouteUtils.paths.texFab.customer.absolutePath;
  private urlToEditCustomer = RouteUtils.paths.texFab.customer.editCustomer.path;
  private saveButtonTouched = false;
  private loading = false;
  private readonly labelForCustomer = 'CUSTOMERS.CUSTOMER';
  private invalidFormFieldMessageChangeSubject: Subject<string> = new Subject<string>();
  private customer: Customer;
  private currentSubscription: Subscription;

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(CUSTOMERS) private readonly customers: Customers,
    @Inject(RESPONSIVENESS_VIEW_MODE) private readonly responsivenessViewMode: ResponsivenessViewMode,
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly formBuilder: FormBuilder,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly textileService: TextileService,
    private readonly navigationHelperService: NavigationHelperService<NavigationNewItemData & DialogComponentData>
  ) {
    super();
  }

  public ngOnInit(): void {
    this.currentSubscription = this.authentication.getCurrentSubscription();
    this.initializeAddCustomerComponent();
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.onDestroy();
  }

  public canShowErrorForFormControl(errorCode: string, formControlName: string): boolean {
    return canShowErrorForErrorCodeAndFormControlForFormGroup(errorCode, formControlName, this.addCustomerForm);
  }

  public canShowInvalidFormMessageError(): boolean {
    const isFormInvalid = this.checkIfAddCustomerFormIsValidAfterTouched();
    if (!isFormInvalid) {
      this.saveButtonTouched = false;
    }
    return isFormInvalid && this.saveButtonTouched;
  }

  public saveCustomer(saveType: SaveType): void {
    this.saveButtonTouched = true;
    const isValid = new FormValidationHelper().checkForms([this.addCustomerForm], this.document);
    if (isValid) {
      this.saving = true;
      const customer: Customer = new Customer(
        this.isEditingCustomer() ? this.customer.id : null,
        this.addCustomerForm.value.number,
        this.addCustomerForm.value.name,
        new CustomerContactDetails(this.addCustomerForm.value.contactDetails.email, this.addCustomerForm.value.contactDetails.telephone, this.addCustomerForm.value.contactDetails.fax),
        new CustomerAddressDetails(
          this.addCustomerForm.value.addressDetails.streetName,
          this.addCustomerForm.value.addressDetails.country,
          this.addCustomerForm.value.addressDetails.streetNumber,
          this.addCustomerForm.value.addressDetails.postalCode,
          this.addCustomerForm.value.addressDetails.city
        ),
        this.addCustomerForm.value.comments
      );

      const request: Observable<void | number> = this.isEditingCustomer() ? this.customers.update(customer) : this.customers.save(customer);
      request.pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving())).subscribe({
        next: (id: number) => this.textileService.navigateAndShowToast(saveType, TextileDataType.CUSTOMER, this.labelForCustomer, this.isEditingCustomer(), customer.name, id),
        error: (errorMessage: BackendError) => this.showErrorDialogForBackendError(this.isEditingCustomer() ? 'GENERAL.ACTIONS.EDIT_OBJECT' : 'GENERAL.ACTIONS.CREATE_OBJECT', errorMessage.message)
      });
    }
  }

  public deleteCustomer(): void {
    this.textileService.removeConfirmation(this.customer, TextileDataType.CUSTOMER, false, null, this.customers);
  }

  public hasEditPermission(): boolean {
    return this.currentSubscription?.hasPermission(this.EDIT_PERMISSION);
  }

  public cancel(): void {
    this.navigationHelperService.navigateToPreviousRoute(this.urlToCustomerOverview);
  }

  public isCustomerBeingUsed(): boolean {
    return this.customer && this.customer.used;
  }

  public openConflictsDialog(): void {
    this.customers
      .getConflicts(this.customer.id)
      .pipe(
        switchMap((conflicts: Conflict[]) => {
          return this.dialogBuilderFactoryService
            .getBuilder()
            .withClass('alert-dialog')
            .openDialog(ConflictsDialogComponent, ConflictsDialogData.createInUseData(this.labelForCustomer, this.customer.name, conflicts, ConflictType));
        }),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe();
  }

  public isEditingCustomer(): boolean {
    return isEqual(this.activatedRoute.snapshot.routeConfig.path, this.urlToEditCustomer);
  }

  public getActionText(): string {
    const title = this.isEditingCustomer() ? 'GENERAL.ACTIONS.EDIT_OBJECT' : 'GENERAL.ACTIONS.CREATE_OBJECT';
    return this.translate.instant(title, {object: (this.translate.instant('CUSTOMERS.CUSTOMER', {count: 1}) as string).toLowerCase()});
  }

  public canShowSkeletonView(): boolean {
    return this.loading;
  }

  public isMobile(): boolean {
    return this.responsivenessViewMode.isPhone;
  }

  public canShowInvalidFormFieldMessage(): boolean {
    let result = this.isMobile();

    if (result) {
      const matErrorElement: HTMLElement = this.document.querySelector('.mat-error');

      if (!isNil(matErrorElement)) {
        this.invalidFormFieldMessageChangeSubject.next(matErrorElement.innerHTML);
      } else {
        this.invalidFormFieldMessageChangeSubject.next('');
      }

      result = !isEmpty(this.invalidFormFieldMessage);
    }

    return result;
  }

  private subscribeToInvalidFormFieldMessageChanges(): void {
    this.invalidFormFieldMessageChangeSubject.pipe(takeUntil(this.unSubscribeOnViewDestroy), debounceTime(100), distinctUntilChanged()).subscribe((invalidFormFieldMessage: string) => {
      this.invalidFormFieldMessage = invalidFormFieldMessage;
    });
  }

  private getCustomerId(): number {
    return this.isEditingCustomer() ? this.activatedRoute.snapshot.params.id : null;
  }

  private initializeAddCustomerComponent(): void {
    this.setFormFields();
    if (this.isEditingCustomer()) {
      this.loading = true;
      this.customers
        .getById(this.getCustomerId())
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe({
          next: (customer: Customer) => {
            if (!isNil(customer)) {
              this.customer = customer;
              this.setFormValues(customer);
              this.loading = false;
            }
          },
          error: ErrorHandlers.navigateToOverviewAndThrowError(this.router, this.urlToCustomerOverview)
        });
    }

    this.subscribeToInvalidFormFieldMessageChanges();
  }

  private setFormFields(): void {
    this.addCustomerForm = this.formBuilder.group({
      number: this.formBuilder.control(null, [Validators.required, Validators.max(BackendLimitsConstants.INT64_MAX)]),
      name: this.formBuilder.control(null, Validators.required),
      comments: this.formBuilder.control(null),
      confirm: this.formBuilder.control(false, Validators.requiredTrue),
      addressDetails: this.formBuilder.group({
        streetName: this.formBuilder.control(null),
        country: this.formBuilder.control(null),
        streetNumber: this.formBuilder.control(null),
        postalCode: this.formBuilder.control(null),
        city: this.formBuilder.control(null)
      }),
      contactDetails: this.formBuilder.group({
        telephone: this.formBuilder.control(null),
        fax: this.formBuilder.control(null),
        email: this.formBuilder.control(null, Validators.email)
      })
    });

    if (!this.isEditingCustomer()) {
      this.setTechnicalNumberAsyncValidator();
    }
  }

  private setTechnicalNumberAsyncValidator(number: number = null): void {
    this.addCustomerForm.controls.number.setAsyncValidators([AsyncUniqueValidator.createValidator(this.customers, number)]);
    this.addCustomerForm.controls.number.updateValueAndValidity();
  }

  private setFormValues(customer: Customer): void {
    this.addCustomerForm.reset({
      number: customer.number,
      name: customer.name,
      addressDetails: {
        streetName: customer.addressDetails.streetName,
        country: customer.addressDetails.country,
        streetNumber: customer.addressDetails.streetNumber,
        postalCode: customer.addressDetails.postalCode,
        city: customer.addressDetails.city
      },
      contactDetails: {
        telephone: customer.contactDetails.telephone,
        fax: customer.contactDetails.fax,
        email: customer.contactDetails.email
      },
      comments: customer.comments,
      confirm: true
    });
    if (this.isCustomerBeingUsed() || !this.currentSubscription?.hasPermission(this.EDIT_PERMISSION)) {
      this.addCustomerForm.disable();
    }
    this.setTechnicalNumberAsyncValidator(customer.number);
  }

  private checkIfAddCustomerFormIsValidAfterTouched(): boolean {
    return !isNil(this.addCustomerForm) ? some(this.addCustomerForm.controls, (control: FormControl) => control.invalid && control.touched) : false;
  }

  private showErrorDialogForBackendError(translationKey: string, message: string): void {
    this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
      titleText: this.translate.instant(translationKey, {
        object: this.translate.instant(this.labelForCustomer, {
          count: 1
        })
      }),
      messageText: message,
      type: DialogType.INFORMATION
    });
  }
}
