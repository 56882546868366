<section class="flex-column full-height bms-theme-content">
  <vdw-editor-header
    [identifier]="HEADER_IDENTIFIER"
    [menuItems]="[selectedMenuItem]"
    [selectedMenuItem]="selectedMenuItem"
    [isCreateNewPage]="!isEditingPlasticProductionOrder()"
    [inProgress]="saving"
    (cancelEvent)="cancel()"
    (saveEvent)="save(SAVE_TYPE.SAVE)"
    (saveAndCreateNewEvent)="save(SAVE_TYPE.SAVE_AND_CREATE_NEW)"
    (saveAndCloseEvent)="save(SAVE_TYPE.SAVE_AND_CLOSE)"
    (duplicateEvent)="duplicatePlasticProductionOrder()"
    (deleteEvent)="deletePlasticProductionOrder()"
    (settingsEvent)="openCustomSettings()"
  />

  <div class="bms-theme flex-column p-48 page-content-wrapper">
    <form [formGroup]="addPlasticProductionOrderGeneralForm" class="flex-column" vdwBp.gt-sm="pr-64 half-width" vdwBp.lt-sm="full-width" appBreadcrumbFormLinker>
      <div class="flex-row gap-16">
        <vdw-string-input
          [label]="'GENERAL.NAME' | bmsTranslate"
          [placeholder]="'GENERAL.NAME_PLACEHOLDER' | bmsTranslate: {object: 'ORDERBOOK.ORDER' | bmsTranslate: {count: 1} | lowercase}"
          formControlName="name"
          class="half-width"
        >
          <ng-container error>
            <vdw-invalid-form-message *vdwCanShowErrorForFormControl="'name'; error: 'required'" [message]="'GENERAL.ERRORS.REQUIRED' | bmsTranslate" [compact]="true"></vdw-invalid-form-message>
            <vdw-invalid-form-message
              *vdwCanShowErrorForFormControl="'name'; error: 'identifierTaken'"
              [message]="'GENERAL.ERRORS.NAME_DUPLICATE' | bmsTranslate"
              [compact]="true"
            ></vdw-invalid-form-message>
          </ng-container>
          <ng-container hint>
            <app-name-suggestion-hint
              [currentName]="currentProductionOrder?.name"
              [nameGenerationEntity]="currentProductionOrder"
              [httpRepository]="plasticProductionOrdersCustomSettings"
              (applySuggestion)="applyNameSuggestion($event)"
            />
          </ng-container>
        </vdw-string-input>
        <vdw-datepicker-form-input
          class="half-width"
          [canShowBMSTheme]="true"
          [inputLabel]="'GENERAL.DATETIME.DUE_DATE' | bmsTranslate"
          [placeholder]="'GENERAL.ACTIONS.SELECT_OBJECT' | bmsTranslate: {object: 'GENERAL.DATETIME.DATE' | bmsTranslate | lowercase}"
          [form]="addPlasticProductionOrderGeneralForm"
          formControlNameValue="dueDate"
          [minValue]="MIN_DUE_DATE"
        ></vdw-datepicker-form-input>
      </div>
      <div class="flex-row gap-16">
        <mat-form-field class="full-width">
          <mat-label>{{ 'TEXTILE_DATA.PLASTIC_PRODUCT.PRODUCT' | bmsTranslate: {count: 1} }}</mat-label>
          <vdw-object-selection
            formControlName="product"
            [objectName]="'TEXTILE_DATA.PLASTIC_PRODUCT.PRODUCT' | bmsTranslate: {count: 1} | lowercase"
            [canShowMultiSelectPlaceHolder]="false"
            [canShowBMSTheme]="true"
            [withDialogObjectSelection]="true"
            (selectClicked)="selectProduct()"
          ></vdw-object-selection>
          <mat-error *vdwCanShowErrorForFormControl="'product'; error: 'required'">
            <vdw-invalid-form-message [message]="'GENERAL.ERRORS.REQUIRED' | bmsTranslate" [compact]="true"></vdw-invalid-form-message>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>{{ 'GENERAL.QUANTITY' | bmsTranslate }}</mat-label>
          <input matInput type="number" vdwL10nDecimal [decimals]="true" min="1" formControlName="quantity" placeholder="1" />
          <button
            *ngIf="addPlasticProductionOrderGeneralForm.controls?.quantity.value !== null"
            class="clear-input-button"
            tabindex="-1"
            mat-icon-button
            type="button"
            (click)="addPlasticProductionOrderGeneralForm.controls?.quantity.reset()"
          >
            <mat-icon svgIcon="solid-close" class="icon-12"></mat-icon>
          </button>
          <span matSuffix>
            {{ getQuantitySuffixTranslationKey() | bmsTranslate | lowercase }}
          </span>
          <mat-error *vdwCanShowErrorForFormControl="'quantity'; error: 'badInput'">
            <vdw-invalid-form-message [message]="'GENERAL.ERRORS.INVALID_FORMAT.INVALID_FORMAT' | bmsTranslate" [compact]="true"></vdw-invalid-form-message>
          </mat-error>
          <mat-error *vdwCanShowErrorForFormControl="'quantity'; error: 'min'">
            <vdw-invalid-form-message [message]="'GENERAL.ERRORS.MIN_VALUE' | bmsTranslate: {min: 1}" [compact]="true"></vdw-invalid-form-message>
          </mat-error>
          <mat-error *vdwCanShowErrorForFormControl="'quantity'; error: 'required'">
            <vdw-invalid-form-message [message]="'GENERAL.ERRORS.REQUIRED' | bmsTranslate" [compact]="true"></vdw-invalid-form-message>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex-row gap-16">
        <mat-form-field class="full-width">
          <mat-label>{{ 'MACHINE.MACHINE' | bmsTranslate: {count: 1} }}</mat-label>
          <vdw-object-selection
            formControlName="machine"
            [objectName]="'MACHINE.MACHINE' | bmsTranslate: {count: 1} | lowercase"
            [canShowMultiSelectPlaceHolder]="false"
            [canShowBMSTheme]="true"
            [withDialogObjectSelection]="true"
            (selectClicked)="selectMachine()"
          ></vdw-object-selection>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>{{ 'UTILITIES.TOOL.TOOL' | bmsTranslate: {count: 1} }}</mat-label>
          <vdw-object-selection
            formControlName="tool"
            [objectName]="'UTILITIES.TOOL.TOOL' | bmsTranslate: {count: 1} | lowercase"
            [canShowMultiSelectPlaceHolder]="false"
            [canShowBMSTheme]="true"
            [withDialogObjectSelection]="true"
            (selectClicked)="selectTool()"
          ></vdw-object-selection>
        </mat-form-field>
      </div>
    </form>
  </div>
</section>
