import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {CanShowErrorForFormControlModule} from '../../custom-directives/can-show-error-for-context/can-show-error-for-form-control.module';
import {CommonFormsModule} from '../../forms/common-forms.module';
import {TranslationModule} from '../../translation/translation.module';
import {TimePickerFormInputComponent} from './time-picker-form-input.component';
import {TimePickerMenuComponent} from './time-picker-menu/time-picker-menu.component';

@NgModule({
  declarations: [TimePickerFormInputComponent, TimePickerMenuComponent],
  imports: [
    CommonModule,
    CommonFormsModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslationModule,
    CanShowErrorForFormControlModule
  ],
  exports: [TimePickerFormInputComponent, TimePickerMenuComponent]
})
export class TimePickerFormInputModule {}
