import {ChangeDetectorRef, EventEmitter, Injectable, Type} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {takeUntil} from 'rxjs';
import {BaseComponent} from '../../base-component';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {ArrowPosition} from '../../dialogs/dialog-reposition/arrow-position.enum';
import {HoverDialogBuilderService} from '../../dialogs/hover-dialog/hover-dialog-builder.service';
import {MenuItemEntry} from '../menu-tree-view/menu-item/menu-item-entry.interface';

@Injectable({providedIn: 'root'})
export class ApplicationLayoutService extends BaseComponent {
  public sidebarIcon: string;
  public resizeHovered = false;
  public matDrawer: MatDrawer;
  public hoverDialogOpened: boolean;
  public sidebarHoverButton: HTMLElement;
  public hideFavorites = true;

  public sidebarType: Type<any>;
  public navigationHeaderType: Type<any>;
  public menuItemsChanged = new EventEmitter();

  private _menuItems: MenuItemEntry[];
  private readonly HORIZONTAL_DIALOG_OFFSET = 96;

  public constructor(private readonly hoverDialogBuilderService: HoverDialogBuilderService) {
    super();
  }

  public get menuItems(): MenuItemEntry[] {
    return this._menuItems;
  }

  public toggle(): void {
    this.matDrawer?.toggle();
  }

  public isDrawerOpened(): boolean {
    return this.matDrawer?.opened ?? false;
  }

  public openHoverDialog(hoveredElement: HTMLElement, otherElements: HTMLElement[], data: any, component: Type<any>, changeDetectorRef: ChangeDetectorRef): void {
    if (this.hoverDialogOpened || this.isDrawerOpened()) {
      return;
    }

    this.hoverDialogOpened = true;
    this.sidebarIcon = 'solid-keyboard-double-arrow-right';

    this.hoverDialogBuilderService
      .getBuilderWithHoverElements(hoveredElement, otherElements)
      .withClass(['bms-theme', 'no-left-border-radius'])
      .withWidth('240px')
      .withHeight('calc(100vh - 80px)')
      .withoutBackdrop()
      .openAtElement(hoveredElement, ArrowPosition.TOP, component, data, changeDetectorRef, false, null, null, this.HORIZONTAL_DIALOG_OFFSET, 0)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => {
        this.hoverDialogOpened = false;
        this.sidebarIcon = this.isDrawerOpened() ? 'solid-keyboard-double-arrow-left' : 'solid-menu';
      });
  }

  public getFlattenedMenuItems(): MenuItemEntry[] {
    return this._menuItems.flatMap((entry: MenuItemEntry) => this.flattenMenuItems(entry));
  }

  public flattenMenuItems(menuItem: MenuItemEntry): MenuItemEntry[] {
    const menuItems = [menuItem];

    if (!AssertionUtils.isEmpty(menuItem.childEntries)) {
      menuItems.push(...menuItem.childEntries.flatMap((entry: MenuItemEntry) => this.flattenMenuItems(entry)));
    }

    return menuItems;
  }

  public getMenuItemPath(itemToFind: MenuItemEntry): MenuItemEntry[] {
    return this._menuItems.flatMap((entry: MenuItemEntry) => this.findMenuItemPath(entry, itemToFind, []));
  }

  public findParentEntry(group: MenuItemEntry): MenuItemEntry {
    return this._menuItems?.map((groupEntry: MenuItemEntry) => this.getParentOfChildEntry(groupEntry, group))?.find((groupEntry: MenuItemEntry) => !AssertionUtils.isNullOrUndefined(groupEntry));
  }

  public setMenuItems(menuItems: MenuItemEntry[]): void {
    this._menuItems = menuItems;

    this.menuItemsChanged.emit();
  }

  private findMenuItemPath(parentMenuItem: MenuItemEntry, itemToFind: MenuItemEntry, path: MenuItemEntry[]): MenuItemEntry[] {
    if (AssertionUtils.isEmpty(parentMenuItem.childEntries)) {
      return [];
    }

    if (parentMenuItem.childEntries.includes(itemToFind)) {
      return [...path, parentMenuItem];
    }

    return parentMenuItem.childEntries.flatMap((entry: MenuItemEntry) => this.findMenuItemPath(entry, itemToFind, [parentMenuItem]));
  }

  private getParentOfChildEntry(startGroup: MenuItemEntry, childGroup: MenuItemEntry): MenuItemEntry {
    if (startGroup.childEntries?.find((group: MenuItemEntry) => group.navigationKey === childGroup.navigationKey && group.route === childGroup.route)) {
      return startGroup;
    }

    return startGroup.childEntries?.map((group: MenuItemEntry) => this.getParentOfChildEntry(group, childGroup))?.find((group: MenuItemEntry) => !AssertionUtils.isNullOrUndefined(group));
  }
}
