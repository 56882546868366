import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {AssertionUtils} from '../utils/assertion-utils';

export function timeValidator(notation: 12 | 24): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (AssertionUtils.isNullOrWhitespace(control.value)) {
      return null;
    }

    const pattern = notation === 12 ? /^(1[0-2]|0?[1-9]):[0-5]\d\s?(AM|PM)$/ : /^([0-1]\d|2[0-3]):[0-5]\d$/;
    return pattern.test(control.value) ? null : {invalidTimeFormat: true};
  };
}
