import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {UnsavedChangesNotifierService} from '@application/helper/unsaved-changes-notifier/unsaved-changes-notifier.service';
import {NameGeneratorSeparator} from '@domain/name-generator/enums/name-generator-separator.enum';
import {NameGenerator} from '@domain/name-generator/name-generator';
import {getProperty, getPropertyValue, PropertyValue} from '@domain/property-value';
import {PRODUCTION_ORDERS_WEAVING, ProductionOrdersWeaving} from '@infrastructure/http/production-order-weaving/production-orders-weaving';
import {AssertionUtils, BaseComponent, EnumUtils, ObjectActionType, ToastHelperService, TranslateService} from '@vdw/angular-component-library';
import {finalize, forkJoin, takeUntil} from 'rxjs';

@Component({
  templateUrl: './production-order-weaving-settings.component.html'
})
export class ProductionOrderWeavingSettingsComponent extends BaseComponent implements OnInit {
  public nameGenerationProperties: string[];
  public generateNameConfiguration: NameGenerator;
  public productionOrderWeavingCustomSettingsForm: FormGroup<{namePlaceholderSeparator: FormControl<NameGeneratorSeparator>}>;
  public saving = false;

  private listOfCustomSettings: PropertyValue[] = [];

  public constructor(
    @Inject(PRODUCTION_ORDERS_WEAVING) private readonly productionOrdersWeaving: ProductionOrdersWeaving,
    private readonly formBuilder: FormBuilder,
    private readonly navigationHelperService: NavigationHelperService<PropertyValue[]>,
    private readonly toastHelperService: ToastHelperService,
    private readonly translate: TranslateService,
    public readonly unsavedChangesNotifierService: UnsavedChangesNotifierService,
    private readonly router: Router
  ) {
    super();
  }

  public ngOnInit(): void {
    this.productionOrderWeavingCustomSettingsForm = this.formBuilder.group({namePlaceholderSeparator: this.formBuilder.control(NameGeneratorSeparator.NO_SEPARATOR)});
    this.unsavedChangesNotifierService.notifyChanges(this.productionOrderWeavingCustomSettingsForm, this.unSubscribeOnViewDestroy);
    this.getCustomSettingsAndNameGenerationProperties();
    this.getUnsavedChangesNotifierEmitValue();
  }

  public onNavigationHelperDestroy(): void {
    this.navigationHelperService.onDestroy();
  }

  public navigateBack(navigateToSelectedRoute?: string): void {
    if (!AssertionUtils.isNullOrUndefined(navigateToSelectedRoute)) {
      this.router.navigateByUrl(navigateToSelectedRoute);
    }
    this.navigationHelperService.navigateToPreviousRoute(RouteUtils.paths.texFab.weaving.productionOrder.absolutePath);
  }

  public saveCustomSettings(navigateToSelectedRoute?: string): void {
    this.saving = true;
    this.generateNameConfiguration.version = this.generateNameConfiguration.version ?? 1;
    this.generateNameConfiguration.separator = this.productionOrderWeavingCustomSettingsForm.value.namePlaceholderSeparator;
    getProperty(this.listOfCustomSettings, 'generateNameConfiguration').propertyValue = JSON.stringify(this.generateNameConfiguration);

    this.productionOrdersWeaving
      .saveCustomSettings(this.listOfCustomSettings)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving()))
      .subscribe(() => {
        this.unsavedChangesNotifierService.notifyChangesToForm(false);
        this.toastHelperService.showToastForObjectAction(ObjectActionType.SAVE, this.translate.instant('GENERAL.CUSTOM_SETTINGS.CUSTOM_SETTINGS').toLowerCase(), null);
        this.navigateBack(navigateToSelectedRoute);
      });
  }

  public getKeysOfNameGeneratorSeparator(): string[] {
    return EnumUtils.getEnumNames(NameGeneratorSeparator);
  }

  private getUnsavedChangesNotifierEmitValue(): void {
    this.unsavedChangesNotifierService
      .getSaveEventEmitter()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((res: {action: boolean; navigateToSelectedRoute: string}) => {
        if (!AssertionUtils.isNullOrUndefined(res)) {
          this.saveCustomSettings(res.navigateToSelectedRoute);
        }
      });
  }

  private getCustomSettingsAndNameGenerationProperties(): void {
    forkJoin([this.productionOrdersWeaving.getListOfCustomSettings(), this.productionOrdersWeaving.getNameGenerationProperties()])
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(([listOfCustomSettings, nameGenerationProperties]: [PropertyValue[], string[]]) => {
        this.listOfCustomSettings = listOfCustomSettings;
        this.nameGenerationProperties = nameGenerationProperties;
        this.generateNameConfiguration = JSON.parse(getPropertyValue(this.listOfCustomSettings, 'generateNameConfiguration'));
        this.productionOrderWeavingCustomSettingsForm.controls.namePlaceholderSeparator.patchValue(this.generateNameConfiguration.separator ?? NameGeneratorSeparator.NO_SEPARATOR);
      });
  }
}
