@if (canShowColorPreview()) {
  <div [class.rule-condition-color]="isRuleCondition" [class.multiple-selection]="hasMultipleSelection" class="container flex-center">
    <div class="color-preview no-flex-shrink" [style.background-color]="color.hexadecimalColorCode"></div>

    <div class="name-wrapper">
      <vdw-ellipsis-label class="link-icon-cell-name" [text]="showRGBValue ? color.getRgbAsCommaSeparatedValue() : color.name" [tooltipDisabled]="true"></vdw-ellipsis-label>
      <div class="no-flex-shrink bms-theme">
        <a [routerLink]="editPageUrl">
          @if (enableClickableName) {
            <button mat-icon-button matPrefix class="mat-icon-action-button-sm-with-border ag-icon-button" type="button">
              <mat-icon svgIcon="solid-open-in-full"></mat-icon>
            </button>
          }
        </a>
      </div>
    </div>
  </div>
}
