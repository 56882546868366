import {ChangeDetectionStrategy, Component, signal, WritableSignal} from '@angular/core';
import {NameGeneratorProperty} from '@domain/custom-settings/name-generation-property';
import {NameGeneratorPartType} from '@domain/custom-settings/name-generator-part-type.enum';
import {CutFrom} from '@domain/name-generator/enums/cut-from.enum';
import {BaseComponent} from '@vdw/angular-component-library';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {startWith, takeUntil} from 'rxjs';
import {NameGeneratorPartForm} from '../name-generation-form-type';

@Component({
  templateUrl: './part-values-cell-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartValuesCellRendererComponent extends BaseComponent implements ICellRendererAngularComp {
  protected partForm: NameGeneratorPartForm;

  protected nameGeneratorPartTypeEnum = NameGeneratorPartType;
  protected cutFromEnum = CutFrom;

  protected selectedPropertyType: WritableSignal<NameGeneratorPartType> = signal(null);
  protected selectedCutFrom: WritableSignal<CutFrom> = signal(null);

  public agInit(params: ICellRendererParams<NameGeneratorPartForm>): void {
    this.partForm = params.data;

    this.partForm.controls.property.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy), startWith(this.partForm.controls.property.value)).subscribe((property: NameGeneratorProperty) => {
      this.selectedPropertyType.update(() => property.type);
    });
    this.partForm.controls.entityProperty.controls.cutFrom.valueChanges
      .pipe(takeUntil(this.unSubscribeOnViewDestroy), startWith(this.partForm.controls.entityProperty.controls.cutFrom.value))
      .subscribe((cutFrom: CutFrom) => {
        this.selectedCutFrom.update(() => cutFrom);
      });
  }

  public refresh(_: ICellRendererParams<NameGeneratorPartForm>): boolean {
    return false;
  }
}
