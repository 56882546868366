import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit, Optional} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {HeaderIdentifier} from '@application/headers/header-identifier.enum';
import {ErrorHandlers} from '@application/helper/error-handlers';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {NavigationId} from '@application/helper/routing/navigation-id.enum';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {AsyncUniqueValidator} from '@application/validators/async-unique-validator';
import {isMultipleOfValidator} from '@application/validators/is-multiple-of-validator';
import {IdName} from '@domain/id-name';
import {MachineType} from '@domain/machine/machine-type.enum';
import {NumberingMode} from '@domain/machine/numbering-mode.enum';
import {PlasticsMachineType} from '@domain/machine/plastics-machine-type';
import {StandardSpeed} from '@domain/machine/standard-speed.enum';
import {ToolStatus} from '@domain/machine/tool-status.enum';
import {Permission} from '@domain/profile/permission.enum';
import {PlasticProduct} from '@domain/textile-data/plastic-product/plastic-product';
import {MatchingCriteria} from '@domain/utilities/tool/matching-criteria';
import {Tool} from '@domain/utilities/tool/tool';
import {ToolType} from '@domain/utilities/tool/tool-type.enum';
import {HttpMachineTypesService} from '@infrastructure/http/machine-type/http-machine-types.service';
import {MACHINES, Machines} from '@infrastructure/http/machine/machines.interface';
import {HttpPlasticProductsService} from '@infrastructure/http/plastic-product/http-plastic-products.service';
import {HttpToolsService} from '@infrastructure/http/tool/http-tools.service';
import {MachineOverviewList} from '@presentation/pages/machine-overview/machine-overview-list';
import {TextileService} from '@presentation/pages/textile-data/textile-data-overview/textile.service';
import {TextileDataType} from '@presentation/pages/textile-data/textile-data-type.enum';
import {
  AssertionUtils,
  BackendError,
  BaseComponent,
  ContentSwitcherDialogService,
  DialogBuilderFactoryService,
  DialogType,
  EnumUtils,
  FormValidationHelper,
  PrototypeRouteUtils,
  SaveType,
  skeletonViewAnimation,
  TabsMenuItem,
  TranslateService
} from '@vdw/angular-component-library';
import {distinctUntilChanged, finalize, forkJoin, map, Observable, startWith, takeUntil} from 'rxjs';
import {ToolSelectionService} from '../tool-selection.service';
import {AddToolNavigationData} from './add-tool-navigation-data.interface';
import {AddToolViewMode} from './add-tool-view-mode.enum';
import {MatchingCriteriaData} from './matching-criteria/matching-criteria-data.interface';
import {ToolCavitiesForm, ToolForm, ToolGeneralForm, ToolSetupForm, ToolStatusForm} from './tool.form';

@Component({
  templateUrl: './add-tool-page.component.html',
  animations: [skeletonViewAnimation('.form-field-skeleton-wrapper, .mat-card-header, .button-skeleton-wrapper')]
})
export class AddToolPageComponent extends BaseComponent implements OnInit {
  public addToolForm: ToolForm;
  public toolTypes = EnumUtils.getEnumValues(ToolType);
  public speedUnits = EnumUtils.getEnumValues(StandardSpeed);
  public matchingCriteria: MatchingCriteria[] = [];
  public matchingCriteriaData: MatchingCriteriaData = {machines: [], machineTypes: []};
  public products: PlasticProduct[] = [];

  protected isDialog: boolean;
  public selectedMenuItem: TabsMenuItem = {value: AddToolViewMode.GENERAL, translationKey: 'GENERAL.GENERAL'};

  public menuItems: TabsMenuItem[] = [
    this.selectedMenuItem,
    {value: AddToolViewMode.STATUS, translationKey: 'GENERAL.STATUS'},
    {value: AddToolViewMode.CAVITIES, translationKey: 'UTILITIES.TOOL.CAVITIES.CAVITIES'},
    {value: AddToolViewMode.SETUP, translationKey: 'UTILITIES.TOOL.SETUP.SETUP'},
    {value: AddToolViewMode.MATCHING_CRITERIA, translationKey: 'UTILITIES.TOOL.MATCHING_CRITERIA.MATCHING_CRITERIA'},
    {value: AddToolViewMode.PRODUCTS, translationKey: 'UTILITIES.TOOL.PRODUCTS.PRODUCT', translationParameters: {count: 2}}
  ];

  public readonly SAVE_TYPE = SaveType;
  public readonly VIEW_MODE = AddToolViewMode;
  public readonly HEADER_IDENTIFIER = HeaderIdentifier.ADD_TOOL;

  private toolId: number;

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly tools: HttpToolsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    private readonly textileService: TextileService,
    private readonly translate: TranslateService,
    @Optional() private readonly dialog: MatDialogRef<any>,
    private readonly toolSelectionService: ToolSelectionService,
    private readonly contentSwitcher: ContentSwitcherDialogService<AddToolNavigationData>,
    private readonly navigationService: NavigationHelperService<any>,
    @Inject(MACHINES) private readonly machines: Machines,
    private readonly machineTypes: HttpMachineTypesService,
    private readonly plasticProducts: HttpPlasticProductsService
  ) {
    super();
    this.isDialog = !AssertionUtils.isNullOrUndefined(dialog?.id);
  }

  public get addToolGeneralForm(): ToolGeneralForm {
    return this.addToolForm.controls.addToolGeneralForm;
  }

  public get addToolStatusForm(): ToolStatusForm {
    return this.addToolForm.controls.addToolStatusForm;
  }

  public get addToolCavitiesForm(): ToolCavitiesForm {
    return this.addToolForm.controls.addToolCavitiesForm;
  }

  public get addToolSetupForm(): ToolSetupForm {
    return this.addToolForm.controls.addToolSetupForm;
  }

  public get addToolMatchingCriteriaForm(): ToolSetupForm {
    return this.addToolForm.controls.addToolMatchingCriteriaForm;
  }

  public ngOnInit(): void {
    this.setFormFields();

    const id =
      (PrototypeRouteUtils.isAddPath(this.contentSwitcher.activeEntry?.routeData?.route) ? null : this.contentSwitcher.getActiveEntryData()?.routeId) ?? this.activatedRoute.snapshot.params.id;
    if (!AssertionUtils.isNullOrUndefined(this.contentSwitcher.getActiveEntryData()?.tool)) {
      this.selectedMenuItem = this.contentSwitcher.getActiveEntryData().activeTab;
      this.toolId = this.contentSwitcher.getActiveEntryData().tool.id;
      this.products = this.contentSwitcher.getEntry(NavigationId.PLASTIC_PRODUCT.toString())?.data ?? this.contentSwitcher.getActiveEntryData().products;

      this.setFormValues(this.contentSwitcher.getActiveEntryData().tool, this.contentSwitcher.getEntry('stock-location')?.data);
    } else if (!AssertionUtils.isNullOrUndefined(id)) {
      forkJoin([this.tools.getById(Number(id)), this.plasticProducts.getAll()])
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe(([tool, products]: [Tool, PlasticProduct[]]) => {
          this.toolId = tool.id;
          this.matchingCriteria = tool.matchingCriteria;
          this.products = products.filter((product: PlasticProduct) => tool.productIds.includes(product.id));

          this.setFormValues(tool);
        });
    }

    forkJoin([this.machines.getAll(), this.machineTypes.getAll(MachineType.PLASTIC_MACHINE)])
      .pipe(
        map(([machines, machineTypes]: [MachineOverviewList[], PlasticsMachineType[]]) => [
          machines.filter((machine: MachineOverviewList) => machine.machineType === MachineType.PLASTIC_MACHINE),
          machineTypes
        ]),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe(([machines, machineTypes]: [MachineOverviewList[], PlasticsMachineType[]]) => (this.matchingCriteriaData = {machines, machineTypes}));
  }

  public canShowFormBasedOnViewMode(viewMode: AddToolViewMode): boolean {
    return this.selectedMenuItem.value === viewMode;
  }

  public save(saveType: SaveType): void {
    const isValid = new FormValidationHelper().checkForms(
      [this.addToolGeneralForm, this.addToolStatusForm, this.addToolCavitiesForm, this.addToolSetupForm, this.addToolMatchingCriteriaForm],
      this.document
    );

    if (isValid) {
      const toolToSave = this.getCurrentTool();
      this.saving = true;

      const request: Observable<void | number> = this.isEditingTool() ? this.tools.update(toolToSave) : this.tools.save(toolToSave);
      request.pipe(takeUntil(this.unSubscribeOnViewDestroy), finalize(this.finalizeSaving())).subscribe({
        next: (id: number) => {
          if (this.isDialog) {
            if (saveType === SaveType.SAVE_AND_CLOSE) {
              toolToSave.id = id;
              this.toolSelectionService.setSelected(toolToSave);

              this.contentSwitcher.navigateBack();
            } else if (saveType === SaveType.SAVE_AND_CREATE_NEW) {
              this.setFormFields();
              this.selectedMenuItem = this.menuItems.find((item: TabsMenuItem) => item.value === AddToolViewMode.GENERAL);
            }
            return;
          }

          this.textileService.navigateAndShowToast(saveType, TextileDataType.TOOL, 'UTILITIES.TOOL.TOOL', this.isEditingTool(), toolToSave.name, id);
        },
        error: (errorMessage: BackendError) => this.showErrorDialogForBackendError(this.isEditingTool() ? 'GENERAL.ACTIONS.EDIT_OBJECT' : 'GENERAL.ACTIONS.CREATE_OBJECT', errorMessage.message)
      });
    }
  }

  public isEditingTool(): boolean {
    if (this.isDialog) {
      return PrototypeRouteUtils.isEditPath(this.contentSwitcher.activeEntry?.routeData?.route);
    }

    return this.activatedRoute.snapshot.routeConfig?.path === RouteUtils.paths.utilities.tool.editTool.path;
  }

  public duplicateTool(): void {
    this.router.navigateByUrl(RouteUtils.paths.utilities.tool.duplicateTool.absolutePath.replace(':id', this.toolId.toString()));
  }

  public cancel(): void {
    this.isDialog ? this.dialog.close() : this.navigationService.navigateToPreviousRoute(RouteUtils.paths.utilities.tool.absolutePath);
  }

  public deleteTool(): void {
    this.textileService.removeConfirmation(this.getCurrentTool(), TextileDataType.TOOL, false, null, this.tools);
  }

  public getLoadLink(): string {
    return ErrorHandlers.getLoadLink(RouteUtils.paths.utilities.tool.editTool.absolutePath, this.addToolGeneralForm.controls.name);
  }

  public getCurrentTool(): Tool {
    return new Tool(
      this.isEditingTool() ? this.toolId : null,
      this.addToolGeneralForm.value.name,
      this.addToolGeneralForm.value.toolType,
      this.addToolGeneralForm.value.weight,
      this.addToolGeneralForm.value.speed,
      this.addToolGeneralForm.value.speedUnit,
      this.addToolGeneralForm.value.description,
      this.addToolCavitiesForm.value.cavities,
      this.addToolCavitiesForm.value.usedCavities,
      this.addToolCavitiesForm.value.numberingMode,
      this.addToolCavitiesForm.value.cavitiesAcross,
      this.addToolCavitiesForm.value.cavitiesDown,
      this.addToolCavitiesForm.value.pieceCycles,
      this.addToolSetupForm.value.waiveCycles,
      this.addToolSetupForm.value.mountTime,
      this.addToolSetupForm.value.dismountTime,
      this.addToolStatusForm.getRawValue().status,
      Array.isArray(this.addToolStatusForm.value.stockLocation) ? this.addToolStatusForm.value.stockLocation[0] : this.addToolStatusForm.value.stockLocation,
      this.matchingCriteria,
      this.products.map((product: PlasticProduct) => product.id)
    );
  }

  public getToolPermission(): Permission {
    return RouteUtils.paths.utilities.tool.editTool.requiredPermission;
  }

  private setFormFields(): void {
    const addToolGeneralForm = this.formBuilder.group({
      name: this.formBuilder.control(null, Validators.required, AsyncUniqueValidator.createValidator(this.tools, null)),
      toolType: this.formBuilder.control(null, Validators.required),
      weight: this.formBuilder.control(null, Validators.min(0)),
      speed: this.formBuilder.control(null, Validators.min(0)),
      speedUnit: this.formBuilder.control(StandardSpeed.CYCLE_TIME),
      description: this.formBuilder.control(null)
    });

    const addToolStatusForm = this.formBuilder.group({
      status: this.formBuilder.control(null, Validators.required),
      stockLocation: this.formBuilder.control(null)
    });

    const addToolCavitiesForm = this.formBuilder.group({
      cavities: this.formBuilder.control(null, [Validators.min(1)]),
      usedCavities: this.formBuilder.control(null, [Validators.min(1)]),
      numberingMode: this.formBuilder.control(NumberingMode.LEFT_RIGHT_BOTTOM_TOP),
      cavitiesAcross: this.formBuilder.control(null, [Validators.min(0)]),
      cavitiesDown: this.formBuilder.control(null, [Validators.min(0)]),
      pieceCycles: this.formBuilder.control(null, Validators.min(1))
    });

    const addToolSetupForm = this.formBuilder.group({
      waiveCycles: this.formBuilder.control(null, Validators.min(1)),
      mountTime: this.formBuilder.control(null, [Validators.min(0)]),
      dismountTime: this.formBuilder.control(null, [Validators.min(0)])
    });

    this.addToolForm = this.formBuilder.group({
      addToolGeneralForm,
      addToolStatusForm,
      addToolCavitiesForm,
      addToolSetupForm,
      addToolMatchingCriteriaForm: this.formBuilder.group({})
    });

    this.addToolStatusForm.controls.status.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy), startWith(undefined), distinctUntilChanged()).subscribe((status: ToolStatus) => {
      if (status === ToolStatus.IN_USE) {
        this.addToolStatusForm.controls.status.disable();
      } else {
        this.addToolStatusForm.controls.status.enable();
      }
    });

    this.addToolCavitiesForm.controls.pieceCycles.valueChanges.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((pieceCycles: number) => {
      if (AssertionUtils.isNullOrUndefined(pieceCycles) || this.addToolCavitiesForm.controls.pieceCycles.invalid) {
        this.addToolSetupForm.controls.waiveCycles.reset();
      } else {
        this.setWaiveCyclesValidators(pieceCycles);
      }
    });

    this.menuItems[0].form = this.addToolGeneralForm;
    this.menuItems[1].form = this.addToolStatusForm;
    this.menuItems[2].form = this.addToolCavitiesForm;
    this.menuItems[3].form = this.addToolSetupForm;
    this.menuItems[4].form = this.addToolMatchingCriteriaForm;
  }

  private setFormValues(tool: Tool, stockLocation: IdName = null): void {
    this.addToolGeneralForm.reset({
      name: this.isDuplicatingTool() ? null : tool.name,
      toolType: tool.toolType,
      weight: tool.weightInKg,
      speed: tool.speed,
      speedUnit: tool.speedUnit,
      description: tool.description
    });

    this.addToolStatusForm.reset({status: tool.status, stockLocation: stockLocation ?? tool.stockLocation});

    this.addToolCavitiesForm.reset({
      cavities: tool.cavities,
      usedCavities: tool.usedCavities,
      numberingMode: tool.numberingMode,
      cavitiesAcross: tool.cavitiesAcross,
      cavitiesDown: tool.cavitiesDown,
      pieceCycles: tool.pieceCycles
    });

    this.addToolSetupForm.reset({waiveCycles: tool.waiveCycles, mountTime: tool.mountTimeInMinutes, dismountTime: tool.dismountTimeInMinutes});

    if (this.isEditingTool()) {
      this.addToolGeneralForm.controls.name.setAsyncValidators([AsyncUniqueValidator.createValidator(this.tools, tool.name)]);
      this.addToolGeneralForm.controls.name.updateValueAndValidity();
    }

    if (!AssertionUtils.isNullOrUndefined(tool.pieceCycles)) {
      this.setWaiveCyclesValidators(tool.pieceCycles);
    }

    if (!AssertionUtils.isEmpty(tool?.name) && !this.canShowFormBasedOnViewMode(AddToolViewMode.GENERAL)) {
      this.contentSwitcher.setCreatingObjectName(tool.name);
    }
  }

  private setWaiveCyclesValidators(pieceCycles: number): void {
    this.addToolSetupForm.controls.waiveCycles.setValidators([Validators.min(1), isMultipleOfValidator(pieceCycles)]);
    this.addToolSetupForm.controls.waiveCycles.updateValueAndValidity();
  }

  private isDuplicatingTool(): boolean {
    if (AssertionUtils.isNullOrUndefined(this.activatedRoute.snapshot.routeConfig?.path)) {
      return PrototypeRouteUtils.isDuplicatePath(this.contentSwitcher.activeEntry?.routeData?.route) && this.contentSwitcher.getCachedPreviousEntry()?.routeData?.id === NavigationId.TOOL;
    }

    return this.activatedRoute.snapshot.routeConfig?.path === RouteUtils.paths.utilities.tool.duplicateTool.path;
  }

  private showErrorDialogForBackendError(translationKey: string, message: string): void {
    this.dialogBuilderFactoryService.getBuilder().openAlertDialog({
      titleText: this.translate.instant(translationKey, {object: this.translate.instant('UTILITIES.TOOL.TOOL')}),
      messageText: message,
      type: DialogType.INFORMATION
    });
  }
}
