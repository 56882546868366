import {Inject, Injectable} from '@angular/core';
import {LastModifiedCardUtils} from '@application/helper/last-modified-card-utils';
import {Colors, COLORS} from '@infrastructure/http/color/colors';
import {AssertionUtils, ColDefBuilderFactoryService, GridOptionsBuilder, NoDataOverlayComponentParams, OverlayComponentParams, TranslateService} from '@vdw/angular-component-library';
import {ColDef, ValueGetterParams} from 'ag-grid-community';
import {Observable, Subject, takeUntil} from 'rxjs';
import {GridColorPreviewComponent} from './grid-color-preview/grid-color-preview.component';

@Injectable()
export class ColorOverviewService {
  public constructor(
    @Inject(COLORS) private readonly colors: Colors,
    private readonly colDefBuilderFactoryService: ColDefBuilderFactoryService,
    private readonly translate: TranslateService
  ) {}

  public getColumnDefs(withLinkIcon: boolean = true, unSubscribeOnViewDestroy?: Subject<boolean>): ColDef[] {
    let nameColDef = this.colDefBuilderFactoryService
      .getBuilder()
      .withColIdAndField('name')
      .withHeaderNameAndClass('GENERAL.NAME', 'cursor-default')
      .withCellRenderer(GridColorPreviewComponent, {showRGBValue: false, enableClickableName: withLinkIcon, editPageUrl: LastModifiedCardUtils.getPageUrls('color').edit})
      .withTooltipField('name')
      .withFilterValueGetter((params: ValueGetterParams) => (params.data?.name ? params.data.name : ''));

    let descriptionColDef = this.colDefBuilderFactoryService.getBuilder().withColIdAndField('description', true).withHeaderName('TEXTILE_DATA.COLOR.DESCRIPTION').withSortable(false);

    if (!AssertionUtils.isNullOrUndefined(unSubscribeOnViewDestroy)) {
      nameColDef = nameColDef.withTextMultiFilter(unSubscribeOnViewDestroy ? this.getPossibleColumnsValues('name', unSubscribeOnViewDestroy) : null);
      descriptionColDef = descriptionColDef.withTextMultiFilter(this.getPossibleColumnsValues('description', unSubscribeOnViewDestroy), undefined, true);
    }

    return [
      nameColDef.build(),
      this.getColorColDef('red', unSubscribeOnViewDestroy),
      this.getColorColDef('green', unSubscribeOnViewDestroy),
      this.getColorColDef('blue', unSubscribeOnViewDestroy),
      this.colDefBuilderFactoryService.getBuilder().withColIdAndField('shade').withHeaderName('TEXTILE_DATA.COLOR.SHADE').withoutFilter().build(),
      descriptionColDef.build()
    ];
  }

  public getDefaultGridOptionsBuilder(builder: GridOptionsBuilder): GridOptionsBuilder {
    return builder
      .withLoadingOverlay({
        scale: 0.7
      } as OverlayComponentParams)
      .withNoRowsOverlay({
        scale: 0.7,
        titleParam: this.translate.instant('TEXTILE_DATA.COLOR.COLOR', {count: 1}),
        hideDescription: true
      } as NoDataOverlayComponentParams);
  }

  private getPossibleColumnsValues(columnIdentifier: string, unSubscribeOnViewDestroy: Subject<boolean>): Observable<string[]> {
    return this.colors.getPossibleValues(columnIdentifier).pipe(takeUntil(unSubscribeOnViewDestroy));
  }

  private getColorColDef(color: string, unSubscribeOnViewDestroy?: Subject<boolean>): ColDef {
    let colorColDef = this.colDefBuilderFactoryService.getBuilder().withColId(color).withField(`rgb.${color}`).withHeaderName(`TEXTILE_DATA.COLOR.${color.toUpperCase()}`);

    if (!AssertionUtils.isNullOrUndefined(unSubscribeOnViewDestroy)) {
      colorColDef = colorColDef.withNumericMultiFilter(this.getPossibleColumnsValues(color, unSubscribeOnViewDestroy));
    }

    return colorColDef.build();
  }
}
