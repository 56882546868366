<div class="connect-visual full-height flex-center">
  <div class="app-title-wrapper flex-column flex-center-start">
    <div class="app-title">Connect</div>
    <div class="baseline">
      Empowering
      <strong class="baseline-accent">your</strong>
      productivity
    </div>
  </div>
</div>
