<div fxLayout="column" fxLayoutGap="24px">
  <mat-expansion-panel
    *ngFor="let coloredYarnSetPath of productionSchedule.productionSchedulePathsOfColoredYarnSets; trackBy: trackByColoredYarnSetPathId; let coloredYarnSetIndex = index"
    class="colored-yarn-set-paths expansion-panel-as-card"
    expanded
  >
    <mat-expansion-panel-header collapsedHeight="54px" expandedHeight="54px">
      <mat-panel-title fxLayoutGap="16px">
        <h4 class="font-weight-semibold no-wrap">{{ coloredYarnSetPath.productionScheduleColoredYarnSet.coloredYarnSet.name }}</h4>
      </mat-panel-title>
      <app-color-list-preview
        [colorList]="coloredYarnSetPath.productionScheduleColoredYarnSet.coloredYarnSet"
        [showDetailsOnClick]="false"
        [maximumCreelPositionsToShow]="coloredYarnSetPath.productionScheduleColoredYarnSet.coloredYarnSet.overviewListColorSet.creelPositions.length"
        (click)="showColoredYarnSetDetails($event, coloredYarnSetIndex)"
        [creelMapping]="productionSchedule.mappingForCurrentPositionOnMachine ? this.productionSchedule.mappingForCurrentPositionOnMachine[coloredYarnSetIndex] : null"
      ></app-color-list-preview>
    </mat-expansion-panel-header>
    <article class="content" fxLayout="column" fxLayoutGap="32px">
      <div fxLayout="column" fxLayoutGap="16px">
        <h3 class="font-weight-semibold">{{ 'GENERAL.SUMMARY' | bmsTranslate }}</h3>
        <div fxLayout="row">
          <div class="colored-yarn-set-path-container" fxLayout="column" fxFlex="0 1 100%" fxFlex.gt-md="0 1 50%" fxFlex.gt-lg="0 1 40%">
            <ag-grid-angular
              class="ag-theme-vdw"
              [gridOptions]="gridOptionsForProductionScheduleColoredYarnSets[coloredYarnSetIndex]"
              [rowData]="coloredYarnSetPath.productionSchedulePaths"
              domLayout="autoHeight"
            ></ag-grid-angular>
          </div>
        </div>
      </div>
      <div fxLayout="row" *ngIf="productionSchedule.isCreelMappingApplied()">
        <div class="creel-mapping-container" fxLayout="column" fxLayoutGap="16px" fxFlex="0 1 100%" fxFlex.gt-md="0 1 50%" fxFlex.gt-lg="0 1 40%">
          <div fxLayout="row">
            <h3 fxFlex class="font-weight-semibold">{{ 'MACHINE.DETAILS.CREEL_MAPPING.TITLE' | bmsTranslate }}</h3>
            <div fxFlex fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="end">
              <mat-label>{{ 'TEXTILE_DATA.COLOR.COLOR' | bmsTranslate: {count: 2} }}</mat-label>
              <mat-slide-toggle [checked]="!creelMappingTableComponent.isOfPreviewTypeColor()" (change)="creelMappingTableComponent.togglePreviewType($event.checked)"></mat-slide-toggle>
              <mat-label>{{ 'TEXTILE_DATA.YARN_TYPE.YARN_TYPE' | bmsTranslate: {count: 2} }}</mat-label>
            </div>
          </div>
          <app-creel-mapping-table
            #creelMappingTableComponent
            [coloredYarnSetWithStartDent]="coloredYarnSetPath.productionScheduleColoredYarnSet"
            [activeColoredYarnSetCreelMap]="getColoredYarnSetCreelMap(coloredYarnSetPath.productionScheduleColoredYarnSet.coloredYarnSet.id)"
            [readOnly]="true"
          ></app-creel-mapping-table>
        </div>
      </div>
    </article>
  </mat-expansion-panel>
</div>
