import {CreelPositionForArticle} from '@domain/article/creel-position-for-article';
import {FinishingForArticle} from '@domain/article/finishing-for-article';
import {MachineQualityForArticle} from '@domain/article/machine-quality-for-article';
import {BmpDrawing} from '@domain/production-schedule/bmp-drawing';
import {Drawing} from '@domain/production-schedule/drawing';
import {ColorSet} from '@domain/textile-data/color-set/color-set';
import {OverviewListColoredYarnSet} from '@presentation/pages/textile-data/colored-yarn-set/overview/overview-list-colored-yarn-set';
import {AssertionUtils, convertCommercialUnit, Unit} from '@vdw/angular-component-library';

export class OverviewListArticleForOrderLine {
  private _id: number;
  private _name: string;
  private _machineQuality: MachineQualityForArticle;
  private _coloredYarnSet: OverviewListColoredYarnSet;
  private _colorSet: ColorSet;
  private _mainColor: CreelPositionForArticle;
  private _borderColor: CreelPositionForArticle;
  private _finishing: FinishingForArticle;
  private _design: Drawing;
  private _commercialWidthInMM: number;
  private _commercialHeightInMM: number;
  private _technicalHeightInPicks: number;
  private _technicalWidthInDents: number;

  public constructor(
    id: number,
    name: string,
    machineQuality: MachineQualityForArticle,
    coloredYarnSet: OverviewListColoredYarnSet,
    colorSet: ColorSet,
    mainColor: CreelPositionForArticle,
    borderColor: CreelPositionForArticle,
    finishing: FinishingForArticle,
    commercialWidthInMM: number,
    commercialHeightInMM: number,
    design?: Drawing,
    technicalHeightInPicks?: number,
    technicalWidthInDents?: number
  ) {
    this._id = id;
    this._name = name;
    this._machineQuality = machineQuality;
    this._coloredYarnSet = coloredYarnSet;
    this._colorSet = colorSet;
    this._mainColor = mainColor;
    this._borderColor = borderColor;
    this._finishing = finishing;
    this._design = design;
    this._commercialWidthInMM = commercialWidthInMM;
    this._commercialHeightInMM = commercialHeightInMM;
    this._technicalHeightInPicks = technicalHeightInPicks;
    this._technicalWidthInDents = technicalWidthInDents;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get machineQuality(): MachineQualityForArticle {
    return this._machineQuality;
  }

  public get coloredYarnSet(): OverviewListColoredYarnSet {
    return this._coloredYarnSet;
  }

  public get colorSet(): ColorSet {
    return this._colorSet;
  }

  public get mainColor(): CreelPositionForArticle {
    return this._mainColor;
  }

  public get borderColor(): CreelPositionForArticle {
    return this._borderColor;
  }

  public get finishing(): FinishingForArticle {
    return this._finishing;
  }

  public set finishing(value: FinishingForArticle) {
    this._finishing = value;
  }

  public get design(): Drawing {
    return this._design;
  }

  public set design(design: Drawing) {
    this._design = design;
  }

  public get commercialWidthInMM(): number {
    return this._commercialWidthInMM;
  }

  public get commercialHeightInMM(): number {
    return this._commercialHeightInMM;
  }

  public get technicalHeightInPicks(): number {
    return this._technicalHeightInPicks;
  }

  public get technicalWidthInDents(): number {
    return this._technicalWidthInDents;
  }

  public static fromJSON(overviewListArticleForOrderLineJSON: any): OverviewListArticleForOrderLine {
    return new OverviewListArticleForOrderLine(
      overviewListArticleForOrderLineJSON.id,
      overviewListArticleForOrderLineJSON.name,
      MachineQualityForArticle.fromJSON(overviewListArticleForOrderLineJSON.machineQuality),
      OverviewListColoredYarnSet.fromJSON(overviewListArticleForOrderLineJSON.coloredYarnSet),
      ColorSet.fromJSON(overviewListArticleForOrderLineJSON.colorSet),
      AssertionUtils.isNullOrUndefined(overviewListArticleForOrderLineJSON.mainColor) ? null : CreelPositionForArticle.fromJSON(overviewListArticleForOrderLineJSON.mainColor),
      AssertionUtils.isNullOrUndefined(overviewListArticleForOrderLineJSON.borderColor) ? null : CreelPositionForArticle.fromJSON(overviewListArticleForOrderLineJSON.borderColor),
      AssertionUtils.isNullOrUndefined(overviewListArticleForOrderLineJSON.finishing) ? null : FinishingForArticle.fromJSON(overviewListArticleForOrderLineJSON.finishing),
      overviewListArticleForOrderLineJSON.commercialWidthInMM,
      overviewListArticleForOrderLineJSON.commercialHeightInMM,
      BmpDrawing.fromJSON(overviewListArticleForOrderLineJSON.design),
      overviewListArticleForOrderLineJSON.technicalHeightInPicks,
      overviewListArticleForOrderLineJSON.technicalWidthInDents
    );
  }

  public getCommercialWidthInCommercialUnit(commercialUnit: Unit): number {
    return convertCommercialUnit({from: {unit: Unit.MILLIMETER, value: this.commercialWidthInMM}, to: commercialUnit});
  }
}
