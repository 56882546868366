import {AfterViewInit, Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {UntypedFormControl, ValidatorFn, Validators} from '@angular/forms';
import {MatFormField} from '@angular/material/form-field';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {map, takeUntil} from 'rxjs/operators';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {TimePickerMenuComponent} from '../../custom-components/time-picker-form-input/time-picker-menu/time-picker-menu.component';
import {TranslateService} from '../../translation/translate.service';
import {BaseComponent} from './../../base-component';
import {Unit} from './../../common/unit.enum';
import {CustomCellEditorParams} from './custom-cell-editor-params.interface';
import {ErrorDetails} from './error-details';
import {Validation} from './validation';

@Component({
  templateUrl: './custom-cell-editor.component.html',
  styleUrls: ['./custom-cell-editor.component.scss']
})
export class CustomCellEditorComponent extends BaseComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('inputElement') public inputElement: any;
  @ViewChild(MatFormField, {read: ElementRef}) public formField: ElementRef;
  @ViewChild(TimePickerMenuComponent, {read: ElementRef}) public timePickerMenu: ElementRef;

  public min: number;
  public max: number;
  public unit: Unit | string;
  public notation: 12 | 24;
  public formControl: UntypedFormControl;
  public isNumber: boolean;
  public autoSelect: boolean = false;
  public allowDecimals: boolean = false;
  public maximumFractionDigits: number;
  public errorDetails: ErrorDetails[];
  public canShowErrorMessage: boolean = false;

  private isRequired: boolean;
  private params: any;
  private defaultErrorDetails: ErrorDetails[];
  private focusableElements: HTMLElement[];
  private readonly POPUP_EDITOR_BORDER_WIDTH = 1.5;
  private readonly POPUP_EDITOR_BORDER_HEIGHT = 2;

  public constructor(
    private readonly el: ElementRef,
    private readonly translate: TranslateService
  ) {
    super();
  }

  @HostListener('keydown', ['$event']) public onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Tab' && this.params.colDef.cellEditorPopup) {
      const index = Array.prototype.indexOf.call(this.focusableElements, event.target);
      const nextElementIndex = index + (event.shiftKey ? -1 : 1);

      if (nextElementIndex >= 0 && nextElementIndex < this.focusableElements.length) {
        event.stopPropagation();
      }
    }
  }

  public agInit(params: CustomCellEditorParams): void {
    this.min = params.min;
    this.max = params.max;
    this.unit = params.unit;
    this.isNumber = params.isNumber ?? true;
    this.notation = params.notation;
    this.isRequired = params.isRequired ?? false;
    this.autoSelect = params.autoSelect;
    this.allowDecimals = params.allowDecimals;
    this.maximumFractionDigits = params.maximumFractionDigits;
    this.params = params;

    this.setValidations(params);
    this.setErrorMessages(params);

    this.subscribeToStatusChanges();
  }

  public ngAfterViewInit(): void {
    this.focusableElements = this.el.nativeElement.querySelectorAll('button:not([tabindex="-1"]), input');
    this.setPopupStyle();
  }

  public hasUnit(): boolean {
    return !!this.unit;
  }

  public stopClickEvent(event: PointerEvent): void {
    event.stopPropagation();
  }

  public getValue(): number {
    return this.formControl.value;
  }

  public preventDefaultOnMouseDown(event: MouseEvent): void {
    event.preventDefault();
  }

  public onModelChange(): void {
    this.params.api.dispatchEvent({type: 'cellValueChanged', event: {}});
  }

  public reset(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.formControl.reset();
  }

  public canShowClearInputButton(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.formControl.value) && this.params.cellStartedEdit;
  }

  public getErrorMessage(): string {
    return this.errorDetails.find((error: ErrorDetails) => error.errorKey === Object.keys(this.formControl.errors)[0]).errorMessage;
  }

  private subscribeToStatusChanges(): void {
    this.formControl.statusChanges
      .pipe(
        takeUntil(this.unSubscribeOnViewDestroy),
        map((valid: string) => valid === 'VALID')
      )
      .subscribe((valid: boolean) => {
        this.canShowErrorMessage = !valid;

        if (valid) {
          this.params.eGridCell?.classList?.remove('invalid');
        } else {
          if (AssertionUtils.isNullOrUndefined(this.formControl.value) && this.formControl.hasError('badInput')) {
            delete this.formControl.errors['badInput'];
            this.formControl.updateValueAndValidity({emitEvent: false});
          } else {
            this.params.eGridCell?.classList?.add('invalid');
          }
        }
      });
  }

  private setValidations(params: CustomCellEditorParams): void {
    let validations: ValidatorFn[] = [];
    let value = params.value;

    if (this.isRequired) {
      validations.push(Validators.required);
    }

    if (this.isNumber) {
      validations.push(Validators.min(params.min));
      validations.push(Validators.max(params.max));
    }

    if (params.validations?.length > 0) {
      validations = [...validations, ...params.validations.map((validation: Validation) => validation.validation)];
    }

    this.formControl = new UntypedFormControl(value, validations);
    this.formControl.markAsTouched();
    this.canShowErrorMessage = this.formControl.invalid;
  }

  private setErrorMessages(params: CustomCellEditorParams): void {
    this.defaultErrorDetails = [
      new ErrorDetails('required', 'ANGULAR_COMPONENT_LIBRARY.CUSTOM_CELL_EDITOR.REQUIRED'),
      new ErrorDetails('min', this.translate.instant('ANGULAR_COMPONENT_LIBRARY.CUSTOM_CELL_EDITOR.MIN_VALUE', {min: params.min})),
      new ErrorDetails('max', this.translate.instant('ANGULAR_COMPONENT_LIBRARY.CUSTOM_CELL_EDITOR.MAX_VALUE', {max: params.max})),
      new ErrorDetails('badInput', 'GENERAL.ERRORS.INVALID_FORMAT.INVALID_FORMAT')
    ];

    this.errorDetails = this.defaultErrorDetails;

    if (params.validations?.length > 0) {
      this.errorDetails = [...this.errorDetails, ...params.validations.map((validation: Validation) => new ErrorDetails(validation.errorKey, validation.errorMessage))];
    }
  }

  private setPopupStyle(): void {
    if (this.params.colDef.cellEditorPopup) {
      const cellRect = this.params.eGridCell.getBoundingClientRect();
      this.formField.nativeElement.parentElement.classList.add('bms-theme');
      this.formField.nativeElement.style.width = `${cellRect.width - this.POPUP_EDITOR_BORDER_WIDTH}px`;
      this.formField.nativeElement.style.height = `${cellRect.height - this.POPUP_EDITOR_BORDER_HEIGHT}px`;
      this.timePickerMenu.nativeElement.style.top = `${cellRect.height}px`;
    }
  }
}
