import {CommercialDimensions} from '@domain/commercial-dimensions';
import {CutbarsLength} from '@domain/production-schedule/cutbars-length';
import {Drawing} from '@domain/production-schedule/drawing';
import {DrawingType} from '@domain/production-schedule/drawing-type.enum';
import {ProductionScheduleOrderLineWithQuantity} from '@presentation/pages/texfab/production-schedule/add/drawing-library/views/order-lines-view/production-schedule-order-line-with-quantity';
import {AssertionUtils, UuidUtils} from '@vdw/angular-component-library';
import {isNil} from 'lodash-es';
import {TechnicalDimensions} from '../technical-dimensions';
import {MachineQualityForProductionSchedule} from './machine-quality-for-production-schedule';
import {ProductionScheduleItemInPathGroupStatus} from './production-schedule-item-in-path-group-status';
import {ProductionScheduleItemRecolorInformation} from './production-schedule-item-recolor-information';

export abstract class ProductionScheduleItemInPathGroup {
  private _id: number;
  private _drawing: Drawing;
  private _quantity: number;
  private _commercialDimensionsInMM: CommercialDimensions;
  private _technicalDimensions: TechnicalDimensions;
  private _isProductionItem: boolean;
  private _mainColorPosition: number;
  private _borderColorPosition: number;
  private _uuid: string;
  private _isRecolored = false;
  private _orderLineId: number;
  private _amountProduced: number;
  private _status: ProductionScheduleItemInPathGroupStatus;
  private _epId: number;
  private _totalWovenHeightInPicks: number;
  private _totalWovenHeightInMM: number;
  private _amountIncomplete: number;
  private _machineQuality: MachineQualityForProductionSchedule;
  private _scheduleItemType: DrawingType;
  private _orderline: ProductionScheduleOrderLineWithQuantity;

  protected constructor(
    drawing: Drawing,
    quantity: number,
    commercialDimensionsInMM: CommercialDimensions,
    technicalDimensions: TechnicalDimensions,
    isProductionItem: boolean,
    mainColorPosition: number,
    borderColorPosition: number,
    uuid: string,
    amountProduced: number,
    status: ProductionScheduleItemInPathGroupStatus,
    scheduleItemType: DrawingType,
    orderLineId: number = null,
    id: number = null,
    epId: number = null,
    totalWovenHeightInPicks: number = null,
    totalWovenHeightInMM: number = null,
    amountIncomplete: number = null,
    machineQuality: MachineQualityForProductionSchedule = null
  ) {
    this._drawing = drawing;
    this._quantity = quantity;
    this._commercialDimensionsInMM = commercialDimensionsInMM;
    this._technicalDimensions = technicalDimensions;
    this._isProductionItem = isProductionItem;
    this._mainColorPosition = mainColorPosition;
    this._borderColorPosition = borderColorPosition;
    this._orderLineId = orderLineId;
    this._amountProduced = amountProduced;
    this._status = status;
    this._id = id;
    this._epId = epId;
    this._totalWovenHeightInPicks = totalWovenHeightInPicks;
    this._totalWovenHeightInMM = totalWovenHeightInMM;
    this._amountIncomplete = amountIncomplete;
    this._machineQuality = machineQuality;
    this._scheduleItemType = scheduleItemType;

    if (!isNil(uuid)) {
      this._uuid = uuid;
    } else {
      this.createUuid();
    }
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get drawing(): Drawing {
    return this._drawing;
  }

  public set drawing(value: Drawing) {
    this._drawing = value;
  }

  public get scheduleItemType(): DrawingType {
    return this._scheduleItemType;
  }

  public set scheduleItemType(value: DrawingType) {
    this._scheduleItemType = value;
  }

  public get quantity(): number {
    return this._quantity;
  }

  public set quantity(quantity: number) {
    this._quantity = quantity;
  }

  public get commercialDimensionsInMM(): CommercialDimensions {
    return this._commercialDimensionsInMM;
  }

  public set commercialDimensionsInMM(value: CommercialDimensions) {
    this._commercialDimensionsInMM = value;
  }

  public get technicalDimensions(): TechnicalDimensions {
    return this._technicalDimensions;
  }

  public set technicalDimensions(value: TechnicalDimensions) {
    this._technicalDimensions = value;
  }

  public get isProductionItem(): boolean {
    return this._isProductionItem;
  }

  public set isProductionItem(isProductionItem: boolean) {
    this._isProductionItem = isProductionItem;
  }

  public get mainColorPosition(): number {
    return this._mainColorPosition;
  }

  public set mainColorPosition(value: number) {
    this._mainColorPosition = value;
  }

  public get borderColorPosition(): number {
    return this._borderColorPosition;
  }

  public set borderColorPosition(value: number) {
    this._borderColorPosition = value;
  }

  public get uuid(): string {
    return this._uuid;
  }

  public get isRecolored(): boolean {
    return this._isRecolored;
  }

  public set isRecolored(value: boolean) {
    this._isRecolored = value;
  }

  public get orderLineId(): number {
    return this._orderLineId;
  }

  public set orderLineId(value: number) {
    this._orderLineId = value;
  }

  public set amountProduced(value: number) {
    this._amountProduced = value;
  }

  public get amountProduced(): number {
    return this._amountProduced;
  }

  public get status(): ProductionScheduleItemInPathGroupStatus {
    return this._status;
  }

  public set status(value: ProductionScheduleItemInPathGroupStatus) {
    this._status = value;
  }

  public get epId(): number {
    return this._epId;
  }

  public get totalWovenHeightInPicks(): number {
    return this._totalWovenHeightInPicks;
  }

  public get totalWovenHeightInMM(): number {
    return this._totalWovenHeightInMM;
  }

  public get amountIncomplete(): number {
    return this._amountIncomplete;
  }

  public get machineQuality(): MachineQualityForProductionSchedule {
    return this._machineQuality;
  }

  public set machineQuality(value: MachineQualityForProductionSchedule) {
    this._machineQuality = value;
  }

  public get orderline(): ProductionScheduleOrderLineWithQuantity {
    return this._orderline;
  }

  public set orderline(value: ProductionScheduleOrderLineWithQuantity) {
    this._orderline = value;
  }

  public adjustQuantityOfDrawingsInPathGroup(value: number): void {
    this._quantity = value;
  }

  public createUuid(): void {
    this._uuid = UuidUtils.generateV4Uuid();
  }

  public adjustUuid(value: string): void {
    this._uuid = value;
  }

  public hasOrderLine(): boolean {
    return !AssertionUtils.isNullOrUndefined(this._orderLineId);
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      design: this._drawing.toFullJSON(),
      quantity: this._quantity,
      production: this._isProductionItem,
      mainColorPosition: this._mainColorPosition,
      borderColorPosition: this._borderColorPosition,
      commercialDimensions: {
        heightInMM: !AssertionUtils.isNullOrUndefined(this._commercialDimensionsInMM) ? this._commercialDimensionsInMM.heightInMM : null,
        widthInMM: !AssertionUtils.isNullOrUndefined(this._commercialDimensionsInMM) ? this._commercialDimensionsInMM.widthInMM : null
      },
      technicalDimensions: {
        heightInPicks: !AssertionUtils.isNullOrUndefined(this._technicalDimensions) ? this._technicalDimensions.heightInPicks : null,
        widthInDents: !AssertionUtils.isNullOrUndefined(this._technicalDimensions) ? this._technicalDimensions.widthInDents : null
      },
      dimensionsInPx: {
        heightInPx: this._drawing.dimensionsInPixels.heightInPx,
        widthInPx: this._drawing.dimensionsInPixels.widthInPx
      },
      status: ProductionScheduleItemInPathGroupStatus[this._status],
      orderLineId: this._orderLineId,
      amountProduced: this._amountProduced,
      machineQuality: !AssertionUtils.isNullOrUndefined(this._machineQuality) ? this._machineQuality.toJSON() : null,
      scheduleItemType: this._scheduleItemType
    } as any as JSON;
  }

  public abstract getCommercialCutbarsLengthInMM(): number;

  public abstract getTechnicalCutbarsLengthInNumberOfPicks(): number;

  public abstract getCutbarInformation(): CutbarsLength;

  public abstract canBeRecolored(): boolean;

  public abstract recolorDrawing(recolorInformation: Drawing | ProductionScheduleItemRecolorInformation): void;
}
