import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot} from '@angular/router';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {UnsavedChangesNotifierService} from '@application/helper/unsaved-changes-notifier/unsaved-changes-notifier.service';
import {AlertDialogResult, DialogBuilderFactoryService, DialogType} from '@vdw/angular-component-library';
import {Observable, Observer} from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

export const canDeactivateRouteWithUnsavedChanges: CanDeactivateFn<CanComponentDeactivate> = (
  component: CanComponentDeactivate,
  activatedRoute: ActivatedRouteSnapshot,
  currentState: RouterStateSnapshot,
  nextState: RouterStateSnapshot
): Observable<boolean> => {
  const dialogBuilderFactoryService = inject(DialogBuilderFactoryService);
  const unsavedChangesNotifierService = inject(UnsavedChangesNotifierService);
  const navigationHelperService = inject(NavigationHelperService);
  const currentRouteLeaf = navigationHelperService.getCurrentRouteLeaf(currentState.url);

  return new Observable((observer: Observer<boolean>) => {
    if (unsavedChangesNotifierService.hasUnsavedFormChanges() && !navigationHelperService.isNextRouteRelatedToCurrent(currentRouteLeaf, nextState.url)) {
      dialogBuilderFactoryService
        .getBuilder()
        .openAlertDialog({
          type: DialogType.DISCARD_AND_SAVE
        })
        .subscribe((result: AlertDialogResult) => {
          if (result === AlertDialogResult.DISCARD) {
            unsavedChangesNotifierService.notifyChangesToForm(false);
            observer.next(true);
          } else if (result === AlertDialogResult.SAVE) {
            observer.next(false);
            unsavedChangesNotifierService.setSaveEventEmitter(true, nextState.url);
            navigationHelperService.onDestroy();
          } else {
            observer.next(false);
            navigationHelperService.onDestroy();
          }
          observer.complete();
        });
    } else {
      observer.next(true);
      observer.complete();
    }
  });
};
