import {getDisplayValueForPlaceholderSeparator} from '@application/helper/get-display-value-for-placeholder-separator';
import {NameGeneratorPart} from '@domain/custom-settings/name-generator-part';
import {NameGeneratorPartType} from '@domain/custom-settings/name-generator-part-type.enum';
import {CalculatedValuePart} from '@domain/custom-settings/name-generator-parts/calculated-value-part';
import {CustomTextPart} from '@domain/custom-settings/name-generator-parts/custom-text-part';
import {EntityPropertyPart} from '@domain/custom-settings/name-generator-parts/entity-property-part';
import {SerialNumberPart} from '@domain/custom-settings/name-generator-parts/serial-number-part';
import {CutFrom} from '@domain/name-generator/enums/cut-from.enum';
import {NameGeneratorSeparator} from '@domain/name-generator/enums/name-generator-separator.enum';
import {NameGenerationProperty} from '@domain/name-generator/name-generation-property';
import {TranslateService} from '@vdw/angular-component-library';

export function getPreviewText(nameGeneratorParts: NameGeneratorPart[], separator: NameGeneratorSeparator, translate: TranslateService): string {
  let nameGenerationPatternPreviewText: string[] = [];
  const separatorSymbol = getDisplayValueForPlaceholderSeparator(NameGeneratorSeparator[separator]);

  nameGeneratorParts?.forEach((part: NameGeneratorPart) => {
    let previewText = '';
    let previewTextsInBracket: (string | number)[];

    switch (part.type) {
      case NameGeneratorPartType.ALPHABETIC_SERIAL_NUMBER:
      case NameGeneratorPartType.NUMERIC_SERIAL_NUMBER:
        const serialNumberPart = part as SerialNumberPart;
        previewText = translate.instant(NameGenerationProperty.getTranslationKey(serialNumberPart.type)).split(' ')[0];
        previewTextsInBracket = [serialNumberPart.maxLength ?? ''];
        if (serialNumberPart.startValue) {
          previewTextsInBracket.push(serialNumberPart.startValue);
        }
        if (serialNumberPart.stepSize) {
          previewTextsInBracket.push(serialNumberPart.stepSize);
        }
        break;
      case NameGeneratorPartType.PROPERTY_VALUE:
        const entityPropertyPart = part as EntityPropertyPart;
        previewText = translate.instant(NameGenerationProperty.getTranslationKey(entityPropertyPart.name), {count: 1}).replace(/\s/g, '');
        previewTextsInBracket = [translate.instant(`GENERAL.PLACEHOLDER.PARAMETERS.CUT_FROM.${entityPropertyPart.cutFromDirection}`)];
        if (entityPropertyPart.cutFromDirection !== CutFrom.NONE) {
          previewTextsInBracket.push(entityPropertyPart.cutStartCharacter ?? '', entityPropertyPart.cutLength ?? '');
        }
        break;
      case NameGeneratorPartType.CUSTOM_TEXT:
        const customTextPart = part as CustomTextPart;
        previewText = customTextPart.customText ?? '';
        break;
      case NameGeneratorPartType.CALCULATED_VALUE:
        const calculatedValuePart = part as CalculatedValuePart;
        previewText = translate.instant(NameGenerationProperty.getTranslationKey(calculatedValuePart.name), {count: 1}).replace(/\s/g, '');
        break;
    }

    nameGenerationPatternPreviewText.push(previewText + (previewTextsInBracket?.length ? `(${previewTextsInBracket.join(',')})` : ''));
  });

  return nameGenerationPatternPreviewText.join(separatorSymbol);
}
