import {Component, ElementRef, HostListener, Input, OnInit, Optional} from '@angular/core';
import {PlanningItemHelper} from '@application/helper/planning-prototype/planning-item-helper';
import {GenericOrder} from '@domain/planning-prototype/generic-order.interface';
import {ProductionOrderToPlan} from '@domain/planning-prototype/to-plan/production-order-to-plan';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {RepositionTooltipComponent} from '@presentation/components/reposition-tooltip/reposition-tooltip.component';
import {AssertionUtils, BaseComponent, DOMUtils, DialogBuilder, DialogBuilderFactoryService, PlanningGroupComponent} from '@vdw/angular-component-library';
import {takeUntil} from 'rxjs';
import {PlanningOrderTooltipComponent} from '../../tooltips/planning-order-tooltip.component';

@Component({
  selector: 'app-planning-order',
  templateUrl: './planning-order.component.html',
  styleUrls: ['./planning-order.component.scss']
})
export class PlanningOrderComponent extends BaseComponent implements OnInit {
  @Input() public order: GenericOrder | ProductionOrderToPlan;

  public shouldShowSmallInfo = false;
  private readonly SMALL_ORDER_THRESHOLD = 72;
  private dialogBuilder: DialogBuilder = null;

  public constructor(
    private readonly element: ElementRef<HTMLElement>,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService,
    @Optional() private readonly planningGroup: PlanningGroupComponent
  ) {
    super();
  }

  public ngOnInit(): void {
    DOMUtils.observeResize(this.element.nativeElement)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((entries: ResizeObserverEntry[]) => {
        this.shouldShowSmallInfo = entries[0].contentRect.width < this.SMALL_ORDER_THRESHOLD;
      });
  }

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    if (this.shouldShowSmallInfo && this.dialogBuilder === null) {
      this.openDetailsTooltip();
    }
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    if (this.dialogBuilder !== null) {
      this.closeDetailsTooltip();
    }
  }

  public getIconNameForProductionOrderStatus(): string {
    return ProductionScheduleStatus.getStatusIconName(this.getProductionOrderStatus());
  }

  public getProductionOrderStatus(): ProductionScheduleStatus {
    if (!AssertionUtils.isNullOrUndefined(this.order.productionOrder)) {
      return this.order.productionOrder.status;
    }
    if (this.order instanceof ProductionOrderToPlan) {
      return null;
    }
    return PlanningItemHelper.getItemStatus(this.order);
  }

  public getItemName(): string {
    if (this.order instanceof ProductionOrderToPlan) {
      return this.order.productionOrder.name;
    }
    return PlanningItemHelper.getItemName(this.order);
  }

  private openDetailsTooltip(): void {
    if (AssertionUtils.isNullOrUndefined(this.planningGroup)) {
      return;
    }
    this.dialogBuilder = this.dialogBuilderFactoryService.getBuilder();

    this.dialogBuilder
      .withClass('reposition-tooltip')
      .withMaxWidth('408px')
      .withoutBackdrop()
      .openDialog(RepositionTooltipComponent, {
        forceArrowCentered: true,
        component: PlanningOrderTooltipComponent,
        targetDOMRect: this.element.nativeElement.getBoundingClientRect(),
        planningOrder: this.order,
        runTitle: this.planningGroup.groupText
      })
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe();
  }

  private closeDetailsTooltip(): void {
    this.dialogBuilder?.close();
    this.dialogBuilder = null;
  }
}
