export enum HeaderIdentifier {
  ADD_COLOR = 'addColor',
  ADD_QUALITY = 'addQuality',
  ADD_YARN = 'addYarn',
  ADD_FINISHING_TEMPLATE = 'addFinishingTemplate',
  ADD_WEAVE_PRODUCT = 'addWeaveProduct',
  ADD_PRODUCTION_SCHEDULE = 'addProductionSchedule',
  ADD_PRODUCTION_ORDER_WEAVING = 'addProductionOrderWeaving',
  ADD_TUFT_PRODUCTION_ORDER = 'addTuftProductionOrder',
  ADD_CREEL = 'addCreel',
  ADD_COLOR_SET = 'addColorSet',
  ADD_YARN_SET = 'addYarnSet',
  ADD_TUFT_PRODUCT = 'addTuftProduct',
  ADD_COLORED_YARN = 'addColoredYarn',
  ADD_COLORED_YARN_SET = 'addColoredYarnSet',
  ADD_PATH_LAYOUT_TEMPLATE = 'addPathLayoutTemplate',
  ADD_MANAGE_WEAVE_STRUCTURE = 'addManageWeaveStructure',
  ADD_WEAVE_STRUCTURE = 'addWeaveStructure',
  ADD_WEAVE_STRUCTURE_PATTERN = 'addWeaveStructurePattern',
  ADD_WEFT_COLORED_YARN_SET = 'addWeftColoredYarnSet',
  ADD_CUSTOMER = 'addCustomer',
  ADD_ARTICLE = 'addArticle',
  ADD_SALES_ORDER = 'addSalesOrder',
  ADD_DATA_UNIT_SETUP = 'addDataUnitSetup',
  ADD_DATA_UNIT_SETUP_CATALOG = 'addDataUnitSetupCatalog',
  ADD_MACHINE_DATA_SOURCE = 'addMachineDataSource',
  ADD_OPERATOR = 'addOperator',
  ADD_OPERATOR_TYPE = 'addOperatorType',
  ADD_RESOURCE_TYPE = 'addResourceType',
  ADD_PHYSICAL_QUANTITY = 'addPhysicalQuantity',
  SEND_WINDER_VIBRATION_FORM = 'sendWinderVibrationForm',
  ADD_EVENT = 'addEvent',
  ADD_ALERT = 'addAlert',
  ADD_TEMPLATE = 'addTemplate',
  ADD_REPORT_ITEM = 'addReportItem',
  EDIT_MACHINE = 'editMachine',
  ADD_WINDER_MACHINE_TYPE = 'addWinderMachineType',
  ADD_DRAWING = 'addDrawing',
  ADD_FINISHING = 'addFinishing',
  ADD_WEAVING_MACHINE_TYPE = 'addWeavingMachineType',
  ADD_DYEING_MACHINE_TYPE = 'addDyeingMachineType',
  ADD_TUFTING_MACHINE_TYPE = 'addTuftingMachineType',
  ADD_PLASTIC_PRODUCT = 'addPlasticProduct',
  ADD_TOOL = 'addTool',
  ADD_PRODUCT_CONFIGURATION_ORDER = 'addProductConfigurationOrder',
  ADD_PLASTIC_PRODUCTION_ORDER = 'addPlasticProductionOrder',
  ADD_REJECT_REASON = 'addRejectReason',
  ADD_FIXED_SCHEDULE = 'addFixedSchedule',
  ADD_BUGGY = 'addBuggy',
  ADD_SHIFT = 'addShift',
  ADD_STOCK_LOCATION = 'addStockLocation'
}
