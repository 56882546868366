import {NameGeneratorPart} from '../name-generator-part';
import {NameGeneratorPartType} from '../name-generator-part-type.enum';
import {CalculatedValuePart} from './calculated-value-part';
import {CustomTextPart} from './custom-text-part';
import {EntityPropertyPart} from './entity-property-part';
import {SerialNumberPart} from './serial-number-part';

export function nameGeneratorPartFromJSON(json: any): NameGeneratorPart {
  const type: NameGeneratorPartType = NameGeneratorPartType[json.type];

  switch (type) {
    case NameGeneratorPartType.ALPHABETIC_SERIAL_NUMBER:
    case NameGeneratorPartType.NUMERIC_SERIAL_NUMBER:
      return new SerialNumberPart(type, json.maxLength, json.startValue, json.stepSize);
    case NameGeneratorPartType.PROPERTY_VALUE:
      return new EntityPropertyPart(json.name, json.cutFromDirection, json.cutStartCharacter, json.cutLength);
    case NameGeneratorPartType.CUSTOM_TEXT:
      return new CustomTextPart(json.customText);
    case NameGeneratorPartType.CALCULATED_VALUE:
      return new CalculatedValuePart(json.name);
    default:
      return null;
  }
}
