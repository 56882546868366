import {Injectable} from '@angular/core';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {ApplicationLayoutService, AssertionUtils, BaseComponent, MenuItemEntry, TranslateService} from '@vdw/angular-component-library';
import {cloneDeep} from 'lodash-es';
import {BehaviorSubject, Subject, takeUntil} from 'rxjs';

@Injectable({providedIn: 'root'})
export class NavigationBreadcrumbsService extends BaseComponent {
  public onNameChangedSubject: Subject<string> = new Subject<string>();
  public isUsingBreadcrumbFormLinkerSubject = new BehaviorSubject<boolean>(false);

  public menuItems: MenuItemEntry[] = [];

  public constructor(
    private readonly translate: TranslateService,
    public readonly applicationLayoutService: ApplicationLayoutService
  ) {
    super();

    this.menuItems = this.getAllMenuItemsDetails();
    this.applicationLayoutService.menuItemsChanged.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(() => (this.menuItems = this.getAllMenuItemsDetails()));
  }

  private getAllMenuItemsDetails(): MenuItemEntry[] {
    const defaultMenuItems = cloneDeep(this.applicationLayoutService.menuItems?.filter((menuItem: MenuItemEntry) => menuItem.navigationKey !== 'GENERAL.NAVIGATION.FAVOURITES') ?? []);

    this.setMachineQualityChildEntries(defaultMenuItems);
    this.setMachineChildEntries(defaultMenuItems);

    return [
      ...defaultMenuItems,
      {navigationKey: this.translate.instant('PROFILE.PROFILE'), route: RouteUtils.paths.profile.absolutePath},
      {
        navigationKey: this.translate.instant('SETTINGS.MENU_NAME'),
        route: RouteUtils.paths.settings.absolutePath,
        childEntries: [
          {
            navigationKey: this.translate.instant('BMSCONFIG.AUTOMATIC_STOP_GROUPS.CARD_TITLE'),
            route: RouteUtils.paths.settings.automaticStopGroup.absolutePath
          },
          {
            navigationKey: this.translate.instant('BMSCONFIG.DEVICE_TEMPLATES.CARD_TITLE'),
            route: RouteUtils.paths.settings.deviceTemplate.absolutePath,
            childEntries: [
              {
                navigationKey: this.translate.instant('BMSCONFIG.DEVICE_TEMPLATES.COUNTERS.COUNTERS', {count: 2}),
                route: RouteUtils.paths.settings.deviceTemplate.addCounter.absolutePath.replace('/add', '')
              },
              {
                navigationKey: this.translate.instant('BMSCONFIG.DEVICE_TEMPLATES.CONFIGURATION.CONFIGURATIONS', {count: 1}),
                route: RouteUtils.paths.settings.deviceTemplate.addConfiguration.absolutePath.replace('/add', '')
              }
            ]
          },
          {
            navigationKey: this.translate.instant('BMSCONFIG.DEVICE_TEMPLATES_CATALOG.DEVICE_TEMPLATES_CATALOG'),
            route: RouteUtils.paths.settings.deviceTemplateCatalog.absolutePath,
            childEntries: [
              {
                navigationKey: this.translate.instant('BMSCONFIG.DEVICE_TEMPLATES.COUNTERS.COUNTERS', {count: 2}),
                route: RouteUtils.paths.settings.deviceTemplateCatalog.addCounterCatalog.absolutePath.replace('/add', '')
              },
              {
                navigationKey: this.translate.instant('BMSCONFIG.DEVICE_TEMPLATES.CONFIGURATION.CONFIGURATIONS', {count: 1}),
                route: RouteUtils.paths.settings.deviceTemplateCatalog.addConfigurationCatalog.absolutePath.replace('/add', '')
              }
            ]
          },
          {
            navigationKey: this.translate.instant('BMSCONFIG.PHYSICAL_QUANTITIES.CARD_TITLE'),
            route: RouteUtils.paths.settings.physicalQuantity.absolutePath
          },
          {
            navigationKey: this.translate.instant('BMSCONFIG.DECLARATIONS.CARD_TITLE'),
            route: RouteUtils.paths.settings.declaration.absolutePath
          },
          {
            navigationKey: this.translate.instant('BMSCONFIG.REJECT_REASON.REJECT_REASON', {count: 2}),
            route: RouteUtils.paths.settings.rejectReason.absolutePath
          },
          {
            navigationKey: this.translate.instant('BMSCONFIG.REPORT_ITEMS.CARD_TITLE'),
            route: RouteUtils.paths.settings.reportItem.absolutePath
          },
          {
            navigationKey: this.translate.instant('BMSCONFIG.OPERATORS.CARD_TITLE'),
            route: RouteUtils.paths.settings.operator.absolutePath,
            childEntries: [
              {
                navigationKey: this.translate.instant('MACHINE.OPERATOR_TYPES.OPERATOR_TYPE', {count: 1}),
                route: RouteUtils.paths.settings.operator.addOperatorType.absolutePath.replace('/add', '')
              }
            ]
          },
          {
            navigationKey: this.translate.instant('BMSCONFIG.RESOURCE_TYPES.CARD_TITLE'),
            route: RouteUtils.paths.settings.resourceType.absolutePath
          },
          {
            navigationKey: this.translate.instant('BMSCONFIG.DATA_UNIT_SETUP.CARD_TITLE'),
            route: RouteUtils.paths.settings.dataUnitSetup.absolutePath
          },
          {
            navigationKey: this.translate.instant('BMSCONFIG.DATA_UNIT_SETUP_CATALOG.CARD_TITLE'),
            route: RouteUtils.paths.settings.dataUnitSetupCatalog.absolutePath
          },
          {
            navigationKey: this.translate.instant('BMSCONFIG.MACHINE_DATA_SOURCES.CARD_TITLE'),
            route: RouteUtils.paths.settings.machineDataSources.absolutePath
          },
          {
            navigationKey: this.translate.instant('BMSCONFIG.EVENTS.CARD_TITLE'),
            route: RouteUtils.paths.settings.event.absolutePath,
            childEntries: [{navigationKey: this.translate.instant('BMSCONFIG.ALERTS.ALERT', {count: 2}), route: RouteUtils.paths.settings.alert.absolutePath.replace('/add', '')}]
          }
        ]
      },
      {navigationKey: this.translate.instant('ONBOARDING.NAVIGATION.HELP.TITLE'), route: RouteUtils.paths.help.absolutePath}
    ];
  }

  public onNameChanged(name: string): void {
    this.onNameChangedSubject.next(name);
  }

  public updateBreadcrumbFormLinkerUsed(isUsingBreadcrumbFormLinker: boolean): void {
    this.isUsingBreadcrumbFormLinkerSubject.next(isUsingBreadcrumbFormLinker);
  }

  public findChildEntryByPath(menuItems: MenuItemEntry[], path: string, currentActiveMenuItems: MenuItemEntry[] = []): MenuItemEntry[] {
    let result: MenuItemEntry[] = null;

    menuItems?.forEach((menuItem: MenuItemEntry) => {
      const activeMenuItems = [...currentActiveMenuItems, menuItem];

      if (menuItem.route === path) {
        result = activeMenuItems;
      }

      if (!result && menuItem.childEntries) {
        result = this.findChildEntryByPath(menuItem.childEntries, path, activeMenuItems);
      }
    });

    return result;
  }

  private setMachineQualityChildEntries(menuItems: MenuItemEntry[]): void {
    const childEntries = [
      {
        navigationKey: this.translate.instant('TEXTILE_DATA.FINISHING.FINISHING', {count: 1}),
        route: RouteUtils.paths.texStyle.machineQuality.addFinishing.absolutePath.replace('/add', '')
      }
    ];
    const machineQuality = this.findChildEntryByPath(menuItems, RouteUtils.paths.texStyle.machineQuality.absolutePath);

    if (!AssertionUtils.isEmpty(machineQuality)) {
      machineQuality[machineQuality.length - 1].childEntries = childEntries;
    }
  }

  private setMachineChildEntries(menuItems: MenuItemEntry[]): void {
    const childEntries = [
      {
        navigationKey: this.translate.instant('MACHINE.DETAILS.MACHINE_TYPES.WEAVING'),
        route: RouteUtils.paths.texState.addWeavingMachineType.absolutePath.replace('/add', '')
      },
      {
        navigationKey: this.translate.instant('MACHINE.DETAILS.MACHINE_TYPES.PLASTIC'),
        route: RouteUtils.paths.texState.addPlasticMachineType.absolutePath.replace('/add', '')
      },
      {
        navigationKey: this.translate.instant('MACHINE.DETAILS.MACHINE_TYPES.TUFTING'),
        route: RouteUtils.paths.texState.addTuftingMachineType.absolutePath.replace('/add', '')
      },
      {
        navigationKey: this.translate.instant('MACHINE.DETAILS.MACHINE_TYPES.WINDER'),
        route: RouteUtils.paths.texState.addWinderMachineType.absolutePath.replace('/add', '')
      },
      {
        navigationKey: this.translate.instant('MACHINE.DETAILS.MACHINE_TYPES.DYEING'),
        route: RouteUtils.paths.texState.addDyeingMachineType.absolutePath.replace('/add', '')
      },
      {
        navigationKey: this.translate.instant('SETTINGS.MENU_NAME'),
        route: RouteUtils.paths.texState.settings.absolutePath,
        childEntries: [
          {
            navigationKey: this.translate.instant('MACHINE.SETTINGS.REPORT_TEMPLATE'),
            route: RouteUtils.paths.texState.addTemplate.absolutePath.replace('/add', '')
          }
        ]
      }
    ];
    const machine = this.findChildEntryByPath(menuItems, RouteUtils.paths.texState.absolutePath);

    if (!AssertionUtils.isEmpty(machine)) {
      machine[machine.length - 1].childEntries = childEntries;
    }
  }
}
