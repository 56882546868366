import {WeaveStructureForMachineQuality} from '@domain/textile-data/weave-structure/weave-structure-for-machine-quality';

export class WeaveStructureForProductionSchedule {
  private readonly _id: number;
  private readonly _name: string;
  private readonly _version: number;
  private _picksPerColorLine: number;
  private readonly _dentRepeat: number;
  private _sendRepeat: number;
  private _numberOfCloths: number;
  private readonly _numberOfGroundYarns: number;
  private readonly _numberOfCreelPositions: number;
  private readonly _numberOfSolenoidsPerPile: number;

  public constructor(
    id: number,
    name: string,
    version: number,
    picksPerColorLine: number,
    dentRepeat: number,
    sendRepeat: number,
    numberOfCloths: number,
    numberOfGroundYarns: number,
    numberOfCreelPositions?: number,
    numberOfSolenoidsPerPile?: number
  ) {
    this._id = id;
    this._name = name;
    this._version = version;
    this._picksPerColorLine = picksPerColorLine;
    this._dentRepeat = dentRepeat;
    this._sendRepeat = sendRepeat;
    this._numberOfCloths = numberOfCloths;
    this._numberOfGroundYarns = numberOfGroundYarns;
    this._numberOfCreelPositions = numberOfCreelPositions;
    this._numberOfSolenoidsPerPile = numberOfSolenoidsPerPile;
  }

  public get id(): number {
    return this._id;
  }

  public get name(): string {
    return this._name;
  }

  public get version(): number {
    return this._version;
  }

  public get picksPerColorLine(): number {
    return this._picksPerColorLine;
  }

  public set picksPerColorLine(value: number) {
    this._picksPerColorLine = value;
  }

  public get dentRepeat(): number {
    return this._dentRepeat;
  }

  public get sendRepeat(): number {
    return this._sendRepeat;
  }

  public set sendRepeat(value: number) {
    this._sendRepeat = value;
  }

  public get numberOfCloths(): number {
    return this._numberOfCloths;
  }

  public set numberOfCloths(value: number) {
    this._numberOfCloths = value;
  }

  public get numberOfGroundYarns(): number {
    return this._numberOfGroundYarns;
  }

  public get numberOfCreelPositions(): number {
    return this._numberOfCreelPositions;
  }

  public get numberOfSolenoidsPerPile(): number {
    return this._numberOfSolenoidsPerPile;
  }

  public static fromJSON(weaveStructureJSON: any): WeaveStructureForProductionSchedule {
    return new WeaveStructureForProductionSchedule(
      weaveStructureJSON.id,
      weaveStructureJSON.name,
      weaveStructureJSON.version,
      weaveStructureJSON.picksPerColorLine,
      weaveStructureJSON.dentRepeat,
      weaveStructureJSON.sendRepeat,
      weaveStructureJSON.numberOfCloths,
      weaveStructureJSON.numberOfGroundYarns,
      weaveStructureJSON.numberOfCreelPositions,
      weaveStructureJSON.nrSolenoidsPerPile
    );
  }

  public static fromWeaveStructure(weaveStructure: WeaveStructureForMachineQuality): WeaveStructureForProductionSchedule {
    return new WeaveStructureForProductionSchedule(
      weaveStructure.id,
      weaveStructure.name,
      weaveStructure.version,
      weaveStructure.picksPerColorLine,
      weaveStructure.dentRepeat,
      weaveStructure.sendRepeat,
      weaveStructure.numberOfCloths,
      weaveStructure.numberOfGroundYarns
    );
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name,
      version: this._version,
      picksPerColorLine: this._picksPerColorLine,
      dentRepeat: this._dentRepeat,
      sendRepeat: this._sendRepeat,
      numberOfCloths: this._numberOfCloths,
      numberOfGroundYarns: this._numberOfGroundYarns
    } as any as JSON;
  }
}
