import {Inject, Injectable} from '@angular/core';
import {FinishingForProductionSchedule} from '@domain/production-schedule/finishing-for-production-schedule';
import {ProductionSchedule} from '@domain/production-schedule/production-schedule';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {PropertyValue} from '@domain/property-value';
import {Finishings, FINISHINGS} from '@infrastructure/http/finishing/finishings';
import {PRODUCTION_SCHEDULES, ProductionSchedules} from '@infrastructure/http/production-schedule/production-schedules';
import {ConfirmProductionScheduleDesktopComponent} from '@presentation/pages/texfab/production-schedule/confirm/confirm-desktop/confirm-production-schedule-desktop.component';
import {ConfirmProductionScheduleOnDesktopResult} from '@presentation/pages/texfab/production-schedule/confirm/confirm-production-schedule-on-desktop-result';
import {ConflictedOrderLine} from '@presentation/pages/texfab/production-schedule/confirm/conflicted-order-line';
import {ConflictWithOrderlinesDialogResult} from '@presentation/pages/texfab/production-schedule/confirm/conflicts-with-order-lines/conflict-with-orderlines-dialog-result';
import {ConflictsWithOrderLinesComponent} from '@presentation/pages/texfab/production-schedule/confirm/conflicts-with-order-lines/conflicts-with-order-lines.component';
import {OverviewListFinishing} from '@presentation/pages/textile-data/finishing/overview/overview-list-finishing';
import {BackendError, DialogBuilderFactoryService, UnhandledBackendError, Unit} from '@vdw/angular-component-library';
import {isEmpty, isEqual, isNil} from 'lodash-es';
import {EMPTY, Observable, Subject} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class ConfirmProductionScheduleOnDesktopCommand {
  private confirmProductionScheduleOnDesktopSubject: Subject<ConfirmProductionScheduleOnDesktopResult>;

  public constructor(
    @Inject(FINISHINGS) private readonly finishings: Finishings,
    @Inject(PRODUCTION_SCHEDULES) private readonly productionSchedules: ProductionSchedules,
    private readonly dialogBuilderFactoryService: DialogBuilderFactoryService
  ) {}

  public execute(productionSchedule: ProductionSchedule, listOfProductionOrdersCustomSettings: PropertyValue[]): Observable<ConfirmProductionScheduleOnDesktopResult> {
    this.confirmProductionScheduleOnDesktopSubject = new Subject<ConfirmProductionScheduleOnDesktopResult>();

    if (isEqual(productionSchedule.status, ProductionScheduleStatus.TO_RECONFIRM)) {
      this.confirmProductionSchedule(productionSchedule, listOfProductionOrdersCustomSettings);
    } else {
      this.checkPossibleConflictsWithOrderLines(productionSchedule, listOfProductionOrdersCustomSettings);
    }

    return this.confirmProductionScheduleOnDesktopSubject.asObservable();
  }

  public getAndConfirmProductionSchedule(productionScheduleId: string | number, listOfProductionOrdersCustomSettings: PropertyValue[]): Observable<ConfirmProductionScheduleOnDesktopResult> {
    return this.productionSchedules.getById(productionScheduleId).pipe(
      switchMap((productionSchedule: ProductionSchedule) => {
        return !isNil(productionSchedule) ? this.execute(productionSchedule, listOfProductionOrdersCustomSettings) : EMPTY;
      })
    );
  }

  private checkPossibleConflictsWithOrderLines(productionSchedule: ProductionSchedule, listOfProductionOrdersCustomSettings: PropertyValue[]): void {
    this.productionSchedules.checkPossibleConflictsWithOrderLines(productionSchedule.id as string).subscribe({
      next: (conflictedOrderLines: ConflictedOrderLine[]) => {
        if (isEmpty(conflictedOrderLines)) {
          this.confirmProductionSchedule(productionSchedule, listOfProductionOrdersCustomSettings);
        } else {
          this.showConflictsDialog(productionSchedule, conflictedOrderLines, listOfProductionOrdersCustomSettings);
        }
      },
      error: this.handleUnhandledBackendError()
    });
  }

  private confirmProductionSchedule(productionSchedule: ProductionSchedule, listOfProductionOrdersCustomSettings: PropertyValue[]): void {
    if (!isNil(productionSchedule.finishing)) {
      this.finishings.getOverviewListFinishingById(productionSchedule.finishing.id).subscribe({
        next: (overviewListFinishing: OverviewListFinishing) => {
          productionSchedule.updateFinishing(FinishingForProductionSchedule.fromOverviewListFinishing(overviewListFinishing));
          this.showConfirmDialog(productionSchedule, listOfProductionOrdersCustomSettings);
        },
        error: this.handleUnhandledBackendError()
      });
    } else {
      this.showConfirmDialog(productionSchedule, listOfProductionOrdersCustomSettings);
    }
  }

  private showConflictsDialog(productionSchedule: ProductionSchedule, conflictedOrderLines: ConflictedOrderLine[], listOfProductionOrdersCustomSettings: PropertyValue[]): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .withCloseDisabled()
      .openSelectGridDialog(ConflictsWithOrderLinesComponent, {conflictedOrderLines})
      .subscribe((result: ConflictWithOrderlinesDialogResult) => {
        if (isNil(result)) {
          this.confirmProductionScheduleOnDesktopSubject.next(ConfirmProductionScheduleOnDesktopResult.CANCELLED);
          this.confirmProductionScheduleOnDesktopSubject.complete();
        } else {
          if (isEqual(result, ConflictWithOrderlinesDialogResult.DISMISS)) {
            this.confirmProductionSchedule(productionSchedule, listOfProductionOrdersCustomSettings);
          } else if (isEqual(result, ConflictWithOrderlinesDialogResult.ADJUST_ORDER_PLAN)) {
            this.confirmProductionScheduleOnDesktopSubject.next(ConfirmProductionScheduleOnDesktopResult.PLAN_ADJUSTMENT_NEEDED);
            this.confirmProductionScheduleOnDesktopSubject.complete();
          }
        }
      });
  }

  private showConfirmDialog(productionSchedule: ProductionSchedule, listOfProductionOrdersCustomSettings: PropertyValue[]): void {
    this.dialogBuilderFactoryService
      .getBuilder()
      .withClass('confirm-production-schedule-dialog')
      .withCloseDisabled()
      .openDialog(ConfirmProductionScheduleDesktopComponent, {
        productionSchedule,
        commercialUnit: Unit.CENTIMETER,
        listOfProductionOrdersCustomSettings
      })
      .subscribe((result: ProductionSchedule) => {
        if (isNil(result)) {
          this.confirmProductionScheduleOnDesktopSubject.next(ConfirmProductionScheduleOnDesktopResult.CANCELLED);
        } else {
          this.confirmProductionScheduleOnDesktopSubject.next(ConfirmProductionScheduleOnDesktopResult.CONFIRMED);
        }
        this.confirmProductionScheduleOnDesktopSubject.complete();
      });
  }

  private handleUnhandledBackendError(): (error: BackendError) => void {
    return (error: UnhandledBackendError): void => {
      this.confirmProductionScheduleOnDesktopSubject.error(error);
      this.confirmProductionScheduleOnDesktopSubject.complete();
    };
  }
}
