import {ColorPatternVisualisation} from '@domain/color-pattern-visualisation';
import {DrawingPatternVisualisation} from '@domain/drawing-pattern-visualisation';
import {ImagePatternVisualisation} from '@domain/image-pattern-visualisation';
import {SvgManchonVisualisation} from '@domain/svg-manchon-visualisation';
import {SvgPatternVisualisation} from '@domain/svg-pattern-visualisation';
import {ColorRGBRepresentation} from '@vdw/angular-component-library';
import {PatternVisualisationType} from './pattern-visualisation-type.enum';

export abstract class PatternVisualisation {
  private _type: PatternVisualisationType;

  protected constructor(type: PatternVisualisationType) {
    this._type = type;
  }

  public get type(): PatternVisualisationType {
    return this._type;
  }

  public hasVisualisationData(): boolean {
    return !!PatternVisualisation.getVisualisationRepresentation(this);
  }

  public isWeavePatternVisualisation(): boolean {
    return this.type === PatternVisualisationType.COLOR || this.type === PatternVisualisationType.PATTERN || this.type === PatternVisualisationType.MANCHON;
  }

  private static getVisualisationRepresentation(patternVisualisation: PatternVisualisation): ColorRGBRepresentation | string {
    if (patternVisualisation.type === PatternVisualisationType.COLOR) {
      return (patternVisualisation as ColorPatternVisualisation).rgb;
    } else if (patternVisualisation.type === PatternVisualisationType.DRAWING) {
      return (patternVisualisation as DrawingPatternVisualisation).image;
    } else if (patternVisualisation.type === PatternVisualisationType.IMAGE) {
      return (patternVisualisation as ImagePatternVisualisation).image;
    } else if (patternVisualisation.type === PatternVisualisationType.PATTERN) {
      return (patternVisualisation as SvgPatternVisualisation).svg;
    } else if (patternVisualisation.type === PatternVisualisationType.MANCHON) {
      return (patternVisualisation as SvgManchonVisualisation).svg;
    } else {
      throw new Error('Pattern visualisation type not recognized');
    }
  }

  public abstract toJSON(): JSON;
}
