import {AssertionUtils} from '@vdw/angular-component-library';
import {NameGeneratorProperty} from './name-generation-property';
import {NameGeneratorPartType} from './name-generator-part-type.enum';

export namespace NameGeneratorPropertyTranslate {
  const MACHINE_NAME_TRANSLATION_KEY = 'MACHINE.MACHINE';
  const ENTITY_PROPERTY_TRANSLATION_KEY_MAP = new Map<string, string>([
    ['WeaveStructureName', 'TEXTILE_DATA.WEAVE_STRUCTURE.WEAVE_STRUCTURE'],
    ['ReedDensityInDentsPerMM', 'TEXTILE_DATA.MACHINE_QUALITY.REEDDENSITY'],
    ['WeftDensityInDentsPerMM', 'TEXTILE_DATA.MACHINE_QUALITY.WEFTDENSITY'],
    ['TechnicalCode', 'GENERAL.TECHNICAL_CODE'],
    ['Type', 'GENERAL.TYPE'],
    ['YarnSetName', 'TEXTILE_DATA.YARN_SET.YARN_SET'],
    ['Rgb.Red', 'TEXTILE_DATA.COLOR.RED'],
    ['Rgb.Green', 'TEXTILE_DATA.COLOR.GREEN'],
    ['Rgb.Blue', 'TEXTILE_DATA.COLOR.BLUE'],
    ['YarnCount', 'TEXTILE_DATA.YARN_TYPE.YARN_COUNT'],
    ['YarnCountUnit', 'GENERAL.UNIT.UNIT'],
    ['Threads.RawMaterial', 'TEXTILE_DATA.YARN_TYPE.RAW_MATERIAL'],
    ['AmountOfCreelPositions', 'TEXTILE_DATA.MATERIAL_SET.NUMBER_OF_CREEL_POSITIONS'],
    ['ColorSetsWithStartDents.Name', 'TEXTILE_DATA.COLOR_SET.COLOR_SET'],
    ['NrPositions', 'TEXTILE_DATA.MATERIAL_SET.NUMBER_OF_CREEL_POSITIONS'],
    ['Equipment.Name', MACHINE_NAME_TRANSLATION_KEY],
    ['Quality.Name', 'TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY'],
    ['FinishingType', 'TEXTILE_DATA.FINISHING_TEMPLATE.FINISHINGTYPE'],
    ['FinishingTemplateName', 'TEXTILE_DATA.FINISHING_TEMPLATE.FINISHING_TEMPLATE'],
    ['CutbarsInformation.CutbarBeforeProperties.CommercialLengthInMM', 'TEXTILE_DATA.FINISHING_TEMPLATE.CUTBAR.CUTBAR_BEFORE'],
    ['CutbarsInformation.CutbarAfterProperties.CommercialLengthInMM', 'TEXTILE_DATA.FINISHING_TEMPLATE.CUTBAR.CUTBAR_AFTER'],
    ['LoomType', 'PATH_LAYOUT_TEMPLATE.LOOM_GROUP'],
    ['PathLayoutTemplatePaths.WidthInMM', 'TEXTILE_DATA.MACHINE_QUALITY.OPTIONAL_SETTINGS.PATH_WIDTHS'],
    ['TotalWidthInMM', 'PATH_LAYOUT_TEMPLATE.TOTAL_WIDTH'],
    ['Machine.Name', MACHINE_NAME_TRANSLATION_KEY],
    ['MachineQuality.TechnicalName', 'TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY'],
    ['Creel.Name', 'TEXTILE_DATA.CREEL.CREEL'],
    ['Buggy.Name', 'PLANNING.BUGGY.BUGGY'],
    ['NumberOfYarns', 'TEXTILE_DATA.YARN_SET.NUMBER_OF_YARNS'],
    ['OrderId', 'MACHINE.DETAILS.ORDER_ID'],
    ['NumberOfCreelPositions', 'MACHINE.DETAILS.NR_CREEL_POSITIONS'],
    ['DentsForFabric', 'MACHINE.DETAILS.NR_DENTS'],
    ['MachineName', MACHINE_NAME_TRANSLATION_KEY],
    ['WeaveProductName', 'TEXTILE_DATA.WEAVE_PRODUCT.WEAVE_PRODUCT'],
    ['TuftProduct.Name', 'TEXTILE_DATA.TUFT_PRODUCT.TUFT_PRODUCT'],
    ['CreelName', 'TEXTILE_DATA.CREEL.ENTITY'],
    ['MachineQualityName', 'TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY']
  ]);

  export function getKey(property: NameGeneratorProperty): string {
    if (AssertionUtils.isNullOrUndefined(property.name)) {
      return `GENERAL.PLACEHOLDER.${NameGeneratorPartType[property.type]}`;
    }
    return ENTITY_PROPERTY_TRANSLATION_KEY_MAP.get(property.name);
  }
}
